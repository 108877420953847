import React from 'react'
import './index.scss'

const AuthPopup = () => (
    <div className="auth-popup-container center-flex">
        <div className="auth-popup-message center-flex f-column">
            <img src={require("../../../assets/Waywize-Spin.png")} className="fuelsave-logo" alt="FuelSave" />
            <div>
                Your session has been logged out. Please refresh the window or <div className="reload-act-btn" onClick={() => window.location.reload()}>Click Here</div>
            </div>
        </div>
    </div>
)

export default AuthPopup