import { combineReducers } from 'redux'
import global from './global'
import projects from './project'
import alignments from './alignment'
import presumptions from './presumption'
import settings from './setting'

const rootReducer = combineReducers({
  global,
  projects,
  alignments,
  presumptions,
  settings
})

export default rootReducer
