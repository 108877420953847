import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { DropdownButton, Dropdown, Container, NavDropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import FuelSaveGlobalContext from '../../context/FuelSaveGlobalContext'
import Icon from './Icons'

const NavBarLogin = ({ logoutCallback, user, updateGlobalProject }) => {
    const { projects } = useSelector(state => state.projects)
    const { projectId } = useSelector(state => state.global)

    const {
        globalBrandCrumb,
        navSideBarOpen
    } = useContext(FuelSaveGlobalContext);
    const { t } = useTranslation();


    const projInfo = projects.find(p => p.project_id === projectId)
    const projName = !!projInfo && projInfo.project_name ? projInfo.project_name : 'No Project Selected'
    return (
        <Container fluid style={{ background: "#ffffff", position: 'fixed', top: '0', height: 97, zIndex: 9 }}>
            <div className='h-100 between-flex'>
                <div className={`navbar-heading-${navSideBarOpen ? 'small' : 'large'}`} >
                    <p style={{ margin: 0, float: 'right', fontSize: 30, fontWeight: 'bold', marginRight: navSideBarOpen ? 0 : 95 }}>{globalBrandCrumb}</p>
                </div >
                <div className='f-end-flex'>
                    <div className='d-flex ai-center'>
                        <DropdownButton
                            title={projName}
                            id="nav-project-dd"
                            onSelect={updateGlobalProject}
                            size="lg"
                            className="f-left"
                        >
                            {projects ? projects.map((name) => (
                                <Dropdown.Item key={name.project_id} eventKey={name.project_id}>{name.project_name}</Dropdown.Item>
                            )) : null}
                        </DropdownButton>
                        <NavDropdown id="accountNav" className="navbar-nav open-left" title={<><Icon name='user' style={{ marginRight: 10 }} /> {user}</>}>
                            <NavDropdown.Item onClick={() => window.open(require('./manual.pdf'), "_blank")}>
                                {t('User Manual')}
                            </NavDropdown.Item>
                            <NavDropdown.Item onSelect={logoutCallback}>
                                {t('Logout')}
                            </NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default NavBarLogin