import React, { useState, useEffect } from "react";
import { Chart } from 'react-chartjs-2'
import { useSelector } from "react-redux";
import ComparisonMap from "./ComparisonMap";
import ChartMixedEnergyConsumptionAlongRoad from "./ChartMixedEnergyConsumptionAlongRoad";
import ChartMixedGroundLevelAlongRoad from "./ChartMixedGroundLevelAlongRoad";
import ChartMixedAverageCurvatureAndSpeed from "./ChartMixedAverageCurvatureAndSpeed";
// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  // Button,
  Table,
  Row,
  Col,
} from "react-bootstrap";
// import { Slider } from "@material-ui/core";
import _ from "lodash";
import "./map.css";
import "./filter.css";
import './Slider.css';
import './map.scss'

//Material UI Imports
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
// import Typography from "@material-ui/core/Typography";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { drawVerticalLine } from "../../../../constant/helpers";
import { colorCodes } from "../../../../constant/colors";

// const generateKey = (pre) => {
//   return `${pre}_${new Date().getTime()}`;
// };

// const roundToTwoDecimals = (number) => {
//   return Math.round(number * 100) / 100;
// };

Chart.plugins.register(drawVerticalLine)

const { WHITE_1, BLACK_1 } = colorCodes
const MapsDir1 = (props) => {
  const [baseAlternative, setBaseAlternative] = useState(null);
  const [baseAlternativeId, setBaseAlternativeId] = useState(null);
  const [baseAlternativeDirName, setBaseAlternativeDirName] = useState(null);
  // const [baseAlternativeName, setBaseAlternativeName] = useState(null);

  const [compareAlternative, setCompareAlternative] = useState(null);
  const [compareAlternativeId, setCompareAlternativeId] = useState(null);
  const [compareAlternativeDirName, setCompareAlternativeDirName] =
    useState(null);
  // const [compareAlternativeName, setCompareAlternativeName] = useState(null);

  // const [alternativeChainageMin, setAlternativeChainageMin] = useState(null);
  // const [alternativeChainageMax, setAlternativeChainageMax] = useState(null);

  // const [alternativeFCHeavyMin, setAlternativeFCHeavyMin] = useState(null);
  // const [alternativeFCHeavyMax, setAlternativeFCHeavyyMax] = useState(null);

  const [lengthSliderRange, setLengthSliderRange] = useState([0, 100]);
  const [fcHeavySliderRange, setFcHeavySliderRange] = useState([0, 100]);

  const [filteredBaseAlternative, setFilteredBaseAlternative] = useState(null);
  const [filteredCompareAlternative, setFilteredCompareAlternative] =
    useState(null); //

  // const [filter, setFilter] = useState(false);
  // const [drawCharts, setDrawCharts] = useState(true);
  const drawCharts = true

  // local state for local Map data management
  const [localMapCentreIdData, setLocalMapCentreIdData] = useState(null);
  // const [localPreviousMapCentreIdData, setLocalPreviousMapCentreIdData] =
  //   useState(null);
  const [localMapCoordinateId, setLocalMapCoordinateId] = useState(null);
  const [lineAtIndex, setLineAtIndex] = useState(false);

  const [alternativeSelect, setAlternativeSelect] = useState(
    "Choose an Alternative"
  );
  const [open, setOpen] = useState(false);
  const [accordinOpen, setAccordinOpen] = useState(false);
  const [localAllMapAlternatives, setLocalAllMapAlternatives] = useState([""]);
  const [localBothDirectionsDailys, setLocalBothDirectionsDailys] =
    useState(false);

  const { currency, projectId } = useSelector(state => state.global)

  function handleStateChange(stateType, settingState) {
    switch (stateType) {
      case "localMapCentreIdData":
        setLocalMapCentreIdData(settingState);
        break;
      case "localMapCoordinateId":
        setLocalMapCoordinateId(settingState);
        break;
      // case "localPreviousMapCentreIdData":
      //   setLocalPreviousMapCentreIdData(settingState);
      //   break;
      default:
        break;
    }
  }

  const alternatives = props.mapAlternatives;
  //const alternatives = props.alternatives;
  const bothDirectionsDailys = props.bothDirectionsDailys;
  const allBothDirectionsDailys = props.allBothDirectionsDailys;

  const filterByBoth = (alternative) => {
    //console.log('base_alternative_before_filtering:', baseAlternative)
    const segmentsData = alternative.segments_data;

    const filteredByLength = segmentsData.filter((a) => {
      return (
        a.chainage >= lengthSliderRange[0] && a.chainage <= lengthSliderRange[1]
      );
    });

    const filteredSegmentsData = filteredByLength.filter((a) => {
      return (
        a.fuelconsumption_trucks >= fcHeavySliderRange[0] &&
        a.fuelconsumption_trucks <= fcHeavySliderRange[1]
      );
    });

    if (filteredSegmentsData) {
      return {
        direction: alternative.direction,
        directions_id: alternative.directions_id,
        segments: alternative.segments,
        segments_data: filteredSegmentsData,
      };
    } else {
      return null;
    }
  };

  const handleMinAndMax = (alternative1, alternative2, attribute) => {
    const arr = _.map(alternative1.segments_data, attribute);
    const asc = arr.sort((a, b) => a - b);

    if (alternative2 !== null) {
      const arr2 = _.map(alternative2.segments_data, attribute);
      const asc2 = arr2.sort((a, b) => a - b);
      const min1 = asc[attribute === "chainage" ? 0 : 1];
      const min2 = asc2[attribute === "chainage" ? 0 : 1];
      const max1 = asc[asc.length - 1];
      const max2 = asc2[asc2.length - 1];

      const newMin = min1 > min2 ? min1 : min2;
      const newMax = max1 > max2 ? max1 : max2;

      if (attribute === "chainage") {
        setLengthSliderRange([newMin, newMax]);
        // setAlternativeChainageMin(Math.round(min1 > min2 ? min1 : min2));
        // setAlternativeChainageMax(Math.floor(max1 > max2 ? max1 : max2));
      } else {
        setFcHeavySliderRange([newMin, newMax]);
        // setAlternativeFCHeavyMin(Math.round(min1 > min2 ? min1 : min2));
        // setAlternativeFCHeavyyMax(Math.floor(max1 > max2 ? max1 : max2));
      }
    } else {
      if (attribute === "chainage") {
        // setAlternativeChainageMin(Math.round(asc[0]));
        // setAlternativeChainageMax(Math.floor(asc[asc.length - 1]));

        setLengthSliderRange([asc[0], asc[asc.length - 1]]);
      } else {
        // setAlternativeFCHeavyMin(Math.round(asc[1]));
        // setAlternativeFCHeavyyMax(Math.floor(asc[asc.length - 1]));
        setFcHeavySliderRange([asc[1], asc[asc.length - 1]]);
      }
    }
  };

  function reset() {
    setBaseAlternative(null);
    setFilteredBaseAlternative(null);
    setBaseAlternativeId(null);
    setBaseAlternativeDirName(null);
    // setBaseAlternativeName(null);
    setCompareAlternative(null);
    setCompareAlternativeId(null);
    setCompareAlternativeDirName(null);
    // setCompareAlternativeName(null);
    setFilteredCompareAlternative(null);
  }

  function handleFirstAlternativeClick(alternative, alternative_id, direction) {
    handleMinAndMax(alternative, null, "chainage");
    handleMinAndMax(alternative, null, "fuelconsumption_trucks");
    //Common function
    //handleMinAndMax(alternative, alternative, 'chainage');
    //handleMinAndMax(alternative, null, 'fuelconsumption_trucks');

    //The first direction
    setBaseAlternative(alternative);
    setFilteredBaseAlternative(alternative);
    setBaseAlternativeId(alternative_id);
    setBaseAlternativeDirName(direction);
    // setBaseAlternativeName(alternative_id + " " + direction);

    // The second direction

    //handleMinAndMax(alternative, alternative, 'chainage');
    //handleMinAndMax(alternative, null, 'fuelconsumption_trucks');

    // setFilteredCompareAlternative(alternative);
    // setCompareAlternative(alternative);
    // setCompareAlternativeId(alternative_id);
    // setCompareAlternativeDirName(direction);
    // setCompareAlternativeName(alternative_id + ' ' + direction);

    setCompareAlternative(null);
    setCompareAlternativeId(null);
    setCompareAlternativeDirName(null);
    // setCompareAlternativeName(null);
    setFilteredCompareAlternative(null);
  }

  function handleSecondAlternativeClick(
    alternative,
    alternative_id,
    direction
  ) {
    handleMinAndMax(baseAlternative, alternative, "chainage");
    handleMinAndMax(baseAlternative, null, "fuelconsumption_trucks");
    setFilteredCompareAlternative(alternative);
    setCompareAlternative(alternative);
    setCompareAlternativeId(alternative_id);
    setCompareAlternativeDirName(direction);
    // setCompareAlternativeName(alternative_id + " " + direction);
  }

  const handleChangeSelector = (event) => {
    const input = event.target.value;
    if (input === "reset") {
      reset();
      setAlternativeSelect("");
      setAccordinOpen(true);
    } else {
      setAlternativeSelect(input);
      handleSecondAlternativeClick(
        input.directions[props.selected_direction],
        input.alternative_id,
        input.directions[props.selected_direction].direction
      );
      setAccordinOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleFunctionOutput = (bothDirectionsDailys) => {
    const dailyResults = bothDirectionsDailys.map(
      ({
        name,
        alternative_id,
        direction_name1,
        // direction_name2,
        daily_fuel1,
        daily_CO21,
        daily_energy1,
        daily_cost1,
        daily_fuel2,
        daily_CO22,
        daily_energy2,
        daily_cost2,
      }) => {
        if (baseAlternativeId && alternative_id === baseAlternativeId) {
          if (direction_name1 === baseAlternativeDirName) {
            return {
              name: name,
              alternative: 1,
              daily_fuel: daily_fuel1,
              daily_CO2: daily_CO21,
              daily_energy: daily_energy1,
              daily_cost: daily_cost1,
            };
          } else {
            return {
              name: name,
              alternative: 1,
              daily_fuel: daily_fuel2,
              daily_CO2: daily_CO22,
              daily_energy: daily_energy2,
              daily_cost: daily_cost2,
            };
          }
        } else {
          /*if (compareAlternativeId && alternative_id === compareAlternativeId) {
                if (direction_name1 === compareAlternativeDirName) {
                    return {
                        'name': name,
                        'alternative': 2,
                        'daily_fuel': daily_fuel1,
                        'daily_CO2': daily_CO21,
                        'daily_energy': daily_energy1,
                        'daily_cost': daily_cost1
                    }
                } else {
                    return {
                        'name': name,
                        'alternative': 2,
                        'daily_fuel': daily_fuel2,
                        'daily_CO2': daily_CO22,
                        'daily_energy': daily_energy2,
                        'daily_cost': daily_cost2
                    }
                }
            }*/
          return null;
        }
      }
    );

    let alternative1Results = null;
    let alternative2Results = null;
    // get alternative 1 and 2

    if (dailyResults[0] || dailyResults[1]) {
      dailyResults.forEach((alternative) => {
        if (alternative && alternative.alternative === 1) {
          alternative1Results = alternative;
        }
        if (alternative && alternative.alternative === 2) {
          alternative2Results = alternative;
        }
      });
    }

    if (alternative1Results && alternative2Results) {
      return (
        <>
          <td>{alternative1Results.name}</td>
          <td>
            {alternative1Results.daily_CO2 - alternative2Results.daily_CO2}
          </td>
          <td>
            {alternative1Results.daily_energy -
              alternative2Results.daily_energy}
          </td>
          <td>
            {`${alternative1Results.daily_cost - alternative2Results.daily_cost
              } ${currency}`}
            {/* {alternative1Results.daily_cost - alternative2Results.daily_cost} */}
          </td>
        </>
      );
    }
    if (alternative1Results && alternative2Results == null) {
      return (
        <>
          <td>{alternative1Results.name}</td>
          <td>{alternative1Results.daily_fuel}</td>
          <td>{alternative1Results.daily_CO2}</td>
          <td>{alternative1Results.daily_energy}</td>
          <td>
            {`${alternative1Results.daily_cost} ${currency}`}
            {/* {alternative1Results.daily_cost} */}
          </td>
        </>
      );
    } else {
      return "";
    }
  };

  const insertCompareAlternativeTable = () => {
    const comparitiveDirectionDailys = allBothDirectionsDailys.filter(
      (item) => item.alternative_id === compareAlternativeId
    );
    const dailyResults = comparitiveDirectionDailys.map(
      ({
        name,
        // alternative_id,
        direction_name1,
        // direction_name2,
        daily_fuel1,
        daily_CO21,
        daily_energy1,
        daily_cost1,
        daily_fuel2,
        daily_CO22,
        daily_energy2,
        daily_cost2,
      }) => {
        if (compareAlternativeId) {
          if (direction_name1 === compareAlternativeDirName) {
            return {
              name: name,
              alternative: 2,
              daily_fuel: daily_fuel1,
              daily_CO2: daily_CO21,
              daily_energy: daily_energy1,
              daily_cost: daily_cost1,
            };
          } else {
            return {
              name: name,
              alternative: 2,
              daily_fuel: daily_fuel2,
              daily_CO2: daily_CO22,
              daily_energy: daily_energy2,
              daily_cost: daily_cost2,
            };
          }
        } else {
          return null;
        }
      }
    );

    let alternative1Results = null;
    let alternative2Results = null;
    // get alternative 1 and 2

    if (dailyResults[0] || dailyResults[1]) {
      dailyResults.forEach((alternative) => {
        if (alternative && alternative.alternative === 1) {
          alternative1Results = alternative;
        }
        if (alternative && alternative.alternative === 2) {
          alternative2Results = alternative;
        }
      });
    }

    if (alternative1Results && alternative2Results) {
      return (
        <>
          <td>{alternative1Results.name}</td>
          <td>
            {alternative1Results.daily_CO2 - alternative2Results.daily_CO2}
          </td>
          <td>
            {alternative1Results.daily_energy -
              alternative2Results.daily_energy}
          </td>
          <td>
            {`${alternative1Results.daily_cost - alternative2Results.daily_cost
              } ${currency}`}
            {/* {alternative1Results.daily_cost - alternative2Results.daily_cost} */}
          </td>
        </>
      );
    }
    if (alternative2Results && alternative1Results == null) {
      return (
        <>
          <td>{alternative2Results.name}</td>
          <td>{alternative2Results.daily_fuel}</td>
          <td>{alternative2Results.daily_CO2}</td>
          <td>{alternative2Results.daily_energy}</td>
          <td>
            {`${alternative2Results.daily_cost} ${currency}`}
            {/* {alternative1Results.daily_cost} */}
          </td>
        </>
      );
    } else {
      return "";
    }
  };

  // function valuetext(value) {
  //   return `${value}°C`;
  // }

  // const handleLengthChange = (event, newValue) => {
  //   //setAlternativeChainageMin(newValue[0]);
  //   setDrawCharts(false);
  //   setLengthSliderRange(newValue);
  //   setDrawCharts(true);
  // };

  // const handleFCHeavyChange = (event, newValue) => {
  //   setDrawCharts(false);
  //   setFcHeavySliderRange(Math.round(newValue));
  //   setDrawCharts(true);
  // };

  // const handleFilter = (event) => {
  //   //    const filtered = filterByBoth(baseAlternative);
  //   //    setFilteredBaseAlternative(filtered);
  //   setDrawCharts(true);
  // };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    fontSelection: {
      fontSize: "20px",
      fontWeight: "bold",
    },
  }));

  const handleChangeAcc = () => {
    setAccordinOpen(!accordinOpen);
  };

  const classes = useStyles();

  const [lengthMin, setLengthMin] = useState(null);
  const [lengthMax, setLengthMax] = useState(null);

  const [fcMin, setFcMin] = useState(null);
  const [fcMax, setFcMax] = useState(null);

  // useEffect(() => {

  //     if (baseAlternative) {
  //         setFilteredCompareAlternative(baseAlternative)
  //     }

  // }, [])

  // useEffect(() => {
  //     reset();
  // }, [projectId]);

  useEffect(() => {
    if (props.allMapAlternatives) {
      setLocalAllMapAlternatives(props.allMapAlternatives);
      setLocalBothDirectionsDailys(props.bothDirectionsDailys);
    }
  }, [projectId, props]);

  useEffect(() => {
    //console.log('filter base:', baseAlternative)
    //console.log('filter compare:', compareAlternative)

    if (baseAlternative) {
      let filtered = baseAlternative;
      if (
        lengthSliderRange[0] !== lengthMin ||
        lengthSliderRange[1] !== lengthMax ||
        fcHeavySliderRange[0] !== fcMin ||
        fcHeavySliderRange[1] !== fcMax
      ) {
        filtered = filterByBoth(baseAlternative);
      }
      //console.log('base_alternative_after_filtering:', filtered)
      setFilteredBaseAlternative(filtered);
    }
    if (compareAlternative) {
      let filtered = compareAlternative;
      if (
        lengthSliderRange[0] !== lengthMin ||
        lengthSliderRange[1] !== lengthMax ||
        fcHeavySliderRange[0] !== fcMin ||
        fcHeavySliderRange[1] !== fcMax
      ) {
        filtered = filterByBoth(compareAlternative);
      }

      setFilteredCompareAlternative(filtered);
    }

    setLengthMin(lengthSliderRange[0]);
    setLengthMax(lengthSliderRange[1]);
    setFcMin(fcHeavySliderRange[0]);
    setFcMax(fcHeavySliderRange[1]);

    //   const filteredAlternative =  filterByLength(baseAlternative)

    // eslint-disable-next-line
  }, [fcHeavySliderRange, lengthSliderRange]);

  useEffect(() => {
    if (alternatives) {
      handleFirstAlternativeClick(
        alternatives[0].directions[props.selected_direction],
        alternatives[0].alternative_id,
        alternatives[0].directions[props.selected_direction].direction
      );
      //handleFirstAlternativeClick(alternatives[0].directions[1], alternatives[0].alternative_id, alternatives[0].directions[1].direction)
    }
    /*if (baseAlternativeId !== alternatives[0].alternative_id && baseAlternativeDirName === alternatives[0].directions[0].direction) {
            handleSecondAlternativeClick(alternatives[0].directions[0], alternatives[0].alternative_id, alternatives[0].directions[0].direction)
            handleSecondAlternativeClick(alternatives[0].directions[1], alternatives[0].alternative_id, alternatives[0].directions[1].direction)
        }*/

    // eslint-disable-next-line
  }, [alternatives]);

  return (
    baseAlternative &&
    localBothDirectionsDailys && (
      <Container style={{ backgroundColor: WHITE_1, color: BLACK_1 }}>
        <br />
        <Accordion expanded={accordinOpen}>
          <AccordionSummary
            //expandIcon={<ExpandMoreIcon />}
            //onClick={handleChangeAcc}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Container>
              <Row>
                <Col xs={{ offset: 9, span: 3 }}>
                  <div style={{ float: "right" }}>
                    {localAllMapAlternatives.length !== null &&
                      localBothDirectionsDailys[0] !== undefined ? (
                      <FormControl className={classes.formControl}>
                        {/* <InputLabel style={{ textAlign: 'left' }} id="demo-simple-select-label">Alignment Comparison</InputLabel> */}
                        <Select
                          style={{ fontWeight: "bold" }}
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select"
                          open={open}
                          onClose={handleClose}
                          onOpen={handleOpen}
                          value={alternativeSelect}
                          onChange={handleChangeSelector}
                          placeholder={"Select Alignment..."}
                        >
                          {localBothDirectionsDailys[0] !== undefined &&
                            localAllMapAlternatives
                              .filter((item) => {
                                return (
                                  item.alternative_id !==
                                  localBothDirectionsDailys[0].alternative_id
                                );
                              })
                              .map((a, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={a}
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Alignment: {a.name}{" "}
                                    {
                                      a.directions[props.selected_direction]
                                        .direction
                                    }
                                  </MenuItem>
                                );
                              })}
                          <MenuItem value={"reset"}>Reset</MenuItem>
                        </Select>
                        <FormHelperText style={{ fontWeight: "bold" }}>
                          Alignment Comparison
                        </FormHelperText>
                      </FormControl>
                    ) : (
                      ""
                    )}
                    {/* <div className="dropdown"> */}
                      {/* <Button id="filterbutton" className="dropbtn">
                        <FilterListRoundedIcon />
                      </Button> */}
                      {/* <div className="dropdown-content dropdown-menu-right"> */}
                        {/* {baseAlternative && (
                          <Container>
                            <>
                              <Typography
                                style={{
                                  textAlign: "left",
                                  fontWeight: "bold",
                                }}
                                id="discrete-slider-small-steps"
                                gutterBottom
                              >
                                Alternative length start - end
                              </Typography>
                              <Slider
                                value={lengthSliderRange}
                                onChange={handleLengthChange}
                                valueLabelDisplay="auto"
                                min={alternativeChainageMin}
                                max={alternativeChainageMax}
                                aria-labelledby="range-slider"
                                getAriaValueText={valuetext}
                              />

                              <Typography
                                style={{
                                  textAlign: "left",
                                  fontWeight: "bold",
                                }}
                                id="discrete-slider-small-steps"
                                gutterBottom
                              >
                                Alternatives fuel consumption heavy min-max
                              </Typography>
                              <Slider
                                value={fcHeavySliderRange}
                                onChange={handleFCHeavyChange}
                                valueLabelDisplay="auto"
                                min={alternativeFCHeavyMin}
                                max={alternativeFCHeavyMax}
                                aria-labelledby="range-slider"
                                getAriaValueText={valuetext}
                              />
                            </>
                          </Container>
                        )} */}
                      {/* </div> */}
                    {/* </div> */}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={11}>
                  {baseAlternative && (
                    <Table variant="dark" className="maps-table-body">
                      <thead>
                        <tr>
                          <td
                            colSpan="5"
                            align="left"
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          >
                            Alignment Details - {alternatives[0].name}{" "}
                            {
                              alternatives[0].directions[
                                props.selected_direction
                              ].direction
                            }
                          </td>
                        </tr>
                      </thead>

                      <thead>
                        <tr>
                          <th>File Name</th>
                          <th>Fuel [litres]</th>
                          <th>CO<sub>2</sub> [kg]</th>
                          <th>Energy [kWh]</th>
                          <th>Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {bothDirectionsDailys &&
                            handleFunctionOutput(bothDirectionsDailys)}
                        </tr>
                        {!!compareAlternative && <tr>{insertCompareAlternativeTable()}</tr>}
                      </tbody>
                      <tfoot></tfoot>
                    </Table>
                  )}
                </Col>
                <Col>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    style={{ float: "right", marginTop: "38%" }}
                    onClick={handleChangeAcc}
                  >
                    {!accordinOpen ? (
                      <ExpandMoreOutlinedIcon />
                    ) : (
                      <ExpandLessOutlinedIcon />
                    )}
                  </IconButton>
                </Col>
              </Row>
            </Container>
          </AccordionSummary>
          <AccordionDetails style={{ background: "#FBFBFF" }}>
            {accordinOpen ?
              <>
                <div className="two-map-holder">
                  <div className="first-sec">
                    <Card className="card-div">
                      <CardContent>
                        {drawCharts && filteredBaseAlternative && (
                          <>
                            <div style={{ fontSize: "1vw" }}>
                              Average curvature and speed
                            </div>
                            <ChartMixedAverageCurvatureAndSpeed
                              // baseAlternative={filterByLength(baseAlternative)}
                              // compareAlternative={compareAlternative ? filterByLength(compareAlternative) : compareAlternative}
                              baseAlternative={filteredBaseAlternative}
                              compareAlternative={filteredCompareAlternative}
                              handleStateChange={handleStateChange}
                              lineAtIndex={lineAtIndex}
                              setLineAtIndex={setLineAtIndex}
                              localMapCentreIdData={localMapCentreIdData}
                              localMapCoordinateId={localMapCoordinateId}
                            />
                          </>
                        )}
                      </CardContent>
                    </Card>

                    <div>
                      {!drawCharts && (
                        <div>Filtering average curvature and speed...</div>
                      )}
                    </div>
                  </div>

                  <div className="second-sec">
                    <Card className="card-div">
                      <CardContent>
                        {drawCharts && filteredBaseAlternative && (
                          <>
                            <div style={{ fontSize: "1vw" }}>
                              Alternatives map
                            </div>
                            <div>
                              <ComparisonMap
                                baseAlternative={filteredBaseAlternative}
                                compareAlternative={filteredCompareAlternative}
                                handleStateChange={handleStateChange}
                                setLineAtIndex={setLineAtIndex}
                                localMapCentreIdData={localMapCentreIdData}
                                localMapCoordinateId={localMapCoordinateId}
                                accordinOpen={accordinOpen}
                              />
                            </div>
                          </>
                        )}
                      </CardContent>
                    </Card>

                    <div>{!drawCharts && <div>Filtering map...</div>}</div>
                  </div>
                </div>
                <br />
                <div className="two-map-holder">
                  <div className="first-sec">
                    <Card className="card-div">
                      <CardContent>
                        {drawCharts && filteredBaseAlternative && (
                          <>
                            <div style={{ fontSize: "1vw" }}>
                              Energy consumption along road l/100km, average
                              over 20 meter sections
                            </div>
                            <div>
                              <ChartMixedEnergyConsumptionAlongRoad
                                baseAlternative={filteredBaseAlternative}
                                compareAlternative={filteredCompareAlternative}
                                handleStateChange={handleStateChange}
                                lineAtIndex={lineAtIndex}
                                setLineAtIndex={setLineAtIndex}
                                localMapCentreIdData={localMapCentreIdData}
                                localMapCoordinateId={localMapCoordinateId}
                                accordinOpen={accordinOpen}
                              />
                            </div>
                          </>
                        )}
                      </CardContent>
                    </Card>
                    <div>
                      {!drawCharts && (
                        <div>Filtering Energy consumption along road...</div>
                      )}
                    </div>
                  </div>
                  <div className="second-sec">
                    <Card className="card-div">
                      <CardContent>
                        {drawCharts && filteredBaseAlternative && (
                          <>
                            <div style={{ fontSize: "1vw" }}>
                              Ground level along road
                            </div>
                            <div>
                              <ChartMixedGroundLevelAlongRoad
                                baseAlternative={filteredBaseAlternative}
                                compareAlternative={filteredCompareAlternative}
                                handleStateChange={handleStateChange}
                                lineAtIndex={lineAtIndex}
                                setLineAtIndex={setLineAtIndex}
                                localMapCentreIdData={localMapCentreIdData}
                                localMapCoordinateId={localMapCoordinateId}
                              />
                            </div>
                          </>
                        )}
                      </CardContent>
                    </Card>
                    <div>
                      {!drawCharts && (
                        <div>Filtering Ground level along road...</div>
                      )}
                    </div>
                  </div>
                </div>
              </>
              :
              ""}
          </AccordionDetails>
        </Accordion>
      </Container>
    )
  );
};

export default MapsDir1;
