import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import { Tooltip } from "@material-ui/core";
import { confirmAlert } from 'react-confirm-alert';
import { Col, Container, Button, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom"
import FuelSaveGlobalContext from "../../../context/FuelSaveGlobalContext";
import AddProjectPopup from './AddProjectPopup'
import './project.scss'
import { ReactComponent as AddProject } from "../../../assets/add_project.svg"
import Icon from "../../common/Icons";
import { handleRequest } from "../../../constant/helpers";
import SpinLoader from "../../common/SpinLoader";
import ConfirmAlertPopup from "../../common/ConfirmAlertPopup";
import { fetchAllProjects } from "../../../actions/project";
import { updateGlobalProject } from "../../../actions/global";

export default function Projects() {

    const history = useHistory()
    const dispatch = useDispatch()
    const { isProcess, autoOut, projectId } = useSelector(state => state.global)
    const { projects } = useSelector(state => state.projects)
    const { setGlobalBrandCrumb } = useContext(FuelSaveGlobalContext);

    // const [allProjectName, setAllProjectName] = useState([])
    // const [updateProjectID, setUpdateProjectID] = useState('')
    const [newProjectPopup, setNewProjectPopup] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (autoOut) {
            setNewProjectPopup(false)
        }
    }, [autoOut])

    const deletProject = async (id) => {
        setIsLoading(true)
        toast.info('Deleting...')
        try {
            await handleRequest('post', false, {
                query: `mutation deleteProject($project_id:ID!)
                    {deleteProject(project_id:$project_id)}`,
                variables: {
                    project_id: id,
                }
            }, 'application/json')

            setIsLoading(false)
            toast.success('Deleted Successfully')
            callAllProject()
        } catch (e) {
            setIsLoading(false)
            console.log('deleteProject Error: ', e)
        }
    }

    const callAllProject = () => {
        dispatch(fetchAllProjects())
    }

    useEffect(() => {
        setGlobalBrandCrumb("Projects")
        // eslint-disable-next-line
    }, [])

    return (
        <Container className="black-n-white">
            {(!!isLoading || !!isProcess) && !newProjectPopup && <SpinLoader />}
            <Row className="pt-2 pb-2">
                <AddProjectPopup
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    show={newProjectPopup}
                    onHide={() => setNewProjectPopup(false)}
                />
                <Col>
                    <Container>
                        <Button variant="primary" bsPrefix="btn-project" style={{
                            float: 'right', width: '220px', height: '56px', background: '#05F29B 0% 0% no-repeat padding-box'
                        }} onClick={() => setNewProjectPopup(true)}>
                            <AddProject className="add_project_svg" />
                            Add Project
                        </Button>
                    </Container>
                </Col>
            </Row>
            <Container fluid>
                <Row>
                    <Table hover size="sm">
                        <thead>
                            <tr>
                                <th >S.No</th>
                                <th >Project ID</th>
                                <th >Project Name</th>
                                <th >Start Year</th>
                                <th >End Year</th>
                                <th >Action</th>
                            </tr>
                        </thead>
                        <tbody className="t-body-v-middle">
                            {projects.map((name, idx) => (
                                <tr key={idx}>
                                    <td width='10%'>{idx + 1}</td>
                                    <td width='10%'>{name.project_id}</td>
                                    <td width='30%'>{name.project_name}</td>
                                    <td width='15%'>{name.start_year || '-'}</td>
                                    <td width='15%'>{name.end_year || '-'}</td>
                                    <td width='20%'>
                                        {
                                            (parseInt(projectId) !== parseInt(name.project_id)) &&
                                            <Tooltip title="Select">
                                                <button className='btn-project round-btn' onClick={() => dispatch(updateGlobalProject(name.project_id, projectId))}><ArrowForwardIosOutlinedIcon /></button>
                                            </Tooltip>
                                        }
                                        <Tooltip title="Edit">
                                            <button className='edit-round-btn' onClick={() => {
                                                history.push(`/projects/edit/${name.project_id}`)
                                            }}><Icon name='edit' /></button>
                                        </Tooltip>
                                        {
                                            (parseInt(projectId) !== parseInt(name.project_id)) &&
                                            <Tooltip title="Delete">
                                                <button className='delete-round-btn' onClick={() => {
                                                    confirmAlert({
                                                        customUI: values => <ConfirmAlertPopup {...values} onConfirm={() => deletProject(name.project_id)} />
                                                    })
                                                }}><Icon name='trash' /></button>
                                            </Tooltip>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </Container>
    )
}
