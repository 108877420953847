import { newSlabDiff } from "../views/content/comparison/maps/common";

export const compareChainage = (a, b) => {
    if (a.chainage < b.chainage) {
        return -1;
    }
    if (a.chainage > b.chainage) {
        return 1;
    }
    return 0;
}

export const compareYear = (a, b) => {
    if (a.year < b.year) {
        return -1;
    }
    if (a.year > b.year) {
        return 1;
    }
    return 0;
}

export const getGroundLevelAlongRoadLayers = (baseAlternative) => {

    // -6 = dark blue, -3 = light blue, 0 = grey, 3 = light red, 6 = dark red

    const baseChainageLabels = []

    const baseLevelDarkBlueData = []
    const baseLevelBlueData = []
    const baseLevelGreyBlueData = []
    const baseLevelGreyData = []
    const baseLevelGreyRedData = []
    const baseLevelRedData = []
    const baseLevelDarkRedData = []

    const baseLevelData = []
    const mapPointData = []

    if (baseAlternative) {
        const baseSegmentsData = baseAlternative.segments_data;
        baseSegmentsData.sort(compareChainage);
        let index = 0

        baseSegmentsData.map(segment => {
            const thresHoldLeft3 = -6
            const thresHoldLeft2 = -4
            const thresHoldLeft1 = -2
            const thresHoldMiddle = 0
            const thresHoldRight1 = 2
            const thresHoldRight2 = 4
            const thresHoldRight3 = 6

            let n = 0;

            //console.log(segment.hilliness, "segment hilliness")

            while (n < 2) {
                baseChainageLabels.push(segment.chainage)

                if (segment.hilliness < thresHoldLeft3) {
                    baseLevelDarkBlueData.push(segment.zlevel)
                    baseLevelBlueData.push(null)
                    baseLevelGreyBlueData.push(null)
                    baseLevelGreyData.push(null)
                    baseLevelGreyRedData.push(null)
                    baseLevelRedData.push(null)
                    baseLevelDarkRedData.push(null)
                }
                else if (segment.hilliness >= thresHoldLeft3 && segment.hilliness < thresHoldLeft2) {
                    baseLevelDarkBlueData.push(null)
                    baseLevelBlueData.push(segment.zlevel)
                    baseLevelGreyBlueData.push(null)
                    baseLevelGreyData.push(null)
                    baseLevelGreyRedData.push(null)
                    baseLevelRedData.push(null)
                    baseLevelDarkRedData.push(null)
                }
                else if (segment.hilliness >= thresHoldLeft2 && segment.hilliness < thresHoldLeft1) {
                    baseLevelDarkBlueData.push(null)
                    baseLevelBlueData.push(null)
                    baseLevelGreyBlueData.push(segment.zlevel)
                    baseLevelGreyData.push(null)
                    baseLevelGreyRedData.push(null)
                    baseLevelRedData.push(null)
                    baseLevelDarkRedData.push(null)
                }
                else if (segment.hilliness === thresHoldMiddle) {
                    baseLevelDarkBlueData.push(null)
                    baseLevelBlueData.push(null)
                    baseLevelGreyBlueData.push(null)
                    baseLevelGreyData.push(segment.zlevel)
                    baseLevelGreyRedData.push(null)
                    baseLevelRedData.push(null)
                    baseLevelDarkRedData.push(null)
                }
                else if (segment.hilliness >= thresHoldRight1 && segment.hilliness < thresHoldRight2) {
                    baseLevelDarkBlueData.push(null)
                    baseLevelBlueData.push(null)
                    baseLevelGreyBlueData.push(null)
                    baseLevelGreyData.push(null)
                    baseLevelGreyRedData.push(segment.zlevel)
                    baseLevelRedData.push(null)
                    baseLevelDarkRedData.push(null)
                }
                else if (segment.hilliness >= thresHoldRight2 && segment.hilliness < thresHoldRight3) {
                    baseLevelDarkBlueData.push(null)
                    baseLevelBlueData.push(null)
                    baseLevelGreyBlueData.push(null)
                    baseLevelGreyData.push(null)
                    baseLevelGreyRedData.push(null)
                    baseLevelRedData.push(segment.zlevel)
                    baseLevelDarkRedData.push(null)
                }
                else if (segment.hilliness >= thresHoldRight3) {
                    baseLevelDarkBlueData.push(null)
                    baseLevelBlueData.push(null)
                    baseLevelGreyBlueData.push(null)
                    baseLevelGreyData.push(null)
                    baseLevelGreyRedData.push(null)
                    baseLevelRedData.push(null)
                    baseLevelDarkRedData.push(segment.zlevel)
                }
                else {
                    baseLevelDarkBlueData.push(null)
                    baseLevelBlueData.push(null)
                    baseLevelGreyBlueData.push(null)
                    baseLevelGreyData.push(segment.zlevel)
                    baseLevelGreyRedData.push(null)
                    baseLevelRedData.push(null)
                    baseLevelDarkRedData.push(null)

                }


                baseLevelData.push(segment.zlevel)

                mapPointData.push({
                    'index': (index + n),
                    'chainage': segment.chainage,
                    'segment_order_no': segment.segment_order_no,
                    'fuelconsumption_trucks': segment.fuelconsumption_trucks,
                    'hilliness': segment.hilliness,
                    'speedprofile_car': segment.speedprofile_car,
                    'fuelconsumption_car': segment.fuelconsumption_car,
                    'speedprofile_trucks': segment.speedprofile_trucks,
                    'geom_order_id': segment.geom_order_id,
                    'geom': segment.geom,
                    'zlevel': segment.zlevel
                })
                n++;
            }
            index = index + 2;
            return segment
        })
    }

    return [
        baseChainageLabels,
        baseLevelDarkBlueData,
        baseLevelBlueData,
        baseLevelGreyBlueData,
        baseLevelGreyData,
        baseLevelGreyRedData,
        baseLevelRedData,
        baseLevelDarkRedData,
        baseLevelData,
        mapPointData
    ]
}

export const getGroundLevelAlongRoadDataSets = (

    baseAlternative,
    compareAlternative,
    baseLevelData,
    baseLevelDarkBlueData,
    baseLevelBlueData,
    baseLevelGreyBlueData,
    baseLevelGreyData,
    baseLevelGreyRedData,
    baseLevelRedData,
    baseLevelDarkRedData,
    compareChainageLabels,
    compareLevelData,
    compareLevelDarkBlueData,
    compareLevelBlueData,
    compareLevelGreyBlueData,
    compareLevelGreyData,
    compareLevelGreyRedData,
    compareLevelRedData,
    compareLevelDarkRedData,

) => {

    const compareDir = compareAlternative ? compareAlternative.direction : ''

    return [
        {
            type: 'bar',
            label: 'Base level ' + (baseAlternative ? baseAlternative.direction : ''),
            labelColor: "white",
            //borderColor: 'rgb(193, 66, 66)',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 3,
            fill: false,
            //backgroundColor : "rgb(193, 66, 66,0.8)",
            data: baseLevelData
        },
        //dark blue

        {
            type: 'line',
            label: 'Compare zlevel ' + compareDir,
            borderColor: 'rgb(255,255,255,0.1)',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 3,
            fill: true,
            backgroundColor: "rgb(255,255,255,0.01)",
            data: compareLevelData
        },
        {
            type: 'line',
            label: 'Base < -4',
            labelColor: "white",
            borderColor: 'rgb(0,0,102)',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 3,
            fill: true,
            backgroundColor: "rgb(0,0,102,0.8)",
            data: baseLevelDarkBlueData
        },
        // blue
        {
            type: 'line',
            label: 'Base < -2',
            labelColor: "white",
            borderColor: 'rgb(0,0,255)',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 3,
            fill: true,
            backgroundColor: "rgb(7,9,150,0.8)",
            data: baseLevelBlueData
        },
        //  grey blue
        {
            type: 'line',
            label: ' Base < -0.3 ' + (baseAlternative ? baseAlternative.direction : ''),
            labelColor: "white",
            borderColor: 'rgb(102,102,255)',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 3,
            fill: true,
            backgroundColor: "rgb(102,102,255,0.8)",
            data: baseLevelGreyBlueData
        },
        // grey
        {
            type: 'line',
            label: 'Base 0 ' + (baseAlternative ? baseAlternative.direction : ''),
            labelColor: "white",
            borderColor: 'rgb(128,128,128)',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 3,
            fill: true,
            backgroundColor: "rgb(128,128,128,0.8)",
            data: baseLevelGreyData
        },
        //grey red
        {
            type: 'line',
            label: 'Base > 0.3 ' + (baseAlternative ? baseAlternative.direction : ''),
            labelColor: "white",
            borderColor: 'rgb(193, 66, 66)',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 3,
            fill: true,
            backgroundColor: "rgb(193, 66, 66,0.8)",
            data: baseLevelGreyRedData
        },
        //red
        {
            type: 'line',
            label: 'Base > 2',
            labelColor: "white",
            borderColor: 'rgb(255,0,0)',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 3,
            fill: true,
            backgroundColor: "rgb(255,51,51,0.8)",
            data: baseLevelRedData
        },
        {
            type: 'line',
            label: 'Base > 4 ' + (baseAlternative ? baseAlternative.direction : ''),
            labelColor: "white",
            borderColor: 'rgb(204,0,0)',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 3,
            fill: true,
            backgroundColor: "rgb(204,0,0,0.8)",
            data: baseLevelDarkRedData
        },


        {
            type: 'line',
            label: 'Compare < -4',
            labelColor: "white",
            borderColor: 'rgb(0,0,102)',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 3,
            fill: true,
            backgroundColor: "rgb(0,0,102,0.8)",
            data: compareLevelDarkBlueData
        },
        // blue
        {
            type: 'line',
            label: 'Compare < -2',
            labelColor: "white",
            borderColor: 'rgb(0,0,255)',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 3,
            fill: true,
            backgroundColor: "rgb(7,9,150,0.8)",
            data: compareLevelBlueData
        },
        //  grey blue
        {
            type: 'line',
            label: ' Compare < -0.3 ' + (baseAlternative ? baseAlternative.direction : ''),
            labelColor: "white",
            borderColor: 'rgb(102,102,255)',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 3,
            fill: true,
            backgroundColor: "rgb(102,102,255,0.8)",
            data: compareLevelGreyBlueData
        },
        // grey
        {
            type: 'line',
            label: 'Compare = 0 ' + (baseAlternative ? baseAlternative.direction : ''),
            labelColor: "white",
            borderColor: 'rgb(128,128,128)',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 3,
            fill: true,
            backgroundColor: "rgb(128,128,128,0.8)",
            data: compareLevelGreyData
        },
        //grey red
        {
            type: 'line',
            label: 'Compare > 0.3 ' + (baseAlternative ? baseAlternative.direction : ''),
            labelColor: "white",
            borderColor: 'rgb(193, 66, 66)',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 3,
            fill: true,
            backgroundColor: "rgb(193, 66, 66,0.8)",
            data: compareLevelGreyRedData
        },
        //red
        {
            type: 'line',
            label: 'Compare > 2',
            labelColor: "white",
            borderColor: 'rgb(255,0,0)',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 3,
            fill: true,
            backgroundColor: "rgb(255,51,51,0.8)",
            data: compareLevelRedData
        },
        {
            type: 'line',
            label: 'Compare > 4 ' + (baseAlternative ? baseAlternative.direction : ''),
            labelColor: "white",
            borderColor: 'rgb(204,0,0)',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 3,
            fill: true,
            backgroundColor: "rgb(204,0,0,0.8)",
            data: compareLevelDarkRedData
        },
    ]
}

export const legend = {
    display: false,
    position: 'bottom',
    labels: {
        fontColor: "white",
        fontSize: 14,
        display: false
    }
}

export const tooltips = {
    titleFontSize: 18,
    bodyFontSize: 18,
}

export const xAxes = [
    {
        scaleLabel: {
            display: true,
            fontColor: "#172679",
            labelString: 'Chainage',
            fontSize: 25
        },
        scaleFontSize: 40,
        ticks: {
            fontColor: "#172679",
            display: true,
            //beginAtZero: true,
            min: 0,
            max: 10,
            maxTicksLimit: 10,
            stepSize: 100,
            fontSize: 20
        },
        stacked: false,
        afterBuildTicks: function (mychart) {
            mychart.options.ticks.maxTicksLimit = mychart.max
            const indexCollection = []
            newSlabDiff(mychart.max).map(ins => indexCollection.push((mychart.ticks).indexOf(ins)))
            mychart.options.ticks.callback = function (label, i) {
                return indexCollection.includes(i) ? label : '';
            }
        }
    },
]

export const yAxes = [
    {
        scaleLabel: {
            display: true,
            fontColor: "white",
            labelString: 'Fuel Consumption',
            fontSize: 25
        },
        scaleFontSize: 40,
        ticks: {
            fontColor: "white",
            suggestedMin: 0,
            suggestedMax: 60,
            maxTicksLimit: 10,
            display: true,
            stepSize: 10,
            fontSize: 20
        },
        stacked: false,
    },
]