import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Plus = ({
    fill = colorCodes.BLACK_1,
    height = '18.045',
    width = '18.045',
    style = {},
    viewBox = '0 0 18.045 18.045',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g id="zoom_in" data-name="zoom in" transform="translate(1.25 1.25)">
            <line id="Line_22" data-name="Line 22" y2="15.545" transform="translate(7.773 0)" fill="none" stroke={fill} strokeLinecap="round" strokeWidth="2.5" />
            <path id="Path_36" data-name="Path 36" d="M0,0V15.545" transform="translate(15.545 7.773) rotate(90)" fill="rgba(0,0,0,0)" stroke={fill} strokeLinecap="round" strokeWidth="2.5" />
        </g>
    </svg>


)

export default Plus