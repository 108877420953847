import { useState } from "react"
import { Form, Button } from "react-bootstrap"
import { toast } from "react-toastify"
import axios from "axios"
import './index.scss'

export default function License({ apiAccessToken, logoutCallback }) {

    const [licenseKey, setLicenseKey] = useState("");
    const [inputInvalid, setInputInvalid] = useState(false);

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        if (inputInvalid) {
            return;
        }

        if(!licenseKey) {
            toast.error('Please enter license key')
            return false
        }

        try {
            const reponse = await axios({
                url: process.env.REACT_APP_LICENSE_API_URL + '/license',
                method: "post",
                headers: {
                    'Authorization': 'Bearer ' + apiAccessToken.accessToken
                },
                data: {
                    license_key: licenseKey
                }
            });
            alert(reponse.data);
            window.location.replace('/');
        } catch (err) {
            if (err.response.status === 404) {
                setInputInvalid(true);
                toast.error(err.response.data);
            } else if (err.response.status === 403) {
                toast.error(err.response.data);
                window.location.replace('/');
            } else if (Number.isInteger(err.response.status)) {
                toast.error(err.response.data);
            } else {
                toast.error('Unknown error.');
            }
        }
    }

    return (
        <div className="license-container center-flex">
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Valid License Key Required</Form.Label>
                    <Form.Control isInvalid={inputInvalid} type="text" placeholder="Enter license key" value={licenseKey} onChange={e => { setLicenseKey(e.target.value); setInputInvalid(false); }} />
                    <Form.Text>
                        You can get a license key from your company admin.
                    </Form.Text>
                </Form.Group>
                <Button className="license-submit-btn" variant="primary" type="submit">Submit</Button>
            </Form>
            <Button className="license-logout-btn" variant="danger" onClick={logoutCallback}>Logout</Button>
        </div>
    )
}