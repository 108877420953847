import React, { useState, useEffect, useContext, useCallback } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Papa from "papaparse"
import { useDispatch, useSelector } from "react-redux"
import Popup from './Popup'
import { requestBody3 } from "../../../utils/aiRequestBodyUtils"

import { Col, Container, Button, Row, Table } from "react-bootstrap"
import FuelSaveGlobalContext from "../../../context/FuelSaveGlobalContext"
import "./summary.scss"
// import ACTIONS from '../../../utils/action'
import { // useQuery, useLazyQuery, 
  gql, useMutation
} from "@apollo/client"
//import { GET_VALUES, POST_ALTERNATIVE } from "../../../utils/apolloutils";
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import AddIcon from '@material-ui/icons/Add'
import { confirmAlert } from 'react-confirm-alert'
import CostCircle from "./CostCircle"
import Icon from "../../common/Icons"
import { floatWithDeci, handleCSVArr, isCordValid, presumptionObj } from "../../../constant/helpers"
import SpinLoader from "../../common/SpinLoader"
import { CSV_NAME_REGEX, DEFAULT_EPSG, DEFAULT_EPSG_CODE, PER_PAGE } from "../../../constant"
import ConfirmAlertPopup from "../../common/ConfirmAlertPopup"
import AddAlignment from "./AddAlignment"
import { colorCodes } from "../../../constant/colors"
import { fetchAllAlignments } from "../../../actions/alignment"
import AlignmentPageWidget from "./AlignmentPageWidget"
import CopyTraffic from "./CopyTraffic"

const { WHITE_1, BLACK_1 } = colorCodes
const Summary = () => {

  const { register, handleSubmit, watch } = useForm();
  const { currency, carConfig, truckConfig, projInfo, projectId } = useSelector(state => state.global)
  const { alignments, activePage, isProcessing } = useSelector(state => state.alignments)
  const { presumptions } = useSelector(state => state.presumptions)

  const {
    // refreshProject, setRefreshProject,
    // globalForcastList, forcastDispatch,
    apiAccessToken, // setApiAccessToken,
    // globalForcastListNew, setGlobalForcastListNew,
    // globalBrandCrumb, 
    setGlobalBrandCrumb
  } = useContext(FuelSaveGlobalContext);

  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [loading, setloading] = useState(false);
  const [adjsutedListCO2_new, setAdjsutedListCO2_new] = useState('');
  const [adjsutedListCost_new, setAdjsutedListCost_new] = useState('');
  const [adjsutedListEnergy_new, setAdjsutedListEnergy_new] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [trafficModal, setTrafficModal] = useState(false);
  const [querySearch, setQuerySearch] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [errResp, setErrResp] = useState({});
  const [activeTab, setActiveTab] = useState('csv');
  const [xmlLoader, setXmlLoader] = useState(false);
  const [activeXMLAlign, setActiveXMLAlign] = useState(0);
  const xmlDefaultState = {
    epsgCode: '',
    alignmentOpts: [],
    dataList: [{ name: '', start: 0, end: 0, direction: 'East', step: 20, idx: 0 }],
    selected: [],
    useSame: false,
    alignmentListArr: [{ name: '', start: 0, end: 0, direction: 'East', step: 20, idx: 0 }],
    numberOfAlignments: 1,
    xml: ''
  }
  const [xmlData, setXMLData] = useState(xmlDefaultState);

  // ***************STUFF for Forcast***************************** //

  const getDailyData = useCallback(async (
    startYearData,
    endYearData,
    segmentsDir1,
    segmentsDir2,
    segmentsDataDir1,
    segmentsDataDir2,
    presumptionsData,
    index
  ) => {
    const presumptions = presumptionObj(presumptionsData[0])

    const startYear = startYearData ? startYearData : 2020;
    const endYear = endYearData ? endYearData : 2041;

    const requestBodyDir1 = {
      return_option: 3,
      start_year: startYear,
      end_year: endYear,
      // end_year: startYear + 20, // C wants the prognosis to be not dependent on the endYear
      fuel_distribution_cars: carConfig,
      fuel_distribution_trucks: truckConfig,
      sensitivity_param_factors: {
        speed: 1,
        fuelprice: 1,
        emission: 1,
        AADTheavy: 1,
      },
      presumptions: presumptions,
      segments: segmentsDir1,
      segments_data: segmentsDataDir1,
    };

    const requestBodyDir2 = {
      return_option: 3,
      start_year: startYear,
      end_year: endYear,
      // end_year: startYear + 20,  // C wants the prognosis to be not dependent on the endYear
      fuel_distribution_cars: carConfig,
      fuel_distribution_trucks: truckConfig,
      sensitivity_param_factors: {
        speed: 1,
        fuelprice: 1,
        emission: 1,
        AADTheavy: 1,
      },
      presumptions: presumptions,
      segments: segmentsDir2,
      segments_data: segmentsDataDir2,
    };

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    const AI_API_KEY = process.env.REACT_APP_AI_FUNCTION_API_KEY;
    const urlToken = process.env.REACT_APP_AI_FUNCTION_URL + '/api/HttpTrigger1?code=' + AI_API_KEY;

    try {
      const [responseDir1, responseDir2] = await Promise.all([
        axios.post(urlToken, endYear ? requestBodyDir1 : requestBody3, config),
        axios.post(urlToken, endYear ? requestBodyDir2 : requestBody3, config),
      ]);

      return [responseDir1, responseDir2];

    } catch (err) {
      // throw new Error('Unable to get a response.')
      return null;
    }

    // eslint-disable-next-line
  }, [currency])

  const setPresumptionData = useCallback(async (alternativeArr, presempArr) => {
    if (alternativeArr && presempArr) {
      const localSavingsAlternativetables = [];

      for (let index = 0; index < alternativeArr.length; index++) {
        const alternative = alternativeArr[index];
        const dailyData = await getDailyData(
          alternative.start_year,
          alternative.end_year,
          alternative.directions[0].segments,
          alternative.directions[1].segments,
          alternative.directions[0].segments_data,
          alternative.directions[1].segments_data,
          presempArr,
          index
        );

        if (!dailyData || (!!dailyData && (!dailyData[0] || !dailyData[1]))) {
          return false
        }

        if (!!dailyData && !!dailyData.length) {
          const responseDir1 = dailyData[0];
          const responseDir2 = dailyData[1];

          const year = responseDir1.data.year;

          const forecastData = [];

          year.map((yr, indx) => {
            const annual_co2 =
              responseDir1.data.annual_co2[indx] +
              responseDir2.data.annual_co2[indx];
            const annual_cost =
              responseDir1.data.annual_cost[indx] +
              responseDir2.data.annual_cost[indx];
            const annual_energy =
              responseDir1.data.annual_energy[indx] +
              responseDir2.data.annual_energy[indx];

            forecastData.push({
              year: yr,
              annual_co2: annual_co2,
              annual_cost: annual_cost,
              annual_energy: annual_energy
            });
            return yr
          });

          localSavingsAlternativetables.push({
            alternative_id: alternative.alternative_id,
            forecast_data: forecastData,
          });
        }
      }
      //console.log(localSavingsAlternativetables, "localSavingsAlternativetables")
      //forcastDispatch({ type: ACTIONS.PREDICTION, payload: localSavingsAlternativetables });

      return localSavingsAlternativetables
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {

    setPresumptionData(alignments, presumptions).then((localSavingsAlternativetables) => {

      if (typeof localSavingsAlternativetables !== 'undefined' && !!localSavingsAlternativetables && !!localSavingsAlternativetables.length) {
        var listCO2 = []
        var listCost = []
        var listEnergy = []

        localSavingsAlternativetables.forEach(element => {
          listCO2.push(element.forecast_data[(element.forecast_data.length) - 1].annual_co2)
          listCost.push(element.forecast_data[(element.forecast_data.length) - 1].annual_cost)
          listEnergy.push(element.forecast_data[(element.forecast_data.length) - 1].annual_energy)
        });

        const makingRelative = (eleList) => {
          const max = Math.max(...eleList);
          const newList = []
          eleList.forEach((element) => {
            if ((element / max) === 1) {
              newList.push(((element / max)))
            }
            else {
              newList.push((1 - ((1 - (element / max)))))  //change the factor like this if you wanna change it---> newList.push((1 - ((1 - (element / max)) * 2)))
            }
          })
          return newList
        }

        listCO2 = makingRelative(listCO2)
        listCost = makingRelative(listCost)
        listEnergy = makingRelative(listEnergy)
        //console.log(listCO2)

        setAdjsutedListCO2_new((listCO2))
        setAdjsutedListCost_new((listCost))
        setAdjsutedListEnergy_new((listEnergy))
        //setForcastColor(["red", "blue", "green"])
        //console.log(adjsutedListCO2_new, "forcasted Data")
      }
    })

    // eslint-disable-next-line
  }, [presumptions, alignments]);


  //*************************************************************************************************** */

  // const deleteAllAternative = async (project_id) => {
  //   setloading(true);
  //   /*await axios.post(props.url, {
  //     query: ` mutation deleteAllAlternative($project_id:project_id)
  //       {deleteAllAlternative(project_id:$project_id)}`,
  //     variable: {
  //       project_id
  //     }
  //   }, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + apiAccessToken.accessToken
  //     }
  //   });*/
  //   const parseProjectID = parseInt(projectId)

  //   mutationAlternativeDeleteFunction({
  //     variable: {
  //       project_id: parseProjectID
  //     }
  //   }).then(() => {
  //     setData();
  //   })
  // };

  let history = useHistory();
  const redirectButton = (alternative_id) => {
    history.push('/settings/' + alternative_id)

  }


  const deletAlternative = async (id) => {
    setloading(true);
    toast.info('Deleting...')
    mutationAlternativeDeleteAlternativeFunction({ variables: { alternative_id: id } })
      .then(() => setData())
  };

  const dispatch = useDispatch()
  async function setData() {
    await dispatch(fetchAllAlignments(projectId))
    setloading(false)
  }

  const mutationAlternativeGQL = gql`
  mutation addAlternative($project_id:ID!,$dir1:[AddAlternativeDirInput!]!,
        $dir2:[AddAlternativeDirInput!]!) {
          addAlternative(project_id:$project_id,dir1:$dir1,dir2:$dir2) {
      alternative_id
    }
  }`;

  // const mutationAlternativeDELETEALLGQL = gql`
  // mutation deleteAllAlternative($project_id: ID!) {
  //   deleteAllAlternative(project_id:$project_id)
  // }`;

  const mutationAlternativeDELETEGQL = gql`
  mutation deleteAlternative($alternative_id:ID!)
        {deleteAlternative(alternative_id:$alternative_id)}`;

  const [mutationAlternativeFunction] = useMutation(mutationAlternativeGQL, {
    onCompleted: (() => {
      setModalShow(false)
    })
  })
  // const [mutationAlternativeDeleteFunction, { muatatedDataDeleteALL }] = useMutation(mutationAlternativeDELETEALLGQL);
  const [mutationAlternativeDeleteAlternativeFunction] = useMutation(mutationAlternativeDELETEGQL);

  /*const { preLoading, error, data } = useQuery(getApolloDataNew, {
    variables: { project_ids: projectId },
  });
  
  console.log(data, "newdata")*/

  //const [getNewData, { preLoading, data }] = useLazyQuery(getApolloDataNew); //Lets use it some other day // No promise based solution found

  const csvCheck = (data, direction) => {
    let csvError = false;
    //checking length
    if (data.length <= 4) {
      csvError = true;
      toast.error("Wrong length of the file - " + direction);
      setloading(false);
      return csvError;
    }
    //checking chainage
    let chainage = 0;
    let chainage_new = 0;
    var BreakException = {};
    try {
      data.slice(1).forEach((element) => {
        chainage_new = parseInt(element[1]);
        if (
          chainage_new < chainage ||
          (chainage_new - chainage > 100) ||
          chainage_new === null
        ) {
          toast.error("CSV chainage is wrong - " + direction);
          setloading(false);
          csvError = true;
          throw BreakException;
        }
        chainage = chainage_new;
      });
      return csvError;
    } catch (e) {
      if (e !== BreakException) {
        return csvError;
      }
    }
  };

  const fileDirectionCheck = (fileName, data, direction) => {
    let csvError = false;

    const directionOnName = fileName.split("-")[1].split(".")[0];
    let directionInFile = data[1][7];

    // HINT: Quick fix for the semicolon delimeter csv
    if (!!data && !!data[1] && !!data[1][0] && ((directionInFile === undefined) || (directionInFile === 'undefined'))) {
      const newArr = (data[1][0]).split(";")
      directionInFile = newArr[7]
    }

    if (directionInFile !== directionOnName) {
      setloading(false);
      csvError = true;
      toast.error("CSV direction wrong - " + direction);
      return csvError;
    }

    return csvError;
  };

  const nameCheck = (fileName1, fileName2) => {
    let nameError = false;
    let directionOnName1 = fileName1.split("-")[1].split(".")[0]; //The direction
    let directionOnName2 = fileName2.split("-")[1].split(".")[0];

    let startingName1 = fileName1.split("-")[0]; // The first name
    let startingName2 = fileName2.split("-")[0];

    let format1 = fileName1.split("-")[1].split(".")[1]; //format
    let format2 = fileName2.split("-")[1].split(".")[1];


    let dict = {};
    dict["North"] = "South";
    dict["South"] = "North";
    dict["East"] = "West";
    dict["West"] = "East";

    //checking format
    if (format1 !== "csv" || format2 !== "csv") {
      nameError = true;
      setloading(false);
      toast.error("Please insert a CSV file");
      return nameError;
    }

    if (startingName1 !== startingName2) {
      nameError = true;
      setloading(false);
      toast.error("Please insert the file from the same project");
      return nameError;
    }

    if (directionOnName1 === directionOnName2) {
      nameError = true;
      setloading(false);
      toast.error("Same file name error");
      return nameError;
    }
    if (dict[directionOnName1] !== directionOnName2) {
      nameError = true;
      setloading(false);
      toast.error("Same direction error");
      return nameError;
    }
    return nameError;
  };


  const onSubmit = (data, e) => {

    const reader = new FileReader();
    const reader2 = new FileReader();
    setloading(true);

    try {
      let fileName1 = data.dir1[0].name;
      let fileName2 = data.dir2[0].name;

      let nameError = false;

      nameError = nameCheck(fileName1, fileName2);

      if (nameError === false) {
        reader.readAsBinaryString(data.dir1[0]);
        //reader.readAsText(data.dir1[0]);

        let dir1Data = null;
        reader.onload = (e) => {
          dir1Data = e.target.result;

          Papa.parse(dir1Data, {
            skipEmptyLines: true,
            complete: function (results) {
              const data = results.data
              let csvError = csvCheck(data, "Direction1");
              let csvError2 = fileDirectionCheck(fileName1, data, "Direction1");
              if (csvError === false && csvError2 === false) {
                toast.info("Direction 1 Uploaded");
                setFile1(data);
              }
            },
          });

        };

        let dir2Data = null;
        reader2.readAsBinaryString(data.dir2[0]);
        reader2.onload = (e) => {
          dir2Data = e.target.result;

          Papa.parse(dir2Data, {
            skipEmptyLines: true,
            complete: function (results) {
              const data = results.data
              let csvError = csvCheck(data, "Direction2");
              let csvError2 = fileDirectionCheck(fileName2, data, "Direction2");
              if (csvError === false && csvError2 === false) {
                toast.info("Direction 2 Uploaded");
                setFile2(data);
              }
            },
          });
        };
      }
    } catch (error) {
      setloading(false);
      toast.error("File Error");
    }
  };

  const axiosReq = reqData => axios.post(process.env.REACT_APP_CONVERTER_URL + '/api/convert', reqData, {
    headers: {
      'Authorization': 'Bearer ' + apiAccessToken.accessToken,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  })

  const xmlSubmit = async () => {
    setErrResp({})
    const { xml, dataList, selected, numberOfAlignments, epsgCode } = xmlData

    const alignReq = []
    selected.map((a, i) => {
      const singleRec = dataList.find(b => b.idx === a)
      alignReq[i] = {
        direction: !!singleRec && !!singleRec.direction ? singleRec.direction : '',
        idx: !!singleRec && !!singleRec.idx ? singleRec.idx : 0,
        start: !!singleRec && !!singleRec.start ? singleRec.start : 0,
        step: !!singleRec && !!singleRec.step ? singleRec.step : 10,
        stop: !!singleRec && !!singleRec.stop ? singleRec.stop : 0
      }
      return a
    })
    try {
      setXmlLoader(true)
      const resp = await axiosReq({
        alignments: alignReq,
        name: !!dataList[0] && !!dataList[0].name ? dataList[0].name : '',
        numberOfAlignments,
        xml,
        coordinateSystem: epsgCode ? epsgCode.replace('EPSG:', '') : DEFAULT_EPSG_CODE
      })

      if (!!resp && !!resp.data && !!resp.data.dir1 && !!resp.data.dir2) {

        Papa.parse(resp.data.dir1, {
          skipEmptyLines: true,
          complete: function (results) {
            setFile1(results.data)
          }
        });

        Papa.parse(resp.data.dir2, {
          skipEmptyLines: true,
          complete: function (results) {
            setFile2(results.data)
          }
        });
        toast.info('Uploading...')
      }
      setXmlLoader(false)
    } catch (e) {
      setXmlLoader(false)
      const errRes = e.response
      if (!!errRes && !!errRes.data) {
        if (!!errRes.data.ModelState) {
          setErrResp({ ...errResp, ...errRes.data.ModelState })
        } else if (!!errRes.data.Message) {
          setErrResp({ ...errResp, otherError: errRes.data.Message })
        } else {
          setErrResp({ ...errResp, otherError: 'Error During CSV Generation' })
        }
      }
      console.log('Error During CSV Generation:', !!e.response ? e.response : e)
    }
  }

  useEffect(() => {
    if (file1 && file2) {
      setloading(true)
      const jsonArrayDir1 = handleCSVArr(file1)
      const jsonArrayDir2 = handleCSVArr(file2)
      //mutationAlternative(projectId, props.url, jsonArrayDir1, jsonArrayDir2);

      const mutationAlternativeNew = (() => {
        mutationAlternativeFunction({
          variables: {
            project_id: projectId,
            dir1: jsonArrayDir1,
            dir2: jsonArrayDir2,
          }
        }).then(() => setData())
          .catch((error) => {
            setErrorMessage(error.message)
            setloading(false)
          })

      })
      //calling the function // or you can use function
      mutationAlternativeNew()
      //()
      // when both files are updated combine them as json and send to API
      // ADD SEGMENT
      /*addAlternative({
        variables: {
          dir1: jsonArrayDir1,
          dir2: jsonArrayDir2,
        }
        // ,
        // refetchQueries: [{ query: GET_VALUES }],
      });*/
      // after that set files backto null
      setFile1(null);
      setFile2(null);
      //setRefreshProject(!refreshProject);
      //setData();

    }

    // eslint-disable-next-line
  }, [file1, file2]);

  useEffect(() => {
    setGlobalBrandCrumb("Alignments")
    // eslint-disable-next-line
  }, [])

  const validateInputs = (name, value) => {
    switch (name) {
      case 'name': {
        if (!!value && !CSV_NAME_REGEX.test(value)) {
          return 'Specail Character not allowed'
        } else if (!!value && !/\D/.test(value)) {
          return 'Only numbers not allowed'
        }
        return ''
      }
      case 'start':
      case 'stop':
      case 'step': {
        if (value < 0 && value !== 0) {
          return 'Value should be greater than 0'
        }
        return ''
      }
      case 'epsgCode': {
        if (!!value && !isCordValid(value)) {
          return `Value should be like this: ${DEFAULT_EPSG}`
        }
        return ''
      }
      default:
        return null
    }
  }

  const handleBlurNFocus = (e, onFocus = true) => {
    const { name, value } = e.target
    const nameVal = name.replace(`_${activeXMLAlign}`, '')

    if (onFocus) {
      delete errResp[`input.${nameVal}`]
      delete errResp.otherError
      setErrResp({ ...errResp })
    } else {
      const nameVal = name.replace(`_${activeXMLAlign}`, '')
      setErrResp({ ...errResp, [`input.${nameVal}`]: validateInputs(nameVal, value) })
    }
  }

  const updateXmlData = info => setXMLData({ ...xmlData, ...info })

  const handleReaderEvent = event => {
    if (event.type === 'loadstart') {
      setXmlLoader(true)
    } else if (event.type === 'loadend') {
      setXmlLoader(false)
    }
  }

  const addReaderListeners = reader => {
    reader.addEventListener('loadstart', handleReaderEvent)
    reader.addEventListener('loadend', handleReaderEvent)
  }

  const handleXmlChoose = data => {
    const { files } = data.target
    const reader = new FileReader()

    if (!files.length) {
      updateXmlData(xmlDefaultState)
      return false
    }

    setXmlLoader(true)

    // Checking format
    if (files[0].type !== "text/xml") {
      setXmlLoader(false)
      toast.error("Please insert a XML file")
      return false
    }
    addReaderListeners(reader)

    reader.readAsBinaryString(files[0])
    reader.onload = (e) => {
      // HINT: For now adding temp fix
      const result = (e.target.result).replace("ï»¿", "")
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(result, "text/xml")

      if (xmlDoc.getElementsByTagName('parsererror').length) {
        toast.error('LandXML file is corrupted or unsupported. Check the user manual for more information')
        setXmlLoader(false)
        updateXmlData(xmlDefaultState)
        return false
      }
      const coordinateSys = xmlDoc.getElementsByTagName("CoordinateSystem")

      let epsgCode = 'EPSG:'
      const alignmentListArr = []
      let alignmentNumb = 1
      for (var i = 0; i < coordinateSys.length; i++) {
        epsgCode = epsgCode + coordinateSys[i].getAttribute('epsgCode')
      }
      if (!isCordValid(epsgCode)) {
        setErrResp({ ...errResp, ['input.epsgCode']: `Value should be like this: ${DEFAULT_EPSG}` })
      } else {
        delete errResp['input.epsgCode']
        setErrResp({ ...errResp })
      }

      const alignmentList = xmlDoc.getElementsByTagName("Alignments")
      const alignList = []
      const dataListOpts = []
      const selectedListOpts = []

      if (!!alignmentList && !alignmentList.length) {
        toast.error("Invalid file, Alignments not exist.")
      }

      for (var j = 0; j < alignmentList.length; j++) {
        const alignmentElem = alignmentList[j].children
        alignmentNumb = alignmentElem.length
        for (var k = 0; k < alignmentElem.length; k++) {
          const name = alignmentElem[k].getAttribute('name')
          const start = floatWithDeci(alignmentElem[k].getAttribute('staStart'))
          const stop = Math.round(alignmentElem[k].getAttribute('length'))
          if (alignmentElem.length >= 1 && (k <= 1)) {
            selectedListOpts.push(k)
            dataListOpts.push({ name, start: (start < 0 ? 0 : start), stop, step: 20, direction: k === 0 ? 'East' : 'West', idx: k })
          }

          alignList.push({ name, idx: k })
          alignmentListArr.push({ name, start: (start < 0 ? 0 : start), stop, step: 20, direction: 'East', idx: k })

          if ((k === 0) && (!!name && !CSV_NAME_REGEX.test(name))) {
            setErrResp({
              ...errResp,
              ...{
                ...errResp.input,
                input: {
                  name: validateInputs('name', name)
                }
              }
            })
          }
        }
      }
      updateXmlData({
        epsgCode,
        useSame: false,
        alignmentOpts: alignList,
        selected: selectedListOpts,
        dataList: dataListOpts,
        numberOfAlignments: alignmentNumb > 2 ? 2 : alignmentNumb, // HINT: For now, we're supporting 1 and 2 only
        alignmentListArr, xml: result.toString()
      })
    }
    setXmlLoader(false)
  }

  const validateForm = () => {
    let isValid = true
    const { dataList, epsgCode } = xmlData
    if ((activeTab === 'xml') &&
      (!epsgCode ||
        (!!dataList[0] && !dataList[0].name) ||
        (!!errResp && (!!errResp['input.epsgCode'] || !!errResp['input.name'])) ||
        (!!epsgCode && !isCordValid(epsgCode))
      )) {
      isValid = false
    }
    return isValid
  }

  const isCsvActive = activeTab === 'csv'

  const rederCodition = () => {
    if (errorMessage) {
      return <p>Error: {errorMessage}</p>
    }
    else if (!!modalShow) {
      return (
        <AddAlignment
          onSubmit={handleSubmit(isCsvActive ? onSubmit : xmlSubmit)}
          errResp={errResp}
          inputRef={register}
          xmlData={xmlData}
          xmlLoader={xmlLoader}
          setXMLData={setXMLData}
          handleBlurNFocus={handleBlurNFocus}
          setActiveXMLAlign={setActiveXMLAlign}
          handleXmlChoose={handleXmlChoose}
          updateXmlData={updateXmlData}
          isCsvActive={isCsvActive}
          setActiveTab={setActiveTab}
          validateForm={validateForm}
        />
      )
    }
    else if (alignments && alignments.length > 100) {
      return (<p>Too many alternatives. Should be less than 100. [Alpha Phase]</p>);
    }
  };

  const handleSearchSubmit = (e, data) => {
    const serachdata = watch()
    setQuerySearch(serachdata.Search)
  }

  const headersTable = ["S.No", "Alignment", "Start Year", "End Year", "Forecast", "Action"]

  const isDataFound = alignments && !!alignments.length
  const globalProjInfo = !!projInfo && Object.keys(projInfo).length ? projInfo : false

  return (
    <Container className="black-n-white">
      {(!!loading || !!isProcessing) && <SpinLoader />}
      <Row>
        <Popup show={modalShow} onHide={() => {
          setModalShow(false)
          setTrafficModal(false)
          setErrorMessage(false)
        }}>
          {rederCodition()}
        </Popup>
      </Row>

      {
        !!trafficModal && <CopyTraffic
          alignments={alignments}
          projectId={projectId}
          trafficModal={trafficModal} onHide={() => {
            setModalShow(false)
            setTrafficModal(false)
            setErrorMessage(false)
          }} />
      }
      <Row className="pt-20">
        <Col>
          <Container fluid>
            <Row>
              {
                isDataFound && !!globalProjInfo ?
                  <>
                    <Col xs={2}>
                      <form style={{ paddingTop: "10px" }}>
                        <TextField id="standard-basic" type="text" variant="standard" inputRef={register} name="Search" onChange={handleSearchSubmit} placeholder="Search by alignment..." />
                      </form>
                    </Col>
                    <Col xs={3} />
                  </> :
                  <Col xs={5} className="no-altr-msg">{!!isProcessing ? 'Processing...' : 'There are no alternatives in the Database, add some to view.'}</Col>
              }
              <Col xs={!!alignments && (alignments.length > 1) ? 2 : 5} className='f-end-flex'><CostCircle /></Col>
              {!!alignments && alignments.length > 1 && <Col xs={3}><Button variant="primary" className="copy-traffic" id="btn" onClick={() => {
                setModalShow(false)
                setTrafficModal(true)
              }}>
                Copy Reference Traffic
              </Button></Col>}
              <Col xs={2}>
                <Button variant="primary" id="btn" style={{
                  float: 'right',
                  marginBottom: "auto"
                }} onClick={() => setModalShow(true)}>
                  <AddIcon />
                  Add Alignment
                </Button>
              </Col>
            </Row>
          </Container>
          <br />
          <Row >
            <Col>
              {isDataFound &&
                <Table striped hover size="sm"  >
                  <thead>
                    <tr style={{ backgroundColor: WHITE_1 }}>
                      {headersTable.map((item, index) => (
                        <th key={index} style={{ color: BLACK_1 }}>{item}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="t-body-v-middle">
                    {!!globalProjInfo && alignments &&
                      alignments.filter(item => {
                        return item.name.toLowerCase().includes(querySearch.toLowerCase())
                      })

                        .map((alternative, index) => (
                          <tr className="black-n-white" style={{ alignContent: "center", }} key={index}>
                            <td>{(activePage === 1) ? (index + 1) : ((index + 1) + (PER_PAGE * (activePage - 1)))}</td>
                            <td>{alternative.name}</td>
                            <td>{globalProjInfo.start_year}</td>
                            <td>{globalProjInfo.end_year}</td>
                            <td>
                              {(!!adjsutedListCO2_new.length && !!adjsutedListCost_new.length && !!adjsutedListEnergy_new.length) ?
                                <div className='stats-bar-holder'>
                                  <div className='energy-bar' style={{ width: `${((adjsutedListEnergy_new[index] * 100) / 1)}%` }} />
                                  <div className='cost-bar' style={{ width: `${((adjsutedListCost_new[index] * 100) / 1)}%` }} />
                                  <div className='co2-bar' style={{ width: `${((adjsutedListCO2_new[index] * 100) / 1)}%` }} />
                                </div>
                                :
                                <p>loading...</p>}
                            </td>
                            <td style={{ width: "15%" }}>
                              <Container className="no-shadow">
                                <Tooltip title="View & Edit">
                                  <Button bsPrefix='edit-round-btn' onClick={() => redirectButton(alternative.alternative_id)}><Icon name='edit' /></Button>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <Button bsPrefix='delete-round-btn' onClick={() => {
                                    confirmAlert({
                                      customUI: values => <ConfirmAlertPopup {...values} onConfirm={() => deletAlternative(alternative.alternative_id)} />
                                    })
                                  }}>
                                    <Icon name='trash' /></Button>
                                </Tooltip>
                              </Container></td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              }
              {!!isDataFound && <AlignmentPageWidget />}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container >
  )
}

export default Summary;
