import React from 'react'

const Edit = ({
    fill = '#172679',
    height = 44,
    width = 44,
    style = {},
    viewBox = '0 0 44 44'
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style}>
        <g transform="translate(14 12.996)"><g transform="translate(0 1.289)">
            <path fill={fill} d="M14.785,41.851a.643.643,0,0,0-.643.643v6.428a.643.643,0,0,1-.643.643H1.929a.643.643,0,0,1-.643-.643V36.066a.643.643,0,0,1,.643-.643H9.643a.643.643,0,1,0,0-1.286H1.929A1.929,1.929,0,0,0,0,36.066V48.923a1.929,1.929,0,0,0,1.929,1.929H13.5a1.929,1.929,0,0,0,1.929-1.929V42.494A.643.643,0,0,0,14.785,41.851Z" transform="translate(0 -34.137)" />
        </g>
            <g transform="translate(3.857 0.004)"><g transform="translate(0 0)">
                <path fill={fill} d="M115.831.725a2.461,2.461,0,0,0-3.481,0l-8.468,8.467a.65.65,0,0,0-.155.251L102.442,13.3a.643.643,0,0,0,.609.846.653.653,0,0,0,.2-.033l3.857-1.286a.643.643,0,0,0,.251-.156L115.83,4.2A2.461,2.461,0,0,0,115.831.725ZM114.921,3.3l-8.357,8.357-2.5.834.831-2.494,8.36-8.357a1.175,1.175,0,1,1,1.662,1.66Z" transform="translate(-102.409 -0.004)" />
            </g></g>
        </g>
    </svg>
)

export default Edit