import React, { Fragment, useState, useEffect, useContext } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { requestBody4 } from "../../../utils/aiRequestBodyUtils";
import FuelSaveGlobalContext from "../../../context/FuelSaveGlobalContext";
// import SectionalAnalysisMap from "./SectionalAnalysisMap";
// import ChartMixedGroundLevelAlongRoad from "./ChartMixedGroundLevelAlongRoad";
import "./sectional.css";
// import { numberFormat } from "../../../utils/numberFormat";
import SectionalAnalysisIndividual from "./SectionalAnalysisIndividual"
import SpinLoader from "../../common/SpinLoader";
import { MIN_YEAR } from "../../../constant";
import { presumptionObj } from "../../../constant/helpers";
import AlignmentPageWidget from "../summary/AlignmentPageWidget";

const SectionalAnalysis = () => {
  const {
    // mapCoordinateId, setMapCoordinateId,
    // mapCentreIdData, setMapCentreIdData,
    // chainage,
    // setChainage,
    // fcCar,
    // setFcCar,
    // fcTrucks,
    // setFcTrucks,
    // hilliness,
    // setHilliness,
    // speedProfileCars,
    // setSpeedProfileCars,
    // speedProfileTrucks,
    // setSpeedProfileTrucks,
    // zlevel,
    // setZlevel,
    // curvature,
    // setCurvature,
    // segment,
    // setSegment,
    // mapCentreIdData,
    setMapCentreIdData,
    previousMapCentreIdData,
    setPreviousMapCentreIdData,
    // editSectionalAnalysisChart,
    // setEditSectionalAnalysisChart,
    // globalBrandCrumb,
    setGlobalBrandCrumb
  } = useContext(FuelSaveGlobalContext);
  const { carConfig, truckConfig, projectId } = useSelector(state => state.global)
  const { alignments, activePage, isProcessing } = useSelector(state => state.alignments)
  const { presumptions } = useSelector(state => state.presumptions)

  // HINT: Not in use, set constant value
  const baseAlternative = null
  const startYear = MIN_YEAR
  const endYear = 2041

  // const [baseAlternative, setBaseAlternative] = useState(null);
  // const [startYear, setStartYear] = useState(2020);
  // const [endYear, setEndYear] = useState(2041);

  // const [dailySavings, setDailySavings] = useState(null);
  // const [forecastSavings, setForecastSavings] = useState(null);
  // const [gradientPercent, setGradientPercent] = useState(null);
  const [localLoading, setLocalLoading] = useState(true)
  // const [
  //   averagePositiveGradientDecreasePercent,
  //   setAveragePositiveGradientDecreasePercent,
  // ] = useState(null);
  // const [maxGradientDecreasePercent, setMaxGradientDecreasePercent] = useState(
  //   null
  // );

  const getDailyData = async (
    startSection,
    endSection,
    startYearData,
    endYearData,
    segmentsDir1,
    segmentsDataDir1,
    presumptionsData
  ) => {
    const presumptions = presumptionObj(presumptionsData[0])

    const startYear = startYearData ? startYearData : 2020;
    const endYear = endYearData ? endYearData : 2041;

    const requestBodyDir1 = {
      return_option: 4,
      sectional_start: startSection ? startSection : 3600,
      sectional_end: endSection ? endSection : 6000,
      start_year: startYear,
      end_year: endYear,
      fuel_distribution_cars: carConfig,
      fuel_distribution_trucks: truckConfig,
      sensitivity_param_factors: {
        speed: 1,
        fuelprice: 1,
        emission: 1,
        AADTheavy: 1,
      },
      presumptions: presumptions,
      segments: segmentsDir1,
      segments_data: segmentsDataDir1,
    };

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    const AI_API_KEY = process.env.REACT_APP_AI_FUNCTION_API_KEY;
    const urlToken = process.env.REACT_APP_AI_FUNCTION_URL + '/api/HttpTrigger1?code=' + AI_API_KEY;

    try {
      const [responseDir1] = await Promise.all([
        axios.post(urlToken, endYear ? requestBodyDir1 : requestBody4, config),
      ]);

      return [responseDir1];
    } catch (err) {
      // throw new Error('Unable to get a response.')
      return null;
    }
  };

  useEffect(() => {
    setMapCentreIdData(null);
    setPreviousMapCentreIdData(null);
    // setDailySavings(null);
    // setForecastSavings(null);
    // setGradientPercent(null);
    // setAveragePositiveGradientDecreasePercent(null);
    // setMaxGradientDecreasePercent(null);
    setPreviousMapCentreIdData(null)

    // eslint-disable-next-line
  }, [baseAlternative, projectId]);

  // useEffect(() => {
  //   setBaseAlternative(null);
  // }, [projectId]);

  useEffect(() => {
    setLocalLoading(true)
    async function setData() {

      if (
        previousMapCentreIdData &&
        baseAlternative &&
        presumptions
      ) {

        // setDailySavings(null);
        // setForecastSavings(null);
        // setGradientPercent(null);
        // setAveragePositiveGradientDecreasePercent(null);
        // setMaxGradientDecreasePercent(null);

        let startSection = previousMapCentreIdData[0] ? previousMapCentreIdData[0].chainage : null;
        let endSection = previousMapCentreIdData[1] ? previousMapCentreIdData[1].chainage : null;

        if (startSection > endSection) {
          const dummy = startSection;
          startSection = endSection;
          endSection = dummy;
        }

        const dailyData = await getDailyData(
          startSection,
          endSection,
          startYear,
          endYear,
          baseAlternative.segments,
          baseAlternative.segments_data,
          presumptions
        );

        if (dailyData) {
          const savingsData = dailyData[0].data.alternative;
          if (savingsData) {
            // setDailySavings(savingsData.daily);
            // setForecastSavings(savingsData.forecast);
            // setGradientPercent(savingsData.gradient_percent);
            // setAveragePositiveGradientDecreasePercent(
            //   savingsData.average_positive_gradient_decrease_percent
            // );
            // setMaxGradientDecreasePercent(
            //   savingsData.max_gradient_decrease_percent
            // );
          }
        }
      }
    }

    setData();
    setLocalLoading(false)

    // eslint-disable-next-line
  }, [previousMapCentreIdData]);

  useEffect(() => {
    setGlobalBrandCrumb("Sectional Analysis")

    // eslint-disable-next-line
  }, [])

  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    setExpanded(false)

    // eslint-disable-next-line
  }, [activePage])

  return (
    <>
      {(localLoading || isProcessing) && <SpinLoader />}
      {alignments && alignments.map((a, index) => (
        <Fragment key={index}>
          <SectionalAnalysisIndividual
            key={`sectionalDirection1-${index}`}
            directions={a.directions[0]}
            alternative_id={a.alternative_id}
            direction={a.directions[0].direction}
            start_year={a.start_year}
            end_year={a.end_year}
            directions_id={a.directions[0].directions_id}
            name={a.name}
            handleChange={handleChange}
            expanded={expanded}
          />
          <SectionalAnalysisIndividual
            key={`sectionalDirection2-${index}`}
            directions={a.directions[1]}
            alternative_id={a.alternative_id}
            direction={a.directions[1].direction}
            start_year={a.start_year}
            end_year={a.end_year}
            directions_id={a.directions[1].directions_id}
            name={a.name}
            handleChange={handleChange}
            expanded={expanded}
          />
        </Fragment>
      ))}
      {!!alignments && !!alignments.length && <AlignmentPageWidget />}
    </>
  )
}


export default SectionalAnalysis;
