import React, { useEffect } from 'react'
import { Table } from "react-bootstrap"
import { colorCodes } from '../../../../constant/colors'

const { GREEN_2, YELLOW_1, RED_1 } = colorCodes
const ResponseTable = ({ formData, baseId, alignmentState, setIsLoading }) => {
    let refTotal = 0
    const refCase = formData.find(a => a.baseId === baseId)
    if (!!refCase) {
        refTotal = parseInt(parseInt(refCase.annualCo2 || 0) + parseInt(refCase.investment_emssn || 0) + parseInt(refCase.maintainance_emssn || 0))
    }

    const isCalculating = !!alignmentState && (
        (alignmentState.alignments.length && !alignmentState.cdrAlignments.length) ||
        (alignmentState.alignments.length && alignmentState.cdrAlignments.length && !formData.length))


    useEffect(() => {
        setIsLoading(isCalculating)
        // eslint-disable-next-line
    }, [isCalculating])

    return (
        <div className="response-table-holder center-flex f-column">
            <Table hover size="sm">
                <thead>
                    <tr>
                        <th >S.No</th>
                        <th >Alignment Name</th>
                        <th >Waywize CDR</th>
                        <th >CPT</th>
                    </tr>
                </thead>
                <tbody className="t-body-v-middle">
                    {!!formData && !!formData.length &&
                        formData.map((f, idx) => {
                            const co2Sum = parseInt(parseInt(f.annualCo2 || 0) + parseInt(f.investment_emssn || 0) + parseInt(f.maintainance_emssn || 0))
                            let backgroundColor = null
                            const calculation = Math.round(((co2Sum - refTotal) / refTotal) * 100) / 100 || 0

                            if (calculation < -0.10) {
                                backgroundColor = GREEN_2
                            } else if ((calculation > -0.10) && (calculation < 0.10)) {
                                backgroundColor = YELLOW_1
                            } else if (calculation > 0.10) {
                                backgroundColor = RED_1
                            }
                            return (
                                <tr key={idx}>
                                    <td width='10%'>{idx + 1}</td>
                                    <td>{f.name}</td>
                                    <td>{f.baseId === baseId ? 'Reference' : <div className="ref-color" style={{ backgroundColor }} />}</td>
                                    <td>{calculation}</td>
                                </tr>
                            )
                        })}
                </tbody>
            </Table>
        </div>
    )
}

export default ResponseTable