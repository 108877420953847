import React from 'react'
import Edit from './Edit'
import Plus from './Plus'
import Trash from './Trash'
import User from './User'

const Icon = ({ name, ...rest }) => {
    switch (name) {
        case 'user':
            return <User {...rest} />
        case 'edit':
            return <Edit {...rest} />
        case 'plus':
            return <Plus {...rest} />
        case 'trash':
            return <Trash {...rest} />
        default:
            return null
    }
}

export default Icon
