import React from 'react'
import { useHistory } from 'react-router-dom'
import { currentFullYear } from '../../../constant/helpers'
import './index.scss'

const About = () => {
    const history = useHistory()
    return (
        <div className="about-us-container">
            <div className="about-us-header" onClick={() => history.push('/')}>
                <img src={require("../../../assets/images/about-logo.png")} alt="FuelSave" />
            </div>
            <div className="about-us-content">
                <div className="about-us-section">
                    <div className="about-us-heading">About Fuel Save</div>
                    <div className="about-us-desc">
                        <p>
                            Save CO2 and costs by changing only the most fuel affecting road segments Traffic accounts for more than 90% of the road’s total greenhouse gas emissions. Construction accounts for about 5% of the road’s total greenhouse gas emissions. Tiny changes in hilliness and curvature can make huge differences in energy consumption over a road’s life cycle. It’s time we shift our focus to what really matters.
                        </p>
                        <p>
                            Fuelsave Road design is a digital tool that helps road designers and road project managers make better decisions. It combines forecasts of traffic flow and vehicle electrification with a model that describes how road parameters affect energy consumption.
                        </p>
                    </div>
                </div>

                <div className="about-us-section">
                    <div className="about-us-heading">Version 1.2.0</div>

                    <div className="version-welcome-msg">Welcome to the v1.2.0 release of FuelSave. There are many updates in this version that we hope you will like, some of the highlights include:</div>

                    <div className="about-us-desc">
                        <ul>
                            <li>User can change the chainage start-index point on the map</li>
                            <li>Split here button placed on exact clicked point, when user try to add a new segment</li>
                            <li>Optimized map filter page for comparison page and Segment setting page</li>
                            <li>Added pagination for alignment module</li>
                            <li>Added CDR module where user can compare alignment carbon emission</li>
                            <li>Now user can save investment carbon and maintenance carbon value with annual CO<sub>2</sub> of alignment (In CDR module)</li>
                            <li>Added new county, that list will be served on setting page</li>
                            <li>Now users can save currency and country along with the project setting</li>
                            <li>Infinite loader has been fixed in case of Alignments and Comparison screen</li>
                        </ul>
                    </div>
                </div>

                <div className="about-us-section">
                    <div className="about-us-heading">Version 1.1.0</div>

                    <div className="version-welcome-msg">Welcome to the v1.1.0 release of FuelSave. There are many updates in this version that we hope you will like, some of the highlights include:</div>

                    <div className="about-us-desc">
                        <ul>
                            <li>Shifted start/end year value on project level instead of alignment</li>
                            <li>Added Loading spinner, while system is reading selected LandXML file (In case of a large file)</li>
                            <li>Removed all terminal warnings for FE</li>
                            <li>Updated project settings will be visible on the setting module in real-time</li>
                            <li>Now, flash message will be rendered on UI, in case of session expired due to screen ideal</li>
                            <li>LandXML functionality updated with custom choice of alignments</li>
                        </ul>
                    </div>
                </div>

                <div className="about-us-section">
                    <div className="about-us-heading">Version 1.0.0</div>
                    <div className="version-welcome-msg">Welcome to the v1.0.0 release of FuelSave. There are many updates in this version that we hope you will like, some of the highlights include:</div>
                    <div className="about-us-desc">
                        <ul>
                            <li>Updated Navbar & Sidebar UI</li>
                            <li>Added LandXML feature for alignment module</li>
                            <li>Fixed Cost, CO<sub>2</sub> and Energy map under Forecasts module</li>
                            <li>Improved some points under comparision map's module. Like: x-axis range system, active point for BarChart</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="about-us-footer" onClick={() => history.push('/')}>
                Copyright © {currentFullYear()} Waywize All Rights Reserved
            </div>
        </div>
    )
}

export default About