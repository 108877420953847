import React from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setCountry } from '../../../../actions/global'

const FuelDistribution = ({ setCurrencyState, setCountryId }) => {
    const { countries, country } = useSelector(state => state.global)
    const dispatch = useDispatch()

    const setCountryNCurrency = c => {
        setCurrencyState(c.currency)
        setCountryId(c.country_id)
        dispatch(setCountry(c.country_name))
    }

    return (
        <DropdownButton
            id="other-button"
            className="mb-4 mt-4"
            title={!country ? "Change Fuel Distribution" : `${country} chosen `}>
            <Dropdown.ItemText><b>Fuel Distribution values by country</b></Dropdown.ItemText>
            {countries.map((c, i) =>
                <Dropdown.Item key={i} as="button" onClick={() => setCountryNCurrency(c)}>{c.country_name} forecast</Dropdown.Item>)}
        </DropdownButton>
    )
}

export default FuelDistribution