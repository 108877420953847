import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Form, Container, Button } from 'react-bootstrap'
import { fetchAllProjects } from '../../../actions/project'
import './project.scss'
import { addNewProject } from '../../../utils/project'

const AddProjectPopup = ({
    setIsLoading,
    isLoading = false, newProjectName, show, onHide
}) => {
    const dispatch = useDispatch()
    const currentDate = new Date()

    const [pName, setPName] = useState('')
    const [startYear, setStartYear] = useState(currentDate.getFullYear())
    const [endYear, setEndYear] = useState(2080)

    const handleInputChange = (name, e) => {
        const { value } = e.target
        switch (name) {
            case 'pName':
                setPName(value)
                break;
            case 'start_year':
                setStartYear(parseInt(value))
                break;
            case 'end_year':
                setEndYear(parseInt(value))
                break;
            default:
                break;
        }
    }

    const validateInput = name => {
        switch (name) {
            case 'name':
                return !pName
            case 'start_year':
                return !startYear || startYear < 2020 || endYear < startYear
            case 'end_year':
                return !endYear || endYear > 2080 || endYear < startYear
            default:
                return false
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const data = await addNewProject({
                project_name: pName,
                start_year: parseInt(startYear),
                end_year: parseInt(endYear)
            })

            setIsLoading(false)
            if (!!data && !!data.data && !!data.data.data && !!data.data.data.addProject) {
                onHide()
                dispatch(fetchAllProjects())
            }
        } catch (e) {
            setIsLoading(false)
            console.log('createNewProject Error:', e)
        }
    }

    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Add Project</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Group className="mb-3" controlId="project_name">
                                <Form.Label>Project Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter project name" value={newProjectName} onChange={e => handleInputChange('pName', e)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="start_year">
                                <Form.Label>Start Year</Form.Label>
                                <Form.Control type="text" placeholder={2020} value={startYear} onChange={(e) => handleInputChange('start_year', e)} isInvalid={validateInput('start_year')} />
                                <Form.Control.Feedback type="invalid">You can only choose value between 2020 and 2080</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="end_year">
                                <Form.Label>End Year</Form.Label>
                                <Form.Control type="text" placeholder={2080} value={endYear} onChange={(e) => handleInputChange('end_year', e)} isInvalid={validateInput('end_year')} />
                                <Form.Control.Feedback type="invalid">You can only choose value between 2020 and 2080</Form.Control.Feedback>
                            </Form.Group>
                            <button className="btn-project simple-round-radius" disabled={isLoading || !pName || !startYear || !endYear}>{isLoading ? 'Loading...' : 'Submit'}</button>
                        </Form.Group>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button id="btn-project" onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddProjectPopup