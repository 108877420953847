import React, { useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from 'react-redux'
import msalObj from './services/MsalService'
import "./App.scss";
import NavBar from "./views/common/NavBar";
import NavBarLogin from "./views/common/navbarLogin";
import Login from "./views/content/Login/Login";
import DailyComparisons from "./views/content/comparison/DailyComparisons";
import SectionalAnalysis from './views/content/sectionalanalysis/SectionalAnalysis';
import Forecasts from './views/content/forecasts/Forecasts';
import { Switch, Route, useLocation } from "react-router-dom";
import SettingsIndividual from "./views/content/settings/SettingsIndividual";
import Summary from "./views/content/summary/Summary";
import Projects from "./views/content/projects/Projects"
import { reducerPrediction } from "./utils/reducer";
// for authentication
import config from "../src/services/Config";
// import { getUserDetails } from "../src/services/GraphService";
import FuelSaveGlobalContext from "./context/FuelSaveGlobalContext";
import { setContext } from '@apollo/client/link/context';
import { createHttpLink, ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { Layout, Menu } from 'antd';
import 'antd/dist/antd.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify'

import License from "./views/content/license/License";
import TermsPopUp from "./views/common/TermsPopUp";
import AuthPopup from "./views/common/AuthPopup";
import { checkTokenExp, fetchAllCountries, fetchForecastConfig, fetchGlobalProject, setIsLicense, setWinWidth, updateGlobalProject } from "./actions/global";
import ExpirationMsg from "./views/common/ExpirationMsg";
import EditProject from "./views/content/projects/EditProject";
import { fetchAllProjects } from "./actions/project";
import About from "./views/content/About";
import CDR from "./views/content/CDR";
import { colorCodes } from "./constant/colors";

const { Header, Content } = Layout
const { WHITE_1 } = colorCodes

const App = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { autoOut, isLicense, licenseValid, country, projectId } = useSelector(state => state.global)
  const { projects } = useSelector(state => state.projects)
  const [isLoading, setIsLoading] = useState(false)
  const apiAccessTokenObj = JSON.parse(localStorage.getItem("apiAccessTokenObj"))
  const [globalForcastList, forcastDispatch] = useReducer(reducerPrediction, null);   //Changed it, so you need to check with that
  const [globalForcastListNew, setGlobalForcastListNew] = useState([]);
  const [globalBrandCrumb, setGlobalBrandCrumb] = useState('Projects')
  const [editMap, setEditMap] = useState(false);
  const [addSegment, setAddSegment] = useState(false);
  /* mapCoordinateId, setMapCoordinateId, mapCentreIdData, setMapCentreIdData, previousMapCentreIdData, setPreviousMapCentreIdData
  Only limited to Setting and traffic*/
  const [mapCoordinateId, setMapCoordinateId] = useState(null);
  const [mapCentreIdData, setMapCentreIdData] = useState(null);
  const [previousMapCentreIdData, setPreviousMapCentreIdData] = useState(null);
  const [username, setUserName] = useState(null);
  // const [userEmail, setUserEmail] = useState(null);
  // const [graphAccessToken, setGraphAccessToken] = useState(null);
  const [apiAccessToken, setApiAccessToken] = useState(apiAccessTokenObj);
  const [apolloClient, setApolloClient] = useState(null);
  const [refreshProject, setRefreshProject] = useState(false);
  const [navDefaultKey, setCavDefaultKey] = useState('')
  const [navSideBarOpen, setNavSideBarOpen] = useState(true)

  // map global const values

  const [chainage, setChainage] = useState(null);
  const [fcCar, setFcCar] = useState(null);
  const [fcTrucks, setFcTrucks] = useState(null);
  const [hilliness, setHilliness] = useState(null);
  const [speedProfileCars, setSpeedProfileCars] = useState(null);
  const [speedProfileTrucks, setSpeedProfileTrucks] = useState(null);
  const [zlevel, setZlevel] = useState(null);
  const [curvature, setCurvature] = useState(null);
  const [segment, setSegment] = useState(null);
  // const [collapsed, Iscollapsed] = useState(true);
  // chartGlobal const values
  const [editSectionalAnalysisChart, setEditSectionalAnalysisChart] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', handleResize, false)
    return () => {
      window.removeEventListener('resize', handleResize, false)
    }
    // eslint-disable-next-line
  }, [])

  const handleResize = () => {
    dispatch(setWinWidth(window.innerWidth))
  }


  // const getUserProfile = async (userAccountId) => {
  //   // get token
  //   /*const sessionStorageGraphToken = JSON.parse(sessionStorage.getItem("graphAccessTokenObj"))

  //   if (sessionStorageGraphToken !== null) {
  //     const accessTokenObj = await msalObj.acquireTokenSilent({
  //       scopes: config.graphScopes,
  //       account: sessionStorageGraphToken
  //     });
  //     sessionStorage.setItem("graphAccessTokenObj", JSON.stringify(msalObj.getAllAccounts()[0]))
  //     setGraphAccessToken(accessTokenObj);
  //     setIsAuthenticated(true);
  //     sessionStorage.setItem("isAuthenticated", true)

  //     if (sessionStorageGraphToken) {
  //       const user = await getUserDetails(sessionStorageGraphToken);
  //       sessionStorage.setItem("isAuthenticated", true)
  //       setUserName(user.displayName);
  //     }
  //   }
  //   else {*/
  //   try {
  //     const accounts = msalObj.getAllAccounts();
  //     if (accounts.length <= 0) throw new Error('login_required');

  //     const accessTokenObj = await msalObj.acquireTokenSilent({
  //       scopes: config.graphScopes,
  //       account: accounts.filter(account => account.localAccountId === userAccountId)[0]
  //     });
  //     sessionStorage.setItem("graphAccessTokenObj", JSON.stringify(msalObj.getAllAccounts()[0]))
  //     setGraphAccessToken(accessTokenObj);
  //     setIsAuthenticated(true);
  //     sessionStorage.setItem("isAuthenticated", true)

  //     if (accessTokenObj) {
  //       // get user profile from Graph
  //       const user = await getUserDetails(accessTokenObj);
  //       sessionStorage.setItem("isAuthenticated", true)
  //       setUserName(user.displayName);
  //       sessionStorage.setItem("userName", user.displayName)
  //       // setUserEmail(user.mail);
  //       // setAccessToken(accessToken);
  //     }
  //   } catch (err) {
  //     console.error("Error while getting graph token:", err)
  //     setIsAuthenticated(false);
  //   }
  //   //}
  // }

  const settingNewClient = async () => {
    // Create a new ApolloClient instance with token
    const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_URL })

    //Calling UserID
    const loginDetailSessionUniqueID = localStorage.getItem('loginDetials')

    if (loginDetailSessionUniqueID) {
      const accounts = msalObj.getAllAccounts();
      // TODO: streamline the process of clearing localStorage after logout
      if (accounts.length <= 0) {
        localStorage.clear();
        window.location.href = '/';
      };

      try {
        const accessTokenObj = await msalObj.acquireTokenSilent({
          scopes: [...config.apiScopes],  //Change this auth!,,
          account: accounts.filter(account => account.localAccountId === loginDetailSessionUniqueID)[0]
        });

        if (accessTokenObj) {
          // When using apolloclient, add api access token to headers
          const authLink = setContext((_, { headers }) => {
            // return the headers to the context so httpLink can read them
            return {
              headers: {
                ...headers,
                authorization: accessTokenObj ? `Bearer ${accessTokenObj.accessToken}` : "",
              }
            };
          });
          const client = new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache()
          });
          setApiAccessToken(accessTokenObj)    //do we need this? It is for the Get Query of all the alternative since the new API needs to be set
          localStorage.setItem("apiAccessTokenObj", JSON.stringify(accessTokenObj))

          setApolloClient(client);
          return client
          //localStorage.setItem("sessionApoloClient", JSON.stringify(client))
        }
      } catch (e) {
        localStorage.clear()
        toast.error('Session expired. Please sign in again.')
      }
    }
  }

  const getApiToken = async (userAccountId) => {
    // const sessionStorageAPIToken = JSON.parse(localStorage.getItem("apiAccessTokenObj"))
    try {
      const accounts = msalObj.getAllAccounts();
      // TODO: streamline the process of clearing localStorage after logout. Also make this into a function.
      if (accounts.length <= 0) {
        localStorage.clear();
        window.location.href = '/';
      };

      const accessTokenObj = await msalObj.acquireTokenSilent({
        scopes: [...config.apiScopes],  //Change this auth!,
        forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
        account: accounts.filter(account => account.localAccountId === userAccountId)[0]
      });
      localStorage.setItem("apiAccessTokenObj", JSON.stringify(accessTokenObj))
      setApiAccessToken(accessTokenObj);
      // setIsAuthenticated(true);
      // console.log(accessTokenObj.account.name, "name")
      //Setting local username and password
      localStorage.setItem("userName", accessTokenObj.account.name)
      setUserName(accessTokenObj.account.name)
      const client = await settingNewClient(accessTokenObj)
      return client

    } catch (err) {
      console.error("Error while getting api token:", err)
      // setIsAuthenticated(false);
      localStorage.setItem("isAuthenticated", false)
    }
    //}
  };

  const login = async () => {
    const loginDetailSessionUniqueID = localStorage.getItem('loginDetials')
    if (loginDetailSessionUniqueID === null) {
      try {
        let logindetails = await msalObj.loginPopup({
          scopes: ["openid", ...config.apiScopes],  //Change this auth!
          //prompt: "select_account",
        });
        // We need to store the user information in the sessions
        localStorage.setItem("loginDetials", logindetails.uniqueId)
        //getUserProfile(logindetails.uniqueId);
        getApiToken(logindetails.uniqueId);
      } catch (err) {
        // setIsAuthenticated(false);
      }
    }
    else {
      //getUserProfile(parseInt(loginDetailSessionUniqueID));
      getApiToken(parseInt(loginDetailSessionUniqueID));
    }
  };

  // const logout = async () => {
  //   const userAgentApplication = new PublicClientApplication({
  //     auth: {
  //       clientId: config.appId,
  //       redirectUri: redirectUrl,
  //     },
  //     cache: {
  //       cacheLocation: "localStorage",
  //       storeAuthStateInCookie: true,
  //     },
  //   });

  //   msalObj.logout();
  // };

  const userOid = !!apiAccessToken && !!apiAccessToken.uniqueId ? apiAccessToken.uniqueId : false
  useEffect(() => {
    if (!!userOid) {
      if (!!projects && !projects.length) {
        dispatch(fetchAllProjects())
      }
      dispatch(fetchGlobalProject(userOid))
    }
    // eslint-disable-next-line
  }, [userOid])


  useEffect(() => {
    const userNameFetchSession = localStorage.getItem("userName")
    if (userNameFetchSession) { setUserName(userNameFetchSession); }
    async function watingforclient() {
      if (apiAccessToken) {
        const client = await settingNewClient()
        setApolloClient(client)
      }
    }
    watingforclient()
    // eslint-disable-next-line
  }, []);

  // const onCollapse = () => {
  //   Iscollapsed(!collapsed)
  // }
  useEffect(() => {
    const pageDic = {
      '/': '1',
      '/summary': '2',
      '/comparison': '3',
      '/sectional': '4',
      '/forecasts': '5',
      '/cdr': '6'
    }
    if (pageDic[location.pathname]) {
      setCavDefaultKey(pageDic[location.pathname])
    } else if ((location.pathname).startsWith('/settings/')) {
      setCavDefaultKey('2')
    } else {
      setCavDefaultKey('1')
    }
    window.scrollTo(0, 0)
  }, [location])

  function logout() {
    localStorage.clear();
    msalObj.logout();
    dispatch(setIsLicense(null))
  }

  useEffect(() => {
    if (!!apolloClient && !!isLicense) {
      dispatch(checkTokenExp(apiAccessToken.uniqueId))
      dispatch(fetchAllCountries())
    }
    window.scrollTo(0, 0)

    // eslint-disable-next-line
  }, [apolloClient, isLicense])

  useEffect(() => {
    if (!!country) {
      dispatch(fetchForecastConfig(country))
    }
    // eslint-disable-next-line
  }, [country])

  if (location.pathname === '/about') {
    return <About />
  }
  return (
    <FuelSaveGlobalContext.Provider
      value={{
        isLoading, setIsLoading,
        editMap, setEditMap,
        addSegment, setAddSegment,
        mapCoordinateId, setMapCoordinateId,
        mapCentreIdData, setMapCentreIdData,
        chainage, setChainage,
        fcCar, setFcCar,
        fcTrucks, setFcTrucks,
        hilliness, setHilliness,
        speedProfileCars, setSpeedProfileCars,
        speedProfileTrucks, setSpeedProfileTrucks,
        zlevel, setZlevel,
        curvature, setCurvature,
        segment, setSegment,
        previousMapCentreIdData, setPreviousMapCentreIdData,
        editSectionalAnalysisChart, setEditSectionalAnalysisChart,
        refreshProject, setRefreshProject,
        globalForcastList, forcastDispatch,
        apiAccessToken, setApiAccessToken,
        globalForcastListNew, setGlobalForcastListNew,
        globalBrandCrumb, setGlobalBrandCrumb,
        navSideBarOpen, setNavSideBarOpen
      }}
    >
      <div className="App" style={{ minHeight: "100vh" }}>
        {(apolloClient && isLicense !== null) ? (
          (isLicense) ? (
            <ApolloProvider client={apolloClient}>
              <Layout>
                {autoOut && <AuthPopup />}
                <Header id="header-nav" style={{ backgroundColor: WHITE_1, height: 97 }}>
                  <Menu style={{ backgroundColor: WHITE_1 }} mode="horizontal" defaultSelectedKeys={['2']}>
                    <NavBarLogin user={username} logoutCallback={logout} updateGlobalProject={id => dispatch(updateGlobalProject(id, projectId))} />
                  </Menu>
                </Header>
                <Layout style={{ minHeight: '100vh' }}>
                  <NavBar defaultKeys={navDefaultKey} />

                  <Layout style={{ padding: '0 24px 24px' }}>
                    <Content style={{ padding: 24, margin: 0, minHeight: 280 }}>
                      {!!licenseValid && <ExpirationMsg licenseValid={licenseValid} />}
                      <Switch>
                        <Route exact path={["/", "/projects"]} component={Projects} />
                        <Route exact path={["/projects/edit/:id"]} component={EditProject} />
                        <Route exact path={["/summary"]} component={Summary} />
                        <Route exact path={["/comparison"]} component={DailyComparisons} />
                        <Route exact path={["/sectional"]} component={SectionalAnalysis} />
                        <Route exact path={["/forecasts"]} component={Forecasts} />
                        <Route exact path={["/cdr"]} component={CDR} />
                        <Route exact path={["/settings/:alternative_id"]} component={SettingsIndividual} />
                      </Switch>
                      <TermsPopUp />
                    </Content>
                  </Layout>
                </Layout>
              </Layout>
            </ApolloProvider>
          ) : <License apiAccessToken={apiAccessToken} logoutCallback={logout} />
        ) : <Login authButtonMethod={login} />}
      </div>
    </FuelSaveGlobalContext.Provider>
  )
}

export default App
