import React, { useState, useContext } from 'react'
import {
    Col,
    Container,
    // Card,
    Button,
    Row,
    Table,
    ButtonGroup,
    // ToggleButtonGroup,
    ToggleButton
} from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import { useQuery, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import InputMap from './InputMap';
import { useTranslation } from 'react-i18next';
import './settings.css';
import FuelSaveGlobalContext from '../../../../context/FuelSaveGlobalContext';
import { UPDATE_SEGMENT, POST_SEGMENT, DELETE_SEGMENT, GET_ALTERNATIVE_BY_ID } from '../../../../utils/apolloutils';
// import { currentSegmentNumber, splitSegments } from './segmentutils';
import EditPopUp from './EditPopUp'


//Material UI Imports
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { correctAlternativeForMap } from "./../../../../utils/maputils"
import SpinLoader from '../../../common/SpinLoader';
import ConfirmAlertPopup from '../../../common/ConfirmAlertPopup';
import { addIntersection, deleteIntersection, editIntersection, setAlignmentInfo, setAllAlignments } from '../../../../actions/alignment';
import { getMinMaxChainageVal, getStartDistanceToSegment, getStepValue } from '../../../../constant/helpers';
import AddEditSegmentForm from './AddEditSegmentForm';
import Icon from '../../../common/Icons';
import { colorCodes } from '../../../../constant/colors';
import AddEditInterSecForm from './AddEditInterSecForm';


const TrafficInputForm = ({ alternative_id }) => {
    const {
        editMap, setEditMap,
        mapCoordinateId,
    } = useContext(FuelSaveGlobalContext);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [direction, setDirection] = useState(0)
    const [addInterSec, setAddInterSec] = useState(false)
    const { projectId } = useSelector(state => state.global)
    const { alignmentInfo, isProcessing, alignments } = useSelector(state => state.alignments)


    const { loading: fetchLoading, error } = useQuery(GET_ALTERNATIVE_BY_ID(alternative_id), {
        fetchPolicy: "network-only", // Warning: This causes the page to refetch a lot. Please check
        variables: {
            project_id: projectId
        },
        onCompleted: ((data) => {
            const alignmentResp = !!data && !!data.projects && !!data.projects[0] && !!data.projects[0].alternatives ?
                data.projects[0].alternatives : false
            if (!!alignmentResp && !!alignmentResp.node) {
                const filteredAlignments = correctAlternativeForMap(alignmentResp.node)
                dispatch(setAlignmentInfo(filteredAlignments[0]))
            }
        })
    });


    //const data = props.data
    // const [updateAlternative,
    //     { loading: updateMutationLoading, error: updateMutationError }
    // ] = useMutation(UPDATE_ALTERNATIVE);

    const refreshSegments = newSeg => {
        if (!!alignmentInfo && !!Object.keys(alignmentInfo).length) {
            const alignmentInfoObj = alignmentInfo
            if (!!alignmentInfoObj && !!alignmentInfoObj.directions) {
                alignmentInfoObj.directions[direction].segments = newSeg
            }
            dispatch(setAlignmentInfo(alignmentInfoObj))

            let allAlignments = alignments.map(a => {
                if (parseInt(a.alternative_id) === parseInt(alternative_id)) {
                    return alignmentInfoObj
                }
                return a
            })
            dispatch(setAllAlignments(allAlignments))
        }
    }

    const [updateSegment,
        { loading: updateSegmentMutationLoading, error: updateSegmentMutationError }
    ] = useMutation(UPDATE_SEGMENT, {
        onCompleted: ((data) => {
            refreshSegments(data.updateSegment)
        })
    });

    const [addSegment,
        { loading: postMutationLoading, error: postMutationError }
    ] = useMutation(POST_SEGMENT, {
        onCompleted: ((data) => {
            refreshSegments(data.addSegment)
        })
    });

    const [deleteSegment,
        { loading: postDeleteMutationLoading, error: postDeleteMutationError }
    ] = useMutation(DELETE_SEGMENT, {
        onCompleted: ((data) => {
            refreshSegments(data.deleteSegment)
        })
    });

    // choose direction 
    // const [openAlternative, setOpenAlternative] = useState(false);
    // const [alternativeId, setAlternativeId] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [segmentModalShow, setSegmentModalShow] = useState(false);
    const [segmentIndex, setSegmentIndex] = useState(0);
    const [intersecInfo, setIntersecInfo] = useState({});


    // default constant values
    const defaultSegmentAadt = 20000
    const defaultSegmentPrcHeavy = 15;
    const defaultAnnualIncCars = 1;
    const defaultAnnualIncHeavy = 1.5;

    // update variables 
    // const [segmentAadt, setSegmentAadt] = useState(defaultSegmentAadt);
    // const [prcHeavy, setPrcHeavy] = useState(defaultSegmentPrcHeavy);
    const [cars, setCars] = useState(defaultSegmentAadt * (100 - defaultSegmentPrcHeavy) / 100);
    const [trucks, setTrucks] = useState((defaultSegmentAadt * (defaultSegmentPrcHeavy) / 100) * (2 / 3));
    const [trucksWt, setTrucksWt] = useState((defaultSegmentAadt * (defaultSegmentPrcHeavy) / 100) * (1 / 3));
    const [annualIncCars, setAnnualIncCars] = useState(defaultAnnualIncCars);
    const [annualIncHeavy, setAnnualIncHeavy] = useState(defaultAnnualIncHeavy);
    // const [aName, setAName] = useState();
    // const [aStartYear, setAStartYear] = useState();
    // const [aEndYear, setAEndYear] = useState();
    // const [localAlternative, setLocalAlternative] = useState('');

    // segment update variables
    // const [segmentUpdateAadt, setSegmentUpdateAadt] = useState(null);
    // const [segmentUpdatePrcHeavy, setSegmentUpdatePrcHeavy] = useState(null);

    const [segmentToEdit, setSegmentToEdit] = useState(null)
    const [intersecToEdit, setInterSecToEdit] = useState(null)

    // useEffect(() => {
    //     console.log(data)
    //     setLocalAlternative(data)
    //     if (data){
    //     data.projects[0].alternatives.map((element)=>{
    //         console.log(element)
    //     })}
    // }, [data])


    if (error) return <p>Error fetching data</p>;

    // if (updateMutationLoading) return <img src={Logospin} alt="Loading Spinner CASE 2" style={{ animation: "App-logo-spin 5s infinite" }} />;
    // if (updateMutationError) return <p>Unable to update alternative </p>;

    if (postMutationError) return <p>Unable to add segment </p>;
    if (updateSegmentMutationError) return <p>Unable to update segment </p>;

    if (postDeleteMutationError) return <p>Unable to delete segment...</p>

    const toggleEditMap = () => {
        // setSegmentAadt(defaultSegmentAadt);
        // setPrcHeavy(defaultSegmentPrcHeavy);
        setCars(defaultSegmentAadt * (100 - defaultSegmentPrcHeavy) / 100);
        setTrucks((defaultSegmentAadt * (defaultSegmentPrcHeavy) / 100) * (2 / 3));
        setTrucksWt((defaultSegmentAadt * (defaultSegmentPrcHeavy) / 100) * (1 / 3));
        setAnnualIncCars(defaultAnnualIncCars);
        setAnnualIncHeavy(defaultAnnualIncHeavy);
        setEditMap(!editMap)
        setAddInterSec(false)
    }

    const untoggleEditMap = () => {
        setEditMap(!editMap)
        setAddInterSec(false)
    }

    const addInterSecFn = () => {
        setAddInterSec(true)
        setEditMap(true)
    }

    const toggleSegmentEdit = (segment_id, i) => {

        // setSegmentUpdateAadt(null);
        // setSegmentUpdatePrcHeavy(null);
        setCars(null);
        setTrucks(null);
        setTrucksWt(null);
        setAnnualIncCars(null);
        setAnnualIncHeavy(null);
        setSegmentModalShow(true)
        setSegmentIndex(i)

        setSegmentToEdit(segment_id);
    }

    const toggleInterSecEdit = (info) => {
        setAddInterSec(true)
        setSegmentModalShow(true)
        setIntersecInfo(info)
        setInterSecToEdit(info.intersection_id)
    }


    const handleInputChange = (name, e) => {
        const value = e.target.value;

        switch (name) {
            // alternative values
            // case 'name':
            //     setAName(value);
            //     break;
            // case 'start_year':
            //     setAStartYear(value);
            //     break;
            // case 'end_year':
            //     setAEndYear(value);
            //     break;
            // segment values
            // case 'year':
            //     setYear(value);
            //     break;
            // case 'segmentAadt':
            //     setSegmentAadt(cars + trucks + trucksWt)
            //     break;
            case 'segmentUpdateCars':
                setCars(value);
                // setSegmentAadt(value + trucks + trucksWt)
                // setPrcHeavy(Math.round((trucks + trucksWt) / (value + trucks + trucksWt)) * 100)
                break;
            case 'segmentUpdateTrucks':
                setTrucks(value);
                // setSegmentAadt(cars + value + trucksWt)
                // setPrcHeavy(Math.round((value + trucksWt) / (cars + value + trucksWt)) * 100)
                break;
            case 'segmentUpdateTrucksWt':
                setTrucksWt(value);
                // setSegmentAadt(cars + trucks + value)
                // setPrcHeavy(Math.round((trucks + value) / (cars + trucks + value)) * 100)
                break;
            // case 'prcHeavy':
            //     setPrcHeavy(Math.round((trucks + trucksWt) / (cars + trucks + trucksWt)) * 100);
            //     // setCars(segmentAadt * (100 - value) / 100);
            //     // setTrucks((segmentAadt * (value) / 100) * (2 / 3));
            //     // setTrucksWt((segmentAadt * (value) / 100) * (1 / 3));
            //     break;
            // case 'segmentUpdateAadt':
            //     setSegmentUpdateAadt(cars + trucks + value);
            //     break;
            // case 'segmentUpdatePrcHeavy':
            //     setSegmentUpdatePrcHeavy(Math.round((trucks + trucksWt) / (cars + trucks + trucksWt)) * 100);
            //     break;
            case 'cars':
                setCars(value);
                // setSegmentAadt(value + trucks + trucksWt)
                // setPrcHeavy(Math.round((trucks + trucksWt) / (value + trucks + trucksWt)) * 100)
                break;
            case 'trucks':
                setTrucks(value);
                // setSegmentAadt(cars + value + trucksWt)
                // setPrcHeavy(Math.round((value + trucksWt) / (cars + value + trucksWt)) * 100)
                break;
            case 'trucksWt':
                setTrucksWt(value);
                // setSegmentAadt(cars + trucks + value)
                // setPrcHeavy(Math.round((trucks + value) / (cars + trucks + value)) * 100)
                break;
            case 'annualIncCars':
                setAnnualIncCars(value);
                break;
            case 'annualIncHeavy':
                setAnnualIncHeavy(value);
                break;
            default:
                break;
        }
    }

    const handleDeleteSegment = async ({ segment_id }) => {
        deleteSegment({
            variables: {
                segment_id,
                //segment_data: updatedSegmentData,
                //old_segments: updatedSegments,
            }
        })
    }

    const handleOnHide = () => {
        setEditMap(false)
        setSegmentModalShow(false)
        setAddInterSec(false)
        setSegmentToEdit(null)
        setInterSecToEdit(null)
    }

    const handleSegFormSubmit = (e, info, segLength = 0) => {
        e.preventDefault()
        if (segmentToEdit !== null) {
            const { segment_id, aadt_cars, aadt_trucks, aadt_trucks_with_trailer, annual_increase_cars, annual_increase_heavy } = info
            updateSegment({
                variables: {
                    segment_id: segment_id,
                    data: {
                        //aadt: segmentUpdateAadt ? parseFloat(segmentUpdateAadt) : aadt,
                        //prc_heavy: segmentUpdatePrcHeavy ? parseFloat(segmentUpdatePrcHeavy) : prc_heavy,
                        ...(segLength > 1 && { start_index: mapCoordinateId }),
                        aadt_cars: cars ? parseFloat(cars) : aadt_cars,
                        aadt_trucks: trucks ? parseFloat(trucks) : aadt_trucks,
                        aadt_trucks_with_trailer: trucksWt ? parseFloat(trucksWt) : aadt_trucks_with_trailer,
                        annual_increase_cars: annualIncCars ? parseFloat(annualIncCars) : annual_increase_cars,
                        annual_increase_heavy: annualIncHeavy ? parseFloat(annualIncHeavy) : annual_increase_heavy
                    }
                }
            })
        } else {
            addSegment({
                variables: {
                    new_segment: {
                        directions_id: parseInt(info.directions_id),
                        year: parseInt(info.segments[0].year),
                        //aadt: parseFloat(segmentAadt),
                        //prc_heavy: parseFloat(prcHeavy),
                        aadt_cars: parseFloat(cars),
                        aadt_trucks: parseFloat(trucks),
                        aadt_trucks_with_trailer: parseFloat(trucksWt),
                        annual_increase_cars: parseFloat(annualIncCars),
                        annual_increase_heavy: parseFloat(annualIncHeavy),
                        start_index: mapCoordinateId
                    }
                }
            })

            setEditMap(false);
            setCars(null);
            setTrucks(null);
            setTrucksWt(null);
            setAnnualIncCars(null);
            setAnnualIncHeavy(null);
        }


        setSegmentModalShow(false)
        setSegmentToEdit(null)
        setInterSecToEdit(null)
    }

    const handleInterSecFormSubmit = (e, info, segLength = 0) => {
        e.preventDefault()
        console.log('inf ', info, mapCoordinateId)

        if (intersecToEdit !== null) {
            // const { segment_id, aadt_cars, aadt_trucks, aadt_trucks_with_trailer, annual_increase_cars, annual_increase_heavy } = info
            dispatch(editIntersection({
                directions_id: parseInt(info.directions_id || alignmentInfo.directions[direction].directions_id),
                update_intersection_data: {
                    old_intersection_point: info.geom_order_id,
                    new_intersection_point: mapCoordinateId
                }
            }, direction))
        } else {
            dispatch(addIntersection({
                new_intersection: {
                    directions_id: parseInt(info.directions_id || alignmentInfo.directions[direction].directions_id),
                    intersection_point: mapCoordinateId
                }
            }, { geom_order_id: mapCoordinateId }, direction))
            setEditMap(false);
            setCars(null);
            setTrucks(null);
            setTrucksWt(null);
            setAnnualIncCars(null);
            setAnnualIncHeavy(null);
        }

        setSegmentModalShow(false)
        setSegmentToEdit(null)
        setInterSecToEdit(null)
    }

    const segData = (!!alignmentInfo && !!alignmentInfo.directions && alignmentInfo.directions[direction] && alignmentInfo.directions[direction].segments_data)
        ? alignmentInfo.directions[direction].segments_data : []

    const { minChainage, maxChainage } = getMinMaxChainageVal(segData)
    const intersectionData = !!alignmentInfo && !!alignmentInfo.directions && !!alignmentInfo.directions[direction] && !!alignmentInfo.directions[direction].segments_data ?
        (alignmentInfo.directions[direction].segments_data).filter(a => !!a.a_intersection) : []
    return (
        <Container fluid style={{ padding: '10px' }}>
            {
                (!!fetchLoading || !!postMutationLoading || !!updateSegmentMutationLoading || !!postDeleteMutationLoading || !!isProcessing) && <SpinLoader />
            }

            {
                !!alignmentInfo && Object.keys(alignmentInfo).length &&
                <div>
                    {!!segmentModalShow &&
                        <>{
                            !!addInterSec ? <AddEditInterSecForm
                                segment={alignmentInfo.directions[direction].segments}
                                show={segmentModalShow}
                                segData={segData}
                                isEdit={intersecToEdit !== null}
                                onHide={handleOnHide}
                                handleSubmit={handleInterSecFormSubmit}
                                stepValue={getStepValue(segData)}
                                minChainage={minChainage}
                                maxChainage={maxChainage}
                                interSecInfo={intersecInfo}
                            /> :
                                <AddEditSegmentForm
                                    segment={alignmentInfo.directions[direction].segments}
                                    show={segmentModalShow}
                                    segData={segData}
                                    isEdit={segmentToEdit !== null}
                                    onHide={handleOnHide}
                                    handleInputChange={handleInputChange}
                                    handleSubmit={handleSegFormSubmit}
                                    segIdx={segmentToEdit !== null ? segmentIndex : 0}
                                    stepValue={getStepValue(segData)}
                                    minChainage={minChainage}
                                    maxChainage={maxChainage}
                                    alternativeData={alignmentInfo.directions[direction]}
                                />
                        }
                        </>
                    }

                    {!!modalShow && <EditPopUp
                        alternative={alignmentInfo}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />}
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            {!!alignmentInfo && alignmentInfo.alternative_id &&
                                <Table variant="dark" className="maps-table-body">
                                    <thead>
                                        <tr><td colSpan="5" align="left" style={{ fontSize: "25px", fontWeight: "bold" }}>Alignment Details</td></tr>
                                    </thead>
                                    <thead>
                                        <tr><th>Name</th><th>Action</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{alignmentInfo.name}</td>
                                            <td width="20%">
                                                <Button variant="primary" bsPrefix="btn-segment" onClick={() => setModalShow(true)}>
                                                    Edit Alternative
                                                </Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                    </tfoot>
                                </Table>}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Container fluid>
                                <Row className="d-flex ai-center">
                                    <Col><h1 className="f-left">Segments</h1></Col>
                                    <Col>
                                        <ButtonGroup>
                                            <ToggleButton
                                                key={'direction0'}
                                                id={`btn-popup`}
                                                type="radio"
                                                className="m-2"
                                                variant="secondary"
                                                name="radio"
                                                value={0}
                                                checked={direction === 0}
                                                onClick={() => { setDirection(0) }}
                                            >
                                                Direction 1
                                            </ToggleButton>
                                            <ToggleButton
                                                key={'direction1'}
                                                id={`btn-popup`}
                                                type="radio"
                                                className="m-2"
                                                variant="secondary"
                                                name="radio"
                                                value={1}
                                                checked={direction === 1}
                                                onClick={() => { setDirection(1) }}
                                            >
                                                Direction 2
                                            </ToggleButton>
                                        </ButtonGroup>
                                    </Col>
                                    <Col>
                                        {!editMap && <div className='around-flex'>
                                            <Button bsPrefix="btn-segment" className='f-right' onClick={toggleEditMap}><Icon name="plus" fill={colorCodes.WHITE_1} /> Segment</Button>
                                            <Button bsPrefix="btn-segment" className='f-right' onClick={addInterSecFn}><Icon name="plus" fill={colorCodes.WHITE_1} /> Stop Sign</Button>
                                        </div>
                                        }
                                        {editMap &&
                                            <div className='around-flex'>
                                                <div>Click on Map  {!!addInterSec ? 'to add stop sign' : 'to split it and add segment'}</div>
                                                <Button bsPrefix="btn-delete-segment" className='addsegment f-right' onClick={untoggleEditMap}>Cancel</Button>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                                <Container>
                                    {alignmentInfo.directions[direction] && <InputMap addInterSec={addInterSec}
                                        isNewSegEnable={!!modalShow} setSegmentModalShow={setSegmentModalShow}
                                        directions={alignmentInfo.directions[direction]} editMap={editMap} />}
                                </Container>
                                <br />
                                <Row>
                                    <Table className='segmenttable'>
                                        <thead>
                                            <tr>
                                                <th>{t('Segment')}</th>
                                                <th>{t('Year')}</th>
                                                <th>{t('Segment AADT')}</th>
                                                <th>{t('Start Distance')}</th>
                                                <th>{t('% heavy')}</th>
                                                <th>{t('Cars')}</th>
                                                <th>{t('Trucks')}</th>
                                                <th>{t('Trucks + T')}</th>
                                                <th>{t('Annual Increase cars')}</th>
                                                <th>{t('Annual Increase heavy')}</th>
                                                <th>{'Edit'} </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {alignmentInfo.directions[direction].segments.map((segment, i) => {
                                                const calculatedVal = Math.round((((segment.aadt_trucks || 0) + (segment.aadt_trucks_with_trailer || 0)) / ((segment.aadt_cars || 0) + (segment.aadt_trucks || 0) + (segment.aadt_trucks_with_trailer || 0))) * 100, 2)
                                                return (
                                                    <tr key={i}>
                                                        <td>{segment.start_index} </td>
                                                        <td>{segment.year} </td>
                                                        <td>{(segment.aadt_cars + segment.aadt_trucks + segment.aadt_trucks_with_trailer)}</td>
                                                        <td>{getStartDistanceToSegment(segment.start_index, segData)}</td>
                                                        <td>{isNaN(calculatedVal) ? '-' : calculatedVal}</td>
                                                        <td>{segment.aadt_cars}</td>
                                                        <td>{segment.aadt_trucks}</td>
                                                        <td>{segment.aadt_trucks_with_trailer}</td>
                                                        <td>{segment.annual_increase_cars}</td>
                                                        <td>{segment.annual_increase_heavy}</td>
                                                        <td>{<Button id="btn-segment" onClick={() => toggleSegmentEdit(segment.segment_id, i)} >Edit</Button>}
                                                        </td>
                                                        <td>{segmentToEdit !== segment.segment_id && (segment.start_index !== 0) &&
                                                            (<Button id="btn-delete" onClick={() => {
                                                                confirmAlert({
                                                                    customUI: values => <ConfirmAlertPopup {...values} onConfirm={() => handleDeleteSegment(segment)} />
                                                                })
                                                            }
                                                            }>Delete</Button>)
                                                        }
                                                            {/*  {segmentToEdit === segment.segment_id && segment.segment_order_no === 1 && ''} */}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Row>

                                <div className='center-flex mt-10'><h4>Stop Sign</h4></div>
                                <Row>
                                    <Table className='segmenttable'>
                                        <thead>
                                            <tr>
                                                <th>{t('Stop Sign Point')}</th>
                                                <th>{'Actions'} </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {intersectionData.map((interSec, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{getStartDistanceToSegment(interSec.geom_order_id, segData)}</td>
                                                        <td><Button id="btn-segment" className='mr-4' onClick={() => toggleInterSecEdit(interSec)} >Edit</Button>
                                                            <Button id="btn-delete" onClick={() => {
                                                                confirmAlert({
                                                                    customUI: values => <ConfirmAlertPopup {...values} onConfirm={() => dispatch(deleteIntersection(interSec, direction))} />
                                                                })
                                                            }
                                                            }>Delete</Button></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                    {
                                        !!intersectionData && !intersectionData.length &&
                                        <div className='center-flex w-100'>No Data Available</div>
                                    }
                                </Row>
                            </Container>
                        </AccordionDetails>
                    </Accordion>
                </div>
            }
        </Container>)
}

export default TrafficInputForm