import React from 'react'
import './index.scss'

const LandXMLInput = ({
    id = '',
    type = 'text',
    name,
    label,
    value,
    errMsg = false,
    className = '',
    placeholder = '',
    handleOnFocus,
    handleOnBlur,
    handleOnChange
}) => (
    <div className="landxml-number-input-container">
        <label className="landxml-number-input-label" htmlFor={id || name}>{label}</label>
        <input
            name={name}
            type={type}
            id={id || name}
            className={className}
            value={value}
            placeholder={placeholder}
            onFocus={e => !!handleOnFocus && handleOnFocus(e)}
            onBlur={e => !!handleOnBlur && handleOnBlur(e)}
            onChange={e => !!handleOnChange && handleOnChange(e)}
        />
        {!!errMsg && <p className="err-msg">{typeof errMsg === 'string' ? errMsg : errMsg[0]}</p>}
    </div>
)

export default LandXMLInput