import React, { useState } from "react";
// import {
//   requestBody3, fuelDistributionCarsNorway, fuelDistributionTrucksNorway,
//   fuelDistributionCarsSweden, fuelDistributionTrucksSweden
// } from "../../../utils/aiRequestBodyUtils";
import { Container, Button, Table, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
// import {
//   legend,
//   tooltips,
//   xAxes,
//   yAxes,
//   compareYear,
// } from "../../../utils/chartutils";
import "./forcastfilter.css";
import ChartMixed from "./ChartMixed";
// import { min } from "moment-timezone";
import { numberFormat } from "../../../utils/numberFormat";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
// import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Select from '@material-ui/core/Select';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import { makeStyles } from '@material-ui/core/styles';
// import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import SpinLoader from "../../common/SpinLoader";
import { getColorForMultiChart } from "../../../constant/helpers";



const ForecastsIndividual = (props) => {

  // const [baseAlternative, setBaseAlternative] = useState(null);
  const { currency } = useSelector(state => state.global)
  const [compareAlternative, setCompareAlternative] = useState(null);
  const [accordinOpen, setAccordinOpen] = useState(false)
  // const [open, setOpen] = useState(false);
  // const [alternativeSelect, setAlternativeSelect] = useState('');


  const baseAlternative = props.selectedAlternative
  // const savingsAlternativetables = props.savingsAlternativetables
  // const altIndex = props.altIndex

  // function handleFirstAlternativeClick(alternative) {
  //   setBaseAlternative(alternative);
  // }

  // function handleSecondAlternativeClick(alternative) {
  //   setCompareAlternative(alternative);
  // }

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    fontSelection: {
      fontSize: '20px',
      fontWeight: 'bold'
    }
  }));

  const classes = useStyles();

  function handleFunctionOutput() {
    const baseData = baseAlternative.forecast_data;
    const compareData = compareAlternative ? compareAlternative.forecast_data : null;
    const datasetLength = baseAlternative.forecast_data.length;
    let stepSize = Math.round(datasetLength / 3);
    let lastIndex = false

    if (stepSize % 2 !== 0) {
      stepSize--;

      // In case of odd value
      if (!!datasetLength) {
        lastIndex = datasetLength - 1
      }
    }
    const tableData = [];

    for (let i = 0; i < datasetLength; i = i + stepSize) {

      if (i < datasetLength) {
        const fuelSavings = compareData ? baseData[i].annual_fuel - compareData[i].annual_fuel : baseData[i].annual_fuel;
        const co2Savings = compareData ? baseData[i].annual_co2 - compareData[i].annual_co2 : baseData[i].annual_co2;
        const energySavings = compareData ? baseData[i].annual_energy - compareData[i].annual_energy : baseData[i].annual_energy;
        const costSavings = compareData ? baseData[i].annual_cost - compareData[i].annual_cost : baseData[i].annual_cost;

        tableData.push({
          year: baseData[i].year,
          fuel: fuelSavings,
          co2: co2Savings,
          energy: energySavings,
          cost: costSavings,
        });
      }
    }

    if (!!lastIndex) {

      const fuelSavingsL = compareData ? baseData[lastIndex].annual_fuel - compareData[lastIndex].annual_fuel : baseData[lastIndex].annual_fuel;
      const co2SavingsL = compareData ? baseData[lastIndex].annual_co2 - compareData[lastIndex].annual_co2 : baseData[lastIndex].annual_co2;
      const energySavingsL = compareData ? baseData[lastIndex].annual_energy - compareData[lastIndex].annual_energy : baseData[lastIndex].annual_energy;
      const costSavingsL = compareData ? baseData[lastIndex].annual_cost - compareData[lastIndex].annual_cost : baseData[lastIndex].annual_cost;

      tableData.push({
        year: baseData[lastIndex].year,
        fuel: fuelSavingsL,
        co2: co2SavingsL,
        energy: energySavingsL,
        cost: costSavingsL,
      });
    }

    return (
      <>
        {
          tableData.map((row, i) => {
            return (
              <tr key={i}>
                <td>{Math.round(row.year)}</td>
                <td >
                  {numberFormat(Math.round(row.fuel))}
                </td>
                <td >
                  {numberFormat(Math.round(row.co2))}
                </td>
                <td >
                  {numberFormat(Math.round(row.energy))}
                </td>
                <td >
                  {numberFormat(Math.round(row.cost))} {currency}
                </td>
              </tr>
            )
          })
        }
      </>
    );
  }

  const handleChangeAcc = () => {
    setAccordinOpen(!accordinOpen)
  }
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleChangeSelector = (event) => {
    const input = JSON.parse(event.target.value)
    if (event.target.checked) {
      if (compareAlternative) {
        setCompareAlternative([...compareAlternative.filter(c => c.alternative_id !== input.alternative_id), input])
        setAccordinOpen(true)
      }
      else {
        setCompareAlternative([input])
        setAccordinOpen(true)
      }
    } else {
      if (compareAlternative) {
        setCompareAlternative([...compareAlternative.filter(c => c.alternative_id !== input.alternative_id)])
        setAccordinOpen(true)
      }
      else {
        setCompareAlternative(null)
        setAccordinOpen(true)
      }
    }
  }

  const chartInfoData = type => {
    const baseYearLabels = [];
    const dataSets = [];

    const firstDataset = []
    baseAlternative.forecast_data.map(year => {
      baseYearLabels.push(year.year)
      if (type === 'co2') { firstDataset.push(Math.floor(year.annual_co2)) }
      else if (type === 'cost') { firstDataset.push(Math.floor(year.annual_cost)) }
      else if (type === 'energy') { firstDataset.push(Math.floor(year.annual_energy)) }
      else { firstDataset.push(Math.floor(year.annual_fuel)) }

      return year
    })

    dataSets.push({
      type: 'line',
      label: 'Base Alternative ' + baseAlternative.alternative_id,
      borderColor: 'rgb(54, 162, 235)',
      borderWidth: 2,
      fill: true,
      data: firstDataset,
    })
    if (compareAlternative) {
      compareAlternative.map((alternative, colorIndex) => {
        if (baseAlternative.alternative_id !== alternative.alternative_id) {
          const dataset = []
          alternative.forecast_data.map(year => {
            if (type === 'co2') { dataset.push(Math.floor(year.annual_co2)) }
            else if (type === 'cost') { dataset.push(year.annual_cost) }
            else if (type === 'energy') { dataset.push(year.annual_energy) }
            else { dataset.push(year.annual_fuel) }

            return year
          })
          dataSets.push({
            type: "line",
            label: "Alternative " + alternative.alternative_id,
            borderWidth: 2,
            borderColor: getColorForMultiChart(colorIndex),
            fill: false,
            data: dataset
          })
        }
        return alternative
      })
    }

    return {
      labels: baseYearLabels,
      datasets: dataSets
    }
  }

  return (
    <Container>

      {baseAlternative ? (
        <Accordion expanded={accordinOpen}>
          <AccordionSummary
            //expandIcon={<ExpandMoreIcon />}
            //onClick={handleChangeAcc}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Container>
              <Row>
                <Col xs={{ span: 10 }}>
                  <div style={{ float: "left", fontSize: "1.3vw" }}>
                    Alternative {baseAlternative.alternative_id}: {baseAlternative.name}
                  </div>
                </Col>
                <Col xs={{ span: 2 }}>
                  <div style={{ float: 'right' }}>
                    {props.savingsAlternativetables &&
                      <div className="dropdownF">
                        <Button id="filterbutton" className="dropbtn"><FilterListRoundedIcon /></Button>
                        <div className="dropdownF-content dropdownF-menu-right">
                          <FormControl className={classes.formControl}>
                            <FormGroup>
                              {props.savingsAlternativetables
                                .filter(item => {
                                  return item.alternative_id !== baseAlternative.alternative_id
                                })
                                .map((a, index) => {
                                  return (
                                    <FormControlLabel
                                      key={index}
                                      control={
                                        <Checkbox
                                          value={JSON.stringify(a)}
                                          onChange={handleChangeSelector}
                                        />
                                      }
                                      label={`Alignment ${a.alternative_id}: ${a.name}`}
                                    />
                                  )
                                })}
                            </FormGroup>
                            <FormHelperText style={{ fontWeight: "bold" }}>Alignment Comparison</FormHelperText>
                          </FormControl>
                        </div>
                      </div>
                    }
                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleChangeAcc}>
                      {!accordinOpen ?
                        <ExpandMoreOutlinedIcon /> :
                        <ExpandLessOutlinedIcon />
                      }
                    </IconButton>
                  </div>
                </Col>
              </Row>
              <Row>
                <Table hover>
                  <thead>
                    <tr>
                      <td>Fuel (L)</td>
                      <td>CO<sub>2</sub> (kg)</td>
                      <td>Energy (kWh)</td>
                      <td>Cost</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{numberFormat(Math.round(baseAlternative.forecast_data[0].annual_fuel))}</td>
                      <td>{numberFormat(Math.round(baseAlternative.forecast_data[0].annual_co2))}</td>
                      <td>{numberFormat(Math.round(baseAlternative.forecast_data[0].annual_energy))}</td>
                      <td>{numberFormat(Math.round(baseAlternative.forecast_data[0].annual_cost))} {currency}</td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            </Container>
          </AccordionSummary>
          <AccordionDetails style={{ background: '#FBFBFF' }}>
            <Row>
              {baseAlternative && (
                <>
                  <Container>
                    <Row >
                      <Col xs={{ span: 12 }}>
                        {baseAlternative && (
                          <>
                            <div style={{ float: "left", fontSize: "1.3vw" }}>
                              Forecast
                            </div>
                            <div style={{ flexDirection: 'column-reverse' }}>
                              <Table
                                hover
                              >
                                <thead>
                                  <tr>
                                    <td>Year</td>
                                    <td>Fuel (L)</td>
                                    <td>CO<sub>2</sub> (kg)</td>
                                    <td>Energy (kWh)</td>
                                    <td>Cost</td>
                                  </tr>
                                </thead>
                                <tbody>{handleFunctionOutput()}</tbody>
                                <tfoot></tfoot>
                              </Table>
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>


                    <Row>
                      <Col>
                        <div style={{ width: '90%', height: '20vw' }}>
                          <ChartMixed
                            type={"co2"}
                            chartInfo={chartInfoData('co2')}
                          />
                        </div>
                      </Col>
                      <Col >
                        <div style={{ width: '90%', height: '20vw' }}>
                          <ChartMixed
                            type={"energy"}
                            chartInfo={chartInfoData('energy')}
                          />
                        </div>
                        {/* <ChartMixed baseAlternative={baseAlternative} compareAlternatives={savingsAlternativetables} type={'fuel'} />*/}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <div style={{ width: '90%', height: '20vw' }}>
                          <ChartMixed
                            type={"cost"}
                            chartInfo={chartInfoData('cost')}
                          />
                        </div>
                      </Col>
                      <Col />
                    </Row>
                  </Container>
                </>
              )}
            </Row>
          </AccordionDetails>
        </Accordion>
      )
        : <SpinLoader />}
    </Container >
  );
};

export default ForecastsIndividual;
