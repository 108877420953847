export const DEFAULT_EPSG_CODE = '3009'
export const DEFAULT_EPSG = `EPSG:${DEFAULT_EPSG_CODE}`

export const CSV_NAME_REGEX = /^[a-zA-Z0-9- ,_]*$/

export const chartCommonConfig = {
    responsive: true,
    maintainAspectRatio: true,
}

export const DATE_FORMAT = 'YYYY-MM-DD'

export const MIN_YEAR = 2020
export const MAX_YEAR = 2080

export const CURRENCY = {
    NORWAY: 'NOK',
    SWEDISH: 'SEK',
    DANISH: 'DKK',
    FINNISH: 'EUR'
}

export const PER_PAGE = 10