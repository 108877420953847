import {
  SET_AUTO_OUT, SET_WIN_WIDTH, SET_IS_LICENSE, SET_LICENSE_DATE,
  SET_CURRENCY, SET_FORECAST_CAR_CONFIG, SET_FORECAST_TRUCK_CONFIG, SET_COUNTRY,
  SET_COUNTRIES_LIST, SET_GLOBAL_IS_PROCESS, SET_USER_INFO, SET_PROJECT_ID, SET_PROJ_INFO
} from '../actions/global'
import { CURRENCY } from '../constant'
import { fuelDistributionCarsNorway, fuelDistributionTrucksNorway } from '../utils/aiRequestBodyUtils'

const defaultState = {
  isProcess: false,
  isLicense: null,
  licenseValid: false,
  userInfo: {},
  projectId: false,
  projInfo: {},
  currency: CURRENCY.NORWAY,
  country: '',
  autoOut: false,
  winWidth: window.innerWidth,
  countries: [],
  carConfig: fuelDistributionCarsNorway,
  truckConfig: fuelDistributionTrucksNorway
}

const Global = (state = defaultState, action) => {
  switch (action.type) {
    case SET_GLOBAL_IS_PROCESS:
    case SET_IS_LICENSE:
    case SET_AUTO_OUT:
    case SET_PROJECT_ID:
    case SET_PROJ_INFO:
    case SET_WIN_WIDTH:
    case SET_LICENSE_DATE:
    case SET_CURRENCY:
    case SET_COUNTRIES_LIST:
    case SET_COUNTRY:
    case SET_FORECAST_CAR_CONFIG:
    case SET_FORECAST_TRUCK_CONFIG:
    case SET_USER_INFO:
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}

export default Global
