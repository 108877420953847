import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
// import FuelSaveGlobalContext from "../../../context/FuelSaveGlobalContext";
import "./chartMixedsectional.css";
// import { useTranslation } from "react-i18next";
// import _ from "lodash";
import {
  legend,
  tooltips,
  xAxes,
  yAxes,
  compareChainage,
} from "../../../utils/chartutils";

// "chart.js": "^2.8.0",
// "react-chartjs-2": "^2.7.6",

const barChartOptions = {
  animation: {
    duration: 0,
  },
  // title: { text: 'Energy consumption along road [l/100km, average over 20 meter sections]', display: true },
  tooltips: {
    titleFontSize: 20,
    bodyFontSize: 20,
  },
  lineTension: 2,
  scaleOverride: true,
  // scaleSteps: 200,
  // scaleStepWidth: 200,
  scaleShowLabels: true,
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "FC",
        },
        scaleFontSize: 40,
        ticks: {
          fontColor: "white",
          display: true,
          beginAtZero: true,
          min: 0,
          max: 10,
        },

        stacked: false,
      },
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "chainage",
        },
        scaleFontSize: 40,
        ticks: {
          fontColor: "white",
          suggestedMin: 0,
          suggestedMax: 60,
          maxTicksLimit: 10,
          display: true,
          stepSize: 10,
        },
        stacked: false,
      },
    ],
  },
};

const data = {
  labels: ["not found"],
  datasets: [
    {
      type: "line",
      label: "Alternative 2",
      //borderColor: 'rgb(54, 162, 235)',
      //borderWidth: 2,
      fill: false,
      data: [null],
    },
    {
      type: "line",
      label: "Alternative 1 line",
      // backgroundColor: 'rgb(255, 99, 132)',
      data: [null],
      borderColor: "white",
      //borderWidth: 2,
    },
  ],
};

const ChartMixedGroundLevelAlongRoad = (props) => {
  // const {
  //   mapCoordinateId,
  //   setMapCoordinateId,
  //   mapCentreIdData,
  //   setMapCentreIdData,
  //   previousMapCentreIdData,
  //   setPreviousMapCentreIdData,
  //   editSectionalAnalysisChart,
  //   setEditSectionalAnalysisChart,
  // } = useContext(FuelSaveGlobalContext);

  // const { t, i18n } = useTranslation();

  const baseAlternative = props.baseAlternative;
  const compareAlternative = props.compareAlternative;

  const [chartData, setChartData] = useState(data);
  const [chartOptions, setChartOptions] = useState(barChartOptions);
  // const [mapData, setMapData] = useState(null);
  const [prevMapData, setPrevMapData] = useState(null);
  const [currentMapData, setCurrentMapData] = useState(null);

  async function createChart() {
    const baseChainageLabels = [];
    const baseLevelData = [];
    const mapPointData = [];
    const compareLine = [];

    if (baseAlternative) {
      const baseSegmentsData = baseAlternative.segments_data;
      baseSegmentsData.sort(compareChainage);

      let index = 0;
      // let firstFound = false;
      let drawSlope = false;

      baseSegmentsData.map((segment) => {
        baseChainageLabels.push(segment.chainage);
        baseLevelData.push(segment.zlevel);

        if (prevMapData && segment.chainage === prevMapData.chainage) {
          compareLine.push(segment.zlevel);
          prevMapData.chainage < currentMapData.chainage ? drawSlope = true : drawSlope = false;
        } else if (prevMapData && segment.chainage === currentMapData.chainage) {
          compareLine.push(segment.zlevel);
          prevMapData.chainage < currentMapData.chainage ? drawSlope = false : drawSlope = true;
        } else {
          if (drawSlope) {
            const x = currentMapData.chainage - prevMapData.chainage
            const y = currentMapData.zlevel - prevMapData.zlevel
            const slope = y / x;
            const currentX = segment.chainage - prevMapData.chainage;
            const Y = (slope * currentX) + prevMapData.zlevel;
            compareLine.push(Y);
          }
          else {
            compareLine.push(null);
          }
        }

        mapPointData.push({
          index: index,
          chainage: segment.chainage,
          segment_order_no: segment.segment_order_no,
          fuelconsumption_trucks: segment.fuelconsumption_trucks,
          hilliness: segment.hilliness,
          speedprofile_car: segment.speedprofile_car,
          fuelconsumption_car: segment.fuelconsumption_car,
          speedprofile_trucks: segment.speedprofile_trucks,
          geom_order_id: segment.geom_order_id,
          geom: segment.geom,
          zlevel: segment.zlevel,
        });

        index++;

        return segment
      });
    }

    // setMapData(mapPointData);

    const data = {
      labels: baseChainageLabels,
      datasets: [
        {
          type: "line",
          label: "compare line",
          labelColor: "white",
          borderColor: "blue",
          borderWidth: 1,
          pointRadius: 1,
          pointHoverRadius: 1,
          fill: true,
          backgroundColor: "blue",
          data: compareLine,
        },
        {
          type: "line",
          label:
            "Base level " + (baseAlternative ? baseAlternative.direction : ""),
          labelColor: "white",
          borderColor: "rgb(255,255,255,0.5)",
          borderWidth: 1,
          pointRadius: 1,
          pointHoverRadius: 1,
          fill: true,
          backgroundColor: "rgb(255,255,255,0.9)",
          data: baseLevelData,
        },
        {
          type: "bar",
          label:
            "Base level " + (baseAlternative ? baseAlternative.direction : ""),
          labelColor: "white",
          borderColor: "rgb(193, 66, 66)",
          borderWidth: 1,
          pointRadius: 1,
          pointHoverRadius: 1,
          fill: true,
          backgroundColor: "rgb(193, 66, 66,0.5)",
          data: baseLevelData,
        }
      ],
    };

    const barChartOptions = {
      animation: {
        duration: 0,
      },
      legend: legend,
      events: ["click"],
      tooltipEvents: ["mousemove", "touchstart", "touchmove", "click"],
      tooltips: tooltips,
      onHover: function (evt, element) { },
      onClick: function (evt, element) {
        if (element[0]) {
          // const chart = element[0]._chart;
          const index = element[0]._index;
          // const activePoints = chart.getElementsAtEventForMode(
          //   evt,
          //   "point",
          //   chart.options
          // );
          // const firstPoint = activePoints[0];
          // const label = chart.data.labels[firstPoint._index];
          // const value =
          //   chart.data.datasets[firstPoint._datasetIndex].data[
          //   firstPoint._index
          //   ];

          setPrevMapData(currentMapData);
          setCurrentMapData(mapPointData[index]);
          //setPreviousMapCentreIdData(prevMapData)
          props.handleStateChange("localPreviousMapCentreIdData", prevMapData)
          //setMapCentreIdData(mapPointData[index]);
          props.handleStateChange("localMapCentreIdData", mapPointData[index])
        }
      },
      lineTension: 2,
      scaleOverride: true,
      scaleShowLabels: true,
      scales: {
        xAxes: xAxes,
        yAxes: yAxes,
      },
    };
    setChartData(data);
    setChartOptions(barChartOptions);
  }

  useEffect(() => {
    const newData = [prevMapData, currentMapData]
    //setPreviousMapCentreIdData(prevMapData => newData)
    props.handleStateChange("localPreviousMapCentreIdData", prevMapData => newData)
    //setMapCentreIdData(currentMapData => (currentMapData));
    props.handleStateChange("localMapCentreIdData", currentMapData => (currentMapData))
    createChart();

    // eslint-disable-next-line
  }, [baseAlternative, compareAlternative, props.localMapCentreIdData]);

  return <Bar data={chartData} options={chartOptions} />;
};

export default ChartMixedGroundLevelAlongRoad;
