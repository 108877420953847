import { handleRequest } from "../constant/helpers"

/**
 * Fetch project setting By ID
 * @param {*} id 
 * @returns 
 */
export const fetchProjSettings = id => handleRequest("post", false, {
    query: `
        query{
            setting(project_id:${parseInt(id)}){
                base_comp_thrshld
            }
          }`
})


/**
 * Update Project setting
 * @param {*} data 
 * @returns 
 */
export const updateProjSettings = data => handleRequest('post', false, {
    query: `mutation updateSetting($data:UpdateSettingInput!)
    {updateSetting(data: $data){
    project_id, base_comp_thrshld,
    }}`,
    variables: { data }
}, 'application/json')