import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getProjectSettingsById, updateProjectSettingById } from '../../../../actions/setting'

const CDRSettings = ({ match }) => {
    const { id } = !!match && !!match.params ? match.params : {}

    const dispatch = useDispatch()
    const { isProcessing, project } = useSelector(state => state.projects)
    const { isLoading, cdrSettings } = useSelector(state => state.settings)
    const [threshold, setThresholdVal] = useState(project.base_comp_thrshld || 0)

    useEffect(() => {
        if (!!id) {
            dispatch(getProjectSettingsById(id))
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const { base_comp_thrshld } = cdrSettings
        setThresholdVal(base_comp_thrshld)
    }, [cdrSettings])

    const onSubmit = async (e) => {
        e.preventDefault()
        toast.info('Updating...')
        try {
            const settingResp = await dispatch(updateProjectSettingById({
                project_id: parseInt(id),
                base_comp_thrshld: parseFloat(threshold)
            }))

            if (!!settingResp && !!settingResp.data && !!settingResp.data.data && !!settingResp.data.data.updateSetting) {
                toast.success('Updated Successfully')
            }
        } catch (e) {
            toast.error('Update Failed')
            console.log('Project Edit Error:', e)
        }
    }

    const handleInputChange = (name, e) => {
        const value = e.target.value;
        switch (name) {
            case 'base_comp_thrshld':
                setThresholdVal(value)
                break;
            default:
                break;
        }
    }

    const errMsg = 'Value can not be empty'
    return (
        <div className="project-info-sec">
            <form onSubmit={onSubmit}>
                <h2>CDR Settings</h2>
                <div className="project-edit-cont">
                    <div className="project-btn-n-input">
                        <Form.Group className="mb-3" controlId="base_comp_thrshld">
                            <Form.Label>Base Comparison Threshold</Form.Label>
                            <Form.Control type="text" placeholder="Base Threshold" value={threshold || 0} onChange={(e) => handleInputChange('base_comp_thrshld', e)} />
                            <Form.Control.Feedback type="invalid">{errMsg}</Form.Control.Feedback>
                        </Form.Group>
                        <button className="btn-project mt-3" disabled={isProcessing || isLoading || !threshold}>{(isProcessing || isLoading) ? 'Loading...' : 'Update'}</button>
                    </div>
                </div>
            </form>
        </div >
    )
}

export default CDRSettings
