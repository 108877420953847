import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { fetchAllProjects, getProjectInfoById, updateProjectInfoById } from '../../../../actions/project'
import { MAX_YEAR, MIN_YEAR } from '../../../../constant'
import './index.scss'

const EditForm = ({ match }) => {
    const { id } = !!match && !!match.params ? match.params : {}

    const dispatch = useDispatch()
    const { isProcessing, project } = useSelector(state => state.projects)
    const [pName, setPName] = useState(project.project_name || '')
    const [startYear, setStartYear] = useState(project.start_year || 0)
    const [endYear, setEndYear] = useState(project.end_year || 0)

    useEffect(() => {
        const getProjectById = async (id) => {
            try {
                const data = await dispatch(getProjectInfoById(id))
                if (!!data && !!data[0]) {
                    const { project_name, start_year, end_year } = data[0]
                    setPName(project_name)
                    setStartYear(start_year || 0)
                    setEndYear(end_year || 0)
                }
            } catch (e) {
                console.log('getProjectById Error: ', e)
            }
        }

        if (!!id) {
            getProjectById(id)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const { project_name, start_year, end_year } = project
        setPName(project_name)
        setStartYear(start_year)
        setEndYear(end_year)
    }, [project])

    const onSubmit = async (e) => {
        e.preventDefault()
        toast.info('Updating...')
        try {
            const projectResp = await dispatch(updateProjectInfoById({
                project_id: id,
                project_name: pName,
                start_year: parseInt(startYear),
                end_year: parseInt(endYear)
            }))

            if (!!projectResp && !!projectResp.data && !!projectResp.data.data && !!projectResp.data.data.updateProject) {
                toast.success('Updated Successfully')
                dispatch(fetchAllProjects())
            }
        } catch (e) {
            toast.error('Update Failed')
            console.log('Project Edit Error:', e)
        }
    }

    const handleInputChange = (name, e) => {
        const value = e.target.value;
        switch (name) {
            case 'pName':
                setPName(value)
                break;
            case 'start_year':
                setStartYear(value)
                break;
            case 'end_year':
                setEndYear(value)
                break;
            default:
                break;
        }
    }

    const validateInput = name => {
        switch (name) {
            case 'name':
                return !pName
            case 'start_year':
                return !startYear || startYear < MIN_YEAR || endYear < startYear
            case 'end_year':
                return !endYear || endYear > MAX_YEAR || endYear < startYear
            default:
                return false
        }
    }

    const projName = !!project && !!project.project_name ? project.project_name : ''
    const isFormValid = (validateInput('name') || validateInput('start_year') || validateInput('end_year'))
    const errMsg = `You can only choose value between ${MIN_YEAR} and ${MAX_YEAR}`
    return (
        <div className="project-info-sec">
            <form onSubmit={onSubmit}>
                <h2>Project Info</h2>
                <div className="project-edit-cont">
                    <div>Update project name: <span>{projName || ''}</span></div>
                    <div className="project-btn-n-input">
                        <Form.Group className="mb-3" controlId="start_year">
                            <Form.Label>Project Name</Form.Label>
                            <Form.Control type="text" placeholder={'projName'} value={pName || ''} onChange={(e) => handleInputChange('pName', e)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="start_year">
                            <Form.Label>Start Year</Form.Label>
                            <Form.Control type="text" placeholder={MIN_YEAR} value={startYear || 0} onChange={(e) => handleInputChange('start_year', e)} isInvalid={validateInput('start_year')} />
                            <Form.Control.Feedback type="invalid">{errMsg}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="end_year">
                            <Form.Label>End Year</Form.Label>
                            <Form.Control type="text" placeholder={MAX_YEAR} value={endYear || 0} onChange={(e) => handleInputChange('end_year', e)} isInvalid={validateInput('end_year')} />
                            <Form.Control.Feedback type="invalid">{errMsg}</Form.Control.Feedback>
                        </Form.Group>
                        <button className="btn-project mt-3" disabled={isProcessing || isFormValid}>{isProcessing ? 'Loading...' : 'Update'}</button>
                    </div>
                </div>
            </form>
        </div >
    )
}

export default EditForm
