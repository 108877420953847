import React, { useState, useEffect } from 'react';
import { Bar, defaults } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
// import FuelSaveGlobalContext from '../../../../context/FuelSaveGlobalContext';
import './chartMixed.css';
// import { useTranslation } from "react-i18next"
// import _ from 'lodash';
import { legend, tooltips, getGroundLevelAlongRoadLayers, getGroundLevelAlongRoadDataSets } from '../../../../utils/chartutils';
import { chartCommonConfig } from '../../../../constant';
import { chartSizeAccToWidth } from '../../../../constant/helpers';
import { newSlabDiff } from './common';
import { colorCodes } from '../../../../constant/colors';

// "chart.js": "^2.8.0",
// "react-chartjs-2": "^2.7.6",

defaults.global.defaultFontFamily = 'MediumSizeFont';

const { BLUE_3 } = colorCodes
const barChartOptions = {
    ...chartCommonConfig,
    animation: {
        duration: 0
    },
    // title: { text: 'Energy consumption along road [l/100km, average over 20 meter sections]', display: true },
    tooltips: {
        titleFontSize: 20,
        bodyFontSize: 20
    },
    lineTension: 2,
    scaleOverride: true,
    // scaleSteps: 200,
    // scaleStepWidth: 200,
    scaleShowLabels: true,
    scales: {
        xAxes: [
            {
                scaleLabel: {
                    display: true,
                    labelString: 'FC',
                },
                scaleFontSize: 40,
                ticks: {
                    fontColor: BLUE_3,
                    display: true,
                    beginAtZero: true,
                    min: 0,
                    max: 10,

                },
                gridLines: {
                    display: false
                },

                stacked: false,
            },
        ],
        yAxes: [
            {
                scaleLabel: {
                    display: true,
                    labelString: 'chainage',
                },
                scaleFontSize: 40,
                ticks: {
                    fontColor: "white",
                    suggestedMin: 0,
                    suggestedMax: 60,
                    maxTicksLimit: 10,
                    display: true,
                    stepSize: 10
                },
                gridLines: {
                    display: false
                },
                stacked: false,
            },
        ],
    },
};

const data = {
    labels: ['not found'],
    datasets: [
        {
            type: 'line',
            label: 'Alternative 2',
            //borderColor: 'rgb(54, 162, 235)',
            //borderWidth: 2,
            fill: false,
            data: [null],
        },
        {
            type: 'line',
            label: 'Alternative 1 line',
            // backgroundColor: 'rgb(255, 99, 132)',
            data: [null],
            borderColor: 'white',
            //borderWidth: 2,
        },
    ],
}


// const compareChainage = (a, b) => {
//     if (a.chainage < b.chainage) {
//         return -1;
//     }
//     if (a.chainage > b.chainage) {
//         return 1;
//     }
//     return 0;
// }


const ChartMixedGroundLevelAlongRoad = ({
    baseAlternative, compareAlternative,
    handleStateChange, setLineAtIndex, lineAtIndex
}) => {

    // const {
    //     mapCoordinateId, setMapCoordinateId,
    //     mapCentreIdData, setMapCentreIdData
    // } = useContext(FuelSaveGlobalContext)

    // const { t, i18n } = useTranslation();
    const { winWidth } = useSelector(state => state.global)
    const [chartData, setChartData] = useState(data);
    const [chartOptions, setChartOptions] = useState(barChartOptions);
    // const [mapData, setMapData] = useState(null);

    useEffect(() => {
        async function createChart() {

            const [baseChainageLabels,
                baseLevelDarkBlueData,
                baseLevelBlueData,
                baseLevelGreyBlueData,
                baseLevelGreyData,
                baseLevelGreyRedData,
                baseLevelRedData,
                baseLevelDarkRedData,
                baseLevelData,
                mapPointData] = getGroundLevelAlongRoadLayers(baseAlternative);

            // setMapData(mapPointData);

            const [
                compareChainageLabels,
                compareLevelDarkBlueData,
                compareLevelBlueData,
                compareLevelGreyBlueData,
                compareLevelGreyData,
                compareLevelGreyRedData,
                compareLevelRedData,
                compareLevelDarkRedData,
                compareLevelData,
                // compareMapPointData
            ] = getGroundLevelAlongRoadLayers(compareAlternative);

            const data = {
                labels: baseChainageLabels,
                datasets: getGroundLevelAlongRoadDataSets(
                    baseAlternative,
                    compareAlternative,
                    baseLevelData,
                    baseLevelDarkBlueData,
                    baseLevelBlueData,
                    baseLevelGreyBlueData,
                    baseLevelGreyData,
                    baseLevelGreyRedData,
                    baseLevelRedData,
                    baseLevelDarkRedData,
                    compareChainageLabels,
                    compareLevelData,
                    compareLevelDarkBlueData,
                    compareLevelBlueData,
                    compareLevelGreyBlueData,
                    compareLevelGreyData,
                    compareLevelGreyRedData,
                    compareLevelRedData,
                    compareLevelDarkRedData)
            }

            const barChartOptions = {
                ...chartCommonConfig,
                animation: {
                    duration: 0
                },
                legend: legend,
                events: ['click'],
                tooltipEvents: ["mousemove", "touchstart", "touchmove", "click"],
                tooltips: tooltips,
                onHover: function (evt, element) {
                },
                onClick: function (evt, element) {
                    if (element[0]) {
                        // const chart = element[0]._chart
                        const index = element[0]._index
                        // const activePoints = chart.getElementsAtEventForMode(evt, 'point', chart.options);
                        // const firstPoint = activePoints[0];
                        // const label = chart.data.labels[firstPoint._index];
                        // const value = chart.data.datasets[firstPoint._datasetIndex].data[firstPoint._index];
                        //setMapCentreIdData(mapPointData[index]);
                        handleStateChange("localMapCentreIdData", mapPointData[index])
                        setLineAtIndex(false)
                    }
                },
                lineTension: 2,
                scaleOverride: true,
                scaleShowLabels: true,
                scales: {
                    xAxes: [
                        {
                            scaleLabel: {
                                display: true,
                                fontColor: BLUE_3,
                                labelString: 'Chainage',
                                fontSize: chartSizeAccToWidth(winWidth),
                            },
                            scaleFontSize: 40,
                            ticks: {
                                fontColor: BLUE_3,
                                display: true,
                                min: 0,
                                max: 10,
                                maxTicksLimit: 10,
                                stepSize: 100,
                                fontSize: chartSizeAccToWidth(winWidth),
                            },
                            stacked: false,
                            afterBuildTicks: function (mychart) {
                                mychart.options.ticks.maxTicksLimit = mychart.max
                                const indexCollection = []
                                mychart.chart.config.lineAtIndex = !!lineAtIndex ? [parseInt(lineAtIndex)] : []
                                newSlabDiff(mychart.max).map(ins => indexCollection.push((mychart.ticks).indexOf(ins)))
                                mychart.options.ticks.callback = function (label, i) {
                                    return indexCollection.includes(i) ? label : '';
                                }
                            }
                        },
                    ],
                    yAxes: [
                        {
                            scaleLabel: {
                                display: true,
                                fontColor: BLUE_3,
                                labelString: 'Meters',
                                fontSize: chartSizeAccToWidth(window.innerWidth)
                            },
                            scaleFontSize: 40,
                            ticks: {
                                fontColor: BLUE_3,
                                suggestedMin: 0,
                                suggestedMax: 60,
                                maxTicksLimit: 10,
                                display: true,
                                stepSize: 10,
                                fontSize: chartSizeAccToWidth(window.innerWidth)
                            },
                            stacked: false,
                        },
                    ],
                },
            }
            setChartData(data);
            setChartOptions(barChartOptions);
        }

        createChart()

        // eslint-disable-next-line
    }, [baseAlternative, compareAlternative, winWidth, lineAtIndex])

    return (
        <div className="mapRow">
            <div className="map-container chart-mixed-ground-level-along-road">
                <Bar data={chartData} options={chartOptions} />
            </div>
        </div>
    )
}

export default ChartMixedGroundLevelAlongRoad