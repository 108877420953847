import React from 'react'
import './index.scss'

const XMLCSVTab = ({ isCsvActive, setActiveTab }) => (
    <div className="alignment-tab-container center-flex">
        <div className={`alignment-tab-btn center-flex mr-3 ${isCsvActive ? 'is-active' : ''}`} onClick={() => setActiveTab('csv')}>CSV</div>
        <div className={`alignment-tab-btn center-flex ml-3 ${!isCsvActive ? 'is-active' : ''}`} onClick={() => setActiveTab('xml')}>XML</div>
    </div>
)

export default XMLCSVTab
