import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const PresumptionsForm = ({ presumptions, onChange }) => {
    const { currency } = useSelector(state => state.global)

    const handleChange = (event) => {
        let value = event.currentTarget.value

        if (value === 'true') {
            value = true;
        } else if (value === 'false') {
            value = false;
        }

        let presumptions_copy = { ...presumptions };
        presumptions_copy[event.currentTarget.name] = value
        onChange(presumptions_copy)
    }

    const presumption_titles = ["Fuel type", "CO2 factor [kg/l]", "kWh factor [kWh/l]", "Engine efficiency [0-1]", `Fuel price ${currency}`]
    const presumption_rows = [
        ["Battery", "battery_co2", "battery_kwh", "battery_efficiency", "battery_cost"],
        ["Chargeable Hybrid", "chargeable_hybrid_co2", "chargeable_hybrid_kwh", "chargeable_hybrid_efficiency", "chargeable_hybrid_cost"],
        ["Diesel", "diesel_co2", "diesel_kwh", "diesel_efficiency", "diesel_cost"],
        ["Gasoline", "gasoline_co2", "gasoline_kwh", "gasoline_efficiency", "gasoline_cost"],
        ["Hydrogen", "hydrogen_co2", "hydrogen_kwh", "hydrogen_efficiency", "hydrogen_cost"],
        ["Nonchargeable Hybrid", "inchargeable_hybrid_co2", "inchargeable_hybrid_kwh", "inchargeable_hybrid_efficiency", "inchargeable_hybrid_cost"]
    ]
    const additional_titles = ["Car average speed [km/h]", "Truck average speed [km/h]", "MPD [mm]", "IRI [mm/m]", 'MPD increase [mm/year]', 'IRI increase [mm/m/year]', 'IRI limit [mm/m]']
    const additional_row = ["speed_car", "speed_truck", "mpd", "iri", "mpd_increase", "iri_increase", "iri_limit"]

    return (
        <>
            <h3>Presumptions</h3>
            <Form>
                <Row key="presumption_titles">
                    {presumption_titles.map(title => <Col key={title}><Form.Label style={{ color: 'black' }}>{title}</Form.Label></Col>)}
                </Row>
                {!!presumption_rows && !!presumption_rows.length && presumption_rows.map(row => {
                    const [name, ...col_vals] = row;
                    return (
                        <Row key={name}>
                            <Col key="name">
                                <Form.Group className='form-group-margin'>
                                    <Form.Control name="name"
                                        type="text" pattern='[0-9]{0,5}' placeholder={name}
                                        onChange={handleChange} required disabled />
                                </Form.Group>
                            </Col>
                            {!!col_vals && !!col_vals.length && col_vals.map(col_val => (
                                <Col key={col_val}>
                                    <Form.Group controlId={col_val}>
                                        <Form.Control name={col_val} defaultValue={presumptions[col_val]} value={presumptions[col_val]}
                                            type="number" onChange={handleChange} required />
                                    </Form.Group>
                                </Col>
                            ))}
                        </Row>
                    )
                })}
                <Row key="additional_titles">
                    {!!additional_titles && !!additional_titles.length && additional_titles.map(title => <Col key={title}><Form.Label style={{ color: 'black' }}>{title}</Form.Label></Col>)}
                </Row>
                <Row key="additional_row">
                    {!!additional_row && !!additional_row.length && additional_row.map(col_val => (
                        <Col key={col_val}>
                            <Form.Group controlId={col_val} className='form-group-margin'>
                                <Form.Control name={col_val} defaultValue={presumptions[col_val]} type="number" onChange={handleChange} required />
                            </Form.Group>
                        </Col>
                    ))}
                </Row>
            </Form >
        </>
    )
}

export default PresumptionsForm