import React, { useState } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { useQuery, useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { GET_VALUES, UPDATE_ALTERNATIVE } from '../../../../utils/apolloutils'
import { setAllAlignments } from '../../../../actions/alignment'

const EditPopUp = ({ show, onHide, alternative }) => {

    const dispatch = useDispatch()
    const { alignments } = useSelector(state => state.alignments)
    const { projectId } = useSelector(state => state.global)
    const alternateId = !!alternative ? alternative : {}
    const [aName, setAName] = useState(alternateId.name || '');
    const { // loading, error, 
        data } = useQuery(GET_VALUES, {
            variables: {
                project_id: projectId
            }
        });

    const refreshAlternatives = newAlt => {
        const updatedRec = newAlt.find(item => (item.alternative_id === alternative.alternative_id))
        if (!!alignments && !!alignments.length) {
            const newResp = alignments.map((item, idx) => {
                if (item.alternative_id === alternative.alternative_id) {
                    return {
                        ...item,
                        ...(!!updatedRec && { ...updatedRec })
                    }
                }
                return item
            })
            dispatch(setAllAlignments(newResp))
        }
    }

    const [updateAlternative, { loading: mutationLoading }] = useMutation(UPDATE_ALTERNATIVE, {
        onCompleted: ((data) => {
            onHide()
            refreshAlternatives(data.updateAlternative)
        })
    })


    const handleInputChange = (name, e) => {
        const value = e.target.value;

        switch (name) {
            case 'name':
                setAName(value);
                break;
            default:
                break;
        }
    }

    const validateInput = name => {
        switch (name) {
            case 'name':
                return !aName
            default:
                return false
        }
    }

    const isFormValid = () => {
        let isValid = true
        const fields = ['name', 'start_year', 'end_year']
        for (var i = 0; i <= fields.length; i++) {
            if (!!validateInput(fields[i])) {
                isValid = false
                break;
            }
        }
        return isValid
    }

    const handleUpdateAlternative = async (e, id, oldName) => {
        e.preventDefault()
        updateAlternative(
            {
                variables: {
                    alternative_id: id,
                    data: {
                        name: aName ? aName : oldName
                    }
                },
                refetchQueries: [{
                    query: GET_VALUES,
                    variables: {
                        project_id: projectId
                    }
                }]
            }
        )
    }

    const alignmentResp = (!!data && !!data.projects && !!data.projects[0] && !!data.projects[0].alternatives
        && !!data.projects[0].alternatives.node && !!data.projects[0].alternatives.node[0]) ?
        data.projects[0].alternatives.node[0] : false
    return (
        (!!alignmentResp && <Modal
            size="lg"
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {`Edit Alternative: ${alignmentResp.alternative_id}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={e => handleUpdateAlternative(e, alternative.alternative_id, alternative.name)}>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder={alternative.name} value={aName} onChange={(e) => handleInputChange('name', e)} isInvalid={validateInput('name')} />
                        <Form.Control.Feedback type="invalid">Please enter valid name</Form.Control.Feedback>
                    </Form.Group>
                    <Button disabled={!isFormValid() || mutationLoading} variant="primary" id="btn" type='submit'>{mutationLoading ? 'Loading...' : 'Update'}</Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button id="btn" onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
        )
    );
}

export default EditPopUp