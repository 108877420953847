import { fetchProjSettings, updateProjSettings } from "../utils/setting"

export const SET_SETTING_PROCESSING = 'SET_SETTING_PROCESSING'
export const SET_CDR_SETTINGS = 'SET_CDR_SETTINGS'

export const setSettingIsProcessing = isLoading => ({
  type: SET_SETTING_PROCESSING,
  payload: { isLoading }
})

const setCDRSettings = cdrSettings => ({
  type: SET_CDR_SETTINGS,
  payload: { cdrSettings }
})


/**
 * Get Project Info
 * @param {*} id 
 * @returns 
 */
export const getProjectSettingsById = (id) => async (dispatch) => {
  dispatch(setSettingIsProcessing(true))
  try {
    const settingInfo = await fetchProjSettings(id)
    dispatch(setSettingIsProcessing(false))
    if (!!settingInfo && !!settingInfo.data && !!settingInfo.data.data && !!settingInfo.data.data.setting) {
      const resp = settingInfo.data.data.setting
      dispatch(setCDRSettings(resp))
    }
    return settingInfo
  } catch (e) {
    dispatch(setSettingIsProcessing(false))
    console.log('getProjectSettingsById Error: ', e)
    return e
  }
}



/**
 * Update Project Setting
 * @param {*} data
 * @returns 
 */
 export const updateProjectSettingById = (data) => async (dispatch) => {
  dispatch(setSettingIsProcessing(true))
  try {
    const settingInfo = await updateProjSettings(data)

    dispatch(setSettingIsProcessing(false))
    if (!!settingInfo && !!settingInfo.data && !!settingInfo.data.data &&
      !!settingInfo.data.data.updateSetting) {
      const { base_comp_thrshld } = settingInfo.data.data.updateSetting
      dispatch(setCDRSettings({ base_comp_thrshld }))
    }
    return settingInfo
  } catch (e) {
    dispatch(setSettingIsProcessing(false))
    console.log('updateProjectSettingById Error: ', e)
    return e
  }
}