import React, { useState, useEffect, useContext } from 'react'
import { toast } from 'react-toastify'
import { requestBody3 } from '../../../utils/aiRequestBodyUtils'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import FuelSaveGlobalContext from '../../../context/FuelSaveGlobalContext'
import { compareYear } from '../../../utils/chartutils'
import './forecasts.css'
// import { min } from 'moment-timezone'
import ForecastsIndividual from './ForecastsIndividual'
import SpinLoader from '../../common/SpinLoader'
import { flashErrMsg, handleRequest, presumptionObj } from '../../../constant/helpers'
import { MIN_YEAR } from '../../../constant'
import { colorCodes } from '../../../constant/colors'
import AlignmentPageWidget from '../summary/AlignmentPageWidget'

const { BLUE_3 } = colorCodes
const compareMin = (a, b) => {
  if (a.year > b.year) {
    return -1;
  }
  if (a.year < b.year) {
    return 1;
  }
  return 0;
};

const compareMax = (a, b) => {
  if (a.year < b.year) {
    return -1;
  }
  if (a.year > b.year) {
    return 1;
  }
  return 0;
};

const sliceYears = (localSavingsAlternativetables) => {
  const minYears = [];
  const maxYears = [];

  localSavingsAlternativetables.map((alternative) => {
    const forecastData = alternative.forecast_data;
    forecastData.sort(compareYear);
    const min = forecastData[0];
    const max = forecastData[forecastData.length - 1];
    if (min) {
      minYears.push(min);
    }
    if (max) {
      maxYears.push(max);
    }
    return alternative
  });

  if (minYears.length > 0 && maxYears.length > 0) {
    minYears.sort(compareMin);
    maxYears.sort(compareMax);

    const commonMin = minYears[0];
    const commonMax = maxYears[0];

    const slicedAlternativeTables = [];
    localSavingsAlternativetables.map((alternative) => {
      // find min index

      let continueLoop = true;
      let index = 0;
      let minIndex = 0;
      let maxIndex = 0;

      while (continueLoop) {
        const fd = alternative.forecast_data[index];
        if (!fd) {
          continueLoop = false;
        }
        if (fd && fd.year === commonMin.year) {
          minIndex = index;
          continueLoop = false;
        }
        index++;
      }

      index = alternative.forecast_data.length - 1;
      continueLoop = true;

      while (continueLoop) {
        const fd = alternative.forecast_data[index];
        if (!fd) {
          continueLoop = false;
        }
        if (fd && fd.year === commonMax.year) {
          maxIndex = index;
          continueLoop = false;
        }
        index--;
      }

      const slicedAlternativeForecast = alternative.forecast_data.slice(
        minIndex,
        maxIndex + 1
      );

      slicedAlternativeTables.push({
        name: alternative.name,
        alternative_id: alternative.alternative_id,
        forecast_data: slicedAlternativeForecast,
      });
      return alternative
    });

    return slicedAlternativeTables;
  } else {
    return [];
  }
};

const Forecasts = () => {
  const {
    // mapCoordinateId, setMapCoordinateId,
    // mapCentreIdData, setMapCentreIdData,
    // chainage,
    // setChainage,
    // fcCar,
    // setFcCar,
    // fcTrucks,
    // setFcTrucks,
    // hilliness,
    // setHilliness,
    // speedProfileCars,
    // setSpeedProfileCars,
    // speedProfileTrucks,
    // setSpeedProfileTrucks,
    // zlevel,
    // setZlevel,
    // curvature,
    // setCurvature,
    // segment,
    // setSegment,
    // mapCentreIdData,
    // setMapCentreIdData,
    // previousMapCentreIdData,
    // setPreviousMapCentreIdData,
    // editSectionalAnalysisChart,
    // setEditSectionalAnalysisChart,
    isLoading, setIsLoading,
    // globalBrandCrumb, 
    setGlobalBrandCrumb
  } = useContext(FuelSaveGlobalContext);
  const { carConfig, truckConfig, projInfo, projectId } = useSelector(state => state.global)
  const { alignments, isProcessing } = useSelector(state => state.alignments)
  const { presumptions } = useSelector(state => state.presumptions)

  const [savingsAlternativetables, setSavingsAlternativetables] = useState(null);

  const getDailyData = async (
    startYearData,
    endYearData,
    segmentsDir1,
    segmentsDir2,
    segmentsDataDir1,
    segmentsDataDir2,
    presumptionsData,
    index
  ) => {
    const presumptions = presumptionObj(presumptionsData[0])

    const startYear = startYearData ? startYearData : MIN_YEAR;
    const endYear = endYearData ? endYearData : 2041;

    const requestBodyDir1 = {
      return_option: 3,
      start_year: startYear,
      end_year: endYear,
      // end_year: startYear + 20, //TODO: A temp fix
      fuel_distribution_cars: carConfig,
      fuel_distribution_trucks: truckConfig,
      sensitivity_param_factors: {
        speed: 1,
        fuelprice: 1,
        emission: 1,
        AADTheavy: 1,
      },
      presumptions: presumptions,
      segments: segmentsDir1,
      segments_data: segmentsDataDir1,
    };

    const requestBodyDir2 = {
      return_option: 3,
      start_year: startYear,
      end_year: endYear, //TODO: A temp fix
      // end_year: startYear + 20,
      fuel_distribution_cars: carConfig,
      fuel_distribution_trucks: truckConfig,
      sensitivity_param_factors: {
        speed: 1,
        fuelprice: 1,
        emission: 1,
        AADTheavy: 1,
      },
      presumptions: presumptions,
      segments: segmentsDir2,
      segments_data: segmentsDataDir2,
    };

    const urlToken = process.env.REACT_APP_AI_FUNCTION_URL + '/api/HttpTrigger1?code=' + process.env.REACT_APP_AI_FUNCTION_API_KEY;

    try {
      setIsLoading(true)
      const [responseDir1, responseDir2] = await Promise.all([
        handleRequest('post', urlToken, endYear ? requestBodyDir1 : requestBody3, 'application/json', 'application/json'),
        handleRequest('post', urlToken, endYear ? requestBodyDir2 : requestBody3, 'application/json', 'application/json'),
      ])

      setIsLoading(false)
      return [responseDir1, responseDir2];

    } catch (err) {
      // throw new Error('Unable to get a response.')
      flashErrMsg(err)
      setIsLoading(false)
      return null;
    }
  };

  useEffect(() => {
    async function setData() {
      const globalProjInfo = !!projInfo && Object.keys(projInfo).length ? projInfo : false
      if (!globalProjInfo || (!!globalProjInfo && (!globalProjInfo.start_year || !globalProjInfo.end_year))) {
        toast.error('Start/End year value not found with this project')
        return
      }
      if (!alignments || !presumptions || !globalProjInfo || !carConfig || !truckConfig) {
        return
      }

      setIsLoading(true)
      const localSavingsAlternativetables = [];

      for (let index = 0; index < alignments.length; index++) {
        const alternative = alignments[index];

        const dailyData = await getDailyData(
          globalProjInfo.start_year,
          globalProjInfo.end_year,
          alternative.directions[0].segments,
          alternative.directions[1].segments,
          alternative.directions[0].segments_data,
          alternative.directions[1].segments_data,
          presumptions,
          index
        );

        if (!dailyData || (!!dailyData && (!dailyData[0] || !dailyData[1]))) {
          return false
        }

        const responseDir1 = dailyData[0];
        const responseDir2 = dailyData[1];

        const year = responseDir1.data.year;

        const forecastData = [];
        year.map((yr, idx) => {
          if (yr <= globalProjInfo.end_year) {  //TODO: A temp fix calculation problem
            const annual_co2 =
              responseDir1.data.annual_co2[idx] +
              responseDir2.data.annual_co2[idx];
            const annual_cost =
              responseDir1.data.annual_cost[idx] +
              responseDir2.data.annual_cost[idx];
            const annual_energy =
              responseDir1.data.annual_energy[idx] +
              responseDir2.data.annual_energy[idx];
            const annual_fuel =
              responseDir1.data.annual_fuel[idx] +
              responseDir2.data.annual_fuel[idx];

            forecastData.push({ year: yr, annual_co2, annual_cost, annual_energy, annual_fuel })
          }
          return yr
        });

        localSavingsAlternativetables.push({
          name: alternative.name,
          alternative_id: alternative.alternative_id,
          forecast_data: forecastData
        });
      }

      const slicedAlternativeTables = sliceYears(localSavingsAlternativetables);

      setSavingsAlternativetables(slicedAlternativeTables);
      setIsLoading(false)
    }

    setData();

    // eslint-disable-next-line
  }, [alignments, presumptions, carConfig, truckConfig]);

  useEffect(() => {
    //setBaseAlternative(null);
    setSavingsAlternativetables(null);
  }, [projectId])

  useEffect(() => {
    setGlobalBrandCrumb("Forecasts")

    // eslint-disable-next-line
  }, [])


  if (!!isLoading || !!isProcessing) {
    return <SpinLoader />
  }

  return (
    <Container>
      {savingsAlternativetables &&
        <>
          <div style={{ fontSize: 35, color: BLUE_3 }}>Forecasts</div>
          {savingsAlternativetables.map((a, altIndex) => (
            <ForecastsIndividual
              key={a.alternative_id}
              selectedAlternative={a}
              savingsAlternativetables={savingsAlternativetables}
              altIndex={altIndex} />
          ))}
          {!!savingsAlternativetables && !!savingsAlternativetables.length && <AlignmentPageWidget />}
        </>}
    </Container>
  );
};

export default Forecasts;
