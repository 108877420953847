import React, { useEffect } from 'react'
import './index.scss'

const SpinLoader2 = () => {
    useEffect(() => {
        let i = 0;
        if (i === 0) {
            i = 1;
            const elem = document.getElementById("waywize-progress-bar");
            let width = 10;
            let id = setInterval(frame, 200);
            function frame() {
                if (width >= 80) {
                    clearInterval(id);
                    i = 0;
                } else {
                    width++;
                    elem.style.maxWidth = width + "%";
                    elem.innerHTML = width + "%";
                }
            }
        }
    }, [])

    return (
        <div className="spin-loader-container center-flex">
            <div className='waywize-progress-container'>
                <img src={require('../../../assets/Waywize-Spin.png')} className="fuelsave-logo" alt="Loading" />
                <div className='mt-2 mb-2 progress-bar-text'>We're calculating on the basis on high number of variables, this can take some time. Please wait while processing is made</div>
                <div id="waywize-progress-holder"><div id="waywize-progress-bar">1%</div></div>
            </div>
        </div>
    )
}

export default SpinLoader2