import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
// import FuelSaveGlobalContext from "../../../context/FuelSaveGlobalContext";
import "./chartMixed.css";
// import { useTranslation } from "react-i18next";
// import _ from "lodash";
import {
  tooltips,
  // xAxes,
  // yAxes,
  // compareChainage,
} from "../../../utils/chartutils";
import { colorCodes } from "../../../constant/colors";

const { WHITE_1, GREY_3, BLUE_1 } = colorCodes

// "chart.js": "^2.8.0",
// "react-chartjs-2": "^2.7.6",



// const returnRgbColour = (index, type) => {

//   const remainderIndex = index % 10;
//   let colour = 'white'
//   let first = null
//   let second = null

//   if (type === 'co2') {
//     first = '255'
//     second = '25'

//   }
//   else if (type === 'cost') {
//     first = '255'
//     second = '170'

//   }
//   else if (type === 'energy') {
//     first = '0'
//     second = '128'
//   }
//   else {
//     first = '255'
//     second = '255'
//   }

//   if (remainderIndex === 0) { colour = 'rgb(' + first + ',' + second + ',10)' }
//   else if (remainderIndex === 1) { colour = 'rgb(' + first + ',' + second + ',20)' }
//   else if (remainderIndex === 2) { colour = 'rgb(' + first + ',' + second + ',30)' }
//   else if (remainderIndex === 3) { colour = 'rgb(' + first + ',' + second + ',40)' }
//   else if (remainderIndex === 4) { colour = 'rgb(' + first + ',' + second + ',50)' }
//   else if (remainderIndex === 5) { colour = 'rgb(' + first + ',' + second + ',60)' }
//   else if (remainderIndex === 6) { colour = 'rgb(' + first + ',' + second + ',70)' }
//   else if (remainderIndex === 7) { colour = 'rgb(' + first + ',' + second + ',80)' }
//   else if (remainderIndex === 8) { colour = 'rgb(' + first + ',' + second + ',90)' }
//   else if (remainderIndex === 9) { colour = 'rgb(' + first + ',' + second + ',100)' }


//   return colour

// }

const LableDic = {
  'co2': 'CO2',
  'energy': 'Energy',
  'cost': 'Cost'
}



const getBarChartOptions = (type) => {
  return {
    animation: {
      duration: 0,
    },
    // title: { text: 'Energy consumption along road [l/100km, average over 20 meter sections]', display: true },
    tooltips: {
      titleFontSize: 20,
      bodyFontSize: 20,
      callbacks: {
        label: function (tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          label += (Math.round(tooltipItem.yLabel * 100) / 100).toLocaleString();
          return label;
        }
      }
    },
    lineTension: 2,
    scaleOverride: true,
    scaleSteps: 200,
    scaleStepWidth: 200,
    scaleShowLabels: true,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            fontColor: BLUE_1,
            labelString: "Years",
            fontSize: 12,
          },
          ticks: {
            fontColor: GREY_3,
            display: true,
            beginAtZero: false,
            maxTicksLimit: 4,
            fontSize: 12,
          },
          gridLines: {
            display: false
          },
          stacked: false,
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            fontColor: BLUE_1,
            labelString: LableDic[type],
            fontSize: 15,
          },
          ticks: {
            fontColor: GREY_3,
            display: true,
            maxTicksLimit: 4,
            fontSize: 12,
          },
          gridLines: {
            display: true
          },
          stacked: false,
          afterBuildTicks: function (mychart) {
            const yLabels = mychart.ticks;
            mychart.options.ticks.callback = function (label) {
              return (yLabels).includes(label) ? label.toLocaleString() : '';
            }
          }
        },
      ],
    },
  }
}

const ChartMixed = ({ type, chartInfo }) => {
  // const {
  //   mapCoordinateId,
  //   setMapCoordinateId,
  //   mapCentreIdData,
  //   setMapCentreIdData,
  //   previousMapCentreIdData,
  //   setPreviousMapCentreIdData,
  //   editSectionalAnalysisChart,
  //   setEditSectionalAnalysisChart,
  // } = useContext(FuelSaveGlobalContext);

  // const { t, i18n } = useTranslation();

  const [chartData, setChartData] = useState(chartInfo);
  const [chartOptions, setChartOptions] = useState(getBarChartOptions(type));
  // const [mapData, setMapData] = useState(null);
  // const [currentMapData, setCurrentMapData] = useState(null);

  useEffect(() => {
    async function createChart() {

      const barChartOptions = {
        animation: {
          duration: 0,
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontSize: 12,
            fontColor: GREY_3
          }
        },
        events: ["click"],
        tooltipEvents: ["mousemove", "touchstart", "touchmove", "click"],
        tooltips: tooltips,
        lineTension: 1,
        scaleOverride: true,
        scaleShowLabels: true,
        scales: {
          xAxes: {
            scaleLabel: {
              display: true,
              fontColor: WHITE_1,
              labelString: 'Years'
            },
            scaleFontSize: 40,
            ticks: {
              fontColor: WHITE_1,
              display: false,
              min: 0,
              max: 10,
              maxTicksLimit: 10,
              stepSize: 10,
            },
            gridLines: {
              display: false
            }
          },
          yAxes: {
            scaleLabel: {
              display: true,
              fontColor: WHITE_1,
              labelString: 'CO2'
            },
            scaleFontSize: 40,
            ticks: {
              fontColor: WHITE_1,
              suggestedMin: 0,
              suggestedMax: 60,
              maxTicksLimit: 10,
              display: false,
              stepSize: 1000
            },
            gridLines: {
              display: false
            },
            stacked: false,
          },
        },
      };

      setChartData(chartInfo);
      setChartOptions(barChartOptions);
    }
    createChart();
  }, [chartInfo]);

  return <Bar className="barchart" data={chartData} options={chartOptions} />;
};

export default ChartMixed;
