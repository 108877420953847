import React, { Fragment } from 'react'
import { Form, Row } from 'react-bootstrap'

const CSVImporter = ({ inputRef }) => (
    <Fragment>
        <Row className="mr-0 ml-0">
            <Form.Label>Direction 1</Form.Label>
            <input ref={inputRef} type="file" name="dir1" className="ref-reg-input" />
        </Row>
        <Row className="mr-0 ml-0">
            <Form.Text className="text-muted">
                CSV format only | Max size 15 mb
            </Form.Text>
        </Row>
        <p className="pt-3">-------------------------------------------------------------------------------------</p>
        <Row className="mr-0 ml-0">
            <Form.Label>Direction 2</Form.Label>
            <input ref={inputRef} type="file" name="dir2" className="ref-reg-input" />
        </Row>
        <Row className="mr-0 ml-0">
            <Form.Text className="text-muted">CSV format only | Max size 15 mb</Form.Text>
        </Row>
    </Fragment>
)

export default CSVImporter