import { gql } from '@apollo/client';
import { alternativeQueryParentBlock, singleAlternativeQueryParentBlock } from './alignment';

export const UPDATE_SEGMENT = gql`
mutation updateSegment($segment_id:ID!, $data:UpdateSegmentInput!) {
  updateSegment(segment_id:$segment_id, data:$data) {
      segment_id
      directions_id
      year
      aadt_cars
      aadt_trucks
      aadt_trucks_with_trailer
      annual_increase_cars
      annual_increase_heavy
      start_index
  }
}`;


export const UPDATE_ALTERNATIVE = gql`
mutation updateAlternative($alternative_id:ID!, $data:UpdateAlternativeInput!) { 
  updateAlternative( alternative_id:$alternative_id, data:$data) {
    alternative_id
    name
  }
}`;


export const POST_SEGMENT = gql`
mutation 
addSegment($new_segment:AddSegmentSegmentsInput!){
  addSegment(new_segment:$new_segment) {
    segment_id
    directions_id
    year
    aadt_cars
    aadt_trucks
    aadt_trucks_with_trailer
    annual_increase_cars
    annual_increase_heavy
    start_index
  }
}`;

export const DELETE_SEGMENT = gql`
mutation 
deleteSegment($segment_id:ID!) {
    deleteSegment(segment_id:$segment_id){
    segment_id
    directions_id
    year
    aadt_cars
    aadt_trucks
    aadt_trucks_with_trailer
    annual_increase_cars
    annual_increase_heavy
    start_index
  }
}`;

export const POST_ALTERNATIVE = gql`
mutation
  addAlternative( $project_id: ID!, $dir1:[AddAlternativeDirInput!]!, $dir2:[AddAlternativeDirInput!]!) {
    addAlternative(project_id:$project_id, dir1:$dir1, dir2:$dir2){
      alternative_id
    }
  }
`;


export const GET_VALUES = gql`
query GetAlternatives($project_id: ID!) {
  projects(project_ids: [$project_id]) {
    ${alternativeQueryParentBlock(1, `
    alternative_id
      name
      geom
      ts_uploaded_on
      directions{
        directions_id
        direction
        segments{
          segment_id 
          directions_id
          year
          aadt_cars
          aadt_trucks
          aadt_trucks_with_trailer
          annual_increase_cars
          annual_increase_heavy
          start_index
        }
        segments_data{
          segment_data_id
          geom_order_id
          directions_id
          chainage
          zlevel
          radius
          a_intersection
          geom
        }
      }
      `)}
  }
}`;

export const GET_ALTERNATIVE_BY_ID = (altId) => gql`
query GetAlternatives($project_id: ID!) {
  projects(project_ids: [$project_id]) {
    ${singleAlternativeQueryParentBlock(`
    alternative_id
      name
      geom
      ts_uploaded_on
      directions{
        directions_id
        direction
        segments{
          segment_id 
          directions_id
          year
          aadt_cars
          aadt_trucks
          aadt_trucks_with_trailer
          annual_increase_cars
          annual_increase_heavy
          start_index
        }
        segments_data{
          segment_data_id
          geom_order_id
          directions_id
          chainage
          zlevel
          radius
          a_intersection
          geom
        }
      }
      `, altId)}
  }
}`;