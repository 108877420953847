import { editProjectById, getAllProjects, getProjectById } from "../utils/project"
import { setIsLicense } from "./global"

export const SET_ALL_PROJECTS = 'SET_ALL_PROJECTS'
export const SET_PROJECT_INFO = 'SET_PROJECT_INFO'
export const SET_IS_PROCESSING = 'SET_IS_PROCESSING'
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS'

export const setProjectIsProcessing = isProcessing => ({
  type: SET_IS_PROCESSING,
  payload: { isProcessing }
})

const setAllProjects = projects => ({
  type: SET_ALL_PROJECTS,
  payload: {
    projects,
    isProcessing: false
  }
})

export const setProjectInfo = project => ({
  type: SET_PROJECT_INFO,
  payload: {
    project,
    isProcessing: false
  }
})

const editProjSuccess = (projectId, projectInfo) => ({
  type: EDIT_PROJECT_SUCCESS,
  projectId, projectInfo
})

/**
 * Fetch All Products
 * @returns 
 */
export const fetchAllProjects = () => async (dispatch) => {
  dispatch(setProjectIsProcessing(true))
  try {
    const allProj = await getAllProjects()
    dispatch(setIsLicense(true))
    if (!!allProj && !!allProj.data && !!allProj.data.data &&
      !!allProj.data.data.projects && !!allProj.data.data.projects.length) {
      const resp = (allProj.data.data.projects).filter(p => p.project_id !== '0')
      if (!!resp && !!resp.length) {
        dispatch(setAllProjects(resp))
      }
    } else {
      dispatch(setAllProjects([]))
      return []
    }
  } catch (e) {
    dispatch(setProjectIsProcessing(false))
    dispatch(setIsLicense(false))
    console.log('fetchAllProjects Error: ', e)
    return e
  }
}


/**
 * Get Project Info
 * @param {*} id 
 * @returns 
 */
export const getProjectInfoById = (id, saveInStore = true) => async (dispatch) => {
  dispatch(setProjectIsProcessing(true))
  try {
    const projectInfo = await getProjectById(id)
    dispatch(setProjectIsProcessing(false))
    if (!!projectInfo && !!projectInfo.data && !!projectInfo.data.data &&
      !!projectInfo.data.data.projects && !!projectInfo.data.data.projects.length) {
      const resp = projectInfo.data.data.projects
      if (!!saveInStore) {
        dispatch(setProjectInfo(resp[0]))
      }
      return resp
    }
    return projectInfo
  } catch (e) {
    dispatch(setProjectIsProcessing(false))
    console.log('getProjectById Error: ', e)
    return e
  }
}


/**
 * Update Project
 * @param {*} data
 * @returns 
 */
export const updateProjectInfoById = (data) => async (dispatch) => {
  dispatch(setProjectIsProcessing(true))
  try {
    const projectInfo = await editProjectById(data)
    dispatch(setProjectIsProcessing(false))
    if (!!projectInfo && !!projectInfo.data && !!projectInfo.data.data &&
      !!projectInfo.data.data.updateProject && !!projectInfo.data.data.updateProject) {
      const resp = projectInfo.data.data.updateProject

      // TODO: Need to check, is this in use or not
      dispatch(editProjSuccess(data.project_id, resp))
      dispatch(setProjectInfo(resp))
    }
    return projectInfo
  } catch (e) {
    dispatch(setProjectIsProcessing(false))
    console.log('updateProjectInfoById Error: ', e)
    return e
  }
}