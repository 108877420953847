import React, { useContext, useEffect, useState } from 'react'
import { Modal, Form, Container, Button, Row, Col } from 'react-bootstrap'
import { getStartDistanceToSegment } from '../../../../constant/helpers'
import FuelSaveGlobalContext from '../../../../context/FuelSaveGlobalContext'
import './editpopup.scss'

const AddEditInterSecForm = ({
    minChainage, maxChainage, stepValue,
    isEdit = false, handleSubmit,
    show, onHide, segment,
    interSecInfo, segData
}) => {
    const { mapCoordinateId, setMapCoordinateId } = useContext(FuelSaveGlobalContext)
    const [startDis, setStartDis] = useState(getStartDistanceToSegment((!!isEdit ? interSecInfo.geom_order_id : mapCoordinateId), segData))

    const [isValid, setIsValid] = useState(false)

    const handleStartDis = e => {
        const { value } = e.target
        setStartDis(value)
    }

    useEffect(() => {
        if ((startDis % stepValue) === 0) {
            setIsValid(true)
            const newMapCord = segData.find(a => (a.chainage === parseInt(startDis)))
            if (!!newMapCord && !!newMapCord.geom_order_id) {
                setMapCoordinateId(newMapCord.geom_order_id)
            }
        } else {
            setIsValid(false)
        }

        // eslint-disable-next-line
    }, [startDis])

    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{!!isEdit ? 'Edit' : 'New'} Stop Sign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Form onSubmit={e => handleSubmit(e, interSecInfo, segment.length)}>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="startDistance">
                                    <Form.Label>Stop Sign Point - (Min: {minChainage} Max: {maxChainage})</Form.Label>
                                    <Form.Control type="number"
                                        min={minChainage} max={maxChainage} step={stepValue}
                                        placeholder={startDis} value={startDis}
                                        onChange={e => handleStartDis(e)}
                                        isInvalid={!isValid}
                                    />
                                    <Form.Control.Feedback type="invalid">Value should be in between {minChainage} and {maxChainage} and dividable with {stepValue}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Button disabled={!isValid} bsPrefix="btn-segment" type='submit'>{!!isEdit ? 'Update' : 'Save'}</Button>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button bsPrefix="btn-segment" onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddEditInterSecForm