import React from 'react'
import './index.scss'

const LabelSelectBox = ({
    name,
    label,
    value,
    nameKey = 'name',
    valueKey = 'value',
    options = [],
    className = '',
    parentClassName = '',
    handleOnChange
}) => (
    <div className={`waywize-select-input-container ${parentClassName}`}>
        <label className="waywize-select-input-label" htmlFor={name}>{label}</label>
        <select className={className} name={name} value={value} onChange={e => !!handleOnChange && handleOnChange(e)}>
            {!!options.length && options.map((op, i) =>
                !!op[nameKey] && <option key={i} value={op[valueKey]}>{op[nameKey]}</option>
            )}
        </select>
    </div>
)

export default LabelSelectBox