import React, { useEffect, useRef } from 'react'
import './index.scss'

const ConfirmAlertPopup = ({
    title = 'Are you sure?',
    message = 'You want to delete this file?',
    onConfirm, onClose
}) => {
    const wrapperRef = useRef()

    const handleClickOutside = event => {
        if (!!wrapperRef &&
            (wrapperRef.current !== null) &&
            !wrapperRef.current.contains(event.target)) {
            onClose()
        }
    }

    const handleKeyDown = e => {
        if (((e.keyCode === 27) ||
            (e.key === 'Escape') ||
            (e.key === 'Esc'))) {
            onClose()
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener('keydown', handleKeyDown)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className="confirm-alert-popup-container center-flex">
            <div className="confirm-alert-popup-message center-flex f-column" ref={wrapperRef}>
                <img src={require("../../../assets/Waywize-Spin.png")} className="fuelsave-logo" alt="FuelSave" />
                <h3>{title}</h3>
                <p>{message}</p>
                <div className="around-flex w-100">
                    <button onClick={() => {
                        onConfirm()
                        onClose()
                    }}>Yes</button>
                    <button onClick={onClose}>No</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmAlertPopup