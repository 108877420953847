import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './bothdirections.css';
import TextRotateUpOutlinedIcon from '@material-ui/icons/TextRotateUpOutlined';
import TextRotationDownOutlinedIcon from '@material-ui/icons/TextRotationDownOutlined';
import '../dailysums/dailysums.css'
import AlignmentPageWidget from '../../summary/AlignmentPageWidget';


const BothDirectionTable = ({ bothDirectionsDailys, dataQuerySearch, searchDefaultValue }) => {
    const { currency } = useSelector(state => state.global)

    const convertToLowerCase = (initialList, bothDirDailys) => {
        const randomList = [""]
        if (initialList) {
            initialList.map((item) => {
                randomList.push(item.toLowerCase())
                return item
            })
        }
        else {
            bothDirDailys.map((item) => {
                randomList.push(item.name.toLowerCase())
                return item
            })
        }
        return randomList
    }

    const searchDefaultVal = convertToLowerCase(searchDefaultValue, bothDirectionsDailys)

    const [sortConfig, setSortConfig] = React.useState(null);
    const [sortedBothSums, setSortedBothSums] = useState([...bothDirectionsDailys])
    const [localCombinedBothDirectin, setLocalCombinedBothDirectin] = useState(null)

    const requestSort = key => {

        let direction = 'ascending';
        if (sortConfig !== null) {
            if (sortConfig.key === key && sortConfig.direction === 'ascending') {
                direction = 'descending';
                setSortConfig({ key, direction });
            }
            else {
                direction = 'ascending';
                setSortConfig({ key, direction });
            }
        } else {
            direction = 'ascending';
            setSortConfig({ key, direction });
        }

        const newlist = [...localCombinedBothDirectin]
        if (newlist !== null && sortConfig !== null) {
            newlist.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });

            setSortedBothSums(newlist)
        }
    }

    useEffect(() => {

        const combinedBothDirectin = []
        bothDirectionsDailys.map((element) => {
            combinedBothDirectin.push({
                "name": element.name,
                "alternative_id": element.alternative_id,
                "direction_name": element.direction_name1,
                "daily_fuel": element.daily_fuel1,
                "daily_CO2": element.daily_CO21,
                "daily_energy": element.daily_energy1,
                "daily_cost": element.daily_cost1
            })

            combinedBothDirectin.push({
                "name": element.name,
                "alternative_id": element.alternative_id,
                "direction_name": element.direction_name2,
                "daily_fuel": element.daily_fuel2,
                "daily_CO2": element.daily_CO22,
                "daily_energy": element.daily_energy2,
                "daily_cost": element.daily_cost2
            })
            return element
        })
        setLocalCombinedBothDirectin(combinedBothDirectin)
        setSortedBothSums(combinedBothDirectin)

        // eslint-disable-next-line
    }, [bothDirectionsDailys])


    return (sortedBothSums !== null &&
        <>
            <br />
            <Table hover>
                <thead>
                    <tr>
                        <th>
                            <button type="button" id="rowButton" onClick={() => requestSort('name')} >
                                Alignment
                                {(sortConfig !== null &&
                                    (sortConfig.key === "name" ?
                                        (sortConfig.direction === "ascending" ?
                                            <TextRotateUpOutlinedIcon /> :
                                            <TextRotationDownOutlinedIcon />) :
                                        "")
                                )
                                }
                            </button>
                        </th>

                        <th>
                            <button type="button" id="rowButton" onClick={() => {
                                requestSort('direction_name')
                            }} >
                                Direction
                                {(sortConfig !== null &&
                                    (sortConfig.key === "direction_name" ?
                                        (sortConfig.direction === "ascending" ?
                                            <TextRotateUpOutlinedIcon /> :
                                            <TextRotationDownOutlinedIcon />) :
                                        "")
                                )
                                }
                            </button>
                        </th>
                        <th>
                            <button type="button" id="rowButton" onClick={() => requestSort('daily_fuel')} >
                                Daily fuel (litres)
                                {(sortConfig !== null &&
                                    (sortConfig.key === "daily_fuel" ?
                                        (sortConfig.direction === "ascending" ?
                                            <TextRotateUpOutlinedIcon /> :
                                            <TextRotationDownOutlinedIcon />) :
                                        "")
                                )
                                }
                            </button>
                        </th>
                        <th>
                            <button type="button" id="rowButton" onClick={() => requestSort('daily_CO2')} >
                                Daily CO<sub>2</sub> (kg)
                                {(sortConfig !== null &&
                                    (sortConfig.key === "daily_CO2" ?
                                        (sortConfig.direction === "ascending" ?
                                            <TextRotateUpOutlinedIcon /> :
                                            <TextRotationDownOutlinedIcon />) :
                                        "")
                                )
                                }
                            </button>
                        </th>
                        <th>
                            <button type="button" id="rowButton" onClick={() => requestSort('daily_energy')} >
                                Daily (kWh)
                                {(sortConfig !== null &&
                                    (sortConfig.key === "daily_energy" ?
                                        (sortConfig.direction === "ascending" ?
                                            <TextRotateUpOutlinedIcon /> :
                                            <TextRotationDownOutlinedIcon />) :
                                        "")
                                )
                                }
                            </button>
                        </th>
                        <th>
                            <button type="button" id="rowButton" onClick={() => requestSort('daily_cost')} >
                                Daily cost
                                {(sortConfig !== null &&
                                    (sortConfig.key === "daily_cost" ?
                                        (sortConfig.direction === "ascending" ?
                                            <TextRotateUpOutlinedIcon /> :
                                            <TextRotationDownOutlinedIcon />) :
                                        "")
                                )
                                }
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedBothSums
                        .filter(item => {
                            return item.name.toLowerCase().includes(dataQuerySearch.toLowerCase()) && (searchDefaultVal).includes(item.name.toLowerCase())
                        })
                        .map(({ name, direction_name, daily_fuel, daily_CO2, daily_energy, daily_cost }, i) => (
                            <tr key={i}>
                                <td>{name}</td>
                                <td>{direction_name}</td>
                                <td>{daily_fuel}</td>
                                <td>{daily_CO2}</td>
                                <td>{daily_energy}</td>
                                <td>{`${daily_cost} ${currency}`}</td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            {!!sortedBothSums && !!sortedBothSums.length && <AlignmentPageWidget />}
        </>
    )
}

export default BothDirectionTable
