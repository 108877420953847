import React from 'react'

const Trash = ({
    fill = '#ff313a',
    height = 44,
    width = 44,
    style = {},
    viewBox = '0 0 44 44'
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style}>
        <g transform="translate(-1761 -301)">
            <path fill={fill} d="M222.917,154.7a.519.519,0,0,0-.519.519v9.81a.519.519,0,0,0,1.038,0v-9.81A.519.519,0,0,0,222.917,154.7Zm0,0" transform="translate(1563.146 165.327)" />
            <path fill={fill} d="M104.917,154.7a.519.519,0,0,0-.519.519v9.81a.519.519,0,1,0,1.038,0v-9.81A.519.519,0,0,0,104.917,154.7Zm0,0" transform="translate(1675.021 165.327)" />
            <path fill={fill} d="M1.471,6.6V19.384a2.864,2.864,0,0,0,.761,1.975,2.556,2.556,0,0,0,1.855.8h9.82a2.555,2.555,0,0,0,1.855-.8,2.864,2.864,0,0,0,.761-1.975V6.6a1.983,1.983,0,0,0-.509-3.9H13.357V2.049A2.039,2.039,0,0,0,11.3,0H6.692a2.039,2.039,0,0,0-2.055,2.05V2.7H1.98a1.983,1.983,0,0,0-.509,3.9ZM13.907,21.123H4.087a1.644,1.644,0,0,1-1.578-1.739V6.642H15.485V19.384a1.644,1.644,0,0,1-1.578,1.739ZM5.675,2.049A1,1,0,0,1,6.692,1.037H11.3a1,1,0,0,1,1.017,1.012V2.7H5.675ZM1.98,3.736H16.014a.934.934,0,0,1,0,1.868H1.98a.934.934,0,1,1,0-1.868Zm0,0" transform="translate(1774.003 312.001)" />
            <path fill={fill} d="M163.917,154.7a.519.519,0,0,0-.519.519v9.81a.519.519,0,1,0,1.038,0v-9.81A.519.519,0,0,0,163.917,154.7Zm0,0" transform="translate(1619.083 165.327)" /></g>
    </svg>
)

export default Trash