import { PublicClientApplication } from '@azure/msal-browser'
import config from './Config'

const publicClientApplication =  new PublicClientApplication({
    auth: {
        clientId: config.appId,
        authority: (config.b2cPolicies.authorities.signUpSignIn.authority).toString(), //Change this auth!
        knownAuthorities: [(config.b2cPolicies.authorityDomain).toString()], //Change this auth!
        redirectUri: process.env.REACT_APP_UI_URL,
        postLogoutRedirectUri: process.env.REACT_APP_UI_URL
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    }
})

export default publicClientApplication