import React, { useState, useEffect, useContext } from 'react'
import DailySums from './dailysums/DailySums';
import axios from 'axios';
// import AlternativeTableRow from './dailysums/AlternativeTableRow';
// import BothDirectionCard from './bothdirection/BothDirectionCard';
import BothDirectionTable from './bothdirection/BothDirectionTable';
// import Maps from './maps/Maps';
import { useForm } from "react-hook-form";
import Chip from '@material-ui/core/Chip';
import { requestBody, requestBody2 } from '../../../utils/aiRequestBodyUtils';
import {
    Container,
    Row,
    // Col,
    Tab,
    // Button,
    // Spinner,
    Tabs,
    Dropdown,
    DropdownButton,
    FormControl,
    InputGroup
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import './Comparison.css';
import FuelSaveGlobalContext from '../../../context/FuelSaveGlobalContext';
import InitiateMaps from './maps/InitiationMap';
import SpinLoader from '../../common/SpinLoader';
import { flashErrMsg, presumptionObj } from '../../../constant/helpers';
import './Comparison.css'

const dummyData = [{ "daily_fuel": 10235.77191787462, "daily_CO2": 28282.744636709584, "daily_energy": 313084.9598411531, "daily_cost": 163772.3506859939 }]


const DailyComparison = () => {

    const {
        // mapCoordinateId, setMapCoordinateId,
        // mapCentreIdData, setMapCentreIdData,
        // chainage, setChainage,
        // fcCar, setFcCar,
        // fcTrucks, setFcTrucks,
        // hilliness, setHilliness,
        // speedProfileCars, setSpeedProfileCars,
        // speedProfileTrucks, setSpeedProfileTrucks,
        // zlevel, setZlevel,
        // curvature, setCurvature,
        // segment, setSegment,
        // globalBrandCrumb, 
        setGlobalBrandCrumb,
    } = useContext(FuelSaveGlobalContext);
    const { carConfig, truckConfig } = useSelector(state => state.global)
    const { alignments, isProcessing } = useSelector(state => state.alignments)
    const { presumptions } = useSelector(state => state.presumptions)

    const [alternativeDailySums, setAlternativeDailySums] = useState(null);
    const [bothDirectionsDailys, setBothDirectionsDailys] = useState(null);
    const [mapAlternatives, setMapAlternatives] = useState(null);
    // const [dailyFuel, setDailyFuel] = useState(null);
    // const [dailyCo2, setDailyCo2] = useState(null);
    // const [dailyEnergy, setDailyEnergy] = useState(null);
    // const [dailyCost, setDailyCost] = useState(null);
    // const [cardViewOn, setCardViewOn] = useState(true);
    // const [dropQuerySearch, setDropQuerySearch] = useState('');
    const [dataQuerySearch, setDataQuerySearch] = useState('');
    const [searchDefaultValue, setSearchDefaultValue] = useState('');
    const [key, setKey] = useState('dailySum');
    const [localLoading, setlocalLoading] = useState(false)

    // const toggleView = () => {
    //     setCardViewOn(!cardViewOn)
    // }
    const { register, watch } = useForm();

    const getDailyData = async (direction1Name, direction2Name, startYearData, endYearData, segmentsDir1, segmentsDir2, segmentsDataDir1, segmentsDataDir2, presumptionsData) => {

        const presumptions = presumptionObj(presumptionsData[0])

        const startYear = startYearData ? startYearData : 2020
        const endYear = endYearData ? endYearData : 2041
        // const endYear = startYear + 20  //C wants the end year uneffected!

        const requestBodyDir1 = {
            "return_option": 1,
            "start_year": startYear,
            "end_year": endYear,
            "fuel_distribution_cars": carConfig,
            "fuel_distribution_trucks": truckConfig,
            "sensitivity_param_factors": {
                "speed": 1,
                "fuelprice": 1,
                "emission": 1,
                "AADTheavy": 1
            },
            "presumptions": presumptions,
            'segments': segmentsDir1,
            'segments_data': segmentsDataDir1
        }

        const requestBodyDir2 = {
            "return_option": 1,
            "start_year": startYear,
            "end_year": endYear,
            "fuel_distribution_cars": carConfig,
            "fuel_distribution_trucks": truckConfig,
            "sensitivity_param_factors": {
                "speed": 1,
                "fuelprice": 1,
                "emission": 1,
                "AADTheavy": 1
            },
            "presumptions": presumptions,
            'segments': segmentsDir2,
            'segments_data': segmentsDataDir2
        }

        const requestBodyDir1Option1 = {
            "return_option": 2,
            "start_year": startYear,
            "end_year": endYear,
            "fuel_distribution_cars": carConfig,
            "fuel_distribution_trucks": truckConfig,
            "sensitivity_param_factors": {
                "speed": 1,
                "fuelprice": 1,
                "emission": 1,
                "AADTheavy": 1
            },
            "presumptions": presumptions,
            'segments': segmentsDir1,
            'segments_data': segmentsDataDir1
        }

        const requestBodyDir2Option2 = {
            "return_option": 2,
            "start_year": startYear,
            "end_year": endYear,
            "fuel_distribution_cars": carConfig,
            "fuel_distribution_trucks": truckConfig,
            "sensitivity_param_factors": {
                "speed": 1,
                "fuelprice": 1,
                "emission": 1,
                "AADTheavy": 1
            },
            "presumptions": presumptions,
            'segments': segmentsDir2,
            'segments_data': segmentsDataDir2
        }

        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        const AI_API_KEY = process.env.REACT_APP_AI_FUNCTION_API_KEY

        const urlToken = process.env.REACT_APP_AI_FUNCTION_URL + '/api/HttpTrigger1?code=' + AI_API_KEY;

        try {
            setlocalLoading(true)
            const [responseDir1, responseDir2, responseDir1Option2, responseDir2Option2] = await Promise.all([
                axios.post(urlToken,
                    endYear ? requestBodyDir1 : requestBody,
                    config
                ),
                axios.post(urlToken,
                    endYear ? requestBodyDir2 : requestBody,
                    config
                ),
                axios.post(urlToken,
                    endYear ? requestBodyDir1Option1 : requestBody2,
                    config
                ),
                axios.post(urlToken,
                    endYear ? requestBodyDir2Option2 : requestBody2,
                    config
                )
            ])

            const sumDailyFuel = Math.round(responseDir1.data.daily_fuel + responseDir2.data.daily_fuel)
            const sumDailyCO2 = Math.round(responseDir1.data.daily_CO2 + responseDir2.data.daily_CO2)
            const sumDailyEnergy = Math.round(responseDir1.data.daily_energy + responseDir2.data.daily_energy)

            const sumDailyCost = Math.round(responseDir1.data.daily_cost + responseDir2.data.daily_cost)

            // setDailyFuel(sumDailyFuel)
            // setDailyCo2(sumDailyCO2)
            // setDailyEnergy(sumDailyEnergy)
            // setDailyCost(sumDailyCost)

            setlocalLoading(false)
            return {
                'daily_CO2': sumDailyCO2,
                'daily_cost': sumDailyCost,
                'daily_energy': sumDailyEnergy,
                'daily_fuel': sumDailyFuel,
                'direction_name1': direction1Name,
                'direction_name2': direction2Name,
                'daily_fuel1': Math.round(responseDir1.data.daily_fuel),
                'daily_CO21': Math.round(responseDir1.data.daily_CO2),
                'daily_energy1': Math.round(responseDir1.data.daily_energy),
                'daily_cost1': Math.round(responseDir1.data.daily_cost),
                'daily_fuel2': Math.round(responseDir2.data.daily_fuel),
                'daily_CO22': Math.round(responseDir2.data.daily_CO2),
                'daily_energy2': Math.round(responseDir2.data.daily_energy),
                'daily_cost2': Math.round(responseDir2.data.daily_cost),
                'segment_results_dir1': responseDir1Option2.data.segment_results,
                'segment_results_dir2': responseDir2Option2.data.segment_results
            }
        }
        catch (err) {
            // throw new Error('Unable to get a response.')
            // setDailyFuel(null)
            // setDailyCo2(null)
            // setDailyEnergy(null)
            // setDailyCost(null)
            flashErrMsg(err)
            setlocalLoading(false)
            return null
        }
    }

    useEffect(() => {

        async function setData() {
            setlocalLoading(true)
            if (!alignments || !presumptions) {
                return
            }

            const alternativeDailySums = []
            const bothDirectionsDailys = []
            const mapAlternatives = []

            // use for loop to keep the order
            for (let index = 0; index < alignments.length; index++) {
                const alternative = alignments[index]

                const dailyData = await getDailyData(
                    alternative.directions[0].direction,
                    alternative.directions[1].direction,
                    alternative.start_year,
                    alternative.end_year,
                    alternative.directions[0].segments,
                    alternative.directions[1].segments,
                    alternative.directions[0].segments_data,
                    alternative.directions[1].segments_data,
                    presumptions);

                if (!dailyData) {
                    return false
                }

                alternativeDailySums.push({
                    'name': alternative.name,
                    'alternative_id': alternative.alternative_id,
                    'daily_fuel': dailyData.daily_fuel,
                    'daily_CO2': dailyData.daily_CO2,
                    'daily_energy': dailyData.daily_energy,
                    'daily_cost': dailyData.daily_cost
                })

                bothDirectionsDailys.push({
                    'name': alternative.name,
                    'alternative_id': alternative.alternative_id,
                    'direction_name1': dailyData.direction_name1,
                    'direction_name2': dailyData.direction_name2,
                    'daily_fuel1': dailyData.daily_fuel1,
                    'daily_CO21': dailyData.daily_CO21,
                    'daily_energy1': dailyData.daily_energy1,
                    'daily_cost1': dailyData.daily_cost1,
                    'daily_fuel2': dailyData.daily_fuel2,
                    'daily_CO22': dailyData.daily_CO22,
                    'daily_energy2': dailyData.daily_energy2,
                    'daily_cost2': dailyData.daily_cost2
                })

                // loop segments data

                const segments_data_dir1 = alternative.directions[0].segments_data
                const fc_data1 = dailyData.segment_results_dir1
                const segmentsDataDir1 = segments_data_dir1.map(segment => {

                    const segmentId = segment.segment_data_id;
                    const object = _.find(fc_data1, (elem) => {
                        return elem.segment_data_id.toString() === segmentId ? elem : null;
                    });

                    return {
                        'a_intersection': segment.a_intersection,
                        'geom': segment.geom,
                        'geom_order_id': segment.geom_order_id,
                        'radius': segment.radius,
                        'segment_data_id': segment.segment_data_id,
                        'segment_order_no': segment.segment_order_no,
                        'zlevel': segment.zlevel,
                        'chainage': segment ? segment.chainage : null,
                        'curvature': object ? object.curvature : null,
                        'fuelconsumption_car': object ? object.fuelconsumption_car : null,
                        'fuelconsumption_trucks': object ? object.fuelconsumption_trucks : null,
                        'hilliness': object ? object.hilliness : null,
                        'speedprofile_car': object ? object.speedprofile_car : null,
                        'speedprofile_trucks': object ? object.speedprofile_trucks : null
                    }
                })
                const segments_data_dir2 = alternative.directions[1].segments_data
                const fc_data2 = dailyData.segment_results_dir2
                const segmentsDataDir2 = segments_data_dir2.map(segment => {

                    const segmentId = segment.segment_data_id;
                    const object = _.find(fc_data2, (elem) => {
                        return elem.segment_data_id.toString() === segmentId ? elem : null;
                    });

                    return {
                        'a_intersection': segment.a_intersection,
                        // HINT: it's duplicate
                        // 'chainage': segment.chainage,
                        'geom': segment.geom,
                        'geom_order_id': segment.geom_order_id,
                        'radius': segment.radius,
                        'segment_data_id': segment.segment_data_id,
                        'segment_order_no': segment.segment_order_no,
                        'zlevel': segment.zlevel,
                        'chainage': object ? object.chainage : null,
                        'curvature': object ? object.curvature : null,
                        'fuelconsumption_car': object ? object.fuelconsumption_car : null,
                        'fuelconsumption_trucks': object ? object.fuelconsumption_trucks : null,
                        'hilliness': object ? object.hilliness : null,
                        'speedprofile_car': object ? object.speedprofile_car : null,
                        'speedprofile_trucks': object ? object.speedprofile_trucks : null
                    }
                })

                mapAlternatives.push({
                    'name': alternative.name,
                    'alternative_id': alternative.alternative_id,
                    'start_year': alternative.start_year,
                    'end_year': alternative.end_year,
                    'directions': [
                        {
                            'directions_id': alternative.directions[0].directions_id,
                            'direction': alternative.directions[0].direction,
                            'segments': alternative.directions[0].segments,
                            'segments_data': segmentsDataDir1,
                            'fc_data': dailyData.segment_results_dir1
                        }
                        ,
                        {
                            'directions_id': alternative.directions[1].directions_id,
                            'direction': alternative.directions[1].direction,
                            'segments': alternative.directions[1].segments,
                            'segments_data': segmentsDataDir2,
                            'fc_data': dailyData.segment_results_dir2
                        }
                    ]

                })
            }

            setMapAlternatives(mapAlternatives)
            setAlternativeDailySums(alternativeDailySums)
            setBothDirectionsDailys(bothDirectionsDailys)
            setlocalLoading(false)
        }
        setData();

        // eslint-disable-next-line
    }, [alignments]);

    useEffect(() => {
        setGlobalBrandCrumb("Comparison")

        // eslint-disable-next-line
    }, [])


    const handleDropSearch = (e, data) => {
        // const searchdata = watch()
        // setDropQuerySearch(data.target.innerText)
        //setDataQuerySearch(data.target.innerText)
        //Todo: You can make it a list later
        if (searchDefaultValue) {
            if (!searchDefaultValue.includes(data.target.innerText))
                setSearchDefaultValue([...searchDefaultValue, data.target.innerText])
        }
        else {
            setSearchDefaultValue([data.target.innerText])
        }
    }


    const handleDataSearch = (e, data) => {
        const searchdata = watch()
        setDataQuerySearch(searchdata.DateSearch)
    }

    const handleClear = (deletedItem) => {
        setDataQuerySearch("")
        //setDropQuerySearch("")
        const newList = searchDefaultValue.filter((item) => item !== deletedItem)
        if (newList.length === 0) {
            setSearchDefaultValue("")
        }
        else {
            setSearchDefaultValue([...newList])
        }
    }

    return (
        <Container style={{ backgroundColor: "#ffffff", color: "black" }}>
            <Container>
                <Row>
                    <InputGroup className="mb-3">
                        <FormControl
                            ref={register}
                            aria-label="Text input with dropdown button"
                            type="text"
                            name='DateSearch'
                            placeholder='Search...'
                            onChange={handleDataSearch}
                        />

                        <DropdownButton
                            variant="outline-secondary"
                            title=""
                            id="input-group-dropdown-2"
                            align="end"
                            name="dropDownData"
                            onSelect={handleDropSearch}
                        >
                            {alternativeDailySums && alternativeDailySums
                                .filter(item => {
                                    return item.name.toLowerCase().includes(dataQuerySearch.toLowerCase())
                                })
                                .map((alternative_id, index) =>
                                    <Dropdown.Item key={index}>{alternative_id.name}</Dropdown.Item>
                                )}
                        </DropdownButton>
                    </InputGroup>
                </Row>
                {(searchDefaultValue.length) ?
                    <>
                        <Row>
                            <div className="f-left">
                                {searchDefaultValue.map((item, i) =>
                                    <Chip key={i} label={item} onDelete={() => handleClear(item)} />)}
                            </div>
                        </Row>
                        <br />
                    </> : <></>}
            </Container>
            {(!!localLoading || !!isProcessing) && <SpinLoader />}
            <Tabs transition={false} id="noanim-tab-example" className="tab-body"
                activeKey={key}
                onSelect={(k) => setKey(k)}>
                <Tab eventKey="dailySum" title="Daily Sums">
                    {alternativeDailySums && <DailySums alternativeDailySums={alternativeDailySums} dataQuerySearch={dataQuerySearch} searchDefaultValue={searchDefaultValue} />}
                </Tab>
                <Tab eventKey="bothdirections" title="Both Directions">
                    {bothDirectionsDailys && <BothDirectionTable data={dummyData} bothDirectionsDailys={bothDirectionsDailys} dataQuerySearch={dataQuerySearch} searchDefaultValue={searchDefaultValue} />}
                </Tab>
                <Tab eventKey="maps" title="Maps">
                    {alignments && bothDirectionsDailys && mapAlternatives &&

                        //bothDirectionsDailys.filter()
                        <InitiateMaps
                            bothDirectionsDailys={bothDirectionsDailys}
                            mapAlternatives={mapAlternatives}
                            dataQuerySearch={dataQuerySearch}
                            searchDefaultValue={searchDefaultValue}
                        />
                    }
                </Tab>
            </Tabs>
        </Container>
    )

}

export default DailyComparison;