import React, { useState, useEffect } from 'react';
import { Bar, defaults } from 'react-chartjs-2'
import { useSelector } from 'react-redux';
import { chartCommonConfig } from '../../../../constant';
import { colorCodes } from '../../../../constant/colors';
import { chartSizeAccToWidth } from '../../../../constant/helpers';
import './chartMixed.css';
import { newSlabDiff } from './common';


// "chart.js": "^2.8.0",
// "react-chartjs-2": "^2.7.6",

defaults.global.defaultFontFamily = 'MediumSizeFont';
const { WHITE_1, BLACK_1, BLUE_3, GREEN_2 } = colorCodes
const barChartOptions = {
    ...chartCommonConfig,
    maintainAspectRatio: false,

    // title: { text: 'Energy consumption along road [l/100km, average over 20 meter sections]', display: true },
    tooltips: {
        titleFontSize: 20,
        bodyFontSize: 20
    },
    lineTension: 2,
    scaleOverride: true,
    // scaleSteps: 200,
    // scaleStepWidth: 200,
    scaleShowLabels: true,
    scales: {
        xAxes: [
            {
                scaleLabel: {
                    display: true,
                    labelString: 'FC',

                },
                scaleFontSize: 50,
                ticks: {
                    fontColor: BLACK_1,
                    display: true,
                    beginAtZero: true,
                    min: 0,
                    max: 10,
                },
                stacked: false,
            },
        ],
        yAxes: [
            {
                scaleLabel: {
                    display: true,
                    labelString: 'chainage'
                },
                scaleFontSize: 40,
                ticks: {
                    fontColor: BLACK_1,
                    suggestedMin: 0,
                    suggestedMax: 60,
                    maxTicksLimit: 10,
                    display: true,
                    stepSize: 10
                },
                stacked: false,
            },
        ],
    },
};

// const barChartData = {
//     labels: ['a', 'b', 'c', 'd', 'e'],
//     datasets: [
//         {
//             label: 'Active',
//             backgroundColor: 'rgba(255, 0, 0, 0.3)',
//             // stack: '2',
//             data: [100, 300, 400, 100, 200]
//         },
//         {
//             label: 'Banned',
//             backgroundColor: '#0cc2aa',
//             // stack: '2',
//             data: [200, 200, 400, 300, 200]
//         },
//     ],
// }

const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            type: 'line',
            label: 'Alternative 2',
            borderColor: 'rgb(54, 162, 235)',
            borderWidth: 2,
            fill: true,
            data: [200, 200, 400, 300, 200, 100],
        },
        {
            type: 'line',
            label: 'Alternative 1 line',
            backgroundColor: 'rgb(255, 99, 132)',
            data: [150, 100, 200, 200, 100, 200],
            borderColor: 'white',
            borderWidth: 2,
        },
    ],
}

const compareChainage = (a, b) => {
    if (a.chainage < b.chainage) {
        return -1;
    }
    if (a.chainage > b.chainage) {
        return 1;
    }
    return 0;
}

const ChartMixedAverageCurvatureAndSpeed = ({
    baseAlternative, compareAlternative,
    setLineAtIndex, lineAtIndex, handleStateChange
}) => {

    // const {
    //     mapCoordinateId, setMapCoordinateId,
    //     mapCentreIdData, setMapCentreIdData
    // } = useContext(FuelSaveGlobalContext)

    // console.log(baseAlternative,"baseAlternative")
    const [chartData, setChartData] = useState(data);
    const [chartOptions, setChartOptions] = useState(barChartOptions);
    // const [mapData, setMapData] = useState(null);

    const { winWidth } = useSelector(state => state.global)

    useEffect(() => {
        async function createChart() {
            const baseChainageLabels = []

            // curvature
            const baseCurvatureData = []
            // baseSpeedCarsData
            const baseSpeedCarsData = []

            // compareCurvatureData
            const compareCurvatureData = []
            //compareSpeedCarsData
            const compareSpeedCarsData = []

            const baseSegmentsData = baseAlternative.segments_data;
            baseSegmentsData.sort(compareChainage);

            const mapPointData = []

            let index = 0

            baseSegmentsData.map(segment => {
                let n = 0;
                while (n < 4) {
                    baseChainageLabels.push(segment.chainage)
                    baseCurvatureData.push(segment.curvature)
                    baseSpeedCarsData.push(segment.speedprofile_car)
                    mapPointData.push({
                        'index': (index + n),
                        'chainage': segment.chainage,
                        'segment_order_no': segment.segment_order_no,
                        'fuelconsumption_trucks': segment.fuelconsumption_trucks,
                        'hilliness': segment.hilliness,
                        'speedprofile_car': segment.speedprofile_car,
                        'fuelconsumption_car': segment.fuelconsumption_car,
                        'speedprofile_trucks': segment.speedprofile_trucks,
                        'geom_order_id': segment.geom_order_id,
                        'geom': segment.geom,
                    })
                    n++;
                }
                index = index + 4;
                return segment
            })
            // setMapData(mapPointData);

            if (compareAlternative) {

                const compareSegmentsData = compareAlternative.segments_data;
                compareSegmentsData.sort(compareChainage);

                compareSegmentsData.map(segment => {

                    let n = 0;
                    while (n < 4) {
                        compareCurvatureData.push(segment.curvature)
                        compareSpeedCarsData.push(segment.speedprofile_car)
                        n++;
                    }

                    return segment
                })
            }

            const compareDir = compareAlternative ? compareAlternative.direction : ''

            const data = {
                labels: baseChainageLabels,
                datasets: [
                    {
                        type: 'line',
                        label: 'Base curvature ' + (baseAlternative ? baseAlternative.direction : ''),
                        labelColor: WHITE_1,
                        borderColor: BLUE_3,
                        borderWidth: 1,
                        pointRadius: 1,
                        pointHoverRadius: 1,
                        fill: false,
                        data: baseCurvatureData
                    },
                    {
                        type: 'line',
                        label: 'Base speed cars ' + compareDir,
                        borderColor: GREEN_2,
                        borderWidth: 1,
                        pointRadius: 1,
                        pointHoverRadius: 1,
                        fill: false,
                        data: baseSpeedCarsData
                    },

                    /* {
                         type: 'line',
                         label: 'Compare curvature ' + compareDir,
                         borderColor: 'rgb(255, 99, 132)',
                         borderWidth: 1,
                         pointRadius: 1,
                         pointHoverRadius: 1,
                         fill: true,
                         data: compareCurvatureData
                     },
     
                     {
                         type: 'line',
                         label: 'Compare speed ' + compareDir,
                         borderColor: 'rgb(255,102,102)',
                         borderWidth: 1,
                         pointRadius: 1,
                         pointHoverRadius: 1,
                         fill: true,
                         data: compareSpeedCarsData
                     },*/
                ]
            }

            if (compareAlternative) {
                data.datasets.push({
                    type: 'line',
                    label: 'Compare curvature ' + compareDir,
                    borderColor: '#9eabed',
                    borderWidth: 1,
                    pointRadius: 1,
                    pointHoverRadius: 1,
                    fill: false,
                    data: compareCurvatureData
                })
                data.datasets.push({
                    type: 'line',
                    label: 'Compare speed ' + compareDir,
                    borderColor: '#9cfad6',
                    borderWidth: 1,
                    pointRadius: 1,
                    pointHoverRadius: 1,
                    fill: false,
                    data: compareSpeedCarsData
                })
            }


            const barChartOptions = {
                ...chartCommonConfig,
                maintainAspectRatio: false,
                animation: {
                    duration: 0
                },
                legend: {
                    labels: {
                        fontColor: BLUE_3,
                        fontSize: chartSizeAccToWidth(window.innerWidth)
                    },
                    position: 'bottom'
                },
                // title: {
                //    // text: 'Energy consumption along road [l/100km, average over 20 meter sections]',
                //     fontColor: WHITE_1,
                //     displayBLUE_3                // },
                events: ['click'],
                tooltipEvents: ["mousemove", "touchstart", "touchmove", "click"],
                tooltips: {
                    titleFontSize: chartSizeAccToWidth(window.innerWidth),
                    bodyFontSize: chartSizeAccToWidth(window.innerWidth),
                },

                onHover: function (evt, element) {

                },
                onClick: function (evt, element) {
                    if (element[0]) {
                        // const chart = element[0]._chart
                        const index = element[0]._index
                        // const activePoints = chart.getElementsAtEventForMode(evt, 'point', chart.options);
                        // const firstPoint = activePoints[0];
                        // const label = chart.data.labels[firstPoint._index];
                        // const value = chart.data.datasets[firstPoint._datasetIndex].data[firstPoint._index];
                        //setMapCentreIdData(mapPointData[index]);                   
                        handleStateChange("localMapCentreIdData", mapPointData[index])
                        setLineAtIndex(false)
                    }
                },
                lineTension: 2,
                scaleOverride: true,
                scaleShowLabels: true,
                scales: {
                    xAxes: [
                        {
                            scaleLabel: {
                                //display: true,
                                fontColor: BLUE_3,
                                labelString: 'Chainage',
                                fontSize: chartSizeAccToWidth(window.innerWidth)
                            },
                            scaleFontSize: 50,
                            ticks: {
                                fontColor: BLUE_3,
                                //display: true,
                                beginAtZero: true,
                                maxTicksLimit: 10,
                                stepSize: 10,
                                fontSize: chartSizeAccToWidth(window.innerWidth)
                                // min: 0,
                                //max: 10000,
                            },
                            gridLines: {
                                display: false
                            },
                            stacked: false,
                            afterBuildTicks: function (mychart) {
                                mychart.options.ticks.maxTicksLimit = mychart.max
                                mychart.chart.config.lineAtIndex = !!lineAtIndex ? [parseInt(lineAtIndex)] : []
                                const indexCollection = []
                                newSlabDiff(mychart.max).map(ins => indexCollection.push((mychart.ticks).indexOf(ins)))
                                mychart.options.ticks.callback = function (label, i) {
                                    return indexCollection.includes(i) ? label : '';
                                }
                            }
                        },
                    ],
                    yAxes: [
                        {
                            scaleLabel: {
                                //display: true,
                                fontColor: BLUE_3,
                                labelString: 'Speed (km/h)',
                                fontSize: chartSizeAccToWidth(window.innerWidth)
                            },
                            scaleFontSize: 90,
                            ticks: {
                                fontColor: BLUE_3,
                                suggestedMin: 0,
                                suggestedMax: 180,
                                maxTicksLimit: 10,
                                //display: true,
                                stepSize: 10,
                                max: 120,
                                fontSize: chartSizeAccToWidth(window.innerWidth)
                            },
                            gridLines: {
                                display: false
                            },
                            stacked: false,
                        },
                    ],
                },
            }
            setChartData(data);
            setChartOptions(barChartOptions);
        }

        createChart()

        // eslint-disable-next-line
    }, [baseAlternative, compareAlternative, winWidth, lineAtIndex])

    return (
        <div className="mapRow">
            <div className="map-container">
                <Bar data={chartData} options={chartOptions} id="averageCurvatureChart" />
            </div>
        </div>
    )
}

export default ChartMixedAverageCurvatureAndSpeed