import React, { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import SpinLoader from "../../../common/SpinLoader"
import TablePagination from "../../../common/TablePagination"
import { fetchAllAlignments } from "../../../../actions/alignment"

const AlignmentPageWidget = () => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const { total, activePage } = useSelector(state => state.alignments)
    const { projectId } = useSelector(state => state.global)

    const fetchPaginatedRecords = async (page) => {
        setIsLoading(true)
        try {
            await dispatch(fetchAllAlignments(projectId, page))
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log('Fetch PaginatedRecords:', e)
        }
    }

    return (
        <Fragment>
            {!!isLoading && <SpinLoader />}
            <TablePagination
                activePage={activePage}
                handlePageChange={page => fetchPaginatedRecords(page)} total={total} />
        </Fragment>
    )
}

export default AlignmentPageWidget
