import React, { Fragment } from 'react';
import MapsDir1 from "./Maps"

const InitiateMaps = (props) => {

    const convertToLowerCase = (initialList, actualList) => {
        const randomList = ["Test"]
        if (initialList) {
            initialList.map((item) => (
                randomList.push(item.toLowerCase())
            ))
        }
        else {
            actualList.map((item) => {
                randomList.push(item.name.toLowerCase())
                return item
            })
        }
        return randomList
    }

    //const alternatives = props.alternatives
    const bothDirectionsDailys = props.bothDirectionsDailys
    const mapAlternatives = props.mapAlternatives

    const dataQuerySearch = props.dataQuerySearch
    const searchDefaultValue = convertToLowerCase(props.searchDefaultValue, mapAlternatives)



    return (

        (mapAlternatives && mapAlternatives.filter(item => {
            //return item.name.toLowerCase().includes(dataQuerySearch.toLowerCase()) && item.name.toLowerCase().includes(dropQuerySearch.toLowerCase())
            return item.name.toLowerCase().includes(dataQuerySearch.toLowerCase()) && (searchDefaultValue).includes(item.name.toLowerCase())
        })
            .map((item, idx) => (
                //Need to have both directions individually here
                <Fragment key={idx}>
                    <MapsDir1 //Start from here
                        key={`mapitemDIR1-${idx}`}
                        bothDirectionsDailys={bothDirectionsDailys.filter(dir => dir.name === item.name)}
                        mapAlternatives={mapAlternatives.filter(mapA => mapA.name === item.name)}
                        allMapAlternatives={mapAlternatives}
                        allBothDirectionsDailys={bothDirectionsDailys}
                        selected_direction={0} />
                    <MapsDir1 //Start from here
                        key={`mapitemDIR2-${idx}`}
                        bothDirectionsDailys={bothDirectionsDailys.filter(dir => dir.name === item.name)}
                        mapAlternatives={mapAlternatives.filter(mapA => mapA.name === item.name)}
                        allMapAlternatives={mapAlternatives}
                        allBothDirectionsDailys={bothDirectionsDailys}
                        selected_direction={1} />
                </Fragment>

            ))
        )
    );
}

export default InitiateMaps;