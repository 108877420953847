import React, { useState, useEffect, useRef, useContext } from "react";
import { Map, View } from "ol";
import * as proj from "ol/proj";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import Select from "ol/interaction/Select";
import Overlay from "ol/Overlay.js";
import {
  getCentreCoord,
  createSectionalPointLayer,
  createSectionalComparePointLayer,
} from "../../../utils/maputils";
import "./map.css";
import axios from 'axios'

import FuelSaveGlobalContext from "../../../context/FuelSaveGlobalContext";

function SectionalAnalysisMap(props) {
  const directions = props.baseAlternative;
  // const comparedirections = props.compareAlternative;

  const {
    // mapCoordinateId,
    // setMapCoordinateId,
    // mapCentreIdData,
    // setMapCentreIdData,
    // previousMapCentreIdData,
    // setPreviousMapCentreIdData,
    // chainage,
    setChainage,
    // fcCar,
    setFcCar,
    // fcTrucks,
    setFcTrucks,
    // hilliness,
    setHilliness,
    // speedProfileCars,
    setSpeedProfileCars,
    // speedProfileTrucks,
    setSpeedProfileTrucks,
    // zlevel,
    setZlevel,
    // curvature,
    setCurvature,
    // segment,
    setSegment,
    apiAccessToken
    // editSectionalAnalysisChart,
    // setEditSectionalAnalysisChart,
  } = useContext(FuelSaveGlobalContext);

  const segments = directions && directions.segments_data ? directions.segments : null;
  const segments_data = directions.segments_data ? directions.segments_data : null;
  const centreCoord = segments_data && segments_data.length > 0 ? getCentreCoord(directions) : [0, 0];
  const posCentre = proj.fromLonLat(segments_data && segments_data.length > 0 ? centreCoord.coordinates : centreCoord);

  const overlayRef = useRef(null);
  const [map, setMap] = useState();
  const [zoom, setZoom] = useState(11);
  const mapElement = useRef();

  function customLoader(tile, src) {

    axios({
        method: 'get',
        url: src,
        headers:{
            'Authorization': `Bearer ${apiAccessToken.accessToken}`
        }
        
    }).then((response)=>{
        tile.getImage().src = response.data;
    })

  }

  useEffect(() => {
    let urlVal = process.env.REACT_APP_API_URL
    urlVal = urlVal.replace('/graphql', '')

    const XYZSource = new XYZ({
      url: `${urlVal}/apimaptiler/?url=api.maptiler.com/maps/voyager/{z}/{x}/{y}.png`
    })
		XYZSource.setTileLoadFunction(customLoader);

		const bgLayer = new TileLayer({
			source: XYZSource,
		});

    const initialMap = new Map({
      target: mapElement.current,
      layers: [bgLayer],
      view: new View({
        center: posCentre,
        zoom: zoom,
      }),
      pixelRatio: 1.0
    });

    let select = null;
    let selectSingleClick = new Select();

    const overlay = new Overlay({
      position: posCentre,
      element: overlayRef.current,
      positioning: "center-center",
      stopEvent: false,
    });

    initialMap.on("singleclick", function (evt) {
      // props.setMapRef(mapElement)
      let coordinate = evt.coordinate;
      overlay.setPosition(coordinate);
      props.settingCoordinate(Math.round(coordinate[0]), Math.round(coordinate[1]))
    });

    if (select !== null) {
      initialMap.removeInteraction(select);
    }
    select = selectSingleClick;

    if (select !== null) {
      initialMap.addInteraction(select);
      select.on("select", function (e) {
        if (e.selected[0]) {
          //setMapCoordinateId(e.selected[0].values_.geom_order_id);
          props.handleStateChange('localMapCoordinateId', e.selected[0].values_.geom_order_id)
          setChainage(e.selected[0].values_.chainage);
          setFcCar(e.selected[0].values_.fuelconsumption_car);
          setFcTrucks(e.selected[0].values_.fuelconsumption_trucks);
          setHilliness(e.selected[0].values_.hilliness);
          setSpeedProfileCars(e.selected[0].values_.speedprofile_car);
          setSpeedProfileTrucks(e.selected[0].values_.speedprofile_trucks);
          setZlevel(e.selected[0].values_.zlevel);
          setCurvature(null);
          setSegment(e.selected[0].values_.segment_order_no);
        } else {
          //setMapCoordinateId(null);
          props.handleStateChange('localMapCoordinateId', null)
          setChainage(null);
          setFcCar(null);
          setFcTrucks(null);
          setHilliness(null);
          setSpeedProfileCars(null);
          setSpeedProfileTrucks(null);
          setZlevel(null);
          setCurvature(null);
          setSegment(null);
        }
      });
    }

    initialMap.on("moveend", () => {
      setZoom(initialMap.getView().getZoom());
    });

    initialMap.on("zoomend", function () {
      setZoom(initialMap.getView().getZoom());
    });

    let layersToRemove = [];

    initialMap.getLayers().forEach(function (layer) {
      if (
        layer.get("name") !== undefined &&
        layer.get("name").includes("pointlayer")
      ) {
        layersToRemove.push(layer);
      }
    });

    let len = layersToRemove.length;
    for (let i = 0; i < len; i++) {
      initialMap.removeLayer(layersToRemove[i]);
    }


    const mapLayer =
      segments_data.length != null
        ? createSectionalPointLayer(segments_data, segments, zoom)
        : null;

    if (mapLayer) {
      initialMap.addLayer(mapLayer);
    }

    setMap(initialMap);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (map) {
      const directions = props.baseAlternative;
      const segments = directions && directions.segments_data ? directions.segments : null;
      const segments_data = directions.segments_data ? directions.segments_data : null;
      const centreCoord = segments_data && segments_data.length > 0 ? getCentreCoord(directions) : [0, 0];
      const posCentre = proj.fromLonLat(segments_data && segments_data.length > 0 ? centreCoord.coordinates : centreCoord);
      let layersToRemove = [];
      map.getLayers().forEach(function (layer) {
        if (
          layer.get("name") !== undefined &&
          layer.get("name").includes("pointlayer")
        ) {
          layersToRemove.push(layer);
        }
      });

      let len = layersToRemove.length;
      for (let i = 0; i < len; i++) {
        map.removeLayer(layersToRemove[i]);
      }

      const mapLayer =
        segments_data.length != null
          ? createSectionalPointLayer(segments_data, segments, zoom)
          : null;

      if (mapLayer) {
        map.addLayer(mapLayer);
      }
      map.getView().setCenter(posCentre);
    }
    // eslint-disable-next-line
  }, [props.baseAlternative])

  useEffect(() => {

    if (map) {
      let layersToRemove = [];
      map.getLayers().forEach(function (layer) {
        if (
          layer.get("name") !== undefined &&
          layer.get("name").includes("pointlayer")
        ) {
          layersToRemove.push(layer);
        }
      });

      let len = layersToRemove.length;
      for (let i = 0; i < len; i++) {
        map.removeLayer(layersToRemove[i]);
      }


      const mapLayer =
        segments_data.length != null
          ? createSectionalPointLayer(segments_data, segments, zoom)
          : null;

      if (mapLayer) {
        map.addLayer(mapLayer);
      }
      if (props.localPreviousMapCentreIdData && props.localPreviousMapCentreIdData[0] && props.localPreviousMapCentreIdData[1]) {
        const mapCompareLayer =
          segments_data.length != null
            ? createSectionalComparePointLayer(
              segments_data,
              segments,
              zoom,
              props.localPreviousMapCentreIdData[0],
              props.localPreviousMapCentreIdData[1]
            )
            : null;
        if (mapCompareLayer) {
          map.addLayer(mapCompareLayer);
        }
      }

      // zoom to map if chart is clicked
      if (props.localPreviousMapCentreIdData && props.localPreviousMapCentreIdData[0]) {

        const mapCentreIdData = props.localPreviousMapCentreIdData[0];

        //setMapCentreIdData(null);
        props.handleStateChange('localMapCentreIdData', null)

        /*setMapCoordinateId(
          mapCentreIdData ? mapCentreIdData.geom_order_id : null
        );*/

        props.handleStateChange('localMapCoordinateId', mapCentreIdData ? mapCentreIdData.geom_order_id : null)

        setChainage(mapCentreIdData ? mapCentreIdData.chainage : null);
        setFcCar(mapCentreIdData ? mapCentreIdData.fuelconsumption_car : null);
        setFcTrucks(
          mapCentreIdData ? mapCentreIdData.fuelconsumption_trucks : null
        );
        setHilliness(mapCentreIdData ? mapCentreIdData.hilliness : null);
        setSpeedProfileCars(
          mapCentreIdData ? mapCentreIdData.speedprofile_car : null
        );
        setSpeedProfileTrucks(mapCentreIdData ? mapCentreIdData.speedprofile_trucks : null)
        setZlevel(mapCentreIdData ? mapCentreIdData.zlevel : null);
        setCurvature(null);
        setSegment(mapCentreIdData ? mapCentreIdData.segment_order_no : null);
      }
    }
    // eslint-disable-next-line
  }, [zoom, props.localPreviousMapCentreIdData]);

  // useEffect(() => {
  //   props.setMapRef(mapElement)
  // })


  return <div className="mapRow">
    <div ref={mapElement} className="map-container" />
  </div>
}

export default SectionalAnalysisMap;
