import React, { useContext } from "react";
import { Nav } from "react-bootstrap";
import { Layout, Menu } from "antd";
import { LinkContainer } from "react-router-bootstrap";
import "./navbar.css";
import { useHistory } from "react-router-dom";
import { FolderOutlined, BarChartOutlined, MergeCellsOutlined, LineChartOutlined, PieChartOutlined, CalculatorOutlined } from "@ant-design/icons";
import FuelSaveGlobalContext from "../../context/FuelSaveGlobalContext";
import LogoWaywizeSmall from "../../assets/Waywize-Spin.png"
import { colorCodes } from "../../constant/colors"

const { GREEN_1, BLUE_3 } = colorCodes
const menuList = [
  { title: 'Projects', hoverText: 'Press here to go access the project page', href: '', to: '/', icon: <FolderOutlined /> },
  { title: 'Alignments', hoverText: 'Press here to add Alignments to project', href: 'summary', to: '/summary', icon: <BarChartOutlined /> },
  { title: 'Comparison', hoverText: 'Press here to analyse and compare Alignments added to project', href: 'comparison', to: '/comparison', icon: <MergeCellsOutlined /> },
  { title: 'Sectional Analysis', hoverText: 'Press here to make vertical adjustments on added alignments to project', href: 'sectional', to: '/sectional', icon: <LineChartOutlined /> },
  { title: 'Forecasts', hoverText: 'Press here to see forecasted results of alignments added to project', href: 'forecasts', to: '/forecasts', icon: <PieChartOutlined /> },
  { title: 'CDR', hoverText: 'Press here to see CDR results', href: 'cdr', to: '/cdr', icon: <CalculatorOutlined /> },
  // { title: 'Settings', href: 'presumptions', to: '/settings', icon: <FileOutlined className="icon-picture" /> }
]
const NavBar = (props) => {
  const { Sider } = Layout;
  const { navSideBarOpen, setNavSideBarOpen } = useContext(FuelSaveGlobalContext);
  const history = useHistory();

  const onCollapse = () => {
    setNavSideBarOpen(!navSideBarOpen);
  };
  return (
    <>
      <Sider
        collapsible
        collapsed={navSideBarOpen}
        prefixCls='fuelsave-sider'
        onCollapse={onCollapse}
        width={350}
        className="slider-body"
      >
        <div className={`fuelsave-fixed-sider ${!navSideBarOpen ? 'is-open' : 'is-close'}`}>
          {
            <div className="center-flex" style={{ marginTop: 34, cursor: 'pointer' }} onClick={() => history.push('/')}>
              {!navSideBarOpen && <div className='fuelsave-logo-title'>Fuelsave</div>}
              <img src={LogoWaywizeSmall} className="fuelsave-logo" alt="FuelSave" />
            </div>
          }
          <Menu
            selectedKeys={props.defaultKeys}
            mode="inline"
            theme="dark"
            style={{ background: BLUE_3, color: GREEN_1, margin: '100px 0px 0px 0px' }}
          >
            {
              menuList.map(({ icon, title, hoverText, href, to }, i) => <Menu.Item
                key={i + 1}
                className="sider-element"
                icon={icon}>
                <LinkContainer to={to}>
                  <Nav.Link href={href}>{navSideBarOpen ? hoverText : title}</Nav.Link>
                </LinkContainer>
              </Menu.Item>)
            }
            {!navSideBarOpen && <p className="sidebar-copy-right-txt">Copyright © 2021 Waywize All Rights Reserved</p>}
          </Menu>
        </div>
      </Sider>
    </>
  );
};

export default NavBar;
