import React, { useState } from 'react'
import moment from 'moment'
import { DATE_FORMAT } from '../../../constant'
import './index.scss'

const ExpirationMsg = ({ licenseValid }) => {
    const [isHide, setIsHide] = useState(false)
    const licenseDate = moment(new Date(licenseValid))
    const todayDate = moment(new Date())

    if (licenseDate.diff(todayDate, 'days') > 31) {
        return null
    }
    return (
        <div className={`exp-msg-container between-flex ${isHide ? 'hide-msg' : ''}`}>
            <p>Your license will expire {moment(licenseValid).format(DATE_FORMAT)}. Contact your system administrator.</p>
            <span onClick={() => setIsHide(true)}>X</span>
        </div>
    )
}

export default ExpirationMsg