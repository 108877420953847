import React from 'react'
import './index.scss'

const LandXMLSelect = ({
    name,
    label,
    value,
    options = [],
    className = '',
    parentClassName = '',
    handleOnChange
}) => (
    <div className={`landxml-select-input-container ${parentClassName}`}>
        <label className="landxml-number-input-label" htmlFor={name}>{label}</label>
        <select className={className} name={name} value={value} onChange={e => !!handleOnChange && handleOnChange(e)}>
            <option disabled>{label}:</option>
            {!!options.length && options.map((op, i) =>
                !!op.name && <option key={i} value={op.value || op.idx || i}>{op.name}</option>
            )}
        </select>
    </div>
)

export default LandXMLSelect