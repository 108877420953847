import { CURRENCY } from "../constant"
import { getAllPresumptions } from "../utils/presumption"
import { setCountry, setCurrency } from "./global"

export const SET_ALL_PRESUMPTIONS = 'SET_ALL_PRESUMPTIONS'
export const SET_PRESUMPTION_PROCESSING = 'SET_PRESUMPTION_PROCESSING'

export const setPresumptionIsProcessing = isProcessing => ({
  type: SET_PRESUMPTION_PROCESSING,
  payload: { isProcessing }
})

export const setAllPresumptions = presumptions => ({
  type: SET_ALL_PRESUMPTIONS,
  payload: {
    presumptions,
    isProcessing: false
  }
})


/**
 * Fetch All presumptions
 * @param {*} projectId 
 * @returns 
 */
export const fetchAllPresumptions = (projectId) => async (dispatch) => {
  dispatch(setPresumptionIsProcessing(true))
  try {
    const resp = await getAllPresumptions(projectId)
    if (!!resp && !!resp.data && !!resp.data.data &&
      !!resp.data.data.projects && !!resp.data.data.projects.length) {
      const alignmentAndPresumptions = resp.data.data.projects[0]
      if (!!alignmentAndPresumptions && !!alignmentAndPresumptions.presumptions) {
        dispatch(setAllPresumptions(alignmentAndPresumptions.presumptions))
        dispatch(setCurrency(
          (!!alignmentAndPresumptions.presumptions[0] &&
            !!alignmentAndPresumptions.presumptions[0].currency)
            ? alignmentAndPresumptions.presumptions[0].currency
            : CURRENCY.NORWAY))
        dispatch(setCountry(
          (!!alignmentAndPresumptions.presumptions[0] &&
            !!alignmentAndPresumptions.presumptions[0].country_name)
            ? alignmentAndPresumptions.presumptions[0].country_name
            : null))
      }
    } else {
      dispatch(setAllPresumptions([]))
      return []
    }
  } catch (e) {
    dispatch(setPresumptionIsProcessing(false))
    console.log('fetchAllPresumptions Error: ', e)
    return e
  }
}