import React, { useState, useEffect, useContext } from "react";
import { gql, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import FuelSaveGlobalContext from "../../../../context/FuelSaveGlobalContext";
import { correctAlternativeForMap } from "../../../../utils/maputils"
import SpinLoader from "../../../common/SpinLoader";
import FuelDistribution from "./FuelDistribution";
import PresumptionsForm from "./PresumptionsForm";
import EditForm from "./EditForm";
import CDRSettings from "./CDRSettings";
import { CURRENCY } from "../../../../constant";
import './index.scss'
import { setAllAlignments } from "../../../../actions/alignment";
import { setAllPresumptions } from "../../../../actions/presumption";
import { setCountry, setCurrency } from "../../../../actions/global";
import { alternativeQueryParentBlock } from "../../../../utils/alignment";
import { handleRequest, presumptionObj2 } from "../../../../constant/helpers";
import ConfirmAlertPopup from "../../../common/ConfirmAlertPopup";
import { resetProjectPresumption } from "../../../../utils/presumption";

const ALTERNATIVES_AND_PRES_SCRIPT = `
query projects($project_ids: [ID!]) {
  projects(project_ids: $project_ids) {
      ${alternativeQueryParentBlock(1, `
            alternative_id
              name
        
              directions {
                directions_id
                direction
                segments {
                  segment_id
                  directions_id
                  year
                  aadt_cars
                  aadt_trucks
                  aadt_trucks_with_trailer
                  annual_increase_cars
                  annual_increase_heavy
                  start_index
                }
                segments_data {
                  segment_data_id
                  chainage
                  zlevel
                  radius
                  a_intersection
                  geom_order_id
                  geom
                }
              }`)}
        
            presumptions {
              presumptions_id
              speed_car
              speed_truck
              cost_increase
              gasoline_fuelfactor
              gasoline_co2
              gasoline_kwh
              gasoline_efficiency
              gasoline_cost
              diesel_fuelfactor
              diesel_co2
              diesel_kwh
              diesel_efficiency
              diesel_cost
              battery_fuelfactor
              battery_co2
              battery_kwh
              battery_efficiency
              battery_cost
              inchargeable_hybrid_fuelfactor
              inchargeable_hybrid_co2
              inchargeable_hybrid_kwh
              inchargeable_hybrid_efficiency
              inchargeable_hybrid_cost
              chargable_hybrid_co2
              chargable_hybrid_kwh
              chargable_hybrid_efficiency
              chargable_hybrid_cost
              hydrogen_fuelfactor
              hydrogen_co2
              hydrogen_kwh
              hydrogen_efficiency
              hydrogen_cost
              iri
              mpd
              currency
              country_id
              country_name
              mpd_increase
              iri_increase
              iri_limit
            }
        }
}
`;

const GET_ALTERNATIVES_AND_PRES = gql`
${ALTERNATIVES_AND_PRES_SCRIPT}`;


const UPDATE_PRESUMPTIONS = gql`
  mutation UpdatePresumptions($project_id: ID!, $data: UpdatePresumptionsInput!) {
    updatePresumptions(project_id: $project_id, data: $data) {
      speed_car
      speed_truck
      cost_increase
      gasoline_fuelfactor
      gasoline_co2
      gasoline_kwh
      gasoline_efficiency
      gasoline_cost
      diesel_fuelfactor
      diesel_co2
      diesel_kwh
      diesel_efficiency
      diesel_cost
      battery_fuelfactor
      battery_co2
      battery_kwh
      battery_efficiency
      battery_cost
      inchargeable_hybrid_fuelfactor
      inchargeable_hybrid_co2
      inchargeable_hybrid_kwh
      inchargeable_hybrid_efficiency
      inchargeable_hybrid_cost
      chargable_hybrid_fuelfactor
      chargable_hybrid_co2
      chargable_hybrid_kwh
      chargable_hybrid_efficiency
      chargable_hybrid_cost
      hydrogen_fuelfactor
      hydrogen_co2
      hydrogen_kwh
      hydrogen_efficiency
      hydrogen_cost
      iri
      mpd
      currency
      country_id
      country_name
      mpd_increase
      iri_increase
      iri_limit
    }
  }
`;

const EditProject = ({ match }) => {
    const dispatch = useDispatch()
    const { setGlobalBrandCrumb } = useContext(FuelSaveGlobalContext)
    const { isProcessing } = useSelector(state => state.projects)
    const { isLoading } = useSelector(state => state.settings)
    const { isProcess } = useSelector(state => state.global)
    const [currencyState, setCurrencyState] = useState(CURRENCY.NORWAY)
    const [countryId, setCountryId] = useState()
    const [isLoader, setIsLoader] = useState(false)

    const onSuccess = presumptionResp => {

        dispatch(setAllPresumptions([presumptionResp]))
        const currencyVal = !!presumptionResp && !!presumptionResp.currency ? presumptionResp.currency : CURRENCY.NORWAY
        const countryVal = !!presumptionResp && !!presumptionResp.country_name ? presumptionResp.country_name : false
        const countryIdVal = !!presumptionResp && !!presumptionResp.country_id ? presumptionResp.country_id : null
        dispatch(setCurrency(currencyVal))
        if (countryVal) {
            dispatch(setCountry(countryVal))
        }
        setCurrencyState(currencyVal)
        setCountryId(countryIdVal)
    }

    const [updatePresumptions, { loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_PRESUMPTIONS, {
        onCompleted: ((data) => {
            if (!!data && !!data.updatePresumptions) {
                onSuccess(data.updatePresumptions)
            }
        })
    })

    const [formPresumptions, setFormPresumptions] = useState({});

    useEffect(() => {
        async function handleLogic() {
            if (!!match.params && !!match.params.id) {
                setIsLoader(true)
                const resp = await handleRequest("post", false, {
                    query: ALTERNATIVES_AND_PRES_SCRIPT,
                    variables: {
                        project_ids: match.params.id
                    },
                })

                if (!!resp && !!resp.data && !!resp.data.data && !!resp.data.data.projects && !!resp.data.data.projects[0]) {
                    const alignmentResp = !!resp.data.data.projects[0].alternatives ?
                        resp.data.data.projects[0].alternatives : false
                    if (!!alignmentResp && !!alignmentResp.node) {
                        const filteredAlignments = correctAlternativeForMap(alignmentResp.node)
                        dispatch(setAllAlignments(filteredAlignments, alignmentResp.totalAmount))
                    }
                    const presumptionResp = presumptionObj2(!!resp.data.data.projects[0].presumptions && !!resp.data.data.projects[0].presumptions[0] ?
                        resp.data.data.projects[0].presumptions[0] : {})

                    setFormPresumptions(presumptionResp)
                    onSuccess(presumptionResp)
                }
                setIsLoader(false)
            }
        }
        handleLogic()
        // eslint-disable-next-line
    }, [match.params.id])

    useEffect(() => {
        setGlobalBrandCrumb("Project Settings")

        // eslint-disable-next-line
    }, [])

    if (mutationError) return <p>Error :(</p>;

    async function handleUpdatePresumptions(presumptions) {
        updatePresumptions({
            variables: {
                project_id: match.params.id,
                data: {
                    speed_car: parseFloat(presumptions.speed_car),
                    speed_truck: parseFloat(presumptions.speed_truck),
                    cost_increase: parseFloat(presumptions.cost_increase),
                    gasoline_fuelfactor: parseFloat(presumptions.gasoline_fuelfactor),
                    gasoline_co2: parseFloat(presumptions.gasoline_co2),
                    gasoline_kwh: parseFloat(presumptions.gasoline_kwh),
                    gasoline_efficiency: parseFloat(presumptions.gasoline_efficiency),
                    gasoline_cost: parseFloat(presumptions.gasoline_cost),
                    diesel_fuelfactor: parseFloat(presumptions.diesel_fuelfactor),
                    diesel_co2: parseFloat(presumptions.diesel_co2),
                    diesel_kwh: parseFloat(presumptions.diesel_kwh),
                    diesel_efficiency: parseFloat(presumptions.diesel_efficiency),
                    diesel_cost: parseFloat(presumptions.diesel_cost),
                    battery_fuelfactor: parseFloat(presumptions.battery_fuelfactor),
                    battery_co2: parseFloat(presumptions.battery_co2),
                    battery_kwh: parseFloat(presumptions.battery_kwh),
                    battery_efficiency: parseFloat(presumptions.battery_efficiency),
                    battery_cost: parseFloat(presumptions.battery_cost),
                    inchargeable_hybrid_fuelfactor: parseFloat(
                        presumptions.inchargeable_hybrid_fuelfactor
                    ),
                    inchargeable_hybrid_co2: parseFloat(
                        presumptions.inchargeable_hybrid_co2
                    ),
                    inchargeable_hybrid_kwh: parseFloat(
                        presumptions.inchargeable_hybrid_kwh
                    ),
                    inchargeable_hybrid_efficiency: parseFloat(
                        presumptions.inchargeable_hybrid_efficiency
                    ),
                    inchargeable_hybrid_cost: parseFloat(
                        presumptions.inchargeable_hybrid_cost
                    ),
                    chargable_hybrid_fuelfactor: parseFloat(
                        presumptions.chargable_hybrid_fuelfactor
                    ),
                    chargable_hybrid_co2: parseFloat(presumptions.chargeable_hybrid_co2),
                    chargable_hybrid_kwh: parseFloat(presumptions.chargeable_hybrid_kwh),
                    chargable_hybrid_efficiency: parseFloat(
                        presumptions.chargeable_hybrid_efficiency
                    ),
                    chargable_hybrid_cost: parseFloat(presumptions.chargeable_hybrid_cost),
                    hydrogen_fuelfactor: parseFloat(presumptions.hydrogen_fuelfactor),
                    hydrogen_co2: parseFloat(presumptions.hydrogen_co2),
                    hydrogen_kwh: parseFloat(presumptions.hydrogen_kwh),
                    hydrogen_efficiency: parseFloat(presumptions.hydrogen_efficiency),
                    hydrogen_cost: parseFloat(presumptions.hydrogen_cost),
                    iri: parseFloat(presumptions.iri),
                    mpd: parseFloat(presumptions.mpd),
                    currency: currencyState,
                    country_id: countryId,
                    mpd_increase: parseFloat(presumptions.mpd_increase),
                    iri_increase: parseFloat(presumptions.iri_increase),
                    iri_limit: parseFloat(presumptions.iri_limit)
                },
            },
            refetchQueries: [
                {
                    query: GET_ALTERNATIVES_AND_PRES,
                    variables: {
                        project_id: match.params.id
                    }
                }],
        });
    }

    async function resetPresumption() {
        setIsLoader(true)
        const resp = await resetProjectPresumption(parseInt(match.params.id))
        if (!!resp && !!resp.data && !!resp.data.data && !!resp.data.data.resetPresumptions) {
            const presResp = presumptionObj2(resp.data.data.resetPresumptions)
            setFormPresumptions(presResp)
            onSuccess(presResp)
        }
        setIsLoader(false)
    }

    console.log("formPresumptions ", formPresumptions)
    return (
        <div className="container project-container">
            {(!!isLoader || !!mutationLoading || !!isLoading || !!isProcessing || !!isProcess) && <SpinLoader />}
            <Link to="/" className="btn-project">Back</Link>
            <FuelDistribution setCurrencyState={setCurrencyState} setCountryId={setCountryId} />
            <PresumptionsForm presumptions={formPresumptions} onChange={(presumptions) => { setFormPresumptions(presumptions) }} />
            <div className="center-flex">
                <Button bsPrefix="btn-project ml-5" onClick={() => handleUpdatePresumptions(formPresumptions)}>Update Settings</Button>
                <Button bsPrefix="btn-project ml-5" onClick={() => {
                    confirmAlert({
                        customUI: values => <ConfirmAlertPopup {...values}
                            onConfirm={() => resetPresumption("segment")}
                            message="You want to restore to default settings?" />
                    })
                }}>Restore Settings</Button>
            </div>
            <EditForm match={match} />
            <CDRSettings match={match} />
        </div>
    )
}

export default EditProject
