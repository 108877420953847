import { toast } from "react-toastify"
import { CURRENCY } from "../constant"
import { flashSuccessErrMsg, handleRequest } from "../constant/helpers"
import { copyTrafficInputs, getAllAlignmentAndPresumption, getAllAlignments, getAllCapInputs } from "../utils/alignment"
import { correctAlternativeForMap } from "../utils/maputils"
import { setCurrency } from "./global"
import { setAllPresumptions } from "./presumption"

export const SET_ALL_ALIGNMENTS = 'SET_ALL_ALIGNMENTS'
export const SET_ALIGNMENT_INFO = 'SET_ALIGNMENT_INFO'
export const UPDATE_ALIGNMENT_INFO = 'UPDATE_ALIGNMENT_INFO'
export const SET_CDR_ALL_ALIGNMENTS = 'SET_CDR_ALL_ALIGNMENTS'
export const SET_ALIGNMENT_ACTIVE_PAGE = 'SET_ALIGNMENT_ACTIVE_PAGE'
export const SET_ALIGNMENT_PROCESSING = 'SET_ALIGNMENT_PROCESSING'
export const SET_CDR_ALIGNMENT_PROCESSING = 'SET_CDR_ALIGNMENT_PROCESSING'
export const SET_CDR_ALL_ALIGNMENTS_INPUT = 'SET_CDR_ALL_ALIGNMENTS_INPUT'

export const setAlignmentIsProcessing = isProcessing => ({
  type: SET_ALIGNMENT_PROCESSING,
  payload: { isProcessing }
})

export const setCDRAlignmentIsProcessing = isCDRProcessing => ({
  type: SET_CDR_ALIGNMENT_PROCESSING,
  payload: { isCDRProcessing }
})

export const setAllAlignments = (alignments, total = 0) => ({
  type: SET_ALL_ALIGNMENTS,
  payload: {
    total,
    alignments,
    isProcessing: false
  }
})

export const setAlignmentInfo = (alignmentInfo) => ({
  type: SET_ALIGNMENT_INFO,
  payload: {
    alignmentInfo,
    isProcessing: false
  }
})

export const updateSegDataInfo = (segData, dirId) => ({
  type: UPDATE_ALIGNMENT_INFO,
  payload: {
    segData, dirId
  }
})


export const setAllCDRAlignments = (cdrAlignments) => ({
  type: SET_CDR_ALL_ALIGNMENTS,
  payload: {
    cdrAlignments,
    isCDRProcessing: false
  }
})

export const setAllCDRAlignmentsInput = (cdrAlignmentsInput) => ({
  type: SET_CDR_ALL_ALIGNMENTS_INPUT,
  payload: {
    cdrAlignmentsInput,
    isCDRProcessing: false
  }
})

export const setAlignmentActivePage = (activePage) => ({
  type: SET_ALIGNMENT_ACTIVE_PAGE,
  payload: {
    activePage
  }
})


/**
 * Fetch All Alignments
 * and presumptions
 * @param {*} projectId 
 * @returns 
 */
export const fetchAllAlignmentAndPresumptions = (projectId) => async (dispatch) => {
  dispatch(setAlignmentIsProcessing(true))
  try {
    const resp = await getAllAlignmentAndPresumption(projectId)
    if (!!resp && !!resp.data && !!resp.data.data &&
      !!resp.data.data.projects && !!resp.data.data.projects.length) {
      const alignmentAndPresumptions = resp.data.data.projects[0]

      if (!!alignmentAndPresumptions && !!alignmentAndPresumptions.alternatives) {
        const filteredAlignments = correctAlternativeForMap(alignmentAndPresumptions.alternatives)
        dispatch(setAllAlignments(filteredAlignments))
      }
      if (!!alignmentAndPresumptions && !!alignmentAndPresumptions.presumptions) {
        dispatch(setAllPresumptions(alignmentAndPresumptions.presumptions))
        dispatch(setCurrency(
          (!!alignmentAndPresumptions.presumptions[0] &&
            !!alignmentAndPresumptions.presumptions[0].currency)
            ? alignmentAndPresumptions.presumptions[0].currency
            : CURRENCY.NORWAY))
      }
    } else {
      dispatch(setAllAlignments([]))
      dispatch(setAllPresumptions([]))
      return []
    }
  } catch (e) {
    dispatch(setAlignmentIsProcessing(false))
    console.log('fetchAllAlignmentAndPresumptions Error: ', e)
    return e
  }
}


/**
 * Fetch All Alignments
 * and presumptions
 * @param {*} projectId 
 * @param {*} pageNo 
 * @returns 
 */
export const fetchAllAlignments = (projectId, pageNo = 1) => async (dispatch) => {
  dispatch(setAlignmentIsProcessing(true))
  try {
    const resp = await getAllAlignments(projectId, pageNo)
    if (!!resp && !!resp.data && !!resp.data.data &&
      !!resp.data.data.projects && !!resp.data.data.projects.length) {
      const alignmentResp = resp.data.data.projects[0]

      if (!!alignmentResp && !!alignmentResp.alternatives && !!alignmentResp.alternatives.node) {
        const filteredAlignments = correctAlternativeForMap(alignmentResp.alternatives.node)
        dispatch(setAllAlignments(filteredAlignments, alignmentResp.alternatives.totalAmount))
      }
      dispatch(setAlignmentActivePage(pageNo))
    } else {
      dispatch(setAllAlignments([]))
      return []
    }
  } catch (e) {
    dispatch(setAlignmentIsProcessing(false))
    console.log('fetchAllAlignments Error: ', e)
    return e
  }
}


/**
 * Fetch All Alignments
 * for CDR page
 * @param {*} projectId 
 * @param {*} pageNo 
 * @param {*} fetchAll 
 * @returns 
 */
export const fetchAllCDRAlignments = (projectId, pageNo = 1, fetchAll = true) => async (dispatch) => {
  dispatch(setCDRAlignmentIsProcessing(true))
  try {
    const resp = await getAllAlignments(projectId, pageNo, fetchAll)
    if (!!resp && !!resp.data && !!resp.data.data &&
      !!resp.data.data.projects && !!resp.data.data.projects.length) {
      const alignmentResp = resp.data.data.projects[0]

      if (!!alignmentResp && !!alignmentResp.alternatives && !!alignmentResp.alternatives.node) {
        const filteredAlignments = correctAlternativeForMap(alignmentResp.alternatives.node)
        dispatch(fetchAllCapInputs({
          project_id: (projectId).toString()
        }, filteredAlignments, projectId))
      }
    } else {
      dispatch(setAllCDRAlignments([]))
      return []
    }
  } catch (e) {
    dispatch(setCDRAlignmentIsProcessing(false))
    console.log('fetchAllCDRAlignments Error: ', e)
    return e
  }
}


/**
 * Fetch All Alignments
 * for CDR page
 * @param {*} data 
 * @param {*} alignments 
 * @param {*} projectId 
 * @returns 
 */
export const fetchAllCapInputs = (data, alignments, projectId) => async (dispatch) => {
  dispatch(setCDRAlignmentIsProcessing(true))
  try {
    const resp = await getAllCapInputs(data)
    if (!!resp && !!resp.data && !!resp.data.data &&
      !!resp.data.data.CDRValuesByProjectId && !!resp.data.data.CDRValuesByProjectId.length) {
      const alignmentResp = resp.data.data.CDRValuesByProjectId
      const newResp = await Promise.all(alignments.map(a => {
        const result = alignmentResp.find(b => parseInt(b.alternative_id) === parseInt(a.alternative_id))
        if (!!result) {
          return { ...a, ...result }
        }
        return a
      }))
      dispatch(setAllCDRAlignments(newResp))
    } else {

      if (!!alignments && alignments.length) {
        const newResp = await Promise.all(alignments.map(a => {
          return {
            ...a, ...{
              investment_emssn: "0",
              is_active: "1",
              maintainance_emssn: "0",
              project_id: (projectId).toString()
            }
          }
        }))
        dispatch(setAllCDRAlignments(newResp))
      } else {
        dispatch(setAllCDRAlignments([]))
      }
      return []
    }
  } catch (e) {
    dispatch(setCDRAlignmentIsProcessing(false))
    console.log('fetchAllCapInputs Error: ', e)
    return e
  }
}



/**
 * Copy Traffic Alignments
 * @param {*} alignmentId
 * @returns 
 */
export const copyTrafficAlignments = (alignmentId, projectId) => async (dispatch) => {
  dispatch(setAlignmentIsProcessing(true))
  try {
    const resp = await copyTrafficInputs(alignmentId)

    if (!!resp && !!resp.data && !!resp.data.data &&
      !!resp.data.data.copySegments && !!resp.data.data.copySegments.length) {
      dispatch(fetchAllAlignments(projectId))
    } else {
    }
  } catch (e) {
    dispatch(setAlignmentIsProcessing(false))
    console.log('copyTrafficAlignments Error: ', e)
    return e
  }
}


/**
 * Add Intersection
 * @param {*} data 
 * @param {*} interSec 
 * @param {*} dirId 
 * @returns 
 */
export const addIntersection = (data, interSec, dirId) => async (dispatch, getState) => {
  dispatch(setAlignmentIsProcessing(true))
  try {
    const resp = await handleRequest("post", false, {
      query: `mutation
      addIntersection($new_intersection:AddIntersectionInput!) {
        addIntersection(new_intersection: $new_intersection) {
          success,message
        }
      }`,
      variables: data
    })
    dispatch(setAlignmentIsProcessing(false))
    if (!!resp && !!resp.data && !!resp.data.data && !!resp.data.data.addIntersection && !!resp.data.data.addIntersection.success) {
      interSec.a_intersection = 1
      dispatch(updateSegDataInfo(interSec, dirId))
      toast.success('Stop Sign added successfully')
      if (!!getState().global.projectId) {
        dispatch(fetchAllAlignments(getState().global.projectId))
      }
      return true
    }
    flashSuccessErrMsg(resp)
    return false
  } catch (err) {
    console.log('addIntersection Error:', err);
    dispatch(setAlignmentIsProcessing(false))
    return false
  }
}


/**
 * Edit Intersection
 * @param {*} data
 * @param {*} dirId
 * @returns 
 */
export const editIntersection = (data, dirId) => async (dispatch, getState) => {
  dispatch(setAlignmentIsProcessing(true))
  try {
    const resp = await handleRequest("post", false, {
      query: `mutation
      updateIntersection($directions_id:ID! ,$update_intersection_data:UpdateIntersectionInput!) {
        updateIntersection(directions_id:$directions_id,update_intersection_data: $update_intersection_data) {
          success,message
        }
      }`,
      variables: data
    })
    dispatch(setAlignmentIsProcessing(false))
    if (!!resp && !!resp.data && !!resp.data.data && !!resp.data.data.updateIntersection && !!resp.data.data.updateIntersection.success) {
      dispatch(updateSegDataInfo(data.update_intersection_data, dirId))
      toast.success('Stop Sign updated successfully')
      if (!!getState().global.projectId) {
        dispatch(fetchAllAlignments(getState().global.projectId))
      }
    }
  } catch (err) {
    console.log('editIntersection Error:', err);
    dispatch(setAlignmentIsProcessing(false))
  }
}


/**
 * Delete Intersection
 * @param {*} interSec
 * @param {*} dirId
 * @returns 
 */
export const deleteIntersection = (interSec, dirId) => async (dispatch, getState) => {
  dispatch(setAlignmentIsProcessing(true))
  try {
    const resp = await handleRequest("post", false, {
      query: `mutation deleteIntersection($intersection_point:ID!, $directions_id:ID!) {
        deleteIntersection(intersection_point:$intersection_point, directions_id:$directions_id) {
          success,message
        }
      }`,
      variables: {
        intersection_point: interSec.geom_order_id,
        directions_id: Number(interSec.directions_id)
      }
    })

    dispatch(setAlignmentIsProcessing(false))

    if (!!resp && !!resp.data && !!resp.data.data && !!resp.data.data.deleteIntersection && !!resp.data.data.deleteIntersection.success) {
      interSec.a_intersection = 0
      dispatch(updateSegDataInfo(interSec, dirId))
      toast.success('Stop Sign deleted successfully')
      if (!!getState().global.projectId) {
        dispatch(fetchAllAlignments(getState().global.projectId))
      }
      return true
    }
    return false
  } catch (err) {
    console.log('deleteIntersection Error:', err);
    dispatch(setAlignmentIsProcessing(false))
    return false
  }
}
