import { EDIT_PROJECT_SUCCESS, SET_ALL_PROJECTS, SET_IS_PROCESSING, SET_PROJECT_INFO } from '../actions/project'

const defaultState = {
  isProcessing: false,
  projects: [],
  project: {}
}

const Projects = (state = defaultState, action) => {
  switch (action.type) {
    case SET_PROJECT_INFO:
    case SET_IS_PROCESSING:
    case SET_ALL_PROJECTS:
      return Object.assign({}, state, action.payload)
    case EDIT_PROJECT_SUCCESS: {
      let allProjects = (state.projects).map(a => {
        if (parseInt(a.project_id) === parseInt(action.projectId)) {
          return { ...a, ...action.projectInfo }
        } else {
          return a
        }
      })
      return {
        ...state,
        isProcessing: false,
        projects: allProjects
      }
    }
    default:
      return state
  }
}

export default Projects