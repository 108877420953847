import React from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import "./login.scss"
import waywizeLogo from "../../../assets/Waywize-logotype-white-green.png"
import { currentFullYear } from "../../../constant/helpers"

const Welcome = ({ authButtonMethod }) => (
  <div id="app">
    <div className="d-flex flex-column bd-highlight" style={{ minHeight: "100vh" }}>
      <div className="p-2 bd-highlight">
        <img src={waywizeLogo} className="login-logo" alt="Waywize" />
      </div>
      <div className="p-2 bd-highlight hero">
        <h1 className="welcome-h1">
          {"Welcome to "}
          <span style={{ color: "green" }}>Fuelsave</span>
          <span className="mode-value">BETA</span>
        </h1>
      </div>
      <div className="p-2 bd-highlight">
        <h5 style={{ color: "white" }}>
          {"We can help you to reduce fuel and energy consumption!"}
        </h5>
      </div>
      <div className="p-2 bd-highlight">
        <Button id="sign-in-button" onClick={authButtonMethod}>
          Sign in
        </Button>
      </div>
      <div className="p-2 bd-highlight">
        <div style={{ marginTop: "35vh" }}>
          <div>
            <span className="text-muted">
              Copyright © {currentFullYear()} · Waywize · All Rights Reserved
            </span>
          </div>
          <div>
            <Link to="/about" className="mr-2">About</Link>
            <a href="https://www.waywize.com/fuelsave-terms-and-conditions/" target="#">Terms of Service</a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Welcome
