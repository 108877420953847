import React, { useContext, useEffect, useState } from 'react'
import { Modal, Form, Container, Button, Row, Col } from 'react-bootstrap'
import { getStartDistanceToSegment } from '../../../../constant/helpers'
import FuelSaveGlobalContext from '../../../../context/FuelSaveGlobalContext'
import './editpopup.scss'

const AddEditSegmentForm = ({
    minChainage, maxChainage, stepValue,
    isEdit = false, segIdx, handleSubmit,
    show, onHide, segment, handleInputChange,
    alternativeData, segData
}) => {
    const { mapCoordinateId, setMapCoordinateId } = useContext(FuelSaveGlobalContext)
    const [startDis, setStartDis] = useState(getStartDistanceToSegment((!!isEdit ? segment[segIdx].start_index : mapCoordinateId), segData))

    const [isValid, setIsValid] = useState(false)

    const handleStartDis = e => {
        const { value } = e.target
        setStartDis(value)
    }

    useEffect(() => {
        if ((startDis % stepValue) === 0) {
            setIsValid(true)
            const newMapCord = segData.find(a => (a.chainage === parseInt(startDis)))
            if (!!newMapCord && !!newMapCord.geom_order_id) {
                setMapCoordinateId(newMapCord.geom_order_id)
            }
        } else {
            setIsValid(false)
        }

        // eslint-disable-next-line
    }, [startDis])

    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{!!isEdit ? 'Edit' : 'New'} Segment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Form onSubmit={e => handleSubmit(e, (!!isEdit ? segment[segIdx] : alternativeData), segment.length)}>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Segment</Form.Label>
                                    <Form.Control disabled readOnly type="text" placeholder={segment[segIdx].start_index} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="year">
                                    <Form.Label>Start Year</Form.Label>
                                    <Form.Control disabled type="text" placeholder={segment[segIdx].year} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="segmentUpdateAadt">
                                    <Form.Label>SegmentAadt</Form.Label>
                                    <Form.Control type="text" placeholder={Math.round(segment[segIdx].aadt_cars + segment[segIdx].aadt_trucks + segment[segIdx].aadt_trucks_with_trailer)} onChange={(e) => handleInputChange('segmentUpdateAadt', e)} disabled />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="segmentUpdatePrcHeavy">
                                    <Form.Label>% Heavy</Form.Label>
                                    <Form.Control type="text" disabled readOnly
                                        placeholder={Math.round(((segment[segIdx].aadt_trucks + segment[segIdx].aadt_trucks_with_trailer) / (segment[segIdx].aadt_cars + segment[segIdx].aadt_trucks + segment[segIdx].aadt_trucks_with_trailer)) * 100)}
                                        onChange={(e) => handleInputChange('segmentUpdatePrcHeavy', e)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        {
                            (segment.length > 1) &&
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="startDistance">
                                        <Form.Label>Start Distance - (Min: {minChainage} Max: {maxChainage})</Form.Label>
                                        <Form.Control type="number"
                                            min={minChainage} max={maxChainage} step={stepValue}
                                            placeholder={startDis} value={startDis}
                                            onChange={e => handleStartDis(e)}
                                            isInvalid={!isValid}
                                        />
                                        <Form.Control.Feedback type="invalid">Value should be in between {minChainage} and {maxChainage} and dividable with {stepValue}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col />
                            </Row>
                        }
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="segmentUpdateCars">
                                    <Form.Label>Cars</Form.Label>
                                    <Form.Control type="text" placeholder={segment[segIdx].aadt_cars} onChange={(e) => handleInputChange('segmentUpdateCars', e)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="segmentUpdateTrucks">
                                    <Form.Label>Trucks</Form.Label>
                                    <Form.Control type="text" placeholder={segment[segIdx].aadt_trucks} onChange={(e) => handleInputChange('segmentUpdateTrucks', e)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="segmentUpdateTrucksWt">
                                    <Form.Label>Trucks + T</Form.Label>
                                    <Form.Control type="text" placeholder={segment[segIdx].aadt_trucks_with_trailer} onChange={(e) => handleInputChange('segmentUpdateTrucksWt', e)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="annualIncCars">
                                    <Form.Label>Annual Increase Cars</Form.Label>
                                    <Form.Control type="text" placeholder={segment[segIdx].annual_increase_cars} onChange={(e) => handleInputChange('annualIncCars', e)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="annualIncHeavy">
                                    <Form.Label>Annual Increase Heavy</Form.Label>
                                    <Form.Control type="text" placeholder={segment[segIdx].annual_increase_heavy} onChange={(e) => handleInputChange('annualIncHeavy', e)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Button disabled={!isValid} bsPrefix="btn-segment" type='submit'>{!!isEdit ? 'Update' : 'Save'}</Button>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button bsPrefix="btn-segment" onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddEditSegmentForm