import React, { useEffect, useState } from 'react'
import './index.scss'

const TermsPopUp = () => {
    const apiAccessTokenObj = JSON.parse(sessionStorage.getItem("apiAccessTokenObj"))
    const [termAgreed, setTermAgreed] = useState(false)
    const userId = !!apiAccessTokenObj && !!apiAccessTokenObj.uniqueId ? apiAccessTokenObj.uniqueId : false

    useEffect(() => {
        if (userId) {
            const termAccepted = `${userId}-accepted`
            const termAcceptedVal = sessionStorage.getItem(termAccepted)
            sessionStorage.setItem(termAccepted, termAcceptedVal)
            setTermAgreed(termAcceptedVal)
        }
    }, [apiAccessTokenObj, userId])

    const handleClick = () => {
        const termAccepted = `${userId}-accepted`
        sessionStorage.setItem(termAccepted, true)
        setTermAgreed(true)
    }

    if (termAgreed === 'true' || !userId) return null

    return (
        <div className='terms-accepted-container around-flex'>
            <div className='terms-accepted-content'>
                WayWize uses local and session storage to improve the user experience and to collect usage statistics. By using this website, you accept the use of local and session storage.
            </div>
            <button type="button" onClick={() => handleClick()}>Ok</button>
        </div>
    )
}

export default TermsPopUp