import { handleRequest } from "../constant/helpers"

/**
 * Get All Presumptions
 * @param {*} projectId 
 * @returns 
 */
export const getAllPresumptions = projectId => handleRequest("post", false, {
    query: `
        query {
          projects(project_ids: [${projectId}]) {        
            presumptions {
              presumptions_id
              speed_car
              speed_truck
              cost_increase
              gasoline_fuelfactor
              gasoline_co2
              gasoline_kwh
              gasoline_efficiency
              gasoline_cost
              diesel_fuelfactor
              diesel_co2
              diesel_kwh
              diesel_efficiency
              diesel_cost
              battery_fuelfactor
              battery_co2
              battery_kwh
              battery_efficiency
              battery_cost
              inchargeable_hybrid_fuelfactor
              inchargeable_hybrid_co2
              inchargeable_hybrid_kwh
              inchargeable_hybrid_efficiency
              inchargeable_hybrid_cost
              chargable_hybrid_co2
              chargable_hybrid_kwh
              chargable_hybrid_efficiency
              chargable_hybrid_cost
              hydrogen_fuelfactor
              hydrogen_co2
              hydrogen_kwh
              hydrogen_efficiency
              hydrogen_cost
              iri
              mpd
              currency
              country_id
              country_name
              iri_increase
              mpd_increase
              iri_limit
            }
          }
        }
              `,
})


/**
 * Reset Project Presumption
 * @param {*} projectId 
 * @returns 
 */
export const resetProjectPresumption = projectId => handleRequest("post", false, {
  query: `
  mutation resetPresumptions($project_id:ID!) {
        resetPresumptions(project_id: $project_id) {
            presumptions_id
            speed_car
            speed_truck
            cost_increase
            gasoline_fuelfactor
            gasoline_co2
            gasoline_kwh
            gasoline_efficiency
            gasoline_cost
            diesel_fuelfactor
            diesel_co2
            diesel_kwh
            diesel_efficiency
            diesel_cost
            battery_fuelfactor
            battery_co2
            battery_kwh
            battery_efficiency
            battery_cost
            inchargeable_hybrid_fuelfactor
            inchargeable_hybrid_co2
            inchargeable_hybrid_kwh
            inchargeable_hybrid_efficiency
            inchargeable_hybrid_cost
            chargable_hybrid_co2
            chargable_hybrid_kwh
            chargable_hybrid_efficiency
            chargable_hybrid_cost
            hydrogen_fuelfactor
            hydrogen_co2
            hydrogen_kwh
            hydrogen_efficiency
            hydrogen_cost
            iri
            mpd
            currency
            country_id
            country_name
            iri_increase
            mpd_increase
            iri_limit
        }
      }
            `,
  variables: { "project_id": projectId }
})