const authorityDomain = process.env.REACT_APP_B2C_AUTHORITY_DOMAIN;
const tenantId = process.env.REACT_APP_B2C_TENANT_ID;

module.exports = {
    appId: process.env.REACT_APP_B2C_APP_ID,
    graphScopes: [ "https://graph.microsoft.com/User.Read" ],
    apiScopes: [ process.env.REACT_APP_B2C_API_SCOPE ],
    b2cPolicies: {
        names: {
            signUpSignIn: "B2C_1A_FUELSAVE_SIGNUPSIGNIN",
            forgotPassword: "B2C_1A_FUELSAVE_PASSWORDRESET",
            editProfile: "B2C_1A_FUELSAVE_PROFILEEDIT"
        },
        authorities: {
            signUpSignIn: {
                authority: `https://${authorityDomain}/${tenantId}/B2C_1A_FUELSAVE_SIGNUPSIGNIN`,
            },
            editProfile: {
                authority: `https://${authorityDomain}/${tenantId}/B2C_1A_FUELSAVE_PROFILEEDIT`
            },
            forgotPassword: {
                authority: `https://${authorityDomain}/${tenantId}/B2C_1A_FUELSAVE_PASSWORDRESET`
            }
        },
        authorityDomain
    }
};