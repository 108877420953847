import React, { useState, useEffect } from 'react';
import { Bar, defaults } from 'react-chartjs-2'
import { useSelector } from 'react-redux';
import { colorCodes } from '../../../../constant/colors';
import { chartSizeAccToWidth } from '../../../../constant/helpers';
// import FuelSaveGlobalContext from '../../../../context/FuelSaveGlobalContext';
import './chartMixed.css';
// import { useTranslation } from "react-i18next"
// import _ from 'lodash';
import { newSlabDiff } from './common';


// "chart.js": "^2.8.0",
// "react-chartjs-2": "^2.7.6",
defaults.global.defaultFontFamily = 'MediumSizeFont';
const { BLUE_3, WHITE_1, GREEN_2 } = colorCodes
const barChartOptions = {

    // title: { text: 'Energy consumption along road [l/100km, average over 20 meter sections]', display: true },
    tooltips: {
        titleFontSize: chartSizeAccToWidth(window.innerWidth),
        bodyFontSize: chartSizeAccToWidth(window.innerWidth)
    },
    lineTension: 2,
    scaleOverride: true,
    // scaleSteps: 200,
    // scaleStepWidth: 200,
    scaleShowLabels: true,
    scales: {
        xAxes: [
            {
                scaleLabel: {
                    display: true,
                    labelString: 'FC',

                },
                scaleFontSize: 40,
                ticks: {
                    fontColor: WHITE_1,
                    display: true,
                    beginAtZero: true,
                    maxTicksLimit: 10,
                    stepSize: 100,
                    min: 0,
                    max: 10,

                },

                stacked: false,
            },
        ],
        yAxes: [
            {
                scaleLabel: {
                    display: true,
                    labelString: 'chainage'

                },
                scaleFontSize: 40,
                ticks: {
                    fontColor: WHITE_1,
                    suggestedMin: 0,
                    suggestedMax: 100,
                    maxTicksLimit: 10,
                    display: true,
                    stepSize: 10
                },
                stacked: false,
            },
        ],
    },
};



// const barChartData = {
//     labels: ['a', 'b', 'c', 'd', 'e'],
//     datasets: [
//         {
//             label: 'Active',
//             backgroundColor: 'rgba(255, 0, 0, 0.3)',
//             // stack: '2',
//             data: [100, 300, 400, 100, 200]
//         },
//         {
//             label: 'Banned',
//             backgroundColor: '#0cc2aa',
//             // stack: '2',
//             data: [200, 200, 400, 300, 200]
//         },
//     ],
// }

const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            type: 'line',
            label: 'Alternative 2',
            borderColor: 'rgb(54, 162, 235)',
            borderWidth: 2,
            fill: true,
            data: [200, 200, 400, 300, 200, 100],
        },
        {
            type: 'line',
            label: 'Alternative 1 line',
            backgroundColor: 'rgb(255, 99, 132)',
            data: [150, 100, 200, 200, 100, 200],
            borderColor: 'white',
            borderWidth: 2,
        },
    ],
}

const datasetKeyProvider = () => {
    return btoa(Math.random()).substring(0, 12)
}

const compareChainage = (a, b) => {
    if (a.chainage < b.chainage) {
        return -1;
    }
    if (a.chainage > b.chainage) {
        return 1;
    }
    return 0;
}


const ChartMixedEnergyConsumptionAlongRoad = ({
    baseAlternative, compareAlternative,
    handleStateChange, setLineAtIndex, lineAtIndex
}) => {

    // const {
    //     mapCoordinateId, setMapCoordinateId,
    //     mapCentreIdData, setMapCentreIdData
    // } = useContext(FuelSaveGlobalContext)

    // const { t, i18n } = useTranslation();

    const { winWidth } = useSelector(state => state.global)
    const [chartData, setChartData] = useState(data);
    const [chartOptions, setChartOptions] = useState(barChartOptions);
    // const [mapData, setMapData] = useState(null);

    useEffect(() => {
        async function createChart() {
            const baseChainageLabels = []
            const baseFCTrucksData = []
            const baseFCCarsData = []
            const compareFCTrucksData = []
            const compareFCCarsData = []

            const baseSegmentsData = baseAlternative.segments_data;
            baseSegmentsData.sort(compareChainage);

            const mapPointData = []

            let index = 0

            baseSegmentsData.map(segment => {

                let n = 0;
                while (n < 4) {
                    baseChainageLabels.push(segment.chainage)
                    baseFCTrucksData.push(segment.fuelconsumption_trucks)
                    baseFCCarsData.push(segment.fuelconsumption_car)
                    mapPointData.push({
                        'index': (index + n),
                        'chainage': segment.chainage,
                        'segment_order_no': segment.segment_order_no,
                        'fuelconsumption_trucks': segment.fuelconsumption_trucks,
                        'hilliness': segment.hilliness,
                        'speedprofile_car': segment.speedprofile_car,
                        'fuelconsumption_car': segment.fuelconsumption_car,
                        'speedprofile_trucks': segment.speedprofile_trucks,
                        'geom_order_id': segment.geom_order_id,
                        'geom': segment.geom,
                    })
                    n++;
                }
                index = index + 4;
                return segment
            })
            // setMapData(mapPointData);

            if (compareAlternative) {

                const compareSegmentsData = compareAlternative.segments_data;
                compareSegmentsData.sort(compareChainage);

                compareSegmentsData.map(segment => {

                    let n = 0;
                    while (n < 4) {
                        compareFCTrucksData.push(segment.fuelconsumption_trucks)
                        compareFCCarsData.push(segment.fuelconsumption_car)
                        n++;
                    }

                    return segment
                })
            }

            // const compareDir = compareAlternative ? compareAlternative.direction : ''

            const data = {
                labels: baseChainageLabels,
                datasets: [
                    {
                        type: 'line',
                        label: 'Base Chainage trucks ',// + (baseAlternative ? baseAlternative.direction : ''),
                        labelColor: WHITE_1,
                        borderColor: BLUE_3,
                        borderWidth: 1,
                        pointRadius: 1,
                        pointHoverRadius: 1,
                        fill: false,
                        data: baseFCTrucksData
                    },
                    {
                        type: 'bar',
                        label: 'Base Chainage cars ',// + compareDir,
                        borderColor: GREEN_2,
                        borderWidth: 1,
                        pointRadius: 1,
                        pointHoverRadius: 1,
                        fill: false,
                        data: baseFCCarsData
                    },
                    /*{
                        type: 'line',
                        label: 'Compare Chainage trucks ' + compareDir,
                        borderColor: 'rgb(255, 99, 132)',
                        borderWidth: 1,
                        pointRadius: 1,
                        pointHoverRadius: 1,
                        fill: true,
                        data: compareFCTrucksData
                    },
                    {
                        type: 'line',
                        label: 'Compare Chainage cars ' + compareDir,
                        borderColor: 'rgb(255,102,102)',
                        borderWidth: 1,
                        pointRadius: 1,
                        pointHoverRadius: 1,
                        fill: true,
                        data: compareFCCarsData
                    },*/
                    // {
                    //     type: 'bar',
                    //     label: ' ',
                    //     labelColor: WHITE_1,
                    //     borderColor: 'rgb(0,0,0, 0.1)',
                    //     borderWidth: 1,
                    //     pointRadius: 1,
                    //     pointHoverRadius: 1,
                    //     fill: false,
                    //     data: baseFCTrucksData
                    // },
                ]
            }

            if (compareAlternative) {
                const objectCompareFCTrucksData = {
                    type: 'line',
                    label: 'Compare Chainage trucks ', //+ compareDir,
                    borderColor: '#9eabed',
                    borderWidth: 1,
                    pointRadius: 1,
                    pointHoverRadius: 1,
                    fill: false,
                    data: compareFCTrucksData
                }

                data.datasets.push(objectCompareFCTrucksData)

                const objectCompareFCCarsData = {
                    type: 'bar',
                    label: 'Compare Chainage cars ', //+ compareDir,
                    borderColor: '#9cfad6',
                    borderWidth: 1,
                    pointRadius: 1,
                    pointHoverRadius: 1,
                    fill: true,
                    data: compareFCCarsData
                }

                data.datasets.push(objectCompareFCCarsData)

            }


            const barChartOptions = {
                animation: {
                    duration: 0
                },
                legend: {
                    labels: {
                        fontColor: "black",
                        fontSize: chartSizeAccToWidth(window.innerWidth)
                    },
                    position: 'bottom'
                },
                events: ['click'],
                tooltipEvents: ["mousemove", "touchstart", "touchmove", "click"],
                tooltips: {
                    titleFontSize: chartSizeAccToWidth(window.innerWidth),
                    bodyFontSize: chartSizeAccToWidth(window.innerWidth),
                },

                onHover: function (evt, element) {

                },
                onClick: function (evt, element) {
                    if (element[0]) {
                        // const chart = element[0]._chart
                        const index = element[0]._index
                        // const activePoints = chart.getElementsAtEventForMode(evt, 'point', chart.options);
                        // const firstPoint = activePoints[0];
                        // const label = chart.data.labels[firstPoint._index];
                        // const value = chart.data.datasets[firstPoint._datasetIndex].data[firstPoint._index];
                        //setMapCentreIdData(mapPointData[index]);
                        handleStateChange("localMapCentreIdData", mapPointData[index])
                        setLineAtIndex(false)
                    }
                },
                lineTension: 2,
                scaleOverride: true,
                scaleShowLabels: true,
                scales: {
                    xAxes: [
                        {
                            scaleLabel: {
                                display: true,
                                fontColor: BLUE_3,
                                labelString: 'Chainage',
                                fontSize: chartSizeAccToWidth(window.innerWidth)

                            },
                            scaleFontSize: 40,
                            ticks: {
                                fontColor: BLUE_3,
                                display: true,
                                beginAtZero: true,
                                maxTicksLimit: 20,
                                stepSize: 10,
                                min: 0,
                                max: 10,
                                fontSize: chartSizeAccToWidth(window.innerWidth)
                            },
                            gridLines: {
                                display: false
                            },
                            stacked: false,
                            afterBuildTicks: function (mychart) {
                                mychart.options.ticks.maxTicksLimit = mychart.max
                                const indexCollection = []
                                mychart.chart.config.lineAtIndex = !!lineAtIndex ? [parseInt(lineAtIndex)] : []
                                newSlabDiff(mychart.max).map(ins => indexCollection.push((mychart.ticks).indexOf(ins)))
                                mychart.options.ticks.callback = function (label, i) {
                                    return indexCollection.includes(i) ? label : '';
                                }
                            }
                        },
                    ],
                    yAxes: [
                        {
                            scaleLabel: {
                                display: true,
                                fontColor: BLUE_3,
                                labelString: 'Fuel Consumption',
                                fontSize: chartSizeAccToWidth(window.innerWidth)
                            },
                            scaleFontSize: 40,
                            ticks: {
                                fontColor: BLUE_3,
                                suggestedMin: 0,
                                suggestedMax: 60,
                                maxTicksLimit: 10,
                                display: true,
                                stepSize: 10,
                                fontSize: chartSizeAccToWidth(window.innerWidth)
                            },
                            gridLines: {
                                display: false
                            },
                            stacked: false,
                        },
                    ],
                },
            }
            setChartData(data);
            setChartOptions(barChartOptions);
        }

        createChart()

        // eslint-disable-next-line
    }, [baseAlternative, compareAlternative, winWidth, lineAtIndex])

    return (
        <Bar className="barchart" data={chartData} options={chartOptions} datasetKeyProvider={datasetKeyProvider} />
    )
}

export default ChartMixedEnergyConsumptionAlongRoad