import { norwayCarBattery, norwayCarDiesel, norwayCarGasoline, norwayCarHydro, norwayCarInChargeHybrid, norwayCarPreChargeHybrid, norwayTruckChargeHydro, norwayTruckDiesel, norwayTruckGas, norwayTruckHydro, norwayTruckNonCharge } from "../constant/norway-dummy"

const rangeArr = (start, end) => {
  var list = []
  for (var i = start; i <= end; i++) {
    list.push(i)
  }
  return list
}


const yearArr = rangeArr(2020, 2080)

const carGasolineDieselArr_Finland =[
"0.693590703620339",
"0.681339590052732",
"0.664839101359926",
"0.648651770774712",
"0.632758679047934",
"0.623681076178084",
"0.601786155359045",
"0.586675291477279",
"0.571795678455247",
"0.557134429435798",
"0.546147700570897",
"0.528420648387421",
"0.514347396378445",
"0.500450999184047",
"0.486723414803149",
"0.46846494975102",
"0.459746874766139",
"0.446486122510432",
"0.433370546459129",
"0.420396260805654",
"0.403239459431378",
"0.394859843694846",
"0.382293708048533",
"0.369860814184505",
"0.357560922259203",
"0.34897992739135",
"0.324086845625179",
"0.310017922869721",
"0.295949000114266",
"0.281880077358811",
"0.267811154603353",
"0.253742231847895",
"0.239673309092438",
"0.225604386336982",
"0.211535463581525",
"0.197466540826067",
"0.183397618070611",
"0.16906302212628",
"0.154996087617505",
"0.140692423294794",
"0.12570465618028",
"0.110944335174564",
"0.0963890770692947",
"0.0820555915630043",
"0.0679400262445987",
"0.0540374488599134",
"0.0403430748845398",
"0.0268522620339582",
"0.0135605050167032",
"0.000463430518063219",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0"
]

const carGasolineDieselArr = [
  "0.47769230799999995",
  "0.470923077",
  "0.46415384600000004",
  "0.457384615",
  "0.450615385",
  "0.443846154",
  "0.43707692299999995",
  "0.430307692",
  "0.423538462",
  "0.416769231",
  "0.41",
  "0.4",
  "0.39",
  "0.38",
  "0.37",
  "0.36",
  "0.35",
  "0.34",
  "0.33",
  "0.32",
  "0.31",
  "0.299134227",
  "0.287647059",
  "0.275502959",
  "0.26266436",
  "0.249091543",
  "0.23474252",
  "0.21957290100000001",
  "0.20353575699999998",
  "0.186581477",
  "0.168657611",
  "0.14970871",
  "0.12967615400000002",
  "0.10849797",
  "0.086108642",
  "0.062438908",
  "0.037415542",
  "0.010961132",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0"
]

const truckGasolineDieselArrFinland = [
  "0.998196665",
  "0.996992623",
  "0.99535662",
  "0.993274401",
  "0.990766386",
  "0.987722657",
  "0.984556248",
  "0.980896211",
  "0.97689433",
  "0.972571621",
  "0.9682239",
  "0.963046807",
  "0.957885536",
  "0.952484973",
  "0.946864544",
  "0.940802342",
  "0.935039321",
  "0.928870806",
  "0.92255493",
  "0.916108385",
  "0.909449009",
  "0.902887039",
  "0.896142588",
  "0.889328178",
  "0.88245748",
  "0.875693491",
  "0.879199984",
  "0.874201365",
  "0.869202746",
  "0.864204127",
  "0.859205507",
  "0.854206888",
  "0.849208269",
  "0.84420965",
  "0.83921103",
  "0.834212411",
  "0.829213792",
  "0.824215173",
  "0.819216553",
  "0.814217934",
  "0.809219315",
  "0.804220696",
  "0.799222077",
  "0.794223457",
  "0.789224838",
  "0.784226219",
  "0.7792276",
  "0.77422898",
  "0.769230361",
  "0.764231742",
  "0.759233123",
  "0.754234503",
  "0.749235884",
  "0.744237265",
  "0.739238646",
  "0.734240026",
  "0.729241407",
  "0.724242788",
  "0.719244169",
  "0.714245549",
  "0.70924693"  
]


const truckGasolineDieselArr = [
  "0.389615385",
  "0.386153846",
  "0.382692308",
  "0.37923076899999997",
  "0.37576923100000004",
  "0.372307692",
  "0.368846154",
  "0.365384615",
  "0.36192307700000004",
  "0.35846153799999997",
  "0.355",
  "0.3515",
  "0.348",
  "0.3445",
  "0.341",
  "0.3375",
  "0.33399999999999996",
  "0.3305",
  "0.327",
  "0.3235",
  "0.32",
  "0.316083613",
  "0.311860465",
  "0.307306527",
  "0.30239589",
  "0.297100613",
  "0.291390569",
  "0.285233271",
  "0.278593685",
  "0.271434036",
  "0.263713588",
  "0.255388414",
  "0.246411148",
  "0.23673071399999998",
  "0.226292033",
  "0.215035714",
  "0.202897713",
  "0.18980897",
  "0.175695015",
  "0.160475546",
  "0.14406397099999999",
  "0.126366914",
  "0.10728368699999999",
  "0.086705714",
  "0.064515915",
  "0.040588039",
  "0.014785948",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0",
  "0.0"
]

const hybridArrFinland = [
"0.016663432",
"0.024174951",
"0.037730126",
"0.050549761",
"0.062640408",
"0.062932336",
"0.084653633",
"0.094582381",
"0.103794724",
"0.112291007",
"0.117461388",
"0.127132471",
"0.133474376",
"0.1390939",
"0.143988111",
"0.155257572",
"0.151587428",
"0.154285595",
"0.156244807",
"0.157461776",
"0.162113543",
"0.157657425",
"0.156631488",
"0.154854324",
"0.152325344",
"0.14465695",
"0.185085536",
"0.190658721",
"0.196231906",
"0.201805091",
"0.207378277",
"0.212951462",
"0.218524647",
"0.224097832",
"0.229671017",
"0.235244202",
"0.240817387",
"0.246003991",
"0.251535836",
"0.256627796",
"0.260176737",
"0.263671821",
"0.267066485",
"0.270406256",
"0.273695251",
"0.276934619",
"0.280125474",
"0.283268898",
"0.286365942",
"0.289417624",
"0.288830949",
"0.288138253",
"0.287472104",
"0.286831005",
"0.286213567",
"0.285618506",
"0.285044627",
"0.284490818",
"0.283956044",
"0.283439341",
"0.282939808"
]

const hybridArr = [
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0"
]

const carBatteryArrFinland = [
"0.00353727",
"0.005193063",
"0.012398414",
"0.020434456",
"0.029303641",
"0.033457413",
"0.049552196",
"0.060937029",
"0.073165665",
"0.086240319",
"0.094206227",
"0.114934175",
"0.130555026",
"0.147024916",
"0.16434245",
"0.186911433",
"0.201509038",
"0.221349058",
"0.24201838",
"0.263508549",
"0.293236169",
"0.308908443",
"0.332791728",
"0.357442759",
"0.382842882",
"0.403923432",
"0.390918424",
"0.407341123",
"0.423763822",
"0.44018652",
"0.456609219",
"0.473031918",
"0.489454617",
"0.505877316",
"0.522300014",
"0.538722713",
"0.555145412",
"0.570671332",
"0.586992197",
"0.602279831",
"0.61391456",
"0.625372727",
"0.636544438",
"0.647538152",
"0.658364723",
"0.669027932",
"0.679531451",
"0.68987884",
"0.700073553",
"0.710118946",
"0.711169051",
"0.711861747",
"0.712527896",
"0.713168995",
"0.713786433",
"0.714381494",
"0.714955373",
"0.715509182",
"0.716043956",
"0.716560659",
"0.717060192"
]

const carBatteryArr = [
  "0.044615385",
  "0.058153845999999995",
  "0.071692308",
  "0.085230769",
  "0.098769231",
  "0.11230769199999999",
  "0.12584615400000002",
  "0.139384615",
  "0.152923077",
  "0.16646153800000002",
  "0.18",
  "0.2",
  "0.22",
  "0.24",
  "0.26",
  "0.28",
  "0.3",
  "0.32",
  "0.34",
  "0.36",
  "0.38",
  "0.401731546",
  "0.42470588200000003",
  "0.448994081",
  "0.47467128",
  "0.501816914",
  "0.53051496",
  "0.5608541979999999",
  "0.592928485",
  "0.626837045",
  "0.6626847770000001",
  "0.70058258",
  "0.740647692",
  "0.78300406",
  "0.827782715",
  "0.875122185",
  "0.925168917",
  "0.9780777359999999",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0",
  "1.0"
]

const truckBatteryArrFinland = [
  "0.000288534",
  "0.000876258",
  "0.001726831",
  "0.002868153",
  "0.00429004",
  "0.00610399",
  "0.007933647",
  "0.010134097",
  "0.012572704",
  "0.01523879",
  "0.017955764",
  "0.021211189",
  "0.024496802",
  "0.027968591",
  "0.031616812",
  "0.035498404",
  "0.039405175",
  "0.043527514",
  "0.047790734",
  "0.052186934",
  "0.056851136",
  "0.061348878",
  "0.066101074",
  "0.070959181",
  "0.075917636",
  "0.080849666",
  "0.077095791",
  "0.080367732",
  "0.083639674",
  "0.086911615",
  "0.090183556",
  "0.093455498",
  "0.096727439",
  "0.09999938",
  "0.103271322",
  "0.106543263",
  "0.109815205",
  "0.113087146",
  "0.116359087",
  "0.119631029",
  "0.12290297",
  "0.126174911",
  "0.129446853",
  "0.132718794",
  "0.135990736",
  "0.139262677",
  "0.142534618",
  "0.14580656",
  "0.149078501",
  "0.152350442",
  "0.155622384",
  "0.158894325",
  "0.162166267",
  "0.165438208",
  "0.168710149",
  "0.171982091",
  "0.175254032",
  "0.178525973",
  "0.181797915",
  "0.185069856",
  "0.188341798",
]

const truckBatteryArr = [
  "0.006923077",
  "0.009230769",
  "0.011538462",
  "0.013846154",
  "0.016153846",
  "0.018461538",
  "0.020769231000000003",
  "0.023076923",
  "0.025384615",
  "0.027692308",
  "0.03",
  "0.037000000000000005",
  "0.044000000000000004",
  "0.051",
  "0.057999999999999996",
  "0.065",
  "0.07200000000000001",
  "0.079",
  "0.086",
  "0.09300000000000001",
  "0.1",
  "0.107832773",
  "0.11627907",
  "0.125386946",
  "0.135208221",
  "0.145798774",
  "0.157218861",
  "0.169533458",
  "0.18281262899999998",
  "0.197131928",
  "0.212572825",
  "0.229223172",
  "0.247177703",
  "0.266538572",
  "0.287415934",
  "0.309928572",
  "0.334204574",
  "0.36038206100000003",
  "0.38860997",
  "0.419048908",
  "0.45187205799999997",
  "0.48726617200000005",
  "0.5254326260000001",
  "0.566588572",
  "0.61096817",
  "0.658823921",
  "0.710428105",
  "0.7466070509999999",
  "0.760606775",
  "0.7740670909999999",
  "0.786982535",
  "0.79935105",
  "0.811173719",
  "0.8224544979999999",
  "0.833199925",
  "0.8434188290000001",
  "0.85312204",
  "0.8623221059999999",
  "0.871033017",
  "0.8792699509999999",
  "0.887049022"
]

const truckHydrogenArrFinland = [
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
"0",
]

const truckHydrogenArr = [
  "0.21384615399999998",
  "0.218461538",
  "0.223076923",
  "0.227692308",
  "0.23230769199999998",
  "0.23692307699999998",
  "0.24153846199999998",
  "0.246153846",
  "0.25076923100000004",
  "0.255384615",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.26",
  "0.253392949",
  "0.239393225",
  "0.225932909",
  "0.213017465",
  "0.20064895",
  "0.188826281",
  "0.177545502",
  "0.166800075",
  "0.156581171",
  "0.14687796",
  "0.137677894",
  "0.128966983",
  "0.120730049",
  "0.11295097800000001"
]

export const fuelDistributionCarsNorway = {
  "year": yearArr,

  // Old Settings
  // "prc_gasoline": carGasolineDieselArr,
  // "prc_diesel": carGasolineDieselArr,
  // "prc_inchargeable_hybrid": hybridArr,
  // "prc_chargeable_hybrid": hybridArr,
  // "prc_battery": carBatteryArr,
  // "prc_hydrogen": hybridArr

  "prc_gasoline": norwayCarGasoline,
  "prc_diesel": norwayCarDiesel,
  "prc_inchargeable_hybrid": norwayCarInChargeHybrid,
  "prc_chargeable_hybrid": norwayCarPreChargeHybrid,
  "prc_battery": norwayCarBattery,
  "prc_hydrogen": norwayCarHydro
}

export const fuelDistributionTrucksNorway = {
  "year": yearArr,

  // Old Settings
  // "prc_gasoline": truckGasolineDieselArr,
  // "prc_diesel": truckGasolineDieselArr,
  // "prc_inchargeable_hybrid": hybridArr,
  // "prc_chargeable_hybrid": hybridArr,
  // "prc_battery": truckBatteryArr,
  // "prc_hydrogen": truckHydrogenArr

  "prc_gasoline": norwayTruckGas,
  "prc_diesel": norwayTruckDiesel,
  "prc_inchargeable_hybrid": norwayTruckNonCharge,
  "prc_chargeable_hybrid": norwayTruckChargeHydro,
  "prc_battery": norwayTruckChargeHydro, // both are same
  "prc_hydrogen": norwayTruckHydro
}

export const fuelDistributionCarsSweden = {
  "year": yearArr,
  "prc_gasoline": carGasolineDieselArr,
  "prc_diesel": carGasolineDieselArr,
  "prc_inchargeable_hybrid": hybridArr,
  "prc_chargeable_hybrid": hybridArr,
  "prc_battery": carBatteryArr,
  "prc_hydrogen": hybridArr
}

export const fuelDistributionTrucksSweden = {
  "year": yearArr,
  "prc_gasoline": truckGasolineDieselArr,
  "prc_diesel": truckGasolineDieselArr,
  "prc_inchargeable_hybrid": hybridArr,
  "prc_chargeable_hybrid": hybridArr,
  "prc_battery": truckBatteryArr,
  "prc_hydrogen": truckHydrogenArr,
  "prc_biofuel": []
}

export const fuelDistributionCarsFinland = {
  "year": yearArr,
  "prc_gasoline": carGasolineDieselArr_Finland,
  "prc_diesel": carGasolineDieselArr_Finland,
  "prc_inchargeable_hybrid": hybridArrFinland,
  "prc_chargeable_hybrid": hybridArrFinland,
  "prc_battery": carBatteryArrFinland,
  "prc_hydrogen": hybridArrFinland
}

export const fuelDistributionTrucksFinland = {
  "year": yearArr,
  "prc_gasoline": truckGasolineDieselArrFinland,
  "prc_diesel": truckGasolineDieselArrFinland,
  "prc_inchargeable_hybrid": hybridArrFinland,
  "prc_chargeable_hybrid": hybridArrFinland,
  "prc_battery": truckBatteryArrFinland,
  "prc_hydrogen": truckHydrogenArrFinland,
  "prc_biofuel": []
}






export const requestBody4 = {
  "return_option": 4,
  "sectional_start": 3600,
  "sectional_end": 6000,
  "fuel_distribution_cars": {
    "year": [
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
      "2051",
      "2052",
      "2053",
      "2054",
      "2055",
      "2056",
      "2057",
      "2058",
      "2059",
      "2060",
      "2061",
      "2062",
      "2063",
      "2064",
      "2065",
      "2066",
      "2067",
      "2068",
      "2069",
      "2070",
      "2071",
      "2072",
      "2073",
      "2074",
      "2075",
      "2076",
      "2077",
      "2078",
      "2079",
      "2080"
    ],
    "prc_gasoline": [
      "0.47769230799999995",
      "0.470923077",
      "0.46415384600000004",
      "0.457384615",
      "0.450615385",
      "0.443846154",
      "0.43707692299999995",
      "0.430307692",
      "0.423538462",
      "0.416769231",
      "0.41",
      "0.4",
      "0.39",
      "0.38",
      "0.37",
      "0.36",
      "0.35",
      "0.34",
      "0.33",
      "0.32",
      "0.31",
      "0.299134227",
      "0.287647059",
      "0.275502959",
      "0.26266436",
      "0.249091543",
      "0.23474252",
      "0.21957290100000001",
      "0.20353575699999998",
      "0.186581477",
      "0.168657611",
      "0.14970871",
      "0.12967615400000002",
      "0.10849797",
      "0.086108642",
      "0.062438908",
      "0.037415542",
      "0.010961132",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0"
    ],
    "prc_diesel": [
      "0.47769230799999995",
      "0.470923077",
      "0.46415384600000004",
      "0.457384615",
      "0.450615385",
      "0.443846154",
      "0.43707692299999995",
      "0.430307692",
      "0.423538462",
      "0.416769231",
      "0.41",
      "0.4",
      "0.39",
      "0.38",
      "0.37",
      "0.36",
      "0.35",
      "0.34",
      "0.33",
      "0.32",
      "0.31",
      "0.299134227",
      "0.287647059",
      "0.275502959",
      "0.26266436",
      "0.249091543",
      "0.23474252",
      "0.21957290100000001",
      "0.20353575699999998",
      "0.186581477",
      "0.168657611",
      "0.14970871",
      "0.12967615400000002",
      "0.10849797",
      "0.086108642",
      "0.062438908",
      "0.037415542",
      "0.010961132",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0"
    ],
    "prc_inchargeable_hybrid": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    "prc_chargeable_hybrid": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    "prc_battery": [
      "0.044615385",
      "0.058153845999999995",
      "0.071692308",
      "0.085230769",
      "0.098769231",
      "0.11230769199999999",
      "0.12584615400000002",
      "0.139384615",
      "0.152923077",
      "0.16646153800000002",
      "0.18",
      "0.2",
      "0.22",
      "0.24",
      "0.26",
      "0.28",
      "0.3",
      "0.32",
      "0.34",
      "0.36",
      "0.38",
      "0.401731546",
      "0.42470588200000003",
      "0.448994081",
      "0.47467128",
      "0.501816914",
      "0.53051496",
      "0.5608541979999999",
      "0.592928485",
      "0.626837045",
      "0.6626847770000001",
      "0.70058258",
      "0.740647692",
      "0.78300406",
      "0.827782715",
      "0.875122185",
      "0.925168917",
      "0.9780777359999999",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0"
    ],
    "prc_hydrogen": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ]
  },
  "fuel_distribution_trucks": {
    "year": [
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
      "2051",
      "2052",
      "2053",
      "2054",
      "2055",
      "2056",
      "2057",
      "2058",
      "2059",
      "2060",
      "2061",
      "2062",
      "2063",
      "2064",
      "2065",
      "2066",
      "2067",
      "2068",
      "2069",
      "2070",
      "2071",
      "2072",
      "2073",
      "2074",
      "2075",
      "2076",
      "2077",
      "2078",
      "2079",
      "2080"
    ],
    "prc_gasoline": [
      "0.389615385",
      "0.386153846",
      "0.382692308",
      "0.37923076899999997",
      "0.37576923100000004",
      "0.372307692",
      "0.368846154",
      "0.365384615",
      "0.36192307700000004",
      "0.35846153799999997",
      "0.355",
      "0.3515",
      "0.348",
      "0.3445",
      "0.341",
      "0.3375",
      "0.33399999999999996",
      "0.3305",
      "0.327",
      "0.3235",
      "0.32",
      "0.316083613",
      "0.311860465",
      "0.307306527",
      "0.30239589",
      "0.297100613",
      "0.291390569",
      "0.285233271",
      "0.278593685",
      "0.271434036",
      "0.263713588",
      "0.255388414",
      "0.246411148",
      "0.23673071399999998",
      "0.226292033",
      "0.215035714",
      "0.202897713",
      "0.18980897",
      "0.175695015",
      "0.160475546",
      "0.14406397099999999",
      "0.126366914",
      "0.10728368699999999",
      "0.086705714",
      "0.064515915",
      "0.040588039",
      "0.014785948",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0"
    ],
    "prc_diesel": [
      "0.389615385",
      "0.386153846",
      "0.382692308",
      "0.37923076899999997",
      "0.37576923100000004",
      "0.372307692",
      "0.368846154",
      "0.365384615",
      "0.36192307700000004",
      "0.35846153799999997",
      "0.355",
      "0.3515",
      "0.348",
      "0.3445",
      "0.341",
      "0.3375",
      "0.33399999999999996",
      "0.3305",
      "0.327",
      "0.3235",
      "0.32",
      "0.316083613",
      "0.311860465",
      "0.307306527",
      "0.30239589",
      "0.297100613",
      "0.291390569",
      "0.285233271",
      "0.278593685",
      "0.271434036",
      "0.263713588",
      "0.255388414",
      "0.246411148",
      "0.23673071399999998",
      "0.226292033",
      "0.215035714",
      "0.202897713",
      "0.18980897",
      "0.175695015",
      "0.160475546",
      "0.14406397099999999",
      "0.126366914",
      "0.10728368699999999",
      "0.086705714",
      "0.064515915",
      "0.040588039",
      "0.014785948",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0"
    ],
    "prc_inchargeable_hybrid": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    "prc_chargeable_hybrid": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    "prc_battery": [
      "0.006923077",
      "0.009230769",
      "0.011538462",
      "0.013846154",
      "0.016153846",
      "0.018461538",
      "0.020769231000000003",
      "0.023076923",
      "0.025384615",
      "0.027692308",
      "0.03",
      "0.037000000000000005",
      "0.044000000000000004",
      "0.051",
      "0.057999999999999996",
      "0.065",
      "0.07200000000000001",
      "0.079",
      "0.086",
      "0.09300000000000001",
      "0.1",
      "0.107832773",
      "0.11627907",
      "0.125386946",
      "0.135208221",
      "0.145798774",
      "0.157218861",
      "0.169533458",
      "0.18281262899999998",
      "0.197131928",
      "0.212572825",
      "0.229223172",
      "0.247177703",
      "0.266538572",
      "0.287415934",
      "0.309928572",
      "0.334204574",
      "0.36038206100000003",
      "0.38860997",
      "0.419048908",
      "0.45187205799999997",
      "0.48726617200000005",
      "0.5254326260000001",
      "0.566588572",
      "0.61096817",
      "0.658823921",
      "0.710428105",
      "0.7466070509999999",
      "0.760606775",
      "0.7740670909999999",
      "0.786982535",
      "0.79935105",
      "0.811173719",
      "0.8224544979999999",
      "0.833199925",
      "0.8434188290000001",
      "0.85312204",
      "0.8623221059999999",
      "0.871033017",
      "0.8792699509999999",
      "0.887049022"
    ],
    "prc_hydrogen": [
      "0.21384615399999998",
      "0.218461538",
      "0.223076923",
      "0.227692308",
      "0.23230769199999998",
      "0.23692307699999998",
      "0.24153846199999998",
      "0.246153846",
      "0.25076923100000004",
      "0.255384615",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.253392949",
      "0.239393225",
      "0.225932909",
      "0.213017465",
      "0.20064895",
      "0.188826281",
      "0.177545502",
      "0.166800075",
      "0.156581171",
      "0.14687796",
      "0.137677894",
      "0.128966983",
      "0.120730049",
      "0.11295097800000001"
    ]
  },
  "presumptions": {
    "Speed_car": 80,
    "Speed_truck": 80,
    "cost_increase": 0.01,
    "gasoline_fuelfactor": 1,
    "gasoline_co2": 2.85,
    "gasoline_kwh": 9.1,
    "gasoline_efficiency": 0.25,
    "gasoline_cost": 16,
    "diesel_fuelfactor": 1,
    "diesel_co2": 2.73,
    "diesel_kwh": 9.8,
    "diesel_efficiency": 0.35,
    "diesel_cost": 16,
    "battery_fuelfactor": 0,
    "battery_co2": 0.0,
    "battery_kwh": 9.8,
    "battery_efficiency": 1,
    "battery_cost": 0,
    "inchargeable_hybrid_fuelfactor": 1,
    "inchargeable_hybrid_co2": 2.85,
    "inchargeable_hybrid_kwh": 9.8,
    "inchargeable_hybrid_efficiency": 0.35,
    "inchargeable_hybrid_cost": 16,
    "chargeable_hybrid_fuelfactor": 1,
    "chargeable_hybrid_co2": 2.85,
    "chargeable_hybrid_kwh": 9.8,
    "chargeable_hybrid_efficiency": 0.35,
    "chargeable_hybrid_cost": 16,
    "hydrogen_fuelfactor": 0,
    "hydrogen_co2": 0.0,
    "hydrogen_kwh": 9.8,
    "hydrogen_efficiency": 1,
    "hydrogen_cost": 0,
    "iri": 1.0,
    "mpd": 1.0
  },
  "start_year": 2020,
  "end_year": 2065,
  "segments": [
    {
      "segment_id": 1,
      "directions_id": 590,
      "year": 2020,
      "aadt": 20000,
      "prc_heavy": 10,
      "aadt_cars": 18000,
      "aadt_trucks": 1500,
      "aadt_trucks_with_trailer": 500,
      "annual_increase_cars": 1,
      "annual_increase_heavy": 1.5,
      "segment_order_no": 1
    }
  ],
  "segments_data": [
    {
      "segment_data_id": 590,
      "chainage": 140,
      "zlevel": 41.08,
      "radius": 117.027,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 591,
      "chainage": 160,
      "zlevel": 41.02,
      "radius": 200.421,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 592,
      "chainage": 180,
      "zlevel": 40.96,
      "radius": 697.38,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 593,
      "chainage": 280,
      "zlevel": 40.66,
      "radius": 3910.82,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 594,
      "chainage": 300,
      "zlevel": 40.6,
      "radius": 1658.586,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 595,
      "chainage": 320,
      "zlevel": 40.54,
      "radius": 1052.471,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 596,
      "chainage": 326,
      "zlevel": 40.523,
      "radius": 955.322,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 597,
      "chainage": 340,
      "zlevel": 40.465,
      "radius": 770.792,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 598,
      "chainage": 343,
      "zlevel": 40.449,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 599,
      "chainage": 360,
      "zlevel": 40.335,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 600,
      "chainage": 380,
      "zlevel": 40.148,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 601,
      "chainage": 400,
      "zlevel": 39.904,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 602,
      "chainage": 415,
      "zlevel": 39.687,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 603,
      "chainage": 420,
      "zlevel": 39.603,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 604,
      "chainage": 440,
      "zlevel": 39.245,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 605,
      "chainage": 460,
      "zlevel": 38.829,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 606,
      "chainage": 480,
      "zlevel": 38.356,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 607,
      "chainage": 500,
      "zlevel": 37.826,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 608,
      "chainage": 503,
      "zlevel": 37.743,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 609,
      "chainage": 504,
      "zlevel": 37.714,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 610,
      "chainage": 520,
      "zlevel": 37.257,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 611,
      "chainage": 540,
      "zlevel": 36.687,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 612,
      "chainage": 560,
      "zlevel": 36.117,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 613,
      "chainage": 580,
      "zlevel": 35.547,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 614,
      "chainage": 600,
      "zlevel": 34.977,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 615,
      "chainage": 620,
      "zlevel": 34.407,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 616,
      "chainage": 640,
      "zlevel": 33.837,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 617,
      "chainage": 660,
      "zlevel": 33.267,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 618,
      "chainage": 663,
      "zlevel": 33.187,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 619,
      "chainage": 680,
      "zlevel": 32.697,
      "radius": 949.609,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 620,
      "chainage": 700,
      "zlevel": 32.127,
      "radius": 1416.746,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 621,
      "chainage": 720,
      "zlevel": 31.557,
      "radius": 2788.462,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 622,
      "chainage": 740,
      "zlevel": 30.987,
      "radius": 87731.395,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 623,
      "chainage": 820,
      "zlevel": 28.707,
      "radius": 4690.028,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 624,
      "chainage": 840,
      "zlevel": 28.137,
      "radius": 1738.003,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 625,
      "chainage": 860,
      "zlevel": 27.567,
      "radius": 1066.635,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 626,
      "chainage": 880,
      "zlevel": 26.997,
      "radius": 769.419,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 627,
      "chainage": 900,
      "zlevel": 26.441,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 628,
      "chainage": 900,
      "zlevel": 26.427,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 629,
      "chainage": 920,
      "zlevel": 25.857,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 630,
      "chainage": 940,
      "zlevel": 25.287,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 631,
      "chainage": 960,
      "zlevel": 24.717,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 632,
      "chainage": 980,
      "zlevel": 24.147,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 633,
      "chainage": 1000,
      "zlevel": 23.577,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 634,
      "chainage": 1020,
      "zlevel": 23.007,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 635,
      "chainage": 1040,
      "zlevel": 22.437,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 636,
      "chainage": 1060,
      "zlevel": 21.867,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 637,
      "chainage": 1080,
      "zlevel": 21.297,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 638,
      "chainage": 1100,
      "zlevel": 20.727,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 639,
      "chainage": 1120,
      "zlevel": 20.157,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 640,
      "chainage": 1140,
      "zlevel": 19.587,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 641,
      "chainage": 1160,
      "zlevel": 19.017,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 642,
      "chainage": 1180,
      "zlevel": 18.447,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 643,
      "chainage": 1200,
      "zlevel": 17.877,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 644,
      "chainage": 1220,
      "zlevel": 17.307,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 645,
      "chainage": 1240,
      "zlevel": 16.737,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 646,
      "chainage": 1252,
      "zlevel": 16.401,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 647,
      "chainage": 1260,
      "zlevel": 16.167,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 648,
      "chainage": 1280,
      "zlevel": 15.597,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 649,
      "chainage": 1300,
      "zlevel": 15.027,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 650,
      "chainage": 1320,
      "zlevel": 14.457,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 651,
      "chainage": 1340,
      "zlevel": 13.887,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 652,
      "chainage": 1360,
      "zlevel": 13.317,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 653,
      "chainage": 1380,
      "zlevel": 12.747,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 654,
      "chainage": 1400,
      "zlevel": 12.177,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 655,
      "chainage": 1409,
      "zlevel": 11.913,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 656,
      "chainage": 1420,
      "zlevel": 11.624,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 657,
      "chainage": 1440,
      "zlevel": 11.172,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 658,
      "chainage": 1460,
      "zlevel": 10.835,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 659,
      "chainage": 1480,
      "zlevel": 10.612,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 660,
      "chainage": 1496,
      "zlevel": 10.517,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 661,
      "chainage": 1500,
      "zlevel": 10.504,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 662,
      "chainage": 1509,
      "zlevel": 10.492,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 663,
      "chainage": 1520,
      "zlevel": 10.51,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 664,
      "chainage": 1540,
      "zlevel": 10.63,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 665,
      "chainage": 1560,
      "zlevel": 10.864,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 666,
      "chainage": 1580,
      "zlevel": 11.213,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 667,
      "chainage": 1582,
      "zlevel": 11.264,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 668,
      "chainage": 1600,
      "zlevel": 11.632,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 669,
      "chainage": 1604,
      "zlevel": 11.718,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 670,
      "chainage": 1620,
      "zlevel": 12.052,
      "radius": 784.742,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 671,
      "chainage": 1640,
      "zlevel": 12.472,
      "radius": 1252.51,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 672,
      "chainage": 1660,
      "zlevel": 12.892,
      "radius": 3100.875,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 673,
      "chainage": 1680,
      "zlevel": 13.312,
      "radius": 5308.363,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 674,
      "chainage": 1700,
      "zlevel": 13.732,
      "radius": 1294.079,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 675,
      "chainage": 1720,
      "zlevel": 14.152,
      "radius": 736.855,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 676,
      "chainage": 1740,
      "zlevel": 14.572,
      "radius": 515.069,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 677,
      "chainage": 1742,
      "zlevel": 14.614,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 678,
      "chainage": 1760,
      "zlevel": 14.992,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 679,
      "chainage": 1780,
      "zlevel": 15.412,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 680,
      "chainage": 1800,
      "zlevel": 15.832,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 681,
      "chainage": 1820,
      "zlevel": 16.252,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 682,
      "chainage": 1833,
      "zlevel": 16.528,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 683,
      "chainage": 1840,
      "zlevel": 16.672,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 684,
      "chainage": 1860,
      "zlevel": 17.092,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 685,
      "chainage": 1880,
      "zlevel": 17.512,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 686,
      "chainage": 1900,
      "zlevel": 17.932,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 687,
      "chainage": 1920,
      "zlevel": 18.352,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 688,
      "chainage": 1924,
      "zlevel": 18.442,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 689,
      "chainage": 1940,
      "zlevel": 18.772,
      "radius": 648.83,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 690,
      "chainage": 1960,
      "zlevel": 19.192,
      "radius": 1045.076,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 691,
      "chainage": 1980,
      "zlevel": 19.612,
      "radius": 2684.566,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 692,
      "chainage": 2000,
      "zlevel": 20.032,
      "radius": 7943.515,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 693,
      "chainage": 2017,
      "zlevel": 20.399,
      "radius": 2328.671,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 694,
      "chainage": 2020,
      "zlevel": 20.452,
      "radius": 2113.669,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 695,
      "chainage": 2034,
      "zlevel": 20.722,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 696,
      "chainage": 2040,
      "zlevel": 20.821,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 697,
      "chainage": 2060,
      "zlevel": 21.111,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 698,
      "chainage": 2080,
      "zlevel": 21.321,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 699,
      "chainage": 2100,
      "zlevel": 21.451,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 700,
      "chainage": 2120,
      "zlevel": 21.501,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 701,
      "chainage": 2122,
      "zlevel": 21.501,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 702,
      "chainage": 2140,
      "zlevel": 21.471,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 703,
      "chainage": 2140,
      "zlevel": 21.471,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 704,
      "chainage": 2160,
      "zlevel": 21.361,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 705,
      "chainage": 2180,
      "zlevel": 21.17,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 706,
      "chainage": 2200,
      "zlevel": 20.9,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 707,
      "chainage": 2220,
      "zlevel": 20.552,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 708,
      "chainage": 2220,
      "zlevel": 20.55,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 709,
      "chainage": 2240,
      "zlevel": 20.12,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 710,
      "chainage": 2260,
      "zlevel": 19.609,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 711,
      "chainage": 2262,
      "zlevel": 19.543,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 712,
      "chainage": 2280,
      "zlevel": 19.05,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 713,
      "chainage": 2300,
      "zlevel": 18.49,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 714,
      "chainage": 2320,
      "zlevel": 17.93,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 715,
      "chainage": 2340,
      "zlevel": 17.37,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 716,
      "chainage": 2360,
      "zlevel": 16.81,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 717,
      "chainage": 2380,
      "zlevel": 16.25,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 718,
      "chainage": 2400,
      "zlevel": 15.69,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 719,
      "chainage": 2406,
      "zlevel": 15.532,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 720,
      "chainage": 2420,
      "zlevel": 15.13,
      "radius": 2127.496,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 721,
      "chainage": 2440,
      "zlevel": 14.57,
      "radius": 8142.395,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 722,
      "chainage": 2460,
      "zlevel": 14.01,
      "radius": 3094.559,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 723,
      "chainage": 2480,
      "zlevel": 13.45,
      "radius": 1214.849,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 724,
      "chainage": 2500,
      "zlevel": 12.89,
      "radius": 755.773,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 725,
      "chainage": 2507,
      "zlevel": 12.692,
      "radius": 666.788,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 726,
      "chainage": 2520,
      "zlevel": 12.34,
      "radius": 548.502,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 727,
      "chainage": 2529,
      "zlevel": 12.116,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 728,
      "chainage": 2540,
      "zlevel": 11.838,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 729,
      "chainage": 2560,
      "zlevel": 11.385,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 730,
      "chainage": 2580,
      "zlevel": 10.983,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 731,
      "chainage": 2600,
      "zlevel": 10.63,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 732,
      "chainage": 2604,
      "zlevel": 10.56,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 733,
      "chainage": 2620,
      "zlevel": 10.328,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 734,
      "chainage": 2640,
      "zlevel": 10.075,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 735,
      "chainage": 2659,
      "zlevel": 9.882,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 736,
      "chainage": 2660,
      "zlevel": 9.873,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 737,
      "chainage": 2680,
      "zlevel": 9.72,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 738,
      "chainage": 2680,
      "zlevel": 9.72,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 739,
      "chainage": 2700,
      "zlevel": 9.618,
      "radius": 600.941,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 740,
      "chainage": 2720,
      "zlevel": 9.566,
      "radius": 777.614,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 741,
      "chainage": 2731,
      "zlevel": 9.558,
      "radius": 927.205,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 742,
      "chainage": 2740,
      "zlevel": 9.563,
      "radius": 1101.427,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 743,
      "chainage": 2760,
      "zlevel": 9.611,
      "radius": 1887.357,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 744,
      "chainage": 2780,
      "zlevel": 9.708,
      "radius": 6588.939,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 745,
      "chainage": 2940,
      "zlevel": 11.248,
      "radius": 3627.347,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 746,
      "chainage": 2960,
      "zlevel": 11.448,
      "radius": 1605.379,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 747,
      "chainage": 2980,
      "zlevel": 11.648,
      "radius": 1030.792,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 748,
      "chainage": 2990,
      "zlevel": 11.751,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 749,
      "chainage": 3000,
      "zlevel": 11.848,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 750,
      "chainage": 3020,
      "zlevel": 12.048,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 751,
      "chainage": 3040,
      "zlevel": 12.248,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 752,
      "chainage": 3060,
      "zlevel": 12.448,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 753,
      "chainage": 3080,
      "zlevel": 12.648,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 754,
      "chainage": 3100,
      "zlevel": 12.848,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 755,
      "chainage": 3120,
      "zlevel": 13.047,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 756,
      "chainage": 3140,
      "zlevel": 13.247,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 757,
      "chainage": 3160,
      "zlevel": 13.447,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 758,
      "chainage": 3178,
      "zlevel": 13.632,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 759,
      "chainage": 3180,
      "zlevel": 13.647,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 760,
      "chainage": 3200,
      "zlevel": 13.847,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 761,
      "chainage": 3220,
      "zlevel": 14.047,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 762,
      "chainage": 3240,
      "zlevel": 14.247,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 763,
      "chainage": 3260,
      "zlevel": 14.447,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 764,
      "chainage": 3280,
      "zlevel": 14.647,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 765,
      "chainage": 3300,
      "zlevel": 14.847,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 766,
      "chainage": 3320,
      "zlevel": 15.047,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 767,
      "chainage": 3340,
      "zlevel": 15.247,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 768,
      "chainage": 3360,
      "zlevel": 15.447,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 769,
      "chainage": 3367,
      "zlevel": 15.513,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 770,
      "chainage": 3380,
      "zlevel": 15.647,
      "radius": 998.912,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 771,
      "chainage": 3400,
      "zlevel": 15.847,
      "radius": 1283.916,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 772,
      "chainage": 3420,
      "zlevel": 16.047,
      "radius": 1796.478,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 773,
      "chainage": 3440,
      "zlevel": 16.247,
      "radius": 2990.229,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 774,
      "chainage": 3460,
      "zlevel": 16.447,
      "radius": 8912.629,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 775,
      "chainage": 3840,
      "zlevel": 15.227,
      "radius": 7748.77,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 776,
      "chainage": 3860,
      "zlevel": 14.907,
      "radius": 1907.98,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 777,
      "chainage": 3880,
      "zlevel": 14.587,
      "radius": 1087.931,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 778,
      "chainage": 3897,
      "zlevel": 14.319,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 779,
      "chainage": 3900,
      "zlevel": 14.267,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 780,
      "chainage": 3920,
      "zlevel": 13.947,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 781,
      "chainage": 3940,
      "zlevel": 13.627,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 782,
      "chainage": 3960,
      "zlevel": 13.307,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 783,
      "chainage": 3980,
      "zlevel": 12.987,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 784,
      "chainage": 3983,
      "zlevel": 12.935,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 785,
      "chainage": 4000,
      "zlevel": 12.667,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 786,
      "chainage": 4020,
      "zlevel": 12.347,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 787,
      "chainage": 4040,
      "zlevel": 12.027,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 788,
      "chainage": 4060,
      "zlevel": 11.707,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 789,
      "chainage": 4070,
      "zlevel": 11.552,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 790,
      "chainage": 4080,
      "zlevel": 11.387,
      "radius": 956.509,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 791,
      "chainage": 4100,
      "zlevel": 11.067,
      "radius": 1537.5,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 792,
      "chainage": 4120,
      "zlevel": 10.747,
      "radius": 3916.276,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 793,
      "chainage": 4140,
      "zlevel": 10.427,
      "radius": 8143.435,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 794,
      "chainage": 4160,
      "zlevel": 10.107,
      "radius": 2127.566,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 795,
      "chainage": 4180,
      "zlevel": 9.787,
      "radius": 1223.626,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 796,
      "chainage": 4200,
      "zlevel": 9.467,
      "radius": 858.763,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 797,
      "chainage": 4220,
      "zlevel": 9.147,
      "radius": 661.512,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 798,
      "chainage": 4222,
      "zlevel": 9.111,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 799,
      "chainage": 4240,
      "zlevel": 8.827,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 800,
      "chainage": 4260,
      "zlevel": 8.507,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 801,
      "chainage": 4280,
      "zlevel": 8.187,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 802,
      "chainage": 4300,
      "zlevel": 7.867,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 803,
      "chainage": 4306,
      "zlevel": 7.768,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 804,
      "chainage": 4320,
      "zlevel": 7.563,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 805,
      "chainage": 4340,
      "zlevel": 7.322,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 806,
      "chainage": 4360,
      "zlevel": 7.148,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 807,
      "chainage": 4380,
      "zlevel": 7.041,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 808,
      "chainage": 4400,
      "zlevel": 7.001,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 809,
      "chainage": 4402,
      "zlevel": 7,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 810,
      "chainage": 4420,
      "zlevel": 7.027,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 811,
      "chainage": 4423,
      "zlevel": 7.037,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 812,
      "chainage": 4440,
      "zlevel": 7.12,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 813,
      "chainage": 4460,
      "zlevel": 7.28,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 814,
      "chainage": 4480,
      "zlevel": 7.506,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 815,
      "chainage": 4500,
      "zlevel": 7.799,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 816,
      "chainage": 4520,
      "zlevel": 8.158,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 817,
      "chainage": 4540,
      "zlevel": 8.585,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 818,
      "chainage": 4540,
      "zlevel": 8.587,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 819,
      "chainage": 4560,
      "zlevel": 9.045,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 820,
      "chainage": 4566,
      "zlevel": 9.191,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 821,
      "chainage": 4580,
      "zlevel": 9.505,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 822,
      "chainage": 4600,
      "zlevel": 9.965,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 823,
      "chainage": 4620,
      "zlevel": 10.425,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 824,
      "chainage": 4640,
      "zlevel": 10.885,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 825,
      "chainage": 4660,
      "zlevel": 11.345,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 826,
      "chainage": 4680,
      "zlevel": 11.805,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 827,
      "chainage": 4700,
      "zlevel": 12.265,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 828,
      "chainage": 4720,
      "zlevel": 12.725,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 829,
      "chainage": 4740,
      "zlevel": 13.185,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 830,
      "chainage": 4760,
      "zlevel": 13.645,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 831,
      "chainage": 4780,
      "zlevel": 14.105,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 832,
      "chainage": 4800,
      "zlevel": 14.565,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 833,
      "chainage": 4820,
      "zlevel": 15.025,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 834,
      "chainage": 4840,
      "zlevel": 15.485,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 835,
      "chainage": 4860,
      "zlevel": 15.945,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 836,
      "chainage": 4880,
      "zlevel": 16.405,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 837,
      "chainage": 4900,
      "zlevel": 16.865,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 838,
      "chainage": 4910,
      "zlevel": 17.105,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 839,
      "chainage": 4920,
      "zlevel": 17.325,
      "radius": 722.061,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 840,
      "chainage": 4940,
      "zlevel": 17.785,
      "radius": 963.667,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 841,
      "chainage": 4960,
      "zlevel": 18.245,
      "radius": 1448.266,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 842,
      "chainage": 4980,
      "zlevel": 18.705,
      "radius": 2913.256,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 843,
      "chainage": 5440,
      "zlevel": 36.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 844,
      "chainage": 5460,
      "zlevel": 37.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 845,
      "chainage": 5480,
      "zlevel": 38.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 846,
      "chainage": 5491,
      "zlevel": 39.019,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 847,
      "chainage": 5500,
      "zlevel": 39.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 848,
      "chainage": 5520,
      "zlevel": 40.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 849,
      "chainage": 5540,
      "zlevel": 41.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 850,
      "chainage": 5548,
      "zlevel": 41.898,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 851,
      "chainage": 5560,
      "zlevel": 42.48,
      "radius": 827.634,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 852,
      "chainage": 5580,
      "zlevel": 43.48,
      "radius": 1204.542,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 853,
      "chainage": 5600,
      "zlevel": 44.48,
      "radius": 2211.805,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 854,
      "chainage": 5620,
      "zlevel": 45.48,
      "radius": 13504.82,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 855,
      "chainage": 6600,
      "zlevel": 43.739,
      "radius": 2921.033,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 856,
      "chainage": 6620,
      "zlevel": 42.739,
      "radius": 1450.186,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 857,
      "chainage": 6640,
      "zlevel": 41.739,
      "radius": 964.516,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 858,
      "chainage": 6648,
      "zlevel": 41.337,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 859,
      "chainage": 6660,
      "zlevel": 40.74,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 860,
      "chainage": 6680,
      "zlevel": 39.74,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 861,
      "chainage": 6700,
      "zlevel": 38.74,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 862,
      "chainage": 6720,
      "zlevel": 37.74,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 863,
      "chainage": 6728,
      "zlevel": 37.36,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 864,
      "chainage": 6740,
      "zlevel": 36.752,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 865,
      "chainage": 6760,
      "zlevel": 35.816,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 866,
      "chainage": 6776,
      "zlevel": 35.126,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 867,
      "chainage": 6780,
      "zlevel": 34.938,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 868,
      "chainage": 6800,
      "zlevel": 34.117,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 869,
      "chainage": 6820,
      "zlevel": 33.353,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 870,
      "chainage": 6840,
      "zlevel": 32.647,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 871,
      "chainage": 6850,
      "zlevel": 32.323,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 872,
      "chainage": 6860,
      "zlevel": 31.998,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 873,
      "chainage": 6880,
      "zlevel": 31.406,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 874,
      "chainage": 6900,
      "zlevel": 30.871,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 875,
      "chainage": 6903,
      "zlevel": 30.793,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 876,
      "chainage": 6920,
      "zlevel": 30.394,
      "radius": 1165.745,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 877,
      "chainage": 6940,
      "zlevel": 29.974,
      "radius": 2084.425,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 878,
      "chainage": 6960,
      "zlevel": 29.61,
      "radius": 9835.083,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 879,
      "chainage": 6972,
      "zlevel": 29.419,
      "radius": 8588.7,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 880,
      "chainage": 6980,
      "zlevel": 29.3,
      "radius": 3939.459,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 881,
      "chainage": 7000,
      "zlevel": 29,
      "radius": 1663.716,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 882,
      "chainage": 7020,
      "zlevel": 28.7,
      "radius": 1054.534,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 883,
      "chainage": 7026,
      "zlevel": 28.61,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 884,
      "chainage": 7040,
      "zlevel": 28.4,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 885,
      "chainage": 7060,
      "zlevel": 28.1,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 886,
      "chainage": 7080,
      "zlevel": 27.8,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 887,
      "chainage": 7100,
      "zlevel": 27.5,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 888,
      "chainage": 7120,
      "zlevel": 27.2,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 889,
      "chainage": 7140,
      "zlevel": 26.9,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 890,
      "chainage": 7160,
      "zlevel": 26.6,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 891,
      "chainage": 7180,
      "zlevel": 26.3,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 892,
      "chainage": 7200,
      "zlevel": 26,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 893,
      "chainage": 7220,
      "zlevel": 25.7,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 894,
      "chainage": 7240,
      "zlevel": 25.4,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 895,
      "chainage": 7260,
      "zlevel": 25.1,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 896,
      "chainage": 7280,
      "zlevel": 24.8,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 897,
      "chainage": 7300,
      "zlevel": 24.5,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 898,
      "chainage": 7320,
      "zlevel": 24.2,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 899,
      "chainage": 7332,
      "zlevel": 24.017,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 900,
      "chainage": 7340,
      "zlevel": 23.9,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 901,
      "chainage": 7360,
      "zlevel": 23.6,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 902,
      "chainage": 7380,
      "zlevel": 23.3,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 903,
      "chainage": 7400,
      "zlevel": 23,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 904,
      "chainage": 7420,
      "zlevel": 22.7,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 905,
      "chainage": 7440,
      "zlevel": 22.4,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 906,
      "chainage": 7447,
      "zlevel": 22.301,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 907,
      "chainage": 7460,
      "zlevel": 22.111,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 908,
      "chainage": 7480,
      "zlevel": 21.87,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 909,
      "chainage": 7500,
      "zlevel": 21.678,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 910,
      "chainage": 7520,
      "zlevel": 21.537,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 911,
      "chainage": 7540,
      "zlevel": 21.445,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 912,
      "chainage": 7555,
      "zlevel": 21.41,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 913,
      "chainage": 7560,
      "zlevel": 21.404,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 914,
      "chainage": 7567,
      "zlevel": 21.401,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 915,
      "chainage": 7580,
      "zlevel": 21.413,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 916,
      "chainage": 7600,
      "zlevel": 21.471,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 917,
      "chainage": 7620,
      "zlevel": 21.58,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 918,
      "chainage": 7638,
      "zlevel": 21.723,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 919,
      "chainage": 7640,
      "zlevel": 21.738,
      "radius": 974.843,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 920,
      "chainage": 7660,
      "zlevel": 21.947,
      "radius": 1416.819,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 921,
      "chainage": 7663,
      "zlevel": 21.977,
      "radius": 1504.533,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 922,
      "chainage": 7680,
      "zlevel": 22.186,
      "radius": 2591.973,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 923,
      "chainage": 7700,
      "zlevel": 22.426,
      "radius": 15196.068,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 924,
      "chainage": 8031,
      "zlevel": 27.205,
      "radius": 7415.366,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 925,
      "chainage": 8040,
      "zlevel": 27.505,
      "radius": 3358.169,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 926,
      "chainage": 8060,
      "zlevel": 28.212,
      "radius": 1550.379,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 927,
      "chainage": 8080,
      "zlevel": 29.007,
      "radius": 1007.835,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 928,
      "chainage": 8091,
      "zlevel": 29.465,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 929,
      "chainage": 8100,
      "zlevel": 29.891,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 930,
      "chainage": 8116,
      "zlevel": 30.659,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 931,
      "chainage": 8120,
      "zlevel": 30.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 932,
      "chainage": 8140,
      "zlevel": 31.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 933,
      "chainage": 8160,
      "zlevel": 32.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 934,
      "chainage": 8180,
      "zlevel": 33.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 935,
      "chainage": 8200,
      "zlevel": 34.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 936,
      "chainage": 8220,
      "zlevel": 35.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 937,
      "chainage": 8240,
      "zlevel": 36.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 938,
      "chainage": 8260,
      "zlevel": 37.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 939,
      "chainage": 8280,
      "zlevel": 38.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 940,
      "chainage": 8300,
      "zlevel": 39.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 941,
      "chainage": 8320,
      "zlevel": 40.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 942,
      "chainage": 8321,
      "zlevel": 40.935,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 943,
      "chainage": 8340,
      "zlevel": 41.836,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 944,
      "chainage": 8360,
      "zlevel": 42.748,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 945,
      "chainage": 8380,
      "zlevel": 43.598,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 946,
      "chainage": 8400,
      "zlevel": 44.387,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 947,
      "chainage": 8420,
      "zlevel": 45.114,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 948,
      "chainage": 8440,
      "zlevel": 45.779,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 949,
      "chainage": 8460,
      "zlevel": 46.382,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 950,
      "chainage": 8480,
      "zlevel": 46.924,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 951,
      "chainage": 8494,
      "zlevel": 47.261,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 952,
      "chainage": 8500,
      "zlevel": 47.404,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 953,
      "chainage": 8520,
      "zlevel": 47.823,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 954,
      "chainage": 8531,
      "zlevel": 48.028,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 955,
      "chainage": 8540,
      "zlevel": 48.18,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 956,
      "chainage": 8560,
      "zlevel": 48.476,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 957,
      "chainage": 8580,
      "zlevel": 48.71,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 958,
      "chainage": 8600,
      "zlevel": 48.882,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 959,
      "chainage": 8620,
      "zlevel": 48.993,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 960,
      "chainage": 8640,
      "zlevel": 49.042,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 961,
      "chainage": 8646,
      "zlevel": 49.045,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 962,
      "chainage": 8660,
      "zlevel": 49.03,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 963,
      "chainage": 8680,
      "zlevel": 48.956,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 964,
      "chainage": 8700,
      "zlevel": 48.821,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 965,
      "chainage": 8720,
      "zlevel": 48.624,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 966,
      "chainage": 8740,
      "zlevel": 48.366,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 967,
      "chainage": 8741,
      "zlevel": 48.352,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 968,
      "chainage": 8760,
      "zlevel": 48.074,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 969,
      "chainage": 8780,
      "zlevel": 47.782,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 970,
      "chainage": 8800,
      "zlevel": 47.49,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 971,
      "chainage": 8820,
      "zlevel": 47.198,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 972,
      "chainage": 8840,
      "zlevel": 46.906,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 973,
      "chainage": 8860,
      "zlevel": 46.614,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 974,
      "chainage": 8880,
      "zlevel": 46.322,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 975,
      "chainage": 8897,
      "zlevel": 46.076,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 976,
      "chainage": 8900,
      "zlevel": 46.03,
      "radius": 877.742,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 977,
      "chainage": 8920,
      "zlevel": 45.738,
      "radius": 1109.293,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 978,
      "chainage": 8940,
      "zlevel": 45.446,
      "radius": 1506.788,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 979,
      "chainage": 8960,
      "zlevel": 45.154,
      "radius": 2348.238,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 980,
      "chainage": 8980,
      "zlevel": 44.862,
      "radius": 5318.045,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 981,
      "chainage": 9000,
      "zlevel": 44.57,
      "radius": 8624.157,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 982,
      "chainage": 9020,
      "zlevel": 44.278,
      "radius": 1492.604,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 983,
      "chainage": 9040,
      "zlevel": 43.986,
      "radius": 817.002,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 984,
      "chainage": 9046,
      "zlevel": 43.903,
      "radius": 723.376,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 985,
      "chainage": 9060,
      "zlevel": 43.738,
      "radius": 562.429,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 986,
      "chainage": 9079,
      "zlevel": 43.657,
      "radius": 432.432,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 987,
      "chainage": 9080,
      "zlevel": 43.658,
      "radius": 428.813,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 988,
      "chainage": 9080,
      "zlevel": 43.658,
      "radius": 428.302,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 989,
      "chainage": 9100,
      "zlevel": 43.751,
      "radius": 346.496,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 990,
      "chainage": 9103,
      "zlevel": 43.779,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 991,
      "chainage": 9114,
      "zlevel": 43.927,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 992,
      "chainage": 9120,
      "zlevel": 44.011,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 993,
      "chainage": 9140,
      "zlevel": 44.317,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 994,
      "chainage": 9160,
      "zlevel": 44.623,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 995,
      "chainage": 9180,
      "zlevel": 44.929,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 996,
      "chainage": 9200,
      "zlevel": 45.235,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 997,
      "chainage": 9220,
      "zlevel": 45.541,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 998,
      "chainage": 9240,
      "zlevel": 45.847,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 999,
      "chainage": 9242,
      "zlevel": 45.875,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1000,
      "chainage": 9260,
      "zlevel": 46.153,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1001,
      "chainage": 9280,
      "zlevel": 46.459,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1002,
      "chainage": 9300,
      "zlevel": 46.765,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1003,
      "chainage": 9312,
      "zlevel": 46.947,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1004,
      "chainage": 9320,
      "zlevel": 47.063,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1005,
      "chainage": 9340,
      "zlevel": 47.278,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1006,
      "chainage": 9360,
      "zlevel": 47.394,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1007,
      "chainage": 9373,
      "zlevel": 47.415,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1008,
      "chainage": 9380,
      "zlevel": 47.409,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1009,
      "chainage": 9381,
      "zlevel": 47.408,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1010,
      "chainage": 9400,
      "zlevel": 47.324,
      "radius": 421.504,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1011,
      "chainage": 9420,
      "zlevel": 47.14,
      "radius": 569.744,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1012,
      "chainage": 9440,
      "zlevel": 46.855,
      "radius": 878.819,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1013,
      "chainage": 9440,
      "zlevel": 46.848,
      "radius": 889.525,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1014,
      "chainage": 9460,
      "zlevel": 46.471,
      "radius": 1920.836,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1015,
      "chainage": 188,
      "zlevel": 40.936,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1016,
      "chainage": 200,
      "zlevel": 40.9,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1017,
      "chainage": 220,
      "zlevel": 40.84,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1018,
      "chainage": 240,
      "zlevel": 40.78,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1019,
      "chainage": 260,
      "zlevel": 40.72,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1020,
      "chainage": 265,
      "zlevel": 40.704,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1021,
      "chainage": 741,
      "zlevel": 30.969,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1022,
      "chainage": 760,
      "zlevel": 30.417,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1023,
      "chainage": 780,
      "zlevel": 29.847,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1024,
      "chainage": 800,
      "zlevel": 29.277,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1025,
      "chainage": 808,
      "zlevel": 29.043,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1026,
      "chainage": 1674,
      "zlevel": 13.177,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1027,
      "chainage": 1993,
      "zlevel": 19.88,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1028,
      "chainage": 2447,
      "zlevel": 14.372,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1029,
      "chainage": 2788,
      "zlevel": 9.762,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1030,
      "chainage": 2800,
      "zlevel": 9.856,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1031,
      "chainage": 2811,
      "zlevel": 9.958,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1032,
      "chainage": 2820,
      "zlevel": 10.048,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1033,
      "chainage": 2840,
      "zlevel": 10.248,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1034,
      "chainage": 2860,
      "zlevel": 10.448,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1035,
      "chainage": 2880,
      "zlevel": 10.648,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1036,
      "chainage": 2900,
      "zlevel": 10.848,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1037,
      "chainage": 2920,
      "zlevel": 11.048,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1038,
      "chainage": 2924,
      "zlevel": 11.089,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1039,
      "chainage": 3470,
      "zlevel": 16.548,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1040,
      "chainage": 3480,
      "zlevel": 16.647,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1041,
      "chainage": 3500,
      "zlevel": 16.846,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1042,
      "chainage": 3520,
      "zlevel": 17.046,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1043,
      "chainage": 3540,
      "zlevel": 17.246,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1044,
      "chainage": 3560,
      "zlevel": 17.446,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1045,
      "chainage": 3575,
      "zlevel": 17.601,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1046,
      "chainage": 3580,
      "zlevel": 17.644,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1047,
      "chainage": 3600,
      "zlevel": 17.791,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1048,
      "chainage": 3620,
      "zlevel": 17.866,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1049,
      "chainage": 3630,
      "zlevel": 17.876,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1050,
      "chainage": 3640,
      "zlevel": 17.867,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1051,
      "chainage": 3647,
      "zlevel": 17.851,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1052,
      "chainage": 3660,
      "zlevel": 17.796,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1053,
      "chainage": 3680,
      "zlevel": 17.652,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1054,
      "chainage": 3700,
      "zlevel": 17.436,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1055,
      "chainage": 3718,
      "zlevel": 17.172,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1056,
      "chainage": 3720,
      "zlevel": 17.147,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1057,
      "chainage": 3740,
      "zlevel": 16.827,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1058,
      "chainage": 3760,
      "zlevel": 16.507,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1059,
      "chainage": 3780,
      "zlevel": 16.187,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1060,
      "chainage": 3800,
      "zlevel": 15.867,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1061,
      "chainage": 3820,
      "zlevel": 15.547,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1062,
      "chainage": 3833,
      "zlevel": 15.331,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1063,
      "chainage": 4133,
      "zlevel": 10.54,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1064,
      "chainage": 5000,
      "zlevel": 19.159,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1065,
      "chainage": 5000,
      "zlevel": 19.165,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1066,
      "chainage": 5020,
      "zlevel": 19.625,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1067,
      "chainage": 5040,
      "zlevel": 20.085,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1068,
      "chainage": 5060,
      "zlevel": 20.545,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1069,
      "chainage": 5080,
      "zlevel": 21.005,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1070,
      "chainage": 5100,
      "zlevel": 21.465,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1071,
      "chainage": 5106,
      "zlevel": 21.605,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1072,
      "chainage": 5120,
      "zlevel": 21.944,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1073,
      "chainage": 5140,
      "zlevel": 22.5,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1074,
      "chainage": 5160,
      "zlevel": 23.136,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1075,
      "chainage": 5173,
      "zlevel": 23.61,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1076,
      "chainage": 5180,
      "zlevel": 23.851,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1077,
      "chainage": 5200,
      "zlevel": 24.648,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1078,
      "chainage": 5220,
      "zlevel": 25.524,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1079,
      "chainage": 5240,
      "zlevel": 26.481,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1080,
      "chainage": 5241,
      "zlevel": 26.521,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1081,
      "chainage": 5260,
      "zlevel": 27.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1082,
      "chainage": 5280,
      "zlevel": 28.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1083,
      "chainage": 5300,
      "zlevel": 29.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1084,
      "chainage": 5320,
      "zlevel": 30.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1085,
      "chainage": 5340,
      "zlevel": 31.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1086,
      "chainage": 5360,
      "zlevel": 32.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1087,
      "chainage": 5380,
      "zlevel": 33.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1088,
      "chainage": 5400,
      "zlevel": 34.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1089,
      "chainage": 5420,
      "zlevel": 35.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1090,
      "chainage": 5433,
      "zlevel": 36.139,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1091,
      "chainage": 5624,
      "zlevel": 45.676,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1092,
      "chainage": 5640,
      "zlevel": 46.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1093,
      "chainage": 5660,
      "zlevel": 47.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1094,
      "chainage": 5680,
      "zlevel": 48.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1095,
      "chainage": 5700,
      "zlevel": 49.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1096,
      "chainage": 5720,
      "zlevel": 50.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1097,
      "chainage": 5740,
      "zlevel": 51.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1098,
      "chainage": 5743,
      "zlevel": 51.63,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1099,
      "chainage": 5760,
      "zlevel": 52.46,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1100,
      "chainage": 5780,
      "zlevel": 53.382,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1101,
      "chainage": 5800,
      "zlevel": 54.248,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1102,
      "chainage": 5820,
      "zlevel": 55.056,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1103,
      "chainage": 5840,
      "zlevel": 55.806,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1104,
      "chainage": 5860,
      "zlevel": 56.5,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1105,
      "chainage": 5880,
      "zlevel": 57.136,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1106,
      "chainage": 5900,
      "zlevel": 57.715,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1107,
      "chainage": 5920,
      "zlevel": 58.237,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1108,
      "chainage": 5940,
      "zlevel": 58.701,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1109,
      "chainage": 5960,
      "zlevel": 59.109,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1110,
      "chainage": 5980,
      "zlevel": 59.459,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1111,
      "chainage": 6000,
      "zlevel": 59.752,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1112,
      "chainage": 6020,
      "zlevel": 59.988,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1113,
      "chainage": 6040,
      "zlevel": 60.167,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1114,
      "chainage": 6060,
      "zlevel": 60.288,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1115,
      "chainage": 6080,
      "zlevel": 60.353,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1116,
      "chainage": 6093,
      "zlevel": 60.364,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1117,
      "chainage": 6093,
      "zlevel": 60.364,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1118,
      "chainage": 6100,
      "zlevel": 60.36,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1119,
      "chainage": 6120,
      "zlevel": 60.31,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1120,
      "chainage": 6140,
      "zlevel": 60.203,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1121,
      "chainage": 6160,
      "zlevel": 60.039,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1122,
      "chainage": 6180,
      "zlevel": 59.818,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1123,
      "chainage": 6200,
      "zlevel": 59.54,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1124,
      "chainage": 6220,
      "zlevel": 59.204,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1125,
      "chainage": 6240,
      "zlevel": 58.811,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1126,
      "chainage": 6260,
      "zlevel": 58.361,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1127,
      "chainage": 6280,
      "zlevel": 57.854,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1128,
      "chainage": 6300,
      "zlevel": 57.29,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1129,
      "chainage": 6320,
      "zlevel": 56.668,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1130,
      "chainage": 6340,
      "zlevel": 55.989,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1131,
      "chainage": 6360,
      "zlevel": 55.253,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1132,
      "chainage": 6380,
      "zlevel": 54.46,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1133,
      "chainage": 6400,
      "zlevel": 53.61,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1134,
      "chainage": 6420,
      "zlevel": 52.702,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1135,
      "chainage": 6440,
      "zlevel": 51.736,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1136,
      "chainage": 6442,
      "zlevel": 51.635,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1137,
      "chainage": 6460,
      "zlevel": 50.737,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1138,
      "chainage": 6480,
      "zlevel": 49.737,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1139,
      "chainage": 6500,
      "zlevel": 48.738,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1140,
      "chainage": 6520,
      "zlevel": 47.738,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1141,
      "chainage": 6540,
      "zlevel": 46.738,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1142,
      "chainage": 6560,
      "zlevel": 45.738,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1143,
      "chainage": 6580,
      "zlevel": 44.739,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1144,
      "chainage": 6580,
      "zlevel": 44.725,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1145,
      "chainage": 6965,
      "zlevel": 29.523,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1146,
      "chainage": 7704,
      "zlevel": 22.476,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1147,
      "chainage": 7720,
      "zlevel": 22.666,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1148,
      "chainage": 7740,
      "zlevel": 22.906,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1149,
      "chainage": 7760,
      "zlevel": 23.146,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1150,
      "chainage": 7780,
      "zlevel": 23.386,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1151,
      "chainage": 7800,
      "zlevel": 23.626,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1152,
      "chainage": 7820,
      "zlevel": 23.866,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1153,
      "chainage": 7840,
      "zlevel": 24.106,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1154,
      "chainage": 7860,
      "zlevel": 24.346,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1155,
      "chainage": 7880,
      "zlevel": 24.586,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1156,
      "chainage": 7900,
      "zlevel": 24.826,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1157,
      "chainage": 7920,
      "zlevel": 25.066,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1158,
      "chainage": 7940,
      "zlevel": 25.306,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1159,
      "chainage": 7945,
      "zlevel": 25.369,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1160,
      "chainage": 7960,
      "zlevel": 25.571,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1161,
      "chainage": 7980,
      "zlevel": 25.921,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1162,
      "chainage": 8000,
      "zlevel": 26.36,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1163,
      "chainage": 8020,
      "zlevel": 26.888,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1164,
      "chainage": 8023,
      "zlevel": 26.971,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1165,
      "chainage": 8996,
      "zlevel": 44.631,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1166,
      "chainage": 9477,
      "zlevel": 46.068,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1167,
      "chainage": 9480,
      "zlevel": 45.986,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1168,
      "chainage": 9500,
      "zlevel": 45.401,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1169,
      "chainage": 9520,
      "zlevel": 44.716,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1170,
      "chainage": 9540,
      "zlevel": 43.931,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1171,
      "chainage": 9560,
      "zlevel": 43.046,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1172,
      "chainage": 9569,
      "zlevel": 42.621,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1173,
      "chainage": 9580,
      "zlevel": 42.075,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1174,
      "chainage": 9600,
      "zlevel": 41.103,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 589,
      "chainage": 129,
      "zlevel": 41.113,
      "radius": 95.3,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 588,
      "chainage": 120,
      "zlevel": 41.14,
      "radius": 95.3,
      "segment_order_no": 1,
      "a_intersection": 0
    }
  ]
}

export const requestBody3 = {
  "return_option": 3,
  "start_year": 2020,
  "end_year": 2041,
  "fuel_distribution_cars": {
    "year": [
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
      "2051",
      "2052",
      "2053",
      "2054",
      "2055",
      "2056",
      "2057",
      "2058",
      "2059",
      "2060",
      "2061",
      "2062",
      "2063",
      "2064",
      "2065",
      "2066",
      "2067",
      "2068",
      "2069",
      "2070",
      "2071",
      "2072",
      "2073",
      "2074",
      "2075",
      "2076",
      "2077",
      "2078",
      "2079",
      "2080"
    ],
    "prc_gasoline": [
      "0.47769230799999995",
      "0.470923077",
      "0.46415384600000004",
      "0.457384615",
      "0.450615385",
      "0.443846154",
      "0.43707692299999995",
      "0.430307692",
      "0.423538462",
      "0.416769231",
      "0.41",
      "0.4",
      "0.39",
      "0.38",
      "0.37",
      "0.36",
      "0.35",
      "0.34",
      "0.33",
      "0.32",
      "0.31",
      "0.299134227",
      "0.287647059",
      "0.275502959",
      "0.26266436",
      "0.249091543",
      "0.23474252",
      "0.21957290100000001",
      "0.20353575699999998",
      "0.186581477",
      "0.168657611",
      "0.14970871",
      "0.12967615400000002",
      "0.10849797",
      "0.086108642",
      "0.062438908",
      "0.037415542",
      "0.010961132",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0"
    ],
    "prc_diesel": [
      "0.47769230799999995",
      "0.470923077",
      "0.46415384600000004",
      "0.457384615",
      "0.450615385",
      "0.443846154",
      "0.43707692299999995",
      "0.430307692",
      "0.423538462",
      "0.416769231",
      "0.41",
      "0.4",
      "0.39",
      "0.38",
      "0.37",
      "0.36",
      "0.35",
      "0.34",
      "0.33",
      "0.32",
      "0.31",
      "0.299134227",
      "0.287647059",
      "0.275502959",
      "0.26266436",
      "0.249091543",
      "0.23474252",
      "0.21957290100000001",
      "0.20353575699999998",
      "0.186581477",
      "0.168657611",
      "0.14970871",
      "0.12967615400000002",
      "0.10849797",
      "0.086108642",
      "0.062438908",
      "0.037415542",
      "0.010961132",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0"
    ],
    "prc_inchargeable_hybrid": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    "prc_chargeable_hybrid": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    "prc_battery": [
      "0.044615385",
      "0.058153845999999995",
      "0.071692308",
      "0.085230769",
      "0.098769231",
      "0.11230769199999999",
      "0.12584615400000002",
      "0.139384615",
      "0.152923077",
      "0.16646153800000002",
      "0.18",
      "0.2",
      "0.22",
      "0.24",
      "0.26",
      "0.28",
      "0.3",
      "0.32",
      "0.34",
      "0.36",
      "0.38",
      "0.401731546",
      "0.42470588200000003",
      "0.448994081",
      "0.47467128",
      "0.501816914",
      "0.53051496",
      "0.5608541979999999",
      "0.592928485",
      "0.626837045",
      "0.6626847770000001",
      "0.70058258",
      "0.740647692",
      "0.78300406",
      "0.827782715",
      "0.875122185",
      "0.925168917",
      "0.9780777359999999",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0"
    ],
    "prc_hydrogen": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ]
  },
  "fuel_distribution_trucks": {
    "year": [
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
      "2051",
      "2052",
      "2053",
      "2054",
      "2055",
      "2056",
      "2057",
      "2058",
      "2059",
      "2060",
      "2061",
      "2062",
      "2063",
      "2064",
      "2065",
      "2066",
      "2067",
      "2068",
      "2069",
      "2070",
      "2071",
      "2072",
      "2073",
      "2074",
      "2075",
      "2076",
      "2077",
      "2078",
      "2079",
      "2080"
    ],
    "prc_gasoline": [
      "0.389615385",
      "0.386153846",
      "0.382692308",
      "0.37923076899999997",
      "0.37576923100000004",
      "0.372307692",
      "0.368846154",
      "0.365384615",
      "0.36192307700000004",
      "0.35846153799999997",
      "0.355",
      "0.3515",
      "0.348",
      "0.3445",
      "0.341",
      "0.3375",
      "0.33399999999999996",
      "0.3305",
      "0.327",
      "0.3235",
      "0.32",
      "0.316083613",
      "0.311860465",
      "0.307306527",
      "0.30239589",
      "0.297100613",
      "0.291390569",
      "0.285233271",
      "0.278593685",
      "0.271434036",
      "0.263713588",
      "0.255388414",
      "0.246411148",
      "0.23673071399999998",
      "0.226292033",
      "0.215035714",
      "0.202897713",
      "0.18980897",
      "0.175695015",
      "0.160475546",
      "0.14406397099999999",
      "0.126366914",
      "0.10728368699999999",
      "0.086705714",
      "0.064515915",
      "0.040588039",
      "0.014785948",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0"
    ],
    "prc_diesel": [
      "0.389615385",
      "0.386153846",
      "0.382692308",
      "0.37923076899999997",
      "0.37576923100000004",
      "0.372307692",
      "0.368846154",
      "0.365384615",
      "0.36192307700000004",
      "0.35846153799999997",
      "0.355",
      "0.3515",
      "0.348",
      "0.3445",
      "0.341",
      "0.3375",
      "0.33399999999999996",
      "0.3305",
      "0.327",
      "0.3235",
      "0.32",
      "0.316083613",
      "0.311860465",
      "0.307306527",
      "0.30239589",
      "0.297100613",
      "0.291390569",
      "0.285233271",
      "0.278593685",
      "0.271434036",
      "0.263713588",
      "0.255388414",
      "0.246411148",
      "0.23673071399999998",
      "0.226292033",
      "0.215035714",
      "0.202897713",
      "0.18980897",
      "0.175695015",
      "0.160475546",
      "0.14406397099999999",
      "0.126366914",
      "0.10728368699999999",
      "0.086705714",
      "0.064515915",
      "0.040588039",
      "0.014785948",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0"
    ],
    "prc_inchargeable_hybrid": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    "prc_chargeable_hybrid": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    "prc_battery": [
      "0.006923077",
      "0.009230769",
      "0.011538462",
      "0.013846154",
      "0.016153846",
      "0.018461538",
      "0.020769231000000003",
      "0.023076923",
      "0.025384615",
      "0.027692308",
      "0.03",
      "0.037000000000000005",
      "0.044000000000000004",
      "0.051",
      "0.057999999999999996",
      "0.065",
      "0.07200000000000001",
      "0.079",
      "0.086",
      "0.09300000000000001",
      "0.1",
      "0.107832773",
      "0.11627907",
      "0.125386946",
      "0.135208221",
      "0.145798774",
      "0.157218861",
      "0.169533458",
      "0.18281262899999998",
      "0.197131928",
      "0.212572825",
      "0.229223172",
      "0.247177703",
      "0.266538572",
      "0.287415934",
      "0.309928572",
      "0.334204574",
      "0.36038206100000003",
      "0.38860997",
      "0.419048908",
      "0.45187205799999997",
      "0.48726617200000005",
      "0.5254326260000001",
      "0.566588572",
      "0.61096817",
      "0.658823921",
      "0.710428105",
      "0.7466070509999999",
      "0.760606775",
      "0.7740670909999999",
      "0.786982535",
      "0.79935105",
      "0.811173719",
      "0.8224544979999999",
      "0.833199925",
      "0.8434188290000001",
      "0.85312204",
      "0.8623221059999999",
      "0.871033017",
      "0.8792699509999999",
      "0.887049022"
    ],
    "prc_hydrogen": [
      "0.21384615399999998",
      "0.218461538",
      "0.223076923",
      "0.227692308",
      "0.23230769199999998",
      "0.23692307699999998",
      "0.24153846199999998",
      "0.246153846",
      "0.25076923100000004",
      "0.255384615",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.253392949",
      "0.239393225",
      "0.225932909",
      "0.213017465",
      "0.20064895",
      "0.188826281",
      "0.177545502",
      "0.166800075",
      "0.156581171",
      "0.14687796",
      "0.137677894",
      "0.128966983",
      "0.120730049",
      "0.11295097800000001"
    ]
  },
  "sensitivity_param_factors": {
    "speed": 1,
    "fuelprice": 1,
    "emission": 1,
    "AADTheavy": 1
  },
  "presumptions": {
    "Speed_car": 80,
    "Speed_truck": 80,
    "cost_increase": 0.01,
    "gasoline_fuelfactor": 1,
    "gasoline_co2": 2.85,
    "gasoline_kwh": 9.1,
    "gasoline_efficiency": 0.25,
    "gasoline_cost": 16,
    "diesel_fuelfactor": 1,
    "diesel_co2": 2.73,
    "diesel_kwh": 9.8,
    "diesel_efficiency": 0.35,
    "diesel_cost": 16,
    "battery_fuelfactor": 0,
    "battery_co2": 0.0,
    "battery_kwh": 9.8,
    "battery_efficiency": 1,
    "battery_cost": 0,
    "inchargeable_hybrid_fuelfactor": 1,
    "inchargeable_hybrid_co2": 2.85,
    "inchargeable_hybrid_kwh": 9.8,
    "inchargeable_hybrid_efficiency": 0.35,
    "inchargeable_hybrid_cost": 16,
    "chargeable_hybrid_fuelfactor": 1,
    "chargeable_hybrid_co2": 2.85,
    "chargeable_hybrid_kwh": 9.8,
    "chargeable_hybrid_efficiency": 0.35,
    "chargeable_hybrid_cost": 16,
    "hydrogen_fuelfactor": 0,
    "hydrogen_co2": 0.0,
    "hydrogen_kwh": 9.8,
    "hydrogen_efficiency": 1,
    "hydrogen_cost": 0,
    "iri": 1.0,
    "mpd": 1.0
  },
  "segments": [
    {
      "segment_id": 1,
      "directions_id": 590,
      "year": 2020,
      "aadt": 20000,
      "prc_heavy": 10,
      "aadt_cars": 18000,
      "aadt_trucks": 1500,
      "aadt_trucks_with_trailer": 500,
      "annual_increase_cars": 1,
      "annual_increase_heavy": 1.5,
      "segment_order_no": 1
    }
  ],
  "segments_data": [
    {
      "segment_data_id": 590,
      "chainage": 140,
      "zlevel": 41.08,
      "radius": 117.027,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 591,
      "chainage": 160,
      "zlevel": 41.02,
      "radius": 200.421,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 592,
      "chainage": 180,
      "zlevel": 40.96,
      "radius": 697.38,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 593,
      "chainage": 280,
      "zlevel": 40.66,
      "radius": 3910.82,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 594,
      "chainage": 300,
      "zlevel": 40.6,
      "radius": 1658.586,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 595,
      "chainage": 320,
      "zlevel": 40.54,
      "radius": 1052.471,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 596,
      "chainage": 326,
      "zlevel": 40.523,
      "radius": 955.322,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 597,
      "chainage": 340,
      "zlevel": 40.465,
      "radius": 770.792,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 598,
      "chainage": 343,
      "zlevel": 40.449,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 599,
      "chainage": 360,
      "zlevel": 40.335,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 600,
      "chainage": 380,
      "zlevel": 40.148,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 601,
      "chainage": 400,
      "zlevel": 39.904,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 602,
      "chainage": 415,
      "zlevel": 39.687,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 603,
      "chainage": 420,
      "zlevel": 39.603,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 604,
      "chainage": 440,
      "zlevel": 39.245,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 605,
      "chainage": 460,
      "zlevel": 38.829,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 606,
      "chainage": 480,
      "zlevel": 38.356,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 607,
      "chainage": 500,
      "zlevel": 37.826,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 608,
      "chainage": 503,
      "zlevel": 37.743,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 609,
      "chainage": 504,
      "zlevel": 37.714,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 610,
      "chainage": 520,
      "zlevel": 37.257,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 611,
      "chainage": 540,
      "zlevel": 36.687,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 612,
      "chainage": 560,
      "zlevel": 36.117,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 613,
      "chainage": 580,
      "zlevel": 35.547,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 614,
      "chainage": 600,
      "zlevel": 34.977,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 615,
      "chainage": 620,
      "zlevel": 34.407,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 616,
      "chainage": 640,
      "zlevel": 33.837,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 617,
      "chainage": 660,
      "zlevel": 33.267,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 618,
      "chainage": 663,
      "zlevel": 33.187,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 619,
      "chainage": 680,
      "zlevel": 32.697,
      "radius": 949.609,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 620,
      "chainage": 700,
      "zlevel": 32.127,
      "radius": 1416.746,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 621,
      "chainage": 720,
      "zlevel": 31.557,
      "radius": 2788.462,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 622,
      "chainage": 740,
      "zlevel": 30.987,
      "radius": 87731.395,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 623,
      "chainage": 820,
      "zlevel": 28.707,
      "radius": 4690.028,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 624,
      "chainage": 840,
      "zlevel": 28.137,
      "radius": 1738.003,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 625,
      "chainage": 860,
      "zlevel": 27.567,
      "radius": 1066.635,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 626,
      "chainage": 880,
      "zlevel": 26.997,
      "radius": 769.419,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 627,
      "chainage": 900,
      "zlevel": 26.441,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 628,
      "chainage": 900,
      "zlevel": 26.427,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 629,
      "chainage": 920,
      "zlevel": 25.857,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 630,
      "chainage": 940,
      "zlevel": 25.287,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 631,
      "chainage": 960,
      "zlevel": 24.717,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 632,
      "chainage": 980,
      "zlevel": 24.147,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 633,
      "chainage": 1000,
      "zlevel": 23.577,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 634,
      "chainage": 1020,
      "zlevel": 23.007,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 635,
      "chainage": 1040,
      "zlevel": 22.437,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 636,
      "chainage": 1060,
      "zlevel": 21.867,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 637,
      "chainage": 1080,
      "zlevel": 21.297,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 638,
      "chainage": 1100,
      "zlevel": 20.727,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 639,
      "chainage": 1120,
      "zlevel": 20.157,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 640,
      "chainage": 1140,
      "zlevel": 19.587,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 641,
      "chainage": 1160,
      "zlevel": 19.017,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 642,
      "chainage": 1180,
      "zlevel": 18.447,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 643,
      "chainage": 1200,
      "zlevel": 17.877,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 644,
      "chainage": 1220,
      "zlevel": 17.307,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 645,
      "chainage": 1240,
      "zlevel": 16.737,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 646,
      "chainage": 1252,
      "zlevel": 16.401,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 647,
      "chainage": 1260,
      "zlevel": 16.167,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 648,
      "chainage": 1280,
      "zlevel": 15.597,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 649,
      "chainage": 1300,
      "zlevel": 15.027,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 650,
      "chainage": 1320,
      "zlevel": 14.457,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 651,
      "chainage": 1340,
      "zlevel": 13.887,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 652,
      "chainage": 1360,
      "zlevel": 13.317,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 653,
      "chainage": 1380,
      "zlevel": 12.747,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 654,
      "chainage": 1400,
      "zlevel": 12.177,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 655,
      "chainage": 1409,
      "zlevel": 11.913,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 656,
      "chainage": 1420,
      "zlevel": 11.624,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 657,
      "chainage": 1440,
      "zlevel": 11.172,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 658,
      "chainage": 1460,
      "zlevel": 10.835,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 659,
      "chainage": 1480,
      "zlevel": 10.612,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 660,
      "chainage": 1496,
      "zlevel": 10.517,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 661,
      "chainage": 1500,
      "zlevel": 10.504,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 662,
      "chainage": 1509,
      "zlevel": 10.492,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 663,
      "chainage": 1520,
      "zlevel": 10.51,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 664,
      "chainage": 1540,
      "zlevel": 10.63,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 665,
      "chainage": 1560,
      "zlevel": 10.864,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 666,
      "chainage": 1580,
      "zlevel": 11.213,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 667,
      "chainage": 1582,
      "zlevel": 11.264,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 668,
      "chainage": 1600,
      "zlevel": 11.632,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 669,
      "chainage": 1604,
      "zlevel": 11.718,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 670,
      "chainage": 1620,
      "zlevel": 12.052,
      "radius": 784.742,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 671,
      "chainage": 1640,
      "zlevel": 12.472,
      "radius": 1252.51,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 672,
      "chainage": 1660,
      "zlevel": 12.892,
      "radius": 3100.875,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 673,
      "chainage": 1680,
      "zlevel": 13.312,
      "radius": 5308.363,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 674,
      "chainage": 1700,
      "zlevel": 13.732,
      "radius": 1294.079,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 675,
      "chainage": 1720,
      "zlevel": 14.152,
      "radius": 736.855,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 676,
      "chainage": 1740,
      "zlevel": 14.572,
      "radius": 515.069,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 677,
      "chainage": 1742,
      "zlevel": 14.614,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 678,
      "chainage": 1760,
      "zlevel": 14.992,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 679,
      "chainage": 1780,
      "zlevel": 15.412,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 680,
      "chainage": 1800,
      "zlevel": 15.832,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 681,
      "chainage": 1820,
      "zlevel": 16.252,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 682,
      "chainage": 1833,
      "zlevel": 16.528,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 683,
      "chainage": 1840,
      "zlevel": 16.672,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 684,
      "chainage": 1860,
      "zlevel": 17.092,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 685,
      "chainage": 1880,
      "zlevel": 17.512,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 686,
      "chainage": 1900,
      "zlevel": 17.932,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 687,
      "chainage": 1920,
      "zlevel": 18.352,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 688,
      "chainage": 1924,
      "zlevel": 18.442,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 689,
      "chainage": 1940,
      "zlevel": 18.772,
      "radius": 648.83,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 690,
      "chainage": 1960,
      "zlevel": 19.192,
      "radius": 1045.076,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 691,
      "chainage": 1980,
      "zlevel": 19.612,
      "radius": 2684.566,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 692,
      "chainage": 2000,
      "zlevel": 20.032,
      "radius": 7943.515,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 693,
      "chainage": 2017,
      "zlevel": 20.399,
      "radius": 2328.671,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 694,
      "chainage": 2020,
      "zlevel": 20.452,
      "radius": 2113.669,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 695,
      "chainage": 2034,
      "zlevel": 20.722,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 696,
      "chainage": 2040,
      "zlevel": 20.821,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 697,
      "chainage": 2060,
      "zlevel": 21.111,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 698,
      "chainage": 2080,
      "zlevel": 21.321,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 699,
      "chainage": 2100,
      "zlevel": 21.451,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 700,
      "chainage": 2120,
      "zlevel": 21.501,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 701,
      "chainage": 2122,
      "zlevel": 21.501,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 702,
      "chainage": 2140,
      "zlevel": 21.471,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 703,
      "chainage": 2140,
      "zlevel": 21.471,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 704,
      "chainage": 2160,
      "zlevel": 21.361,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 705,
      "chainage": 2180,
      "zlevel": 21.17,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 706,
      "chainage": 2200,
      "zlevel": 20.9,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 707,
      "chainage": 2220,
      "zlevel": 20.552,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 708,
      "chainage": 2220,
      "zlevel": 20.55,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 709,
      "chainage": 2240,
      "zlevel": 20.12,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 710,
      "chainage": 2260,
      "zlevel": 19.609,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 711,
      "chainage": 2262,
      "zlevel": 19.543,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 712,
      "chainage": 2280,
      "zlevel": 19.05,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 713,
      "chainage": 2300,
      "zlevel": 18.49,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 714,
      "chainage": 2320,
      "zlevel": 17.93,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 715,
      "chainage": 2340,
      "zlevel": 17.37,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 716,
      "chainage": 2360,
      "zlevel": 16.81,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 717,
      "chainage": 2380,
      "zlevel": 16.25,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 718,
      "chainage": 2400,
      "zlevel": 15.69,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 719,
      "chainage": 2406,
      "zlevel": 15.532,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 720,
      "chainage": 2420,
      "zlevel": 15.13,
      "radius": 2127.496,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 721,
      "chainage": 2440,
      "zlevel": 14.57,
      "radius": 8142.395,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 722,
      "chainage": 2460,
      "zlevel": 14.01,
      "radius": 3094.559,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 723,
      "chainage": 2480,
      "zlevel": 13.45,
      "radius": 1214.849,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 724,
      "chainage": 2500,
      "zlevel": 12.89,
      "radius": 755.773,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 725,
      "chainage": 2507,
      "zlevel": 12.692,
      "radius": 666.788,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 726,
      "chainage": 2520,
      "zlevel": 12.34,
      "radius": 548.502,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 727,
      "chainage": 2529,
      "zlevel": 12.116,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 728,
      "chainage": 2540,
      "zlevel": 11.838,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 729,
      "chainage": 2560,
      "zlevel": 11.385,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 730,
      "chainage": 2580,
      "zlevel": 10.983,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 731,
      "chainage": 2600,
      "zlevel": 10.63,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 732,
      "chainage": 2604,
      "zlevel": 10.56,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 733,
      "chainage": 2620,
      "zlevel": 10.328,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 734,
      "chainage": 2640,
      "zlevel": 10.075,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 735,
      "chainage": 2659,
      "zlevel": 9.882,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 736,
      "chainage": 2660,
      "zlevel": 9.873,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 737,
      "chainage": 2680,
      "zlevel": 9.72,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 738,
      "chainage": 2680,
      "zlevel": 9.72,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 739,
      "chainage": 2700,
      "zlevel": 9.618,
      "radius": 600.941,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 740,
      "chainage": 2720,
      "zlevel": 9.566,
      "radius": 777.614,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 741,
      "chainage": 2731,
      "zlevel": 9.558,
      "radius": 927.205,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 742,
      "chainage": 2740,
      "zlevel": 9.563,
      "radius": 1101.427,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 743,
      "chainage": 2760,
      "zlevel": 9.611,
      "radius": 1887.357,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 744,
      "chainage": 2780,
      "zlevel": 9.708,
      "radius": 6588.939,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 745,
      "chainage": 2940,
      "zlevel": 11.248,
      "radius": 3627.347,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 746,
      "chainage": 2960,
      "zlevel": 11.448,
      "radius": 1605.379,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 747,
      "chainage": 2980,
      "zlevel": 11.648,
      "radius": 1030.792,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 748,
      "chainage": 2990,
      "zlevel": 11.751,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 749,
      "chainage": 3000,
      "zlevel": 11.848,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 750,
      "chainage": 3020,
      "zlevel": 12.048,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 751,
      "chainage": 3040,
      "zlevel": 12.248,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 752,
      "chainage": 3060,
      "zlevel": 12.448,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 753,
      "chainage": 3080,
      "zlevel": 12.648,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 754,
      "chainage": 3100,
      "zlevel": 12.848,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 755,
      "chainage": 3120,
      "zlevel": 13.047,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 756,
      "chainage": 3140,
      "zlevel": 13.247,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 757,
      "chainage": 3160,
      "zlevel": 13.447,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 758,
      "chainage": 3178,
      "zlevel": 13.632,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 759,
      "chainage": 3180,
      "zlevel": 13.647,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 760,
      "chainage": 3200,
      "zlevel": 13.847,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 761,
      "chainage": 3220,
      "zlevel": 14.047,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 762,
      "chainage": 3240,
      "zlevel": 14.247,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 763,
      "chainage": 3260,
      "zlevel": 14.447,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 764,
      "chainage": 3280,
      "zlevel": 14.647,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 765,
      "chainage": 3300,
      "zlevel": 14.847,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 766,
      "chainage": 3320,
      "zlevel": 15.047,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 767,
      "chainage": 3340,
      "zlevel": 15.247,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 768,
      "chainage": 3360,
      "zlevel": 15.447,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 769,
      "chainage": 3367,
      "zlevel": 15.513,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 770,
      "chainage": 3380,
      "zlevel": 15.647,
      "radius": 998.912,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 771,
      "chainage": 3400,
      "zlevel": 15.847,
      "radius": 1283.916,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 772,
      "chainage": 3420,
      "zlevel": 16.047,
      "radius": 1796.478,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 773,
      "chainage": 3440,
      "zlevel": 16.247,
      "radius": 2990.229,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 774,
      "chainage": 3460,
      "zlevel": 16.447,
      "radius": 8912.629,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 775,
      "chainage": 3840,
      "zlevel": 15.227,
      "radius": 7748.77,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 776,
      "chainage": 3860,
      "zlevel": 14.907,
      "radius": 1907.98,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 777,
      "chainage": 3880,
      "zlevel": 14.587,
      "radius": 1087.931,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 778,
      "chainage": 3897,
      "zlevel": 14.319,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 779,
      "chainage": 3900,
      "zlevel": 14.267,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 780,
      "chainage": 3920,
      "zlevel": 13.947,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 781,
      "chainage": 3940,
      "zlevel": 13.627,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 782,
      "chainage": 3960,
      "zlevel": 13.307,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 783,
      "chainage": 3980,
      "zlevel": 12.987,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 784,
      "chainage": 3983,
      "zlevel": 12.935,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 785,
      "chainage": 4000,
      "zlevel": 12.667,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 786,
      "chainage": 4020,
      "zlevel": 12.347,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 787,
      "chainage": 4040,
      "zlevel": 12.027,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 788,
      "chainage": 4060,
      "zlevel": 11.707,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 789,
      "chainage": 4070,
      "zlevel": 11.552,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 790,
      "chainage": 4080,
      "zlevel": 11.387,
      "radius": 956.509,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 791,
      "chainage": 4100,
      "zlevel": 11.067,
      "radius": 1537.5,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 792,
      "chainage": 4120,
      "zlevel": 10.747,
      "radius": 3916.276,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 793,
      "chainage": 4140,
      "zlevel": 10.427,
      "radius": 8143.435,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 794,
      "chainage": 4160,
      "zlevel": 10.107,
      "radius": 2127.566,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 795,
      "chainage": 4180,
      "zlevel": 9.787,
      "radius": 1223.626,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 796,
      "chainage": 4200,
      "zlevel": 9.467,
      "radius": 858.763,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 797,
      "chainage": 4220,
      "zlevel": 9.147,
      "radius": 661.512,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 798,
      "chainage": 4222,
      "zlevel": 9.111,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 799,
      "chainage": 4240,
      "zlevel": 8.827,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 800,
      "chainage": 4260,
      "zlevel": 8.507,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 801,
      "chainage": 4280,
      "zlevel": 8.187,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 802,
      "chainage": 4300,
      "zlevel": 7.867,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 803,
      "chainage": 4306,
      "zlevel": 7.768,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 804,
      "chainage": 4320,
      "zlevel": 7.563,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 805,
      "chainage": 4340,
      "zlevel": 7.322,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 806,
      "chainage": 4360,
      "zlevel": 7.148,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 807,
      "chainage": 4380,
      "zlevel": 7.041,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 808,
      "chainage": 4400,
      "zlevel": 7.001,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 809,
      "chainage": 4402,
      "zlevel": 7,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 810,
      "chainage": 4420,
      "zlevel": 7.027,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 811,
      "chainage": 4423,
      "zlevel": 7.037,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 812,
      "chainage": 4440,
      "zlevel": 7.12,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 813,
      "chainage": 4460,
      "zlevel": 7.28,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 814,
      "chainage": 4480,
      "zlevel": 7.506,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 815,
      "chainage": 4500,
      "zlevel": 7.799,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 816,
      "chainage": 4520,
      "zlevel": 8.158,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 817,
      "chainage": 4540,
      "zlevel": 8.585,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 818,
      "chainage": 4540,
      "zlevel": 8.587,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 819,
      "chainage": 4560,
      "zlevel": 9.045,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 820,
      "chainage": 4566,
      "zlevel": 9.191,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 821,
      "chainage": 4580,
      "zlevel": 9.505,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 822,
      "chainage": 4600,
      "zlevel": 9.965,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 823,
      "chainage": 4620,
      "zlevel": 10.425,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 824,
      "chainage": 4640,
      "zlevel": 10.885,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 825,
      "chainage": 4660,
      "zlevel": 11.345,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 826,
      "chainage": 4680,
      "zlevel": 11.805,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 827,
      "chainage": 4700,
      "zlevel": 12.265,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 828,
      "chainage": 4720,
      "zlevel": 12.725,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 829,
      "chainage": 4740,
      "zlevel": 13.185,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 830,
      "chainage": 4760,
      "zlevel": 13.645,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 831,
      "chainage": 4780,
      "zlevel": 14.105,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 832,
      "chainage": 4800,
      "zlevel": 14.565,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 833,
      "chainage": 4820,
      "zlevel": 15.025,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 834,
      "chainage": 4840,
      "zlevel": 15.485,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 835,
      "chainage": 4860,
      "zlevel": 15.945,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 836,
      "chainage": 4880,
      "zlevel": 16.405,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 837,
      "chainage": 4900,
      "zlevel": 16.865,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 838,
      "chainage": 4910,
      "zlevel": 17.105,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 839,
      "chainage": 4920,
      "zlevel": 17.325,
      "radius": 722.061,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 840,
      "chainage": 4940,
      "zlevel": 17.785,
      "radius": 963.667,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 841,
      "chainage": 4960,
      "zlevel": 18.245,
      "radius": 1448.266,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 842,
      "chainage": 4980,
      "zlevel": 18.705,
      "radius": 2913.256,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 843,
      "chainage": 5440,
      "zlevel": 36.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 844,
      "chainage": 5460,
      "zlevel": 37.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 845,
      "chainage": 5480,
      "zlevel": 38.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 846,
      "chainage": 5491,
      "zlevel": 39.019,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 847,
      "chainage": 5500,
      "zlevel": 39.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 848,
      "chainage": 5520,
      "zlevel": 40.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 849,
      "chainage": 5540,
      "zlevel": 41.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 850,
      "chainage": 5548,
      "zlevel": 41.898,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 851,
      "chainage": 5560,
      "zlevel": 42.48,
      "radius": 827.634,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 852,
      "chainage": 5580,
      "zlevel": 43.48,
      "radius": 1204.542,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 853,
      "chainage": 5600,
      "zlevel": 44.48,
      "radius": 2211.805,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 854,
      "chainage": 5620,
      "zlevel": 45.48,
      "radius": 13504.82,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 855,
      "chainage": 6600,
      "zlevel": 43.739,
      "radius": 2921.033,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 856,
      "chainage": 6620,
      "zlevel": 42.739,
      "radius": 1450.186,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 857,
      "chainage": 6640,
      "zlevel": 41.739,
      "radius": 964.516,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 858,
      "chainage": 6648,
      "zlevel": 41.337,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 859,
      "chainage": 6660,
      "zlevel": 40.74,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 860,
      "chainage": 6680,
      "zlevel": 39.74,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 861,
      "chainage": 6700,
      "zlevel": 38.74,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 862,
      "chainage": 6720,
      "zlevel": 37.74,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 863,
      "chainage": 6728,
      "zlevel": 37.36,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 864,
      "chainage": 6740,
      "zlevel": 36.752,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 865,
      "chainage": 6760,
      "zlevel": 35.816,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 866,
      "chainage": 6776,
      "zlevel": 35.126,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 867,
      "chainage": 6780,
      "zlevel": 34.938,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 868,
      "chainage": 6800,
      "zlevel": 34.117,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 869,
      "chainage": 6820,
      "zlevel": 33.353,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 870,
      "chainage": 6840,
      "zlevel": 32.647,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 871,
      "chainage": 6850,
      "zlevel": 32.323,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 872,
      "chainage": 6860,
      "zlevel": 31.998,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 873,
      "chainage": 6880,
      "zlevel": 31.406,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 874,
      "chainage": 6900,
      "zlevel": 30.871,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 875,
      "chainage": 6903,
      "zlevel": 30.793,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 876,
      "chainage": 6920,
      "zlevel": 30.394,
      "radius": 1165.745,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 877,
      "chainage": 6940,
      "zlevel": 29.974,
      "radius": 2084.425,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 878,
      "chainage": 6960,
      "zlevel": 29.61,
      "radius": 9835.083,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 879,
      "chainage": 6972,
      "zlevel": 29.419,
      "radius": 8588.7,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 880,
      "chainage": 6980,
      "zlevel": 29.3,
      "radius": 3939.459,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 881,
      "chainage": 7000,
      "zlevel": 29,
      "radius": 1663.716,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 882,
      "chainage": 7020,
      "zlevel": 28.7,
      "radius": 1054.534,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 883,
      "chainage": 7026,
      "zlevel": 28.61,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 884,
      "chainage": 7040,
      "zlevel": 28.4,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 885,
      "chainage": 7060,
      "zlevel": 28.1,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 886,
      "chainage": 7080,
      "zlevel": 27.8,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 887,
      "chainage": 7100,
      "zlevel": 27.5,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 888,
      "chainage": 7120,
      "zlevel": 27.2,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 889,
      "chainage": 7140,
      "zlevel": 26.9,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 890,
      "chainage": 7160,
      "zlevel": 26.6,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 891,
      "chainage": 7180,
      "zlevel": 26.3,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 892,
      "chainage": 7200,
      "zlevel": 26,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 893,
      "chainage": 7220,
      "zlevel": 25.7,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 894,
      "chainage": 7240,
      "zlevel": 25.4,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 895,
      "chainage": 7260,
      "zlevel": 25.1,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 896,
      "chainage": 7280,
      "zlevel": 24.8,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 897,
      "chainage": 7300,
      "zlevel": 24.5,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 898,
      "chainage": 7320,
      "zlevel": 24.2,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 899,
      "chainage": 7332,
      "zlevel": 24.017,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 900,
      "chainage": 7340,
      "zlevel": 23.9,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 901,
      "chainage": 7360,
      "zlevel": 23.6,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 902,
      "chainage": 7380,
      "zlevel": 23.3,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 903,
      "chainage": 7400,
      "zlevel": 23,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 904,
      "chainage": 7420,
      "zlevel": 22.7,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 905,
      "chainage": 7440,
      "zlevel": 22.4,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 906,
      "chainage": 7447,
      "zlevel": 22.301,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 907,
      "chainage": 7460,
      "zlevel": 22.111,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 908,
      "chainage": 7480,
      "zlevel": 21.87,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 909,
      "chainage": 7500,
      "zlevel": 21.678,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 910,
      "chainage": 7520,
      "zlevel": 21.537,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 911,
      "chainage": 7540,
      "zlevel": 21.445,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 912,
      "chainage": 7555,
      "zlevel": 21.41,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 913,
      "chainage": 7560,
      "zlevel": 21.404,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 914,
      "chainage": 7567,
      "zlevel": 21.401,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 915,
      "chainage": 7580,
      "zlevel": 21.413,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 916,
      "chainage": 7600,
      "zlevel": 21.471,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 917,
      "chainage": 7620,
      "zlevel": 21.58,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 918,
      "chainage": 7638,
      "zlevel": 21.723,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 919,
      "chainage": 7640,
      "zlevel": 21.738,
      "radius": 974.843,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 920,
      "chainage": 7660,
      "zlevel": 21.947,
      "radius": 1416.819,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 921,
      "chainage": 7663,
      "zlevel": 21.977,
      "radius": 1504.533,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 922,
      "chainage": 7680,
      "zlevel": 22.186,
      "radius": 2591.973,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 923,
      "chainage": 7700,
      "zlevel": 22.426,
      "radius": 15196.068,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 924,
      "chainage": 8031,
      "zlevel": 27.205,
      "radius": 7415.366,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 925,
      "chainage": 8040,
      "zlevel": 27.505,
      "radius": 3358.169,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 926,
      "chainage": 8060,
      "zlevel": 28.212,
      "radius": 1550.379,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 927,
      "chainage": 8080,
      "zlevel": 29.007,
      "radius": 1007.835,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 928,
      "chainage": 8091,
      "zlevel": 29.465,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 929,
      "chainage": 8100,
      "zlevel": 29.891,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 930,
      "chainage": 8116,
      "zlevel": 30.659,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 931,
      "chainage": 8120,
      "zlevel": 30.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 932,
      "chainage": 8140,
      "zlevel": 31.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 933,
      "chainage": 8160,
      "zlevel": 32.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 934,
      "chainage": 8180,
      "zlevel": 33.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 935,
      "chainage": 8200,
      "zlevel": 34.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 936,
      "chainage": 8220,
      "zlevel": 35.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 937,
      "chainage": 8240,
      "zlevel": 36.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 938,
      "chainage": 8260,
      "zlevel": 37.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 939,
      "chainage": 8280,
      "zlevel": 38.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 940,
      "chainage": 8300,
      "zlevel": 39.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 941,
      "chainage": 8320,
      "zlevel": 40.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 942,
      "chainage": 8321,
      "zlevel": 40.935,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 943,
      "chainage": 8340,
      "zlevel": 41.836,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 944,
      "chainage": 8360,
      "zlevel": 42.748,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 945,
      "chainage": 8380,
      "zlevel": 43.598,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 946,
      "chainage": 8400,
      "zlevel": 44.387,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 947,
      "chainage": 8420,
      "zlevel": 45.114,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 948,
      "chainage": 8440,
      "zlevel": 45.779,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 949,
      "chainage": 8460,
      "zlevel": 46.382,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 950,
      "chainage": 8480,
      "zlevel": 46.924,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 951,
      "chainage": 8494,
      "zlevel": 47.261,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 952,
      "chainage": 8500,
      "zlevel": 47.404,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 953,
      "chainage": 8520,
      "zlevel": 47.823,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 954,
      "chainage": 8531,
      "zlevel": 48.028,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 955,
      "chainage": 8540,
      "zlevel": 48.18,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 956,
      "chainage": 8560,
      "zlevel": 48.476,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 957,
      "chainage": 8580,
      "zlevel": 48.71,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 958,
      "chainage": 8600,
      "zlevel": 48.882,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 959,
      "chainage": 8620,
      "zlevel": 48.993,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 960,
      "chainage": 8640,
      "zlevel": 49.042,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 961,
      "chainage": 8646,
      "zlevel": 49.045,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 962,
      "chainage": 8660,
      "zlevel": 49.03,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 963,
      "chainage": 8680,
      "zlevel": 48.956,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 964,
      "chainage": 8700,
      "zlevel": 48.821,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 965,
      "chainage": 8720,
      "zlevel": 48.624,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 966,
      "chainage": 8740,
      "zlevel": 48.366,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 967,
      "chainage": 8741,
      "zlevel": 48.352,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 968,
      "chainage": 8760,
      "zlevel": 48.074,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 969,
      "chainage": 8780,
      "zlevel": 47.782,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 970,
      "chainage": 8800,
      "zlevel": 47.49,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 971,
      "chainage": 8820,
      "zlevel": 47.198,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 972,
      "chainage": 8840,
      "zlevel": 46.906,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 973,
      "chainage": 8860,
      "zlevel": 46.614,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 974,
      "chainage": 8880,
      "zlevel": 46.322,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 975,
      "chainage": 8897,
      "zlevel": 46.076,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 976,
      "chainage": 8900,
      "zlevel": 46.03,
      "radius": 877.742,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 977,
      "chainage": 8920,
      "zlevel": 45.738,
      "radius": 1109.293,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 978,
      "chainage": 8940,
      "zlevel": 45.446,
      "radius": 1506.788,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 979,
      "chainage": 8960,
      "zlevel": 45.154,
      "radius": 2348.238,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 980,
      "chainage": 8980,
      "zlevel": 44.862,
      "radius": 5318.045,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 981,
      "chainage": 9000,
      "zlevel": 44.57,
      "radius": 8624.157,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 982,
      "chainage": 9020,
      "zlevel": 44.278,
      "radius": 1492.604,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 983,
      "chainage": 9040,
      "zlevel": 43.986,
      "radius": 817.002,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 984,
      "chainage": 9046,
      "zlevel": 43.903,
      "radius": 723.376,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 985,
      "chainage": 9060,
      "zlevel": 43.738,
      "radius": 562.429,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 986,
      "chainage": 9079,
      "zlevel": 43.657,
      "radius": 432.432,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 987,
      "chainage": 9080,
      "zlevel": 43.658,
      "radius": 428.813,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 988,
      "chainage": 9080,
      "zlevel": 43.658,
      "radius": 428.302,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 989,
      "chainage": 9100,
      "zlevel": 43.751,
      "radius": 346.496,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 990,
      "chainage": 9103,
      "zlevel": 43.779,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 991,
      "chainage": 9114,
      "zlevel": 43.927,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 992,
      "chainage": 9120,
      "zlevel": 44.011,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 993,
      "chainage": 9140,
      "zlevel": 44.317,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 994,
      "chainage": 9160,
      "zlevel": 44.623,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 995,
      "chainage": 9180,
      "zlevel": 44.929,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 996,
      "chainage": 9200,
      "zlevel": 45.235,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 997,
      "chainage": 9220,
      "zlevel": 45.541,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 998,
      "chainage": 9240,
      "zlevel": 45.847,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 999,
      "chainage": 9242,
      "zlevel": 45.875,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1000,
      "chainage": 9260,
      "zlevel": 46.153,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1001,
      "chainage": 9280,
      "zlevel": 46.459,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1002,
      "chainage": 9300,
      "zlevel": 46.765,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1003,
      "chainage": 9312,
      "zlevel": 46.947,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1004,
      "chainage": 9320,
      "zlevel": 47.063,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1005,
      "chainage": 9340,
      "zlevel": 47.278,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1006,
      "chainage": 9360,
      "zlevel": 47.394,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1007,
      "chainage": 9373,
      "zlevel": 47.415,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1008,
      "chainage": 9380,
      "zlevel": 47.409,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1009,
      "chainage": 9381,
      "zlevel": 47.408,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1010,
      "chainage": 9400,
      "zlevel": 47.324,
      "radius": 421.504,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1011,
      "chainage": 9420,
      "zlevel": 47.14,
      "radius": 569.744,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1012,
      "chainage": 9440,
      "zlevel": 46.855,
      "radius": 878.819,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1013,
      "chainage": 9440,
      "zlevel": 46.848,
      "radius": 889.525,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1014,
      "chainage": 9460,
      "zlevel": 46.471,
      "radius": 1920.836,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1015,
      "chainage": 188,
      "zlevel": 40.936,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1016,
      "chainage": 200,
      "zlevel": 40.9,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1017,
      "chainage": 220,
      "zlevel": 40.84,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1018,
      "chainage": 240,
      "zlevel": 40.78,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1019,
      "chainage": 260,
      "zlevel": 40.72,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1020,
      "chainage": 265,
      "zlevel": 40.704,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1021,
      "chainage": 741,
      "zlevel": 30.969,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1022,
      "chainage": 760,
      "zlevel": 30.417,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1023,
      "chainage": 780,
      "zlevel": 29.847,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1024,
      "chainage": 800,
      "zlevel": 29.277,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1025,
      "chainage": 808,
      "zlevel": 29.043,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1026,
      "chainage": 1674,
      "zlevel": 13.177,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1027,
      "chainage": 1993,
      "zlevel": 19.88,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1028,
      "chainage": 2447,
      "zlevel": 14.372,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1029,
      "chainage": 2788,
      "zlevel": 9.762,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1030,
      "chainage": 2800,
      "zlevel": 9.856,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1031,
      "chainage": 2811,
      "zlevel": 9.958,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1032,
      "chainage": 2820,
      "zlevel": 10.048,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1033,
      "chainage": 2840,
      "zlevel": 10.248,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1034,
      "chainage": 2860,
      "zlevel": 10.448,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1035,
      "chainage": 2880,
      "zlevel": 10.648,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1036,
      "chainage": 2900,
      "zlevel": 10.848,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1037,
      "chainage": 2920,
      "zlevel": 11.048,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1038,
      "chainage": 2924,
      "zlevel": 11.089,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1039,
      "chainage": 3470,
      "zlevel": 16.548,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1040,
      "chainage": 3480,
      "zlevel": 16.647,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1041,
      "chainage": 3500,
      "zlevel": 16.846,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1042,
      "chainage": 3520,
      "zlevel": 17.046,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1043,
      "chainage": 3540,
      "zlevel": 17.246,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1044,
      "chainage": 3560,
      "zlevel": 17.446,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1045,
      "chainage": 3575,
      "zlevel": 17.601,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1046,
      "chainage": 3580,
      "zlevel": 17.644,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1047,
      "chainage": 3600,
      "zlevel": 17.791,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1048,
      "chainage": 3620,
      "zlevel": 17.866,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1049,
      "chainage": 3630,
      "zlevel": 17.876,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1050,
      "chainage": 3640,
      "zlevel": 17.867,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1051,
      "chainage": 3647,
      "zlevel": 17.851,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1052,
      "chainage": 3660,
      "zlevel": 17.796,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1053,
      "chainage": 3680,
      "zlevel": 17.652,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1054,
      "chainage": 3700,
      "zlevel": 17.436,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1055,
      "chainage": 3718,
      "zlevel": 17.172,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1056,
      "chainage": 3720,
      "zlevel": 17.147,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1057,
      "chainage": 3740,
      "zlevel": 16.827,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1058,
      "chainage": 3760,
      "zlevel": 16.507,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1059,
      "chainage": 3780,
      "zlevel": 16.187,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1060,
      "chainage": 3800,
      "zlevel": 15.867,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1061,
      "chainage": 3820,
      "zlevel": 15.547,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1062,
      "chainage": 3833,
      "zlevel": 15.331,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1063,
      "chainage": 4133,
      "zlevel": 10.54,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1064,
      "chainage": 5000,
      "zlevel": 19.159,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1065,
      "chainage": 5000,
      "zlevel": 19.165,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1066,
      "chainage": 5020,
      "zlevel": 19.625,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1067,
      "chainage": 5040,
      "zlevel": 20.085,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1068,
      "chainage": 5060,
      "zlevel": 20.545,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1069,
      "chainage": 5080,
      "zlevel": 21.005,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1070,
      "chainage": 5100,
      "zlevel": 21.465,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1071,
      "chainage": 5106,
      "zlevel": 21.605,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1072,
      "chainage": 5120,
      "zlevel": 21.944,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1073,
      "chainage": 5140,
      "zlevel": 22.5,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1074,
      "chainage": 5160,
      "zlevel": 23.136,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1075,
      "chainage": 5173,
      "zlevel": 23.61,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1076,
      "chainage": 5180,
      "zlevel": 23.851,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1077,
      "chainage": 5200,
      "zlevel": 24.648,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1078,
      "chainage": 5220,
      "zlevel": 25.524,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1079,
      "chainage": 5240,
      "zlevel": 26.481,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1080,
      "chainage": 5241,
      "zlevel": 26.521,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1081,
      "chainage": 5260,
      "zlevel": 27.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1082,
      "chainage": 5280,
      "zlevel": 28.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1083,
      "chainage": 5300,
      "zlevel": 29.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1084,
      "chainage": 5320,
      "zlevel": 30.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1085,
      "chainage": 5340,
      "zlevel": 31.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1086,
      "chainage": 5360,
      "zlevel": 32.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1087,
      "chainage": 5380,
      "zlevel": 33.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1088,
      "chainage": 5400,
      "zlevel": 34.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1089,
      "chainage": 5420,
      "zlevel": 35.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1090,
      "chainage": 5433,
      "zlevel": 36.139,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1091,
      "chainage": 5624,
      "zlevel": 45.676,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1092,
      "chainage": 5640,
      "zlevel": 46.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1093,
      "chainage": 5660,
      "zlevel": 47.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1094,
      "chainage": 5680,
      "zlevel": 48.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1095,
      "chainage": 5700,
      "zlevel": 49.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1096,
      "chainage": 5720,
      "zlevel": 50.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1097,
      "chainage": 5740,
      "zlevel": 51.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1098,
      "chainage": 5743,
      "zlevel": 51.63,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1099,
      "chainage": 5760,
      "zlevel": 52.46,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1100,
      "chainage": 5780,
      "zlevel": 53.382,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1101,
      "chainage": 5800,
      "zlevel": 54.248,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1102,
      "chainage": 5820,
      "zlevel": 55.056,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1103,
      "chainage": 5840,
      "zlevel": 55.806,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1104,
      "chainage": 5860,
      "zlevel": 56.5,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1105,
      "chainage": 5880,
      "zlevel": 57.136,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1106,
      "chainage": 5900,
      "zlevel": 57.715,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1107,
      "chainage": 5920,
      "zlevel": 58.237,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1108,
      "chainage": 5940,
      "zlevel": 58.701,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1109,
      "chainage": 5960,
      "zlevel": 59.109,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1110,
      "chainage": 5980,
      "zlevel": 59.459,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1111,
      "chainage": 6000,
      "zlevel": 59.752,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1112,
      "chainage": 6020,
      "zlevel": 59.988,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1113,
      "chainage": 6040,
      "zlevel": 60.167,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1114,
      "chainage": 6060,
      "zlevel": 60.288,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1115,
      "chainage": 6080,
      "zlevel": 60.353,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1116,
      "chainage": 6093,
      "zlevel": 60.364,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1117,
      "chainage": 6093,
      "zlevel": 60.364,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1118,
      "chainage": 6100,
      "zlevel": 60.36,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1119,
      "chainage": 6120,
      "zlevel": 60.31,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1120,
      "chainage": 6140,
      "zlevel": 60.203,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1121,
      "chainage": 6160,
      "zlevel": 60.039,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1122,
      "chainage": 6180,
      "zlevel": 59.818,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1123,
      "chainage": 6200,
      "zlevel": 59.54,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1124,
      "chainage": 6220,
      "zlevel": 59.204,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1125,
      "chainage": 6240,
      "zlevel": 58.811,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1126,
      "chainage": 6260,
      "zlevel": 58.361,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1127,
      "chainage": 6280,
      "zlevel": 57.854,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1128,
      "chainage": 6300,
      "zlevel": 57.29,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1129,
      "chainage": 6320,
      "zlevel": 56.668,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1130,
      "chainage": 6340,
      "zlevel": 55.989,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1131,
      "chainage": 6360,
      "zlevel": 55.253,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1132,
      "chainage": 6380,
      "zlevel": 54.46,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1133,
      "chainage": 6400,
      "zlevel": 53.61,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1134,
      "chainage": 6420,
      "zlevel": 52.702,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1135,
      "chainage": 6440,
      "zlevel": 51.736,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1136,
      "chainage": 6442,
      "zlevel": 51.635,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1137,
      "chainage": 6460,
      "zlevel": 50.737,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1138,
      "chainage": 6480,
      "zlevel": 49.737,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1139,
      "chainage": 6500,
      "zlevel": 48.738,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1140,
      "chainage": 6520,
      "zlevel": 47.738,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1141,
      "chainage": 6540,
      "zlevel": 46.738,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1142,
      "chainage": 6560,
      "zlevel": 45.738,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1143,
      "chainage": 6580,
      "zlevel": 44.739,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1144,
      "chainage": 6580,
      "zlevel": 44.725,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1145,
      "chainage": 6965,
      "zlevel": 29.523,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1146,
      "chainage": 7704,
      "zlevel": 22.476,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1147,
      "chainage": 7720,
      "zlevel": 22.666,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1148,
      "chainage": 7740,
      "zlevel": 22.906,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1149,
      "chainage": 7760,
      "zlevel": 23.146,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1150,
      "chainage": 7780,
      "zlevel": 23.386,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1151,
      "chainage": 7800,
      "zlevel": 23.626,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1152,
      "chainage": 7820,
      "zlevel": 23.866,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1153,
      "chainage": 7840,
      "zlevel": 24.106,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1154,
      "chainage": 7860,
      "zlevel": 24.346,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1155,
      "chainage": 7880,
      "zlevel": 24.586,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1156,
      "chainage": 7900,
      "zlevel": 24.826,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1157,
      "chainage": 7920,
      "zlevel": 25.066,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1158,
      "chainage": 7940,
      "zlevel": 25.306,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1159,
      "chainage": 7945,
      "zlevel": 25.369,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1160,
      "chainage": 7960,
      "zlevel": 25.571,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1161,
      "chainage": 7980,
      "zlevel": 25.921,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1162,
      "chainage": 8000,
      "zlevel": 26.36,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1163,
      "chainage": 8020,
      "zlevel": 26.888,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1164,
      "chainage": 8023,
      "zlevel": 26.971,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1165,
      "chainage": 8996,
      "zlevel": 44.631,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1166,
      "chainage": 9477,
      "zlevel": 46.068,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1167,
      "chainage": 9480,
      "zlevel": 45.986,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1168,
      "chainage": 9500,
      "zlevel": 45.401,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1169,
      "chainage": 9520,
      "zlevel": 44.716,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1170,
      "chainage": 9540,
      "zlevel": 43.931,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1171,
      "chainage": 9560,
      "zlevel": 43.046,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1172,
      "chainage": 9569,
      "zlevel": 42.621,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1173,
      "chainage": 9580,
      "zlevel": 42.075,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1174,
      "chainage": 9600,
      "zlevel": 41.103,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 589,
      "chainage": 129,
      "zlevel": 41.113,
      "radius": 95.3,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 588,
      "chainage": 120,
      "zlevel": 41.14,
      "radius": 95.3,
      "segment_order_no": 1,
      "a_intersection": 0
    }
  ]
}


export const requestBody2 = {
  "return_option": 2,
  "start_year": 2020,
  "end_year": 2041,
  "fuel_distribution_cars": {
    "year": [
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
      "2051",
      "2052",
      "2053",
      "2054",
      "2055",
      "2056",
      "2057",
      "2058",
      "2059",
      "2060",
      "2061",
      "2062",
      "2063",
      "2064",
      "2065",
      "2066",
      "2067",
      "2068",
      "2069",
      "2070",
      "2071",
      "2072",
      "2073",
      "2074",
      "2075",
      "2076",
      "2077",
      "2078",
      "2079",
      "2080"
    ],
    "prc_gasoline": [
      "0.47769230799999995",
      "0.470923077",
      "0.46415384600000004",
      "0.457384615",
      "0.450615385",
      "0.443846154",
      "0.43707692299999995",
      "0.430307692",
      "0.423538462",
      "0.416769231",
      "0.41",
      "0.4",
      "0.39",
      "0.38",
      "0.37",
      "0.36",
      "0.35",
      "0.34",
      "0.33",
      "0.32",
      "0.31",
      "0.299134227",
      "0.287647059",
      "0.275502959",
      "0.26266436",
      "0.249091543",
      "0.23474252",
      "0.21957290100000001",
      "0.20353575699999998",
      "0.186581477",
      "0.168657611",
      "0.14970871",
      "0.12967615400000002",
      "0.10849797",
      "0.086108642",
      "0.062438908",
      "0.037415542",
      "0.010961132",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0"
    ],
    "prc_diesel": [
      "0.47769230799999995",
      "0.470923077",
      "0.46415384600000004",
      "0.457384615",
      "0.450615385",
      "0.443846154",
      "0.43707692299999995",
      "0.430307692",
      "0.423538462",
      "0.416769231",
      "0.41",
      "0.4",
      "0.39",
      "0.38",
      "0.37",
      "0.36",
      "0.35",
      "0.34",
      "0.33",
      "0.32",
      "0.31",
      "0.299134227",
      "0.287647059",
      "0.275502959",
      "0.26266436",
      "0.249091543",
      "0.23474252",
      "0.21957290100000001",
      "0.20353575699999998",
      "0.186581477",
      "0.168657611",
      "0.14970871",
      "0.12967615400000002",
      "0.10849797",
      "0.086108642",
      "0.062438908",
      "0.037415542",
      "0.010961132",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0"
    ],
    "prc_inchargeable_hybrid": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    "prc_chargeable_hybrid": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    "prc_battery": [
      "0.044615385",
      "0.058153845999999995",
      "0.071692308",
      "0.085230769",
      "0.098769231",
      "0.11230769199999999",
      "0.12584615400000002",
      "0.139384615",
      "0.152923077",
      "0.16646153800000002",
      "0.18",
      "0.2",
      "0.22",
      "0.24",
      "0.26",
      "0.28",
      "0.3",
      "0.32",
      "0.34",
      "0.36",
      "0.38",
      "0.401731546",
      "0.42470588200000003",
      "0.448994081",
      "0.47467128",
      "0.501816914",
      "0.53051496",
      "0.5608541979999999",
      "0.592928485",
      "0.626837045",
      "0.6626847770000001",
      "0.70058258",
      "0.740647692",
      "0.78300406",
      "0.827782715",
      "0.875122185",
      "0.925168917",
      "0.9780777359999999",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0"
    ],
    "prc_hydrogen": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ]
  },
  "fuel_distribution_trucks": {
    "year": [
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
      "2051",
      "2052",
      "2053",
      "2054",
      "2055",
      "2056",
      "2057",
      "2058",
      "2059",
      "2060",
      "2061",
      "2062",
      "2063",
      "2064",
      "2065",
      "2066",
      "2067",
      "2068",
      "2069",
      "2070",
      "2071",
      "2072",
      "2073",
      "2074",
      "2075",
      "2076",
      "2077",
      "2078",
      "2079",
      "2080"
    ],
    "prc_gasoline": [
      "0.389615385",
      "0.386153846",
      "0.382692308",
      "0.37923076899999997",
      "0.37576923100000004",
      "0.372307692",
      "0.368846154",
      "0.365384615",
      "0.36192307700000004",
      "0.35846153799999997",
      "0.355",
      "0.3515",
      "0.348",
      "0.3445",
      "0.341",
      "0.3375",
      "0.33399999999999996",
      "0.3305",
      "0.327",
      "0.3235",
      "0.32",
      "0.316083613",
      "0.311860465",
      "0.307306527",
      "0.30239589",
      "0.297100613",
      "0.291390569",
      "0.285233271",
      "0.278593685",
      "0.271434036",
      "0.263713588",
      "0.255388414",
      "0.246411148",
      "0.23673071399999998",
      "0.226292033",
      "0.215035714",
      "0.202897713",
      "0.18980897",
      "0.175695015",
      "0.160475546",
      "0.14406397099999999",
      "0.126366914",
      "0.10728368699999999",
      "0.086705714",
      "0.064515915",
      "0.040588039",
      "0.014785948",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0"
    ],
    "prc_diesel": [
      "0.389615385",
      "0.386153846",
      "0.382692308",
      "0.37923076899999997",
      "0.37576923100000004",
      "0.372307692",
      "0.368846154",
      "0.365384615",
      "0.36192307700000004",
      "0.35846153799999997",
      "0.355",
      "0.3515",
      "0.348",
      "0.3445",
      "0.341",
      "0.3375",
      "0.33399999999999996",
      "0.3305",
      "0.327",
      "0.3235",
      "0.32",
      "0.316083613",
      "0.311860465",
      "0.307306527",
      "0.30239589",
      "0.297100613",
      "0.291390569",
      "0.285233271",
      "0.278593685",
      "0.271434036",
      "0.263713588",
      "0.255388414",
      "0.246411148",
      "0.23673071399999998",
      "0.226292033",
      "0.215035714",
      "0.202897713",
      "0.18980897",
      "0.175695015",
      "0.160475546",
      "0.14406397099999999",
      "0.126366914",
      "0.10728368699999999",
      "0.086705714",
      "0.064515915",
      "0.040588039",
      "0.014785948",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0"
    ],
    "prc_inchargeable_hybrid": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    "prc_chargeable_hybrid": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    "prc_battery": [
      "0.006923077",
      "0.009230769",
      "0.011538462",
      "0.013846154",
      "0.016153846",
      "0.018461538",
      "0.020769231000000003",
      "0.023076923",
      "0.025384615",
      "0.027692308",
      "0.03",
      "0.037000000000000005",
      "0.044000000000000004",
      "0.051",
      "0.057999999999999996",
      "0.065",
      "0.07200000000000001",
      "0.079",
      "0.086",
      "0.09300000000000001",
      "0.1",
      "0.107832773",
      "0.11627907",
      "0.125386946",
      "0.135208221",
      "0.145798774",
      "0.157218861",
      "0.169533458",
      "0.18281262899999998",
      "0.197131928",
      "0.212572825",
      "0.229223172",
      "0.247177703",
      "0.266538572",
      "0.287415934",
      "0.309928572",
      "0.334204574",
      "0.36038206100000003",
      "0.38860997",
      "0.419048908",
      "0.45187205799999997",
      "0.48726617200000005",
      "0.5254326260000001",
      "0.566588572",
      "0.61096817",
      "0.658823921",
      "0.710428105",
      "0.7466070509999999",
      "0.760606775",
      "0.7740670909999999",
      "0.786982535",
      "0.79935105",
      "0.811173719",
      "0.8224544979999999",
      "0.833199925",
      "0.8434188290000001",
      "0.85312204",
      "0.8623221059999999",
      "0.871033017",
      "0.8792699509999999",
      "0.887049022"
    ],
    "prc_hydrogen": [
      "0.21384615399999998",
      "0.218461538",
      "0.223076923",
      "0.227692308",
      "0.23230769199999998",
      "0.23692307699999998",
      "0.24153846199999998",
      "0.246153846",
      "0.25076923100000004",
      "0.255384615",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.253392949",
      "0.239393225",
      "0.225932909",
      "0.213017465",
      "0.20064895",
      "0.188826281",
      "0.177545502",
      "0.166800075",
      "0.156581171",
      "0.14687796",
      "0.137677894",
      "0.128966983",
      "0.120730049",
      "0.11295097800000001"
    ]
  },
  "sensitivity_param_factors": {
    "speed": 1,
    "fuelprice": 1,
    "emission": 1,
    "AADTheavy": 1
  },
  "presumptions": {
    "Speed_car": 80,
    "Speed_truck": 80,
    "cost_increase": 0.01,
    "gasoline_fuelfactor": 1,
    "gasoline_co2": 2.85,
    "gasoline_kwh": 9.1,
    "gasoline_efficiency": 0.25,
    "gasoline_cost": 16,
    "diesel_fuelfactor": 1,
    "diesel_co2": 2.73,
    "diesel_kwh": 9.8,
    "diesel_efficiency": 0.35,
    "diesel_cost": 16,
    "battery_fuelfactor": 0,
    "battery_co2": 0.0,
    "battery_kwh": 9.8,
    "battery_efficiency": 1,
    "battery_cost": 0,
    "inchargeable_hybrid_fuelfactor": 1,
    "inchargeable_hybrid_co2": 2.85,
    "inchargeable_hybrid_kwh": 9.8,
    "inchargeable_hybrid_efficiency": 0.35,
    "inchargeable_hybrid_cost": 16,
    "chargeable_hybrid_fuelfactor": 1,
    "chargeable_hybrid_co2": 2.85,
    "chargeable_hybrid_kwh": 9.8,
    "chargeable_hybrid_efficiency": 0.35,
    "chargeable_hybrid_cost": 16,
    "hydrogen_fuelfactor": 0,
    "hydrogen_co2": 0.0,
    "hydrogen_kwh": 9.8,
    "hydrogen_efficiency": 1,
    "hydrogen_cost": 0,
    "iri": 1.0,
    "mpd": 1.0
  },
  "segments": [
    {
      "segment_id": 1,
      "directions_id": 590,
      "year": 2020,
      "aadt": 20000,
      "prc_heavy": 10,
      "aadt_cars": 18000,
      "aadt_trucks": 1500,
      "aadt_trucks_with_trailer": 500,
      "annual_increase_cars": 1,
      "annual_increase_heavy": 1.5,
      "segment_order_no": 1
    }
  ],
  "segments_data": [
    {
      "segment_data_id": 590,
      "chainage": 140,
      "zlevel": 41.08,
      "radius": 117.027,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 591,
      "chainage": 160,
      "zlevel": 41.02,
      "radius": 200.421,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 592,
      "chainage": 180,
      "zlevel": 40.96,
      "radius": 697.38,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 593,
      "chainage": 280,
      "zlevel": 40.66,
      "radius": 3910.82,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 594,
      "chainage": 300,
      "zlevel": 40.6,
      "radius": 1658.586,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 595,
      "chainage": 320,
      "zlevel": 40.54,
      "radius": 1052.471,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 596,
      "chainage": 326,
      "zlevel": 40.523,
      "radius": 955.322,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 597,
      "chainage": 340,
      "zlevel": 40.465,
      "radius": 770.792,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 598,
      "chainage": 343,
      "zlevel": 40.449,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 599,
      "chainage": 360,
      "zlevel": 40.335,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 600,
      "chainage": 380,
      "zlevel": 40.148,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 601,
      "chainage": 400,
      "zlevel": 39.904,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 602,
      "chainage": 415,
      "zlevel": 39.687,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 603,
      "chainage": 420,
      "zlevel": 39.603,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 604,
      "chainage": 440,
      "zlevel": 39.245,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 605,
      "chainage": 460,
      "zlevel": 38.829,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 606,
      "chainage": 480,
      "zlevel": 38.356,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 607,
      "chainage": 500,
      "zlevel": 37.826,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 608,
      "chainage": 503,
      "zlevel": 37.743,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 609,
      "chainage": 504,
      "zlevel": 37.714,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 610,
      "chainage": 520,
      "zlevel": 37.257,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 611,
      "chainage": 540,
      "zlevel": 36.687,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 612,
      "chainage": 560,
      "zlevel": 36.117,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 613,
      "chainage": 580,
      "zlevel": 35.547,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 614,
      "chainage": 600,
      "zlevel": 34.977,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 615,
      "chainage": 620,
      "zlevel": 34.407,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 616,
      "chainage": 640,
      "zlevel": 33.837,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 617,
      "chainage": 660,
      "zlevel": 33.267,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 618,
      "chainage": 663,
      "zlevel": 33.187,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 619,
      "chainage": 680,
      "zlevel": 32.697,
      "radius": 949.609,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 620,
      "chainage": 700,
      "zlevel": 32.127,
      "radius": 1416.746,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 621,
      "chainage": 720,
      "zlevel": 31.557,
      "radius": 2788.462,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 622,
      "chainage": 740,
      "zlevel": 30.987,
      "radius": 87731.395,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 623,
      "chainage": 820,
      "zlevel": 28.707,
      "radius": 4690.028,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 624,
      "chainage": 840,
      "zlevel": 28.137,
      "radius": 1738.003,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 625,
      "chainage": 860,
      "zlevel": 27.567,
      "radius": 1066.635,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 626,
      "chainage": 880,
      "zlevel": 26.997,
      "radius": 769.419,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 627,
      "chainage": 900,
      "zlevel": 26.441,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 628,
      "chainage": 900,
      "zlevel": 26.427,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 629,
      "chainage": 920,
      "zlevel": 25.857,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 630,
      "chainage": 940,
      "zlevel": 25.287,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 631,
      "chainage": 960,
      "zlevel": 24.717,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 632,
      "chainage": 980,
      "zlevel": 24.147,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 633,
      "chainage": 1000,
      "zlevel": 23.577,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 634,
      "chainage": 1020,
      "zlevel": 23.007,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 635,
      "chainage": 1040,
      "zlevel": 22.437,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 636,
      "chainage": 1060,
      "zlevel": 21.867,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 637,
      "chainage": 1080,
      "zlevel": 21.297,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 638,
      "chainage": 1100,
      "zlevel": 20.727,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 639,
      "chainage": 1120,
      "zlevel": 20.157,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 640,
      "chainage": 1140,
      "zlevel": 19.587,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 641,
      "chainage": 1160,
      "zlevel": 19.017,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 642,
      "chainage": 1180,
      "zlevel": 18.447,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 643,
      "chainage": 1200,
      "zlevel": 17.877,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 644,
      "chainage": 1220,
      "zlevel": 17.307,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 645,
      "chainage": 1240,
      "zlevel": 16.737,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 646,
      "chainage": 1252,
      "zlevel": 16.401,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 647,
      "chainage": 1260,
      "zlevel": 16.167,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 648,
      "chainage": 1280,
      "zlevel": 15.597,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 649,
      "chainage": 1300,
      "zlevel": 15.027,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 650,
      "chainage": 1320,
      "zlevel": 14.457,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 651,
      "chainage": 1340,
      "zlevel": 13.887,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 652,
      "chainage": 1360,
      "zlevel": 13.317,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 653,
      "chainage": 1380,
      "zlevel": 12.747,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 654,
      "chainage": 1400,
      "zlevel": 12.177,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 655,
      "chainage": 1409,
      "zlevel": 11.913,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 656,
      "chainage": 1420,
      "zlevel": 11.624,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 657,
      "chainage": 1440,
      "zlevel": 11.172,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 658,
      "chainage": 1460,
      "zlevel": 10.835,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 659,
      "chainage": 1480,
      "zlevel": 10.612,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 660,
      "chainage": 1496,
      "zlevel": 10.517,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 661,
      "chainage": 1500,
      "zlevel": 10.504,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 662,
      "chainage": 1509,
      "zlevel": 10.492,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 663,
      "chainage": 1520,
      "zlevel": 10.51,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 664,
      "chainage": 1540,
      "zlevel": 10.63,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 665,
      "chainage": 1560,
      "zlevel": 10.864,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 666,
      "chainage": 1580,
      "zlevel": 11.213,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 667,
      "chainage": 1582,
      "zlevel": 11.264,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 668,
      "chainage": 1600,
      "zlevel": 11.632,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 669,
      "chainage": 1604,
      "zlevel": 11.718,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 670,
      "chainage": 1620,
      "zlevel": 12.052,
      "radius": 784.742,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 671,
      "chainage": 1640,
      "zlevel": 12.472,
      "radius": 1252.51,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 672,
      "chainage": 1660,
      "zlevel": 12.892,
      "radius": 3100.875,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 673,
      "chainage": 1680,
      "zlevel": 13.312,
      "radius": 5308.363,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 674,
      "chainage": 1700,
      "zlevel": 13.732,
      "radius": 1294.079,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 675,
      "chainage": 1720,
      "zlevel": 14.152,
      "radius": 736.855,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 676,
      "chainage": 1740,
      "zlevel": 14.572,
      "radius": 515.069,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 677,
      "chainage": 1742,
      "zlevel": 14.614,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 678,
      "chainage": 1760,
      "zlevel": 14.992,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 679,
      "chainage": 1780,
      "zlevel": 15.412,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 680,
      "chainage": 1800,
      "zlevel": 15.832,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 681,
      "chainage": 1820,
      "zlevel": 16.252,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 682,
      "chainage": 1833,
      "zlevel": 16.528,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 683,
      "chainage": 1840,
      "zlevel": 16.672,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 684,
      "chainage": 1860,
      "zlevel": 17.092,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 685,
      "chainage": 1880,
      "zlevel": 17.512,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 686,
      "chainage": 1900,
      "zlevel": 17.932,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 687,
      "chainage": 1920,
      "zlevel": 18.352,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 688,
      "chainage": 1924,
      "zlevel": 18.442,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 689,
      "chainage": 1940,
      "zlevel": 18.772,
      "radius": 648.83,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 690,
      "chainage": 1960,
      "zlevel": 19.192,
      "radius": 1045.076,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 691,
      "chainage": 1980,
      "zlevel": 19.612,
      "radius": 2684.566,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 692,
      "chainage": 2000,
      "zlevel": 20.032,
      "radius": 7943.515,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 693,
      "chainage": 2017,
      "zlevel": 20.399,
      "radius": 2328.671,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 694,
      "chainage": 2020,
      "zlevel": 20.452,
      "radius": 2113.669,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 695,
      "chainage": 2034,
      "zlevel": 20.722,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 696,
      "chainage": 2040,
      "zlevel": 20.821,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 697,
      "chainage": 2060,
      "zlevel": 21.111,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 698,
      "chainage": 2080,
      "zlevel": 21.321,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 699,
      "chainage": 2100,
      "zlevel": 21.451,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 700,
      "chainage": 2120,
      "zlevel": 21.501,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 701,
      "chainage": 2122,
      "zlevel": 21.501,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 702,
      "chainage": 2140,
      "zlevel": 21.471,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 703,
      "chainage": 2140,
      "zlevel": 21.471,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 704,
      "chainage": 2160,
      "zlevel": 21.361,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 705,
      "chainage": 2180,
      "zlevel": 21.17,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 706,
      "chainage": 2200,
      "zlevel": 20.9,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 707,
      "chainage": 2220,
      "zlevel": 20.552,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 708,
      "chainage": 2220,
      "zlevel": 20.55,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 709,
      "chainage": 2240,
      "zlevel": 20.12,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 710,
      "chainage": 2260,
      "zlevel": 19.609,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 711,
      "chainage": 2262,
      "zlevel": 19.543,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 712,
      "chainage": 2280,
      "zlevel": 19.05,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 713,
      "chainage": 2300,
      "zlevel": 18.49,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 714,
      "chainage": 2320,
      "zlevel": 17.93,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 715,
      "chainage": 2340,
      "zlevel": 17.37,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 716,
      "chainage": 2360,
      "zlevel": 16.81,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 717,
      "chainage": 2380,
      "zlevel": 16.25,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 718,
      "chainage": 2400,
      "zlevel": 15.69,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 719,
      "chainage": 2406,
      "zlevel": 15.532,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 720,
      "chainage": 2420,
      "zlevel": 15.13,
      "radius": 2127.496,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 721,
      "chainage": 2440,
      "zlevel": 14.57,
      "radius": 8142.395,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 722,
      "chainage": 2460,
      "zlevel": 14.01,
      "radius": 3094.559,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 723,
      "chainage": 2480,
      "zlevel": 13.45,
      "radius": 1214.849,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 724,
      "chainage": 2500,
      "zlevel": 12.89,
      "radius": 755.773,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 725,
      "chainage": 2507,
      "zlevel": 12.692,
      "radius": 666.788,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 726,
      "chainage": 2520,
      "zlevel": 12.34,
      "radius": 548.502,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 727,
      "chainage": 2529,
      "zlevel": 12.116,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 728,
      "chainage": 2540,
      "zlevel": 11.838,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 729,
      "chainage": 2560,
      "zlevel": 11.385,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 730,
      "chainage": 2580,
      "zlevel": 10.983,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 731,
      "chainage": 2600,
      "zlevel": 10.63,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 732,
      "chainage": 2604,
      "zlevel": 10.56,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 733,
      "chainage": 2620,
      "zlevel": 10.328,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 734,
      "chainage": 2640,
      "zlevel": 10.075,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 735,
      "chainage": 2659,
      "zlevel": 9.882,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 736,
      "chainage": 2660,
      "zlevel": 9.873,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 737,
      "chainage": 2680,
      "zlevel": 9.72,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 738,
      "chainage": 2680,
      "zlevel": 9.72,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 739,
      "chainage": 2700,
      "zlevel": 9.618,
      "radius": 600.941,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 740,
      "chainage": 2720,
      "zlevel": 9.566,
      "radius": 777.614,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 741,
      "chainage": 2731,
      "zlevel": 9.558,
      "radius": 927.205,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 742,
      "chainage": 2740,
      "zlevel": 9.563,
      "radius": 1101.427,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 743,
      "chainage": 2760,
      "zlevel": 9.611,
      "radius": 1887.357,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 744,
      "chainage": 2780,
      "zlevel": 9.708,
      "radius": 6588.939,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 745,
      "chainage": 2940,
      "zlevel": 11.248,
      "radius": 3627.347,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 746,
      "chainage": 2960,
      "zlevel": 11.448,
      "radius": 1605.379,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 747,
      "chainage": 2980,
      "zlevel": 11.648,
      "radius": 1030.792,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 748,
      "chainage": 2990,
      "zlevel": 11.751,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 749,
      "chainage": 3000,
      "zlevel": 11.848,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 750,
      "chainage": 3020,
      "zlevel": 12.048,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 751,
      "chainage": 3040,
      "zlevel": 12.248,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 752,
      "chainage": 3060,
      "zlevel": 12.448,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 753,
      "chainage": 3080,
      "zlevel": 12.648,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 754,
      "chainage": 3100,
      "zlevel": 12.848,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 755,
      "chainage": 3120,
      "zlevel": 13.047,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 756,
      "chainage": 3140,
      "zlevel": 13.247,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 757,
      "chainage": 3160,
      "zlevel": 13.447,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 758,
      "chainage": 3178,
      "zlevel": 13.632,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 759,
      "chainage": 3180,
      "zlevel": 13.647,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 760,
      "chainage": 3200,
      "zlevel": 13.847,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 761,
      "chainage": 3220,
      "zlevel": 14.047,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 762,
      "chainage": 3240,
      "zlevel": 14.247,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 763,
      "chainage": 3260,
      "zlevel": 14.447,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 764,
      "chainage": 3280,
      "zlevel": 14.647,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 765,
      "chainage": 3300,
      "zlevel": 14.847,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 766,
      "chainage": 3320,
      "zlevel": 15.047,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 767,
      "chainage": 3340,
      "zlevel": 15.247,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 768,
      "chainage": 3360,
      "zlevel": 15.447,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 769,
      "chainage": 3367,
      "zlevel": 15.513,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 770,
      "chainage": 3380,
      "zlevel": 15.647,
      "radius": 998.912,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 771,
      "chainage": 3400,
      "zlevel": 15.847,
      "radius": 1283.916,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 772,
      "chainage": 3420,
      "zlevel": 16.047,
      "radius": 1796.478,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 773,
      "chainage": 3440,
      "zlevel": 16.247,
      "radius": 2990.229,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 774,
      "chainage": 3460,
      "zlevel": 16.447,
      "radius": 8912.629,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 775,
      "chainage": 3840,
      "zlevel": 15.227,
      "radius": 7748.77,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 776,
      "chainage": 3860,
      "zlevel": 14.907,
      "radius": 1907.98,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 777,
      "chainage": 3880,
      "zlevel": 14.587,
      "radius": 1087.931,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 778,
      "chainage": 3897,
      "zlevel": 14.319,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 779,
      "chainage": 3900,
      "zlevel": 14.267,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 780,
      "chainage": 3920,
      "zlevel": 13.947,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 781,
      "chainage": 3940,
      "zlevel": 13.627,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 782,
      "chainage": 3960,
      "zlevel": 13.307,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 783,
      "chainage": 3980,
      "zlevel": 12.987,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 784,
      "chainage": 3983,
      "zlevel": 12.935,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 785,
      "chainage": 4000,
      "zlevel": 12.667,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 786,
      "chainage": 4020,
      "zlevel": 12.347,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 787,
      "chainage": 4040,
      "zlevel": 12.027,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 788,
      "chainage": 4060,
      "zlevel": 11.707,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 789,
      "chainage": 4070,
      "zlevel": 11.552,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 790,
      "chainage": 4080,
      "zlevel": 11.387,
      "radius": 956.509,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 791,
      "chainage": 4100,
      "zlevel": 11.067,
      "radius": 1537.5,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 792,
      "chainage": 4120,
      "zlevel": 10.747,
      "radius": 3916.276,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 793,
      "chainage": 4140,
      "zlevel": 10.427,
      "radius": 8143.435,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 794,
      "chainage": 4160,
      "zlevel": 10.107,
      "radius": 2127.566,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 795,
      "chainage": 4180,
      "zlevel": 9.787,
      "radius": 1223.626,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 796,
      "chainage": 4200,
      "zlevel": 9.467,
      "radius": 858.763,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 797,
      "chainage": 4220,
      "zlevel": 9.147,
      "radius": 661.512,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 798,
      "chainage": 4222,
      "zlevel": 9.111,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 799,
      "chainage": 4240,
      "zlevel": 8.827,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 800,
      "chainage": 4260,
      "zlevel": 8.507,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 801,
      "chainage": 4280,
      "zlevel": 8.187,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 802,
      "chainage": 4300,
      "zlevel": 7.867,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 803,
      "chainage": 4306,
      "zlevel": 7.768,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 804,
      "chainage": 4320,
      "zlevel": 7.563,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 805,
      "chainage": 4340,
      "zlevel": 7.322,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 806,
      "chainage": 4360,
      "zlevel": 7.148,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 807,
      "chainage": 4380,
      "zlevel": 7.041,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 808,
      "chainage": 4400,
      "zlevel": 7.001,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 809,
      "chainage": 4402,
      "zlevel": 7,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 810,
      "chainage": 4420,
      "zlevel": 7.027,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 811,
      "chainage": 4423,
      "zlevel": 7.037,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 812,
      "chainage": 4440,
      "zlevel": 7.12,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 813,
      "chainage": 4460,
      "zlevel": 7.28,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 814,
      "chainage": 4480,
      "zlevel": 7.506,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 815,
      "chainage": 4500,
      "zlevel": 7.799,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 816,
      "chainage": 4520,
      "zlevel": 8.158,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 817,
      "chainage": 4540,
      "zlevel": 8.585,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 818,
      "chainage": 4540,
      "zlevel": 8.587,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 819,
      "chainage": 4560,
      "zlevel": 9.045,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 820,
      "chainage": 4566,
      "zlevel": 9.191,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 821,
      "chainage": 4580,
      "zlevel": 9.505,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 822,
      "chainage": 4600,
      "zlevel": 9.965,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 823,
      "chainage": 4620,
      "zlevel": 10.425,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 824,
      "chainage": 4640,
      "zlevel": 10.885,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 825,
      "chainage": 4660,
      "zlevel": 11.345,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 826,
      "chainage": 4680,
      "zlevel": 11.805,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 827,
      "chainage": 4700,
      "zlevel": 12.265,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 828,
      "chainage": 4720,
      "zlevel": 12.725,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 829,
      "chainage": 4740,
      "zlevel": 13.185,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 830,
      "chainage": 4760,
      "zlevel": 13.645,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 831,
      "chainage": 4780,
      "zlevel": 14.105,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 832,
      "chainage": 4800,
      "zlevel": 14.565,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 833,
      "chainage": 4820,
      "zlevel": 15.025,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 834,
      "chainage": 4840,
      "zlevel": 15.485,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 835,
      "chainage": 4860,
      "zlevel": 15.945,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 836,
      "chainage": 4880,
      "zlevel": 16.405,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 837,
      "chainage": 4900,
      "zlevel": 16.865,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 838,
      "chainage": 4910,
      "zlevel": 17.105,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 839,
      "chainage": 4920,
      "zlevel": 17.325,
      "radius": 722.061,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 840,
      "chainage": 4940,
      "zlevel": 17.785,
      "radius": 963.667,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 841,
      "chainage": 4960,
      "zlevel": 18.245,
      "radius": 1448.266,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 842,
      "chainage": 4980,
      "zlevel": 18.705,
      "radius": 2913.256,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 843,
      "chainage": 5440,
      "zlevel": 36.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 844,
      "chainage": 5460,
      "zlevel": 37.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 845,
      "chainage": 5480,
      "zlevel": 38.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 846,
      "chainage": 5491,
      "zlevel": 39.019,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 847,
      "chainage": 5500,
      "zlevel": 39.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 848,
      "chainage": 5520,
      "zlevel": 40.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 849,
      "chainage": 5540,
      "zlevel": 41.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 850,
      "chainage": 5548,
      "zlevel": 41.898,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 851,
      "chainage": 5560,
      "zlevel": 42.48,
      "radius": 827.634,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 852,
      "chainage": 5580,
      "zlevel": 43.48,
      "radius": 1204.542,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 853,
      "chainage": 5600,
      "zlevel": 44.48,
      "radius": 2211.805,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 854,
      "chainage": 5620,
      "zlevel": 45.48,
      "radius": 13504.82,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 855,
      "chainage": 6600,
      "zlevel": 43.739,
      "radius": 2921.033,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 856,
      "chainage": 6620,
      "zlevel": 42.739,
      "radius": 1450.186,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 857,
      "chainage": 6640,
      "zlevel": 41.739,
      "radius": 964.516,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 858,
      "chainage": 6648,
      "zlevel": 41.337,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 859,
      "chainage": 6660,
      "zlevel": 40.74,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 860,
      "chainage": 6680,
      "zlevel": 39.74,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 861,
      "chainage": 6700,
      "zlevel": 38.74,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 862,
      "chainage": 6720,
      "zlevel": 37.74,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 863,
      "chainage": 6728,
      "zlevel": 37.36,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 864,
      "chainage": 6740,
      "zlevel": 36.752,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 865,
      "chainage": 6760,
      "zlevel": 35.816,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 866,
      "chainage": 6776,
      "zlevel": 35.126,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 867,
      "chainage": 6780,
      "zlevel": 34.938,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 868,
      "chainage": 6800,
      "zlevel": 34.117,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 869,
      "chainage": 6820,
      "zlevel": 33.353,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 870,
      "chainage": 6840,
      "zlevel": 32.647,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 871,
      "chainage": 6850,
      "zlevel": 32.323,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 872,
      "chainage": 6860,
      "zlevel": 31.998,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 873,
      "chainage": 6880,
      "zlevel": 31.406,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 874,
      "chainage": 6900,
      "zlevel": 30.871,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 875,
      "chainage": 6903,
      "zlevel": 30.793,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 876,
      "chainage": 6920,
      "zlevel": 30.394,
      "radius": 1165.745,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 877,
      "chainage": 6940,
      "zlevel": 29.974,
      "radius": 2084.425,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 878,
      "chainage": 6960,
      "zlevel": 29.61,
      "radius": 9835.083,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 879,
      "chainage": 6972,
      "zlevel": 29.419,
      "radius": 8588.7,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 880,
      "chainage": 6980,
      "zlevel": 29.3,
      "radius": 3939.459,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 881,
      "chainage": 7000,
      "zlevel": 29,
      "radius": 1663.716,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 882,
      "chainage": 7020,
      "zlevel": 28.7,
      "radius": 1054.534,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 883,
      "chainage": 7026,
      "zlevel": 28.61,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 884,
      "chainage": 7040,
      "zlevel": 28.4,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 885,
      "chainage": 7060,
      "zlevel": 28.1,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 886,
      "chainage": 7080,
      "zlevel": 27.8,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 887,
      "chainage": 7100,
      "zlevel": 27.5,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 888,
      "chainage": 7120,
      "zlevel": 27.2,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 889,
      "chainage": 7140,
      "zlevel": 26.9,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 890,
      "chainage": 7160,
      "zlevel": 26.6,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 891,
      "chainage": 7180,
      "zlevel": 26.3,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 892,
      "chainage": 7200,
      "zlevel": 26,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 893,
      "chainage": 7220,
      "zlevel": 25.7,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 894,
      "chainage": 7240,
      "zlevel": 25.4,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 895,
      "chainage": 7260,
      "zlevel": 25.1,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 896,
      "chainage": 7280,
      "zlevel": 24.8,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 897,
      "chainage": 7300,
      "zlevel": 24.5,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 898,
      "chainage": 7320,
      "zlevel": 24.2,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 899,
      "chainage": 7332,
      "zlevel": 24.017,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 900,
      "chainage": 7340,
      "zlevel": 23.9,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 901,
      "chainage": 7360,
      "zlevel": 23.6,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 902,
      "chainage": 7380,
      "zlevel": 23.3,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 903,
      "chainage": 7400,
      "zlevel": 23,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 904,
      "chainage": 7420,
      "zlevel": 22.7,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 905,
      "chainage": 7440,
      "zlevel": 22.4,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 906,
      "chainage": 7447,
      "zlevel": 22.301,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 907,
      "chainage": 7460,
      "zlevel": 22.111,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 908,
      "chainage": 7480,
      "zlevel": 21.87,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 909,
      "chainage": 7500,
      "zlevel": 21.678,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 910,
      "chainage": 7520,
      "zlevel": 21.537,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 911,
      "chainage": 7540,
      "zlevel": 21.445,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 912,
      "chainage": 7555,
      "zlevel": 21.41,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 913,
      "chainage": 7560,
      "zlevel": 21.404,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 914,
      "chainage": 7567,
      "zlevel": 21.401,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 915,
      "chainage": 7580,
      "zlevel": 21.413,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 916,
      "chainage": 7600,
      "zlevel": 21.471,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 917,
      "chainage": 7620,
      "zlevel": 21.58,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 918,
      "chainage": 7638,
      "zlevel": 21.723,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 919,
      "chainage": 7640,
      "zlevel": 21.738,
      "radius": 974.843,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 920,
      "chainage": 7660,
      "zlevel": 21.947,
      "radius": 1416.819,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 921,
      "chainage": 7663,
      "zlevel": 21.977,
      "radius": 1504.533,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 922,
      "chainage": 7680,
      "zlevel": 22.186,
      "radius": 2591.973,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 923,
      "chainage": 7700,
      "zlevel": 22.426,
      "radius": 15196.068,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 924,
      "chainage": 8031,
      "zlevel": 27.205,
      "radius": 7415.366,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 925,
      "chainage": 8040,
      "zlevel": 27.505,
      "radius": 3358.169,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 926,
      "chainage": 8060,
      "zlevel": 28.212,
      "radius": 1550.379,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 927,
      "chainage": 8080,
      "zlevel": 29.007,
      "radius": 1007.835,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 928,
      "chainage": 8091,
      "zlevel": 29.465,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 929,
      "chainage": 8100,
      "zlevel": 29.891,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 930,
      "chainage": 8116,
      "zlevel": 30.659,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 931,
      "chainage": 8120,
      "zlevel": 30.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 932,
      "chainage": 8140,
      "zlevel": 31.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 933,
      "chainage": 8160,
      "zlevel": 32.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 934,
      "chainage": 8180,
      "zlevel": 33.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 935,
      "chainage": 8200,
      "zlevel": 34.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 936,
      "chainage": 8220,
      "zlevel": 35.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 937,
      "chainage": 8240,
      "zlevel": 36.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 938,
      "chainage": 8260,
      "zlevel": 37.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 939,
      "chainage": 8280,
      "zlevel": 38.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 940,
      "chainage": 8300,
      "zlevel": 39.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 941,
      "chainage": 8320,
      "zlevel": 40.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 942,
      "chainage": 8321,
      "zlevel": 40.935,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 943,
      "chainage": 8340,
      "zlevel": 41.836,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 944,
      "chainage": 8360,
      "zlevel": 42.748,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 945,
      "chainage": 8380,
      "zlevel": 43.598,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 946,
      "chainage": 8400,
      "zlevel": 44.387,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 947,
      "chainage": 8420,
      "zlevel": 45.114,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 948,
      "chainage": 8440,
      "zlevel": 45.779,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 949,
      "chainage": 8460,
      "zlevel": 46.382,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 950,
      "chainage": 8480,
      "zlevel": 46.924,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 951,
      "chainage": 8494,
      "zlevel": 47.261,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 952,
      "chainage": 8500,
      "zlevel": 47.404,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 953,
      "chainage": 8520,
      "zlevel": 47.823,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 954,
      "chainage": 8531,
      "zlevel": 48.028,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 955,
      "chainage": 8540,
      "zlevel": 48.18,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 956,
      "chainage": 8560,
      "zlevel": 48.476,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 957,
      "chainage": 8580,
      "zlevel": 48.71,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 958,
      "chainage": 8600,
      "zlevel": 48.882,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 959,
      "chainage": 8620,
      "zlevel": 48.993,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 960,
      "chainage": 8640,
      "zlevel": 49.042,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 961,
      "chainage": 8646,
      "zlevel": 49.045,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 962,
      "chainage": 8660,
      "zlevel": 49.03,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 963,
      "chainage": 8680,
      "zlevel": 48.956,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 964,
      "chainage": 8700,
      "zlevel": 48.821,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 965,
      "chainage": 8720,
      "zlevel": 48.624,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 966,
      "chainage": 8740,
      "zlevel": 48.366,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 967,
      "chainage": 8741,
      "zlevel": 48.352,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 968,
      "chainage": 8760,
      "zlevel": 48.074,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 969,
      "chainage": 8780,
      "zlevel": 47.782,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 970,
      "chainage": 8800,
      "zlevel": 47.49,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 971,
      "chainage": 8820,
      "zlevel": 47.198,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 972,
      "chainage": 8840,
      "zlevel": 46.906,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 973,
      "chainage": 8860,
      "zlevel": 46.614,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 974,
      "chainage": 8880,
      "zlevel": 46.322,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 975,
      "chainage": 8897,
      "zlevel": 46.076,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 976,
      "chainage": 8900,
      "zlevel": 46.03,
      "radius": 877.742,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 977,
      "chainage": 8920,
      "zlevel": 45.738,
      "radius": 1109.293,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 978,
      "chainage": 8940,
      "zlevel": 45.446,
      "radius": 1506.788,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 979,
      "chainage": 8960,
      "zlevel": 45.154,
      "radius": 2348.238,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 980,
      "chainage": 8980,
      "zlevel": 44.862,
      "radius": 5318.045,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 981,
      "chainage": 9000,
      "zlevel": 44.57,
      "radius": 8624.157,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 982,
      "chainage": 9020,
      "zlevel": 44.278,
      "radius": 1492.604,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 983,
      "chainage": 9040,
      "zlevel": 43.986,
      "radius": 817.002,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 984,
      "chainage": 9046,
      "zlevel": 43.903,
      "radius": 723.376,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 985,
      "chainage": 9060,
      "zlevel": 43.738,
      "radius": 562.429,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 986,
      "chainage": 9079,
      "zlevel": 43.657,
      "radius": 432.432,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 987,
      "chainage": 9080,
      "zlevel": 43.658,
      "radius": 428.813,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 988,
      "chainage": 9080,
      "zlevel": 43.658,
      "radius": 428.302,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 989,
      "chainage": 9100,
      "zlevel": 43.751,
      "radius": 346.496,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 990,
      "chainage": 9103,
      "zlevel": 43.779,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 991,
      "chainage": 9114,
      "zlevel": 43.927,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 992,
      "chainage": 9120,
      "zlevel": 44.011,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 993,
      "chainage": 9140,
      "zlevel": 44.317,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 994,
      "chainage": 9160,
      "zlevel": 44.623,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 995,
      "chainage": 9180,
      "zlevel": 44.929,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 996,
      "chainage": 9200,
      "zlevel": 45.235,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 997,
      "chainage": 9220,
      "zlevel": 45.541,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 998,
      "chainage": 9240,
      "zlevel": 45.847,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 999,
      "chainage": 9242,
      "zlevel": 45.875,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1000,
      "chainage": 9260,
      "zlevel": 46.153,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1001,
      "chainage": 9280,
      "zlevel": 46.459,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1002,
      "chainage": 9300,
      "zlevel": 46.765,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1003,
      "chainage": 9312,
      "zlevel": 46.947,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1004,
      "chainage": 9320,
      "zlevel": 47.063,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1005,
      "chainage": 9340,
      "zlevel": 47.278,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1006,
      "chainage": 9360,
      "zlevel": 47.394,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1007,
      "chainage": 9373,
      "zlevel": 47.415,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1008,
      "chainage": 9380,
      "zlevel": 47.409,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1009,
      "chainage": 9381,
      "zlevel": 47.408,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1010,
      "chainage": 9400,
      "zlevel": 47.324,
      "radius": 421.504,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1011,
      "chainage": 9420,
      "zlevel": 47.14,
      "radius": 569.744,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1012,
      "chainage": 9440,
      "zlevel": 46.855,
      "radius": 878.819,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1013,
      "chainage": 9440,
      "zlevel": 46.848,
      "radius": 889.525,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1014,
      "chainage": 9460,
      "zlevel": 46.471,
      "radius": 1920.836,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1015,
      "chainage": 188,
      "zlevel": 40.936,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1016,
      "chainage": 200,
      "zlevel": 40.9,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1017,
      "chainage": 220,
      "zlevel": 40.84,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1018,
      "chainage": 240,
      "zlevel": 40.78,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1019,
      "chainage": 260,
      "zlevel": 40.72,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1020,
      "chainage": 265,
      "zlevel": 40.704,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1021,
      "chainage": 741,
      "zlevel": 30.969,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1022,
      "chainage": 760,
      "zlevel": 30.417,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1023,
      "chainage": 780,
      "zlevel": 29.847,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1024,
      "chainage": 800,
      "zlevel": 29.277,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1025,
      "chainage": 808,
      "zlevel": 29.043,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1026,
      "chainage": 1674,
      "zlevel": 13.177,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1027,
      "chainage": 1993,
      "zlevel": 19.88,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1028,
      "chainage": 2447,
      "zlevel": 14.372,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1029,
      "chainage": 2788,
      "zlevel": 9.762,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1030,
      "chainage": 2800,
      "zlevel": 9.856,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1031,
      "chainage": 2811,
      "zlevel": 9.958,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1032,
      "chainage": 2820,
      "zlevel": 10.048,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1033,
      "chainage": 2840,
      "zlevel": 10.248,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1034,
      "chainage": 2860,
      "zlevel": 10.448,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1035,
      "chainage": 2880,
      "zlevel": 10.648,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1036,
      "chainage": 2900,
      "zlevel": 10.848,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1037,
      "chainage": 2920,
      "zlevel": 11.048,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1038,
      "chainage": 2924,
      "zlevel": 11.089,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1039,
      "chainage": 3470,
      "zlevel": 16.548,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1040,
      "chainage": 3480,
      "zlevel": 16.647,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1041,
      "chainage": 3500,
      "zlevel": 16.846,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1042,
      "chainage": 3520,
      "zlevel": 17.046,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1043,
      "chainage": 3540,
      "zlevel": 17.246,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1044,
      "chainage": 3560,
      "zlevel": 17.446,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1045,
      "chainage": 3575,
      "zlevel": 17.601,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1046,
      "chainage": 3580,
      "zlevel": 17.644,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1047,
      "chainage": 3600,
      "zlevel": 17.791,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1048,
      "chainage": 3620,
      "zlevel": 17.866,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1049,
      "chainage": 3630,
      "zlevel": 17.876,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1050,
      "chainage": 3640,
      "zlevel": 17.867,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1051,
      "chainage": 3647,
      "zlevel": 17.851,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1052,
      "chainage": 3660,
      "zlevel": 17.796,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1053,
      "chainage": 3680,
      "zlevel": 17.652,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1054,
      "chainage": 3700,
      "zlevel": 17.436,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1055,
      "chainage": 3718,
      "zlevel": 17.172,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1056,
      "chainage": 3720,
      "zlevel": 17.147,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1057,
      "chainage": 3740,
      "zlevel": 16.827,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1058,
      "chainage": 3760,
      "zlevel": 16.507,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1059,
      "chainage": 3780,
      "zlevel": 16.187,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1060,
      "chainage": 3800,
      "zlevel": 15.867,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1061,
      "chainage": 3820,
      "zlevel": 15.547,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1062,
      "chainage": 3833,
      "zlevel": 15.331,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1063,
      "chainage": 4133,
      "zlevel": 10.54,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1064,
      "chainage": 5000,
      "zlevel": 19.159,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1065,
      "chainage": 5000,
      "zlevel": 19.165,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1066,
      "chainage": 5020,
      "zlevel": 19.625,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1067,
      "chainage": 5040,
      "zlevel": 20.085,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1068,
      "chainage": 5060,
      "zlevel": 20.545,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1069,
      "chainage": 5080,
      "zlevel": 21.005,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1070,
      "chainage": 5100,
      "zlevel": 21.465,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1071,
      "chainage": 5106,
      "zlevel": 21.605,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1072,
      "chainage": 5120,
      "zlevel": 21.944,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1073,
      "chainage": 5140,
      "zlevel": 22.5,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1074,
      "chainage": 5160,
      "zlevel": 23.136,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1075,
      "chainage": 5173,
      "zlevel": 23.61,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1076,
      "chainage": 5180,
      "zlevel": 23.851,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1077,
      "chainage": 5200,
      "zlevel": 24.648,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1078,
      "chainage": 5220,
      "zlevel": 25.524,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1079,
      "chainage": 5240,
      "zlevel": 26.481,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1080,
      "chainage": 5241,
      "zlevel": 26.521,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1081,
      "chainage": 5260,
      "zlevel": 27.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1082,
      "chainage": 5280,
      "zlevel": 28.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1083,
      "chainage": 5300,
      "zlevel": 29.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1084,
      "chainage": 5320,
      "zlevel": 30.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1085,
      "chainage": 5340,
      "zlevel": 31.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1086,
      "chainage": 5360,
      "zlevel": 32.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1087,
      "chainage": 5380,
      "zlevel": 33.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1088,
      "chainage": 5400,
      "zlevel": 34.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1089,
      "chainage": 5420,
      "zlevel": 35.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1090,
      "chainage": 5433,
      "zlevel": 36.139,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1091,
      "chainage": 5624,
      "zlevel": 45.676,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1092,
      "chainage": 5640,
      "zlevel": 46.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1093,
      "chainage": 5660,
      "zlevel": 47.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1094,
      "chainage": 5680,
      "zlevel": 48.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1095,
      "chainage": 5700,
      "zlevel": 49.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1096,
      "chainage": 5720,
      "zlevel": 50.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1097,
      "chainage": 5740,
      "zlevel": 51.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1098,
      "chainage": 5743,
      "zlevel": 51.63,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1099,
      "chainage": 5760,
      "zlevel": 52.46,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1100,
      "chainage": 5780,
      "zlevel": 53.382,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1101,
      "chainage": 5800,
      "zlevel": 54.248,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1102,
      "chainage": 5820,
      "zlevel": 55.056,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1103,
      "chainage": 5840,
      "zlevel": 55.806,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1104,
      "chainage": 5860,
      "zlevel": 56.5,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1105,
      "chainage": 5880,
      "zlevel": 57.136,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1106,
      "chainage": 5900,
      "zlevel": 57.715,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1107,
      "chainage": 5920,
      "zlevel": 58.237,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1108,
      "chainage": 5940,
      "zlevel": 58.701,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1109,
      "chainage": 5960,
      "zlevel": 59.109,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1110,
      "chainage": 5980,
      "zlevel": 59.459,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1111,
      "chainage": 6000,
      "zlevel": 59.752,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1112,
      "chainage": 6020,
      "zlevel": 59.988,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1113,
      "chainage": 6040,
      "zlevel": 60.167,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1114,
      "chainage": 6060,
      "zlevel": 60.288,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1115,
      "chainage": 6080,
      "zlevel": 60.353,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1116,
      "chainage": 6093,
      "zlevel": 60.364,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1117,
      "chainage": 6093,
      "zlevel": 60.364,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1118,
      "chainage": 6100,
      "zlevel": 60.36,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1119,
      "chainage": 6120,
      "zlevel": 60.31,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1120,
      "chainage": 6140,
      "zlevel": 60.203,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1121,
      "chainage": 6160,
      "zlevel": 60.039,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1122,
      "chainage": 6180,
      "zlevel": 59.818,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1123,
      "chainage": 6200,
      "zlevel": 59.54,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1124,
      "chainage": 6220,
      "zlevel": 59.204,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1125,
      "chainage": 6240,
      "zlevel": 58.811,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1126,
      "chainage": 6260,
      "zlevel": 58.361,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1127,
      "chainage": 6280,
      "zlevel": 57.854,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1128,
      "chainage": 6300,
      "zlevel": 57.29,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1129,
      "chainage": 6320,
      "zlevel": 56.668,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1130,
      "chainage": 6340,
      "zlevel": 55.989,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1131,
      "chainage": 6360,
      "zlevel": 55.253,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1132,
      "chainage": 6380,
      "zlevel": 54.46,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1133,
      "chainage": 6400,
      "zlevel": 53.61,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1134,
      "chainage": 6420,
      "zlevel": 52.702,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1135,
      "chainage": 6440,
      "zlevel": 51.736,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1136,
      "chainage": 6442,
      "zlevel": 51.635,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1137,
      "chainage": 6460,
      "zlevel": 50.737,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1138,
      "chainage": 6480,
      "zlevel": 49.737,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1139,
      "chainage": 6500,
      "zlevel": 48.738,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1140,
      "chainage": 6520,
      "zlevel": 47.738,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1141,
      "chainage": 6540,
      "zlevel": 46.738,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1142,
      "chainage": 6560,
      "zlevel": 45.738,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1143,
      "chainage": 6580,
      "zlevel": 44.739,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1144,
      "chainage": 6580,
      "zlevel": 44.725,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1145,
      "chainage": 6965,
      "zlevel": 29.523,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1146,
      "chainage": 7704,
      "zlevel": 22.476,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1147,
      "chainage": 7720,
      "zlevel": 22.666,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1148,
      "chainage": 7740,
      "zlevel": 22.906,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1149,
      "chainage": 7760,
      "zlevel": 23.146,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1150,
      "chainage": 7780,
      "zlevel": 23.386,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1151,
      "chainage": 7800,
      "zlevel": 23.626,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1152,
      "chainage": 7820,
      "zlevel": 23.866,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1153,
      "chainage": 7840,
      "zlevel": 24.106,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1154,
      "chainage": 7860,
      "zlevel": 24.346,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1155,
      "chainage": 7880,
      "zlevel": 24.586,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1156,
      "chainage": 7900,
      "zlevel": 24.826,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1157,
      "chainage": 7920,
      "zlevel": 25.066,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1158,
      "chainage": 7940,
      "zlevel": 25.306,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1159,
      "chainage": 7945,
      "zlevel": 25.369,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1160,
      "chainage": 7960,
      "zlevel": 25.571,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1161,
      "chainage": 7980,
      "zlevel": 25.921,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1162,
      "chainage": 8000,
      "zlevel": 26.36,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1163,
      "chainage": 8020,
      "zlevel": 26.888,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1164,
      "chainage": 8023,
      "zlevel": 26.971,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1165,
      "chainage": 8996,
      "zlevel": 44.631,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1166,
      "chainage": 9477,
      "zlevel": 46.068,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1167,
      "chainage": 9480,
      "zlevel": 45.986,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1168,
      "chainage": 9500,
      "zlevel": 45.401,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1169,
      "chainage": 9520,
      "zlevel": 44.716,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1170,
      "chainage": 9540,
      "zlevel": 43.931,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1171,
      "chainage": 9560,
      "zlevel": 43.046,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1172,
      "chainage": 9569,
      "zlevel": 42.621,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1173,
      "chainage": 9580,
      "zlevel": 42.075,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1174,
      "chainage": 9600,
      "zlevel": 41.103,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 589,
      "chainage": 129,
      "zlevel": 41.113,
      "radius": 95.3,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 588,
      "chainage": 120,
      "zlevel": 41.14,
      "radius": 95.3,
      "segment_order_no": 1,
      "a_intersection": 0
    }
  ]
}

export const requestBody = {
  "return_option": 1,
  "start_year": 2020,
  "end_year": 2041,
  "fuel_distribution_cars": {
    "year": [
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
      "2051",
      "2052",
      "2053",
      "2054",
      "2055",
      "2056",
      "2057",
      "2058",
      "2059",
      "2060",
      "2061",
      "2062",
      "2063",
      "2064",
      "2065",
      "2066",
      "2067",
      "2068",
      "2069",
      "2070",
      "2071",
      "2072",
      "2073",
      "2074",
      "2075",
      "2076",
      "2077",
      "2078",
      "2079",
      "2080"
    ],
    "prc_gasoline": [
      "0.47769230799999995",
      "0.470923077",
      "0.46415384600000004",
      "0.457384615",
      "0.450615385",
      "0.443846154",
      "0.43707692299999995",
      "0.430307692",
      "0.423538462",
      "0.416769231",
      "0.41",
      "0.4",
      "0.39",
      "0.38",
      "0.37",
      "0.36",
      "0.35",
      "0.34",
      "0.33",
      "0.32",
      "0.31",
      "0.299134227",
      "0.287647059",
      "0.275502959",
      "0.26266436",
      "0.249091543",
      "0.23474252",
      "0.21957290100000001",
      "0.20353575699999998",
      "0.186581477",
      "0.168657611",
      "0.14970871",
      "0.12967615400000002",
      "0.10849797",
      "0.086108642",
      "0.062438908",
      "0.037415542",
      "0.010961132",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0"
    ],
    "prc_diesel": [
      "0.47769230799999995",
      "0.470923077",
      "0.46415384600000004",
      "0.457384615",
      "0.450615385",
      "0.443846154",
      "0.43707692299999995",
      "0.430307692",
      "0.423538462",
      "0.416769231",
      "0.41",
      "0.4",
      "0.39",
      "0.38",
      "0.37",
      "0.36",
      "0.35",
      "0.34",
      "0.33",
      "0.32",
      "0.31",
      "0.299134227",
      "0.287647059",
      "0.275502959",
      "0.26266436",
      "0.249091543",
      "0.23474252",
      "0.21957290100000001",
      "0.20353575699999998",
      "0.186581477",
      "0.168657611",
      "0.14970871",
      "0.12967615400000002",
      "0.10849797",
      "0.086108642",
      "0.062438908",
      "0.037415542",
      "0.010961132",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0"
    ],
    "prc_inchargeable_hybrid": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    "prc_chargeable_hybrid": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    "prc_battery": [
      "0.044615385",
      "0.058153845999999995",
      "0.071692308",
      "0.085230769",
      "0.098769231",
      "0.11230769199999999",
      "0.12584615400000002",
      "0.139384615",
      "0.152923077",
      "0.16646153800000002",
      "0.18",
      "0.2",
      "0.22",
      "0.24",
      "0.26",
      "0.28",
      "0.3",
      "0.32",
      "0.34",
      "0.36",
      "0.38",
      "0.401731546",
      "0.42470588200000003",
      "0.448994081",
      "0.47467128",
      "0.501816914",
      "0.53051496",
      "0.5608541979999999",
      "0.592928485",
      "0.626837045",
      "0.6626847770000001",
      "0.70058258",
      "0.740647692",
      "0.78300406",
      "0.827782715",
      "0.875122185",
      "0.925168917",
      "0.9780777359999999",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0",
      "1.0"
    ],
    "prc_hydrogen": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ]
  },
  "fuel_distribution_trucks": {
    "year": [
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
      "2051",
      "2052",
      "2053",
      "2054",
      "2055",
      "2056",
      "2057",
      "2058",
      "2059",
      "2060",
      "2061",
      "2062",
      "2063",
      "2064",
      "2065",
      "2066",
      "2067",
      "2068",
      "2069",
      "2070",
      "2071",
      "2072",
      "2073",
      "2074",
      "2075",
      "2076",
      "2077",
      "2078",
      "2079",
      "2080"
    ],
    "prc_gasoline": [
      "0.389615385",
      "0.386153846",
      "0.382692308",
      "0.37923076899999997",
      "0.37576923100000004",
      "0.372307692",
      "0.368846154",
      "0.365384615",
      "0.36192307700000004",
      "0.35846153799999997",
      "0.355",
      "0.3515",
      "0.348",
      "0.3445",
      "0.341",
      "0.3375",
      "0.33399999999999996",
      "0.3305",
      "0.327",
      "0.3235",
      "0.32",
      "0.316083613",
      "0.311860465",
      "0.307306527",
      "0.30239589",
      "0.297100613",
      "0.291390569",
      "0.285233271",
      "0.278593685",
      "0.271434036",
      "0.263713588",
      "0.255388414",
      "0.246411148",
      "0.23673071399999998",
      "0.226292033",
      "0.215035714",
      "0.202897713",
      "0.18980897",
      "0.175695015",
      "0.160475546",
      "0.14406397099999999",
      "0.126366914",
      "0.10728368699999999",
      "0.086705714",
      "0.064515915",
      "0.040588039",
      "0.014785948",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0"
    ],
    "prc_diesel": [
      "0.389615385",
      "0.386153846",
      "0.382692308",
      "0.37923076899999997",
      "0.37576923100000004",
      "0.372307692",
      "0.368846154",
      "0.365384615",
      "0.36192307700000004",
      "0.35846153799999997",
      "0.355",
      "0.3515",
      "0.348",
      "0.3445",
      "0.341",
      "0.3375",
      "0.33399999999999996",
      "0.3305",
      "0.327",
      "0.3235",
      "0.32",
      "0.316083613",
      "0.311860465",
      "0.307306527",
      "0.30239589",
      "0.297100613",
      "0.291390569",
      "0.285233271",
      "0.278593685",
      "0.271434036",
      "0.263713588",
      "0.255388414",
      "0.246411148",
      "0.23673071399999998",
      "0.226292033",
      "0.215035714",
      "0.202897713",
      "0.18980897",
      "0.175695015",
      "0.160475546",
      "0.14406397099999999",
      "0.126366914",
      "0.10728368699999999",
      "0.086705714",
      "0.064515915",
      "0.040588039",
      "0.014785948",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0"
    ],
    "prc_inchargeable_hybrid": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    "prc_chargeable_hybrid": [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    ],
    "prc_battery": [
      "0.006923077",
      "0.009230769",
      "0.011538462",
      "0.013846154",
      "0.016153846",
      "0.018461538",
      "0.020769231000000003",
      "0.023076923",
      "0.025384615",
      "0.027692308",
      "0.03",
      "0.037000000000000005",
      "0.044000000000000004",
      "0.051",
      "0.057999999999999996",
      "0.065",
      "0.07200000000000001",
      "0.079",
      "0.086",
      "0.09300000000000001",
      "0.1",
      "0.107832773",
      "0.11627907",
      "0.125386946",
      "0.135208221",
      "0.145798774",
      "0.157218861",
      "0.169533458",
      "0.18281262899999998",
      "0.197131928",
      "0.212572825",
      "0.229223172",
      "0.247177703",
      "0.266538572",
      "0.287415934",
      "0.309928572",
      "0.334204574",
      "0.36038206100000003",
      "0.38860997",
      "0.419048908",
      "0.45187205799999997",
      "0.48726617200000005",
      "0.5254326260000001",
      "0.566588572",
      "0.61096817",
      "0.658823921",
      "0.710428105",
      "0.7466070509999999",
      "0.760606775",
      "0.7740670909999999",
      "0.786982535",
      "0.79935105",
      "0.811173719",
      "0.8224544979999999",
      "0.833199925",
      "0.8434188290000001",
      "0.85312204",
      "0.8623221059999999",
      "0.871033017",
      "0.8792699509999999",
      "0.887049022"
    ],
    "prc_hydrogen": [
      "0.21384615399999998",
      "0.218461538",
      "0.223076923",
      "0.227692308",
      "0.23230769199999998",
      "0.23692307699999998",
      "0.24153846199999998",
      "0.246153846",
      "0.25076923100000004",
      "0.255384615",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.26",
      "0.253392949",
      "0.239393225",
      "0.225932909",
      "0.213017465",
      "0.20064895",
      "0.188826281",
      "0.177545502",
      "0.166800075",
      "0.156581171",
      "0.14687796",
      "0.137677894",
      "0.128966983",
      "0.120730049",
      "0.11295097800000001"
    ]
  },
  "sensitivity_param_factors": {
    "speed": 1,
    "fuelprice": 1,
    "emission": 1,
    "AADTheavy": 1
  },
  "presumptions": {
    "Speed_car": 80,
    "Speed_truck": 80,
    "cost_increase": 0.01,
    "gasoline_fuelfactor": 1,
    "gasoline_co2": 2.85,
    "gasoline_kwh": 9.1,
    "gasoline_efficiency": 0.25,
    "gasoline_cost": 16,
    "diesel_fuelfactor": 1,
    "diesel_co2": 2.73,
    "diesel_kwh": 9.8,
    "diesel_efficiency": 0.35,
    "diesel_cost": 16,
    "battery_fuelfactor": 0,
    "battery_co2": 0.0,
    "battery_kwh": 9.8,
    "battery_efficiency": 1,
    "battery_cost": 0,
    "inchargeable_hybrid_fuelfactor": 1,
    "inchargeable_hybrid_co2": 2.85,
    "inchargeable_hybrid_kwh": 9.8,
    "inchargeable_hybrid_efficiency": 0.35,
    "inchargeable_hybrid_cost": 16,
    "chargeable_hybrid_fuelfactor": 1,
    "chargeable_hybrid_co2": 2.85,
    "chargeable_hybrid_kwh": 9.8,
    "chargeable_hybrid_efficiency": 0.35,
    "chargeable_hybrid_cost": 16,
    "hydrogen_fuelfactor": 0,
    "hydrogen_co2": 0.0,
    "hydrogen_kwh": 9.8,
    "hydrogen_efficiency": 1,
    "hydrogen_cost": 0,
    "iri": 1.0,
    "mpd": 1.0
  },
  "segments": [
    {
      "segment_id": 1,
      "directions_id": 590,
      "year": 2020,
      "aadt": 20000,
      "prc_heavy": 10,
      "aadt_cars": 18000,
      "aadt_trucks": 1500,
      "aadt_trucks_with_trailer": 500,
      "annual_increase_cars": 1,
      "annual_increase_heavy": 1.5,
      "segment_order_no": 1
    }
  ],
  "segments_data": [
    {
      "segment_data_id": 590,
      "chainage": 140,
      "zlevel": 41.08,
      "radius": 117.027,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 591,
      "chainage": 160,
      "zlevel": 41.02,
      "radius": 200.421,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 592,
      "chainage": 180,
      "zlevel": 40.96,
      "radius": 697.38,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 593,
      "chainage": 280,
      "zlevel": 40.66,
      "radius": 3910.82,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 594,
      "chainage": 300,
      "zlevel": 40.6,
      "radius": 1658.586,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 595,
      "chainage": 320,
      "zlevel": 40.54,
      "radius": 1052.471,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 596,
      "chainage": 326,
      "zlevel": 40.523,
      "radius": 955.322,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 597,
      "chainage": 340,
      "zlevel": 40.465,
      "radius": 770.792,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 598,
      "chainage": 343,
      "zlevel": 40.449,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 599,
      "chainage": 360,
      "zlevel": 40.335,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 600,
      "chainage": 380,
      "zlevel": 40.148,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 601,
      "chainage": 400,
      "zlevel": 39.904,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 602,
      "chainage": 415,
      "zlevel": 39.687,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 603,
      "chainage": 420,
      "zlevel": 39.603,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 604,
      "chainage": 440,
      "zlevel": 39.245,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 605,
      "chainage": 460,
      "zlevel": 38.829,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 606,
      "chainage": 480,
      "zlevel": 38.356,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 607,
      "chainage": 500,
      "zlevel": 37.826,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 608,
      "chainage": 503,
      "zlevel": 37.743,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 609,
      "chainage": 504,
      "zlevel": 37.714,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 610,
      "chainage": 520,
      "zlevel": 37.257,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 611,
      "chainage": 540,
      "zlevel": 36.687,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 612,
      "chainage": 560,
      "zlevel": 36.117,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 613,
      "chainage": 580,
      "zlevel": 35.547,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 614,
      "chainage": 600,
      "zlevel": 34.977,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 615,
      "chainage": 620,
      "zlevel": 34.407,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 616,
      "chainage": 640,
      "zlevel": 33.837,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 617,
      "chainage": 660,
      "zlevel": 33.267,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 618,
      "chainage": 663,
      "zlevel": 33.187,
      "radius": 740,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 619,
      "chainage": 680,
      "zlevel": 32.697,
      "radius": 949.609,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 620,
      "chainage": 700,
      "zlevel": 32.127,
      "radius": 1416.746,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 621,
      "chainage": 720,
      "zlevel": 31.557,
      "radius": 2788.462,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 622,
      "chainage": 740,
      "zlevel": 30.987,
      "radius": 87731.395,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 623,
      "chainage": 820,
      "zlevel": 28.707,
      "radius": 4690.028,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 624,
      "chainage": 840,
      "zlevel": 28.137,
      "radius": 1738.003,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 625,
      "chainage": 860,
      "zlevel": 27.567,
      "radius": 1066.635,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 626,
      "chainage": 880,
      "zlevel": 26.997,
      "radius": 769.419,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 627,
      "chainage": 900,
      "zlevel": 26.441,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 628,
      "chainage": 900,
      "zlevel": 26.427,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 629,
      "chainage": 920,
      "zlevel": 25.857,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 630,
      "chainage": 940,
      "zlevel": 25.287,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 631,
      "chainage": 960,
      "zlevel": 24.717,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 632,
      "chainage": 980,
      "zlevel": 24.147,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 633,
      "chainage": 1000,
      "zlevel": 23.577,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 634,
      "chainage": 1020,
      "zlevel": 23.007,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 635,
      "chainage": 1040,
      "zlevel": 22.437,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 636,
      "chainage": 1060,
      "zlevel": 21.867,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 637,
      "chainage": 1080,
      "zlevel": 21.297,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 638,
      "chainage": 1100,
      "zlevel": 20.727,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 639,
      "chainage": 1120,
      "zlevel": 20.157,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 640,
      "chainage": 1140,
      "zlevel": 19.587,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 641,
      "chainage": 1160,
      "zlevel": 19.017,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 642,
      "chainage": 1180,
      "zlevel": 18.447,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 643,
      "chainage": 1200,
      "zlevel": 17.877,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 644,
      "chainage": 1220,
      "zlevel": 17.307,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 645,
      "chainage": 1240,
      "zlevel": 16.737,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 646,
      "chainage": 1252,
      "zlevel": 16.401,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 647,
      "chainage": 1260,
      "zlevel": 16.167,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 648,
      "chainage": 1280,
      "zlevel": 15.597,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 649,
      "chainage": 1300,
      "zlevel": 15.027,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 650,
      "chainage": 1320,
      "zlevel": 14.457,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 651,
      "chainage": 1340,
      "zlevel": 13.887,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 652,
      "chainage": 1360,
      "zlevel": 13.317,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 653,
      "chainage": 1380,
      "zlevel": 12.747,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 654,
      "chainage": 1400,
      "zlevel": 12.177,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 655,
      "chainage": 1409,
      "zlevel": 11.913,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 656,
      "chainage": 1420,
      "zlevel": 11.624,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 657,
      "chainage": 1440,
      "zlevel": 11.172,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 658,
      "chainage": 1460,
      "zlevel": 10.835,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 659,
      "chainage": 1480,
      "zlevel": 10.612,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 660,
      "chainage": 1496,
      "zlevel": 10.517,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 661,
      "chainage": 1500,
      "zlevel": 10.504,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 662,
      "chainage": 1509,
      "zlevel": 10.492,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 663,
      "chainage": 1520,
      "zlevel": 10.51,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 664,
      "chainage": 1540,
      "zlevel": 10.63,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 665,
      "chainage": 1560,
      "zlevel": 10.864,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 666,
      "chainage": 1580,
      "zlevel": 11.213,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 667,
      "chainage": 1582,
      "zlevel": 11.264,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 668,
      "chainage": 1600,
      "zlevel": 11.632,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 669,
      "chainage": 1604,
      "zlevel": 11.718,
      "radius": 605,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 670,
      "chainage": 1620,
      "zlevel": 12.052,
      "radius": 784.742,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 671,
      "chainage": 1640,
      "zlevel": 12.472,
      "radius": 1252.51,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 672,
      "chainage": 1660,
      "zlevel": 12.892,
      "radius": 3100.875,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 673,
      "chainage": 1680,
      "zlevel": 13.312,
      "radius": 5308.363,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 674,
      "chainage": 1700,
      "zlevel": 13.732,
      "radius": 1294.079,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 675,
      "chainage": 1720,
      "zlevel": 14.152,
      "radius": 736.855,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 676,
      "chainage": 1740,
      "zlevel": 14.572,
      "radius": 515.069,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 677,
      "chainage": 1742,
      "zlevel": 14.614,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 678,
      "chainage": 1760,
      "zlevel": 14.992,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 679,
      "chainage": 1780,
      "zlevel": 15.412,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 680,
      "chainage": 1800,
      "zlevel": 15.832,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 681,
      "chainage": 1820,
      "zlevel": 16.252,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 682,
      "chainage": 1833,
      "zlevel": 16.528,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 683,
      "chainage": 1840,
      "zlevel": 16.672,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 684,
      "chainage": 1860,
      "zlevel": 17.092,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 685,
      "chainage": 1880,
      "zlevel": 17.512,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 686,
      "chainage": 1900,
      "zlevel": 17.932,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 687,
      "chainage": 1920,
      "zlevel": 18.352,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 688,
      "chainage": 1924,
      "zlevel": 18.442,
      "radius": 500,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 689,
      "chainage": 1940,
      "zlevel": 18.772,
      "radius": 648.83,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 690,
      "chainage": 1960,
      "zlevel": 19.192,
      "radius": 1045.076,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 691,
      "chainage": 1980,
      "zlevel": 19.612,
      "radius": 2684.566,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 692,
      "chainage": 2000,
      "zlevel": 20.032,
      "radius": 7943.515,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 693,
      "chainage": 2017,
      "zlevel": 20.399,
      "radius": 2328.671,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 694,
      "chainage": 2020,
      "zlevel": 20.452,
      "radius": 2113.669,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 695,
      "chainage": 2034,
      "zlevel": 20.722,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 696,
      "chainage": 2040,
      "zlevel": 20.821,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 697,
      "chainage": 2060,
      "zlevel": 21.111,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 698,
      "chainage": 2080,
      "zlevel": 21.321,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 699,
      "chainage": 2100,
      "zlevel": 21.451,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 700,
      "chainage": 2120,
      "zlevel": 21.501,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 701,
      "chainage": 2122,
      "zlevel": 21.501,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 702,
      "chainage": 2140,
      "zlevel": 21.471,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 703,
      "chainage": 2140,
      "zlevel": 21.471,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 704,
      "chainage": 2160,
      "zlevel": 21.361,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 705,
      "chainage": 2180,
      "zlevel": 21.17,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 706,
      "chainage": 2200,
      "zlevel": 20.9,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 707,
      "chainage": 2220,
      "zlevel": 20.552,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 708,
      "chainage": 2220,
      "zlevel": 20.55,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 709,
      "chainage": 2240,
      "zlevel": 20.12,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 710,
      "chainage": 2260,
      "zlevel": 19.609,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 711,
      "chainage": 2262,
      "zlevel": 19.543,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 712,
      "chainage": 2280,
      "zlevel": 19.05,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 713,
      "chainage": 2300,
      "zlevel": 18.49,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 714,
      "chainage": 2320,
      "zlevel": 17.93,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 715,
      "chainage": 2340,
      "zlevel": 17.37,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 716,
      "chainage": 2360,
      "zlevel": 16.81,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 717,
      "chainage": 2380,
      "zlevel": 16.25,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 718,
      "chainage": 2400,
      "zlevel": 15.69,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 719,
      "chainage": 2406,
      "zlevel": 15.532,
      "radius": 1390,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 720,
      "chainage": 2420,
      "zlevel": 15.13,
      "radius": 2127.496,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 721,
      "chainage": 2440,
      "zlevel": 14.57,
      "radius": 8142.395,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 722,
      "chainage": 2460,
      "zlevel": 14.01,
      "radius": 3094.559,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 723,
      "chainage": 2480,
      "zlevel": 13.45,
      "radius": 1214.849,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 724,
      "chainage": 2500,
      "zlevel": 12.89,
      "radius": 755.773,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 725,
      "chainage": 2507,
      "zlevel": 12.692,
      "radius": 666.788,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 726,
      "chainage": 2520,
      "zlevel": 12.34,
      "radius": 548.502,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 727,
      "chainage": 2529,
      "zlevel": 12.116,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 728,
      "chainage": 2540,
      "zlevel": 11.838,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 729,
      "chainage": 2560,
      "zlevel": 11.385,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 730,
      "chainage": 2580,
      "zlevel": 10.983,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 731,
      "chainage": 2600,
      "zlevel": 10.63,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 732,
      "chainage": 2604,
      "zlevel": 10.56,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 733,
      "chainage": 2620,
      "zlevel": 10.328,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 734,
      "chainage": 2640,
      "zlevel": 10.075,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 735,
      "chainage": 2659,
      "zlevel": 9.882,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 736,
      "chainage": 2660,
      "zlevel": 9.873,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 737,
      "chainage": 2680,
      "zlevel": 9.72,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 738,
      "chainage": 2680,
      "zlevel": 9.72,
      "radius": 490,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 739,
      "chainage": 2700,
      "zlevel": 9.618,
      "radius": 600.941,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 740,
      "chainage": 2720,
      "zlevel": 9.566,
      "radius": 777.614,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 741,
      "chainage": 2731,
      "zlevel": 9.558,
      "radius": 927.205,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 742,
      "chainage": 2740,
      "zlevel": 9.563,
      "radius": 1101.427,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 743,
      "chainage": 2760,
      "zlevel": 9.611,
      "radius": 1887.357,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 744,
      "chainage": 2780,
      "zlevel": 9.708,
      "radius": 6588.939,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 745,
      "chainage": 2940,
      "zlevel": 11.248,
      "radius": 3627.347,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 746,
      "chainage": 2960,
      "zlevel": 11.448,
      "radius": 1605.379,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 747,
      "chainage": 2980,
      "zlevel": 11.648,
      "radius": 1030.792,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 748,
      "chainage": 2990,
      "zlevel": 11.751,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 749,
      "chainage": 3000,
      "zlevel": 11.848,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 750,
      "chainage": 3020,
      "zlevel": 12.048,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 751,
      "chainage": 3040,
      "zlevel": 12.248,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 752,
      "chainage": 3060,
      "zlevel": 12.448,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 753,
      "chainage": 3080,
      "zlevel": 12.648,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 754,
      "chainage": 3100,
      "zlevel": 12.848,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 755,
      "chainage": 3120,
      "zlevel": 13.047,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 756,
      "chainage": 3140,
      "zlevel": 13.247,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 757,
      "chainage": 3160,
      "zlevel": 13.447,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 758,
      "chainage": 3178,
      "zlevel": 13.632,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 759,
      "chainage": 3180,
      "zlevel": 13.647,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 760,
      "chainage": 3200,
      "zlevel": 13.847,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 761,
      "chainage": 3220,
      "zlevel": 14.047,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 762,
      "chainage": 3240,
      "zlevel": 14.247,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 763,
      "chainage": 3260,
      "zlevel": 14.447,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 764,
      "chainage": 3280,
      "zlevel": 14.647,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 765,
      "chainage": 3300,
      "zlevel": 14.847,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 766,
      "chainage": 3320,
      "zlevel": 15.047,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 767,
      "chainage": 3340,
      "zlevel": 15.247,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 768,
      "chainage": 3360,
      "zlevel": 15.447,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 769,
      "chainage": 3367,
      "zlevel": 15.513,
      "radius": 870,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 770,
      "chainage": 3380,
      "zlevel": 15.647,
      "radius": 998.912,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 771,
      "chainage": 3400,
      "zlevel": 15.847,
      "radius": 1283.916,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 772,
      "chainage": 3420,
      "zlevel": 16.047,
      "radius": 1796.478,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 773,
      "chainage": 3440,
      "zlevel": 16.247,
      "radius": 2990.229,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 774,
      "chainage": 3460,
      "zlevel": 16.447,
      "radius": 8912.629,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 775,
      "chainage": 3840,
      "zlevel": 15.227,
      "radius": 7748.77,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 776,
      "chainage": 3860,
      "zlevel": 14.907,
      "radius": 1907.98,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 777,
      "chainage": 3880,
      "zlevel": 14.587,
      "radius": 1087.931,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 778,
      "chainage": 3897,
      "zlevel": 14.319,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 779,
      "chainage": 3900,
      "zlevel": 14.267,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 780,
      "chainage": 3920,
      "zlevel": 13.947,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 781,
      "chainage": 3940,
      "zlevel": 13.627,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 782,
      "chainage": 3960,
      "zlevel": 13.307,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 783,
      "chainage": 3980,
      "zlevel": 12.987,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 784,
      "chainage": 3983,
      "zlevel": 12.935,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 785,
      "chainage": 4000,
      "zlevel": 12.667,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 786,
      "chainage": 4020,
      "zlevel": 12.347,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 787,
      "chainage": 4040,
      "zlevel": 12.027,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 788,
      "chainage": 4060,
      "zlevel": 11.707,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 789,
      "chainage": 4070,
      "zlevel": 11.552,
      "radius": 800,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 790,
      "chainage": 4080,
      "zlevel": 11.387,
      "radius": 956.509,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 791,
      "chainage": 4100,
      "zlevel": 11.067,
      "radius": 1537.5,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 792,
      "chainage": 4120,
      "zlevel": 10.747,
      "radius": 3916.276,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 793,
      "chainage": 4140,
      "zlevel": 10.427,
      "radius": 8143.435,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 794,
      "chainage": 4160,
      "zlevel": 10.107,
      "radius": 2127.566,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 795,
      "chainage": 4180,
      "zlevel": 9.787,
      "radius": 1223.626,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 796,
      "chainage": 4200,
      "zlevel": 9.467,
      "radius": 858.763,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 797,
      "chainage": 4220,
      "zlevel": 9.147,
      "radius": 661.512,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 798,
      "chainage": 4222,
      "zlevel": 9.111,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 799,
      "chainage": 4240,
      "zlevel": 8.827,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 800,
      "chainage": 4260,
      "zlevel": 8.507,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 801,
      "chainage": 4280,
      "zlevel": 8.187,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 802,
      "chainage": 4300,
      "zlevel": 7.867,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 803,
      "chainage": 4306,
      "zlevel": 7.768,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 804,
      "chainage": 4320,
      "zlevel": 7.563,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 805,
      "chainage": 4340,
      "zlevel": 7.322,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 806,
      "chainage": 4360,
      "zlevel": 7.148,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 807,
      "chainage": 4380,
      "zlevel": 7.041,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 808,
      "chainage": 4400,
      "zlevel": 7.001,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 809,
      "chainage": 4402,
      "zlevel": 7,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 810,
      "chainage": 4420,
      "zlevel": 7.027,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 811,
      "chainage": 4423,
      "zlevel": 7.037,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 812,
      "chainage": 4440,
      "zlevel": 7.12,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 813,
      "chainage": 4460,
      "zlevel": 7.28,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 814,
      "chainage": 4480,
      "zlevel": 7.506,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 815,
      "chainage": 4500,
      "zlevel": 7.799,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 816,
      "chainage": 4520,
      "zlevel": 8.158,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 817,
      "chainage": 4540,
      "zlevel": 8.585,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 818,
      "chainage": 4540,
      "zlevel": 8.587,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 819,
      "chainage": 4560,
      "zlevel": 9.045,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 820,
      "chainage": 4566,
      "zlevel": 9.191,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 821,
      "chainage": 4580,
      "zlevel": 9.505,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 822,
      "chainage": 4600,
      "zlevel": 9.965,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 823,
      "chainage": 4620,
      "zlevel": 10.425,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 824,
      "chainage": 4640,
      "zlevel": 10.885,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 825,
      "chainage": 4660,
      "zlevel": 11.345,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 826,
      "chainage": 4680,
      "zlevel": 11.805,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 827,
      "chainage": 4700,
      "zlevel": 12.265,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 828,
      "chainage": 4720,
      "zlevel": 12.725,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 829,
      "chainage": 4740,
      "zlevel": 13.185,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 830,
      "chainage": 4760,
      "zlevel": 13.645,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 831,
      "chainage": 4780,
      "zlevel": 14.105,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 832,
      "chainage": 4800,
      "zlevel": 14.565,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 833,
      "chainage": 4820,
      "zlevel": 15.025,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 834,
      "chainage": 4840,
      "zlevel": 15.485,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 835,
      "chainage": 4860,
      "zlevel": 15.945,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 836,
      "chainage": 4880,
      "zlevel": 16.405,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 837,
      "chainage": 4900,
      "zlevel": 16.865,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 838,
      "chainage": 4910,
      "zlevel": 17.105,
      "radius": 645,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 839,
      "chainage": 4920,
      "zlevel": 17.325,
      "radius": 722.061,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 840,
      "chainage": 4940,
      "zlevel": 17.785,
      "radius": 963.667,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 841,
      "chainage": 4960,
      "zlevel": 18.245,
      "radius": 1448.266,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 842,
      "chainage": 4980,
      "zlevel": 18.705,
      "radius": 2913.256,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 843,
      "chainage": 5440,
      "zlevel": 36.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 844,
      "chainage": 5460,
      "zlevel": 37.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 845,
      "chainage": 5480,
      "zlevel": 38.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 846,
      "chainage": 5491,
      "zlevel": 39.019,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 847,
      "chainage": 5500,
      "zlevel": 39.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 848,
      "chainage": 5520,
      "zlevel": 40.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 849,
      "chainage": 5540,
      "zlevel": 41.48,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 850,
      "chainage": 5548,
      "zlevel": 41.898,
      "radius": 700,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 851,
      "chainage": 5560,
      "zlevel": 42.48,
      "radius": 827.634,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 852,
      "chainage": 5580,
      "zlevel": 43.48,
      "radius": 1204.542,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 853,
      "chainage": 5600,
      "zlevel": 44.48,
      "radius": 2211.805,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 854,
      "chainage": 5620,
      "zlevel": 45.48,
      "radius": 13504.82,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 855,
      "chainage": 6600,
      "zlevel": 43.739,
      "radius": 2921.033,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 856,
      "chainage": 6620,
      "zlevel": 42.739,
      "radius": 1450.186,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 857,
      "chainage": 6640,
      "zlevel": 41.739,
      "radius": 964.516,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 858,
      "chainage": 6648,
      "zlevel": 41.337,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 859,
      "chainage": 6660,
      "zlevel": 40.74,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 860,
      "chainage": 6680,
      "zlevel": 39.74,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 861,
      "chainage": 6700,
      "zlevel": 38.74,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 862,
      "chainage": 6720,
      "zlevel": 37.74,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 863,
      "chainage": 6728,
      "zlevel": 37.36,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 864,
      "chainage": 6740,
      "zlevel": 36.752,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 865,
      "chainage": 6760,
      "zlevel": 35.816,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 866,
      "chainage": 6776,
      "zlevel": 35.126,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 867,
      "chainage": 6780,
      "zlevel": 34.938,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 868,
      "chainage": 6800,
      "zlevel": 34.117,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 869,
      "chainage": 6820,
      "zlevel": 33.353,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 870,
      "chainage": 6840,
      "zlevel": 32.647,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 871,
      "chainage": 6850,
      "zlevel": 32.323,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 872,
      "chainage": 6860,
      "zlevel": 31.998,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 873,
      "chainage": 6880,
      "zlevel": 31.406,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 874,
      "chainage": 6900,
      "zlevel": 30.871,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 875,
      "chainage": 6903,
      "zlevel": 30.793,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 876,
      "chainage": 6920,
      "zlevel": 30.394,
      "radius": 1165.745,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 877,
      "chainage": 6940,
      "zlevel": 29.974,
      "radius": 2084.425,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 878,
      "chainage": 6960,
      "zlevel": 29.61,
      "radius": 9835.083,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 879,
      "chainage": 6972,
      "zlevel": 29.419,
      "radius": 8588.7,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 880,
      "chainage": 6980,
      "zlevel": 29.3,
      "radius": 3939.459,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 881,
      "chainage": 7000,
      "zlevel": 29,
      "radius": 1663.716,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 882,
      "chainage": 7020,
      "zlevel": 28.7,
      "radius": 1054.534,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 883,
      "chainage": 7026,
      "zlevel": 28.61,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 884,
      "chainage": 7040,
      "zlevel": 28.4,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 885,
      "chainage": 7060,
      "zlevel": 28.1,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 886,
      "chainage": 7080,
      "zlevel": 27.8,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 887,
      "chainage": 7100,
      "zlevel": 27.5,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 888,
      "chainage": 7120,
      "zlevel": 27.2,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 889,
      "chainage": 7140,
      "zlevel": 26.9,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 890,
      "chainage": 7160,
      "zlevel": 26.6,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 891,
      "chainage": 7180,
      "zlevel": 26.3,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 892,
      "chainage": 7200,
      "zlevel": 26,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 893,
      "chainage": 7220,
      "zlevel": 25.7,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 894,
      "chainage": 7240,
      "zlevel": 25.4,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 895,
      "chainage": 7260,
      "zlevel": 25.1,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 896,
      "chainage": 7280,
      "zlevel": 24.8,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 897,
      "chainage": 7300,
      "zlevel": 24.5,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 898,
      "chainage": 7320,
      "zlevel": 24.2,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 899,
      "chainage": 7332,
      "zlevel": 24.017,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 900,
      "chainage": 7340,
      "zlevel": 23.9,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 901,
      "chainage": 7360,
      "zlevel": 23.6,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 902,
      "chainage": 7380,
      "zlevel": 23.3,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 903,
      "chainage": 7400,
      "zlevel": 23,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 904,
      "chainage": 7420,
      "zlevel": 22.7,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 905,
      "chainage": 7440,
      "zlevel": 22.4,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 906,
      "chainage": 7447,
      "zlevel": 22.301,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 907,
      "chainage": 7460,
      "zlevel": 22.111,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 908,
      "chainage": 7480,
      "zlevel": 21.87,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 909,
      "chainage": 7500,
      "zlevel": 21.678,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 910,
      "chainage": 7520,
      "zlevel": 21.537,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 911,
      "chainage": 7540,
      "zlevel": 21.445,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 912,
      "chainage": 7555,
      "zlevel": 21.41,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 913,
      "chainage": 7560,
      "zlevel": 21.404,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 914,
      "chainage": 7567,
      "zlevel": 21.401,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 915,
      "chainage": 7580,
      "zlevel": 21.413,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 916,
      "chainage": 7600,
      "zlevel": 21.471,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 917,
      "chainage": 7620,
      "zlevel": 21.58,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 918,
      "chainage": 7638,
      "zlevel": 21.723,
      "radius": 950,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 919,
      "chainage": 7640,
      "zlevel": 21.738,
      "radius": 974.843,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 920,
      "chainage": 7660,
      "zlevel": 21.947,
      "radius": 1416.819,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 921,
      "chainage": 7663,
      "zlevel": 21.977,
      "radius": 1504.533,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 922,
      "chainage": 7680,
      "zlevel": 22.186,
      "radius": 2591.973,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 923,
      "chainage": 7700,
      "zlevel": 22.426,
      "radius": 15196.068,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 924,
      "chainage": 8031,
      "zlevel": 27.205,
      "radius": 7415.366,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 925,
      "chainage": 8040,
      "zlevel": 27.505,
      "radius": 3358.169,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 926,
      "chainage": 8060,
      "zlevel": 28.212,
      "radius": 1550.379,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 927,
      "chainage": 8080,
      "zlevel": 29.007,
      "radius": 1007.835,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 928,
      "chainage": 8091,
      "zlevel": 29.465,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 929,
      "chainage": 8100,
      "zlevel": 29.891,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 930,
      "chainage": 8116,
      "zlevel": 30.659,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 931,
      "chainage": 8120,
      "zlevel": 30.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 932,
      "chainage": 8140,
      "zlevel": 31.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 933,
      "chainage": 8160,
      "zlevel": 32.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 934,
      "chainage": 8180,
      "zlevel": 33.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 935,
      "chainage": 8200,
      "zlevel": 34.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 936,
      "chainage": 8220,
      "zlevel": 35.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 937,
      "chainage": 8240,
      "zlevel": 36.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 938,
      "chainage": 8260,
      "zlevel": 37.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 939,
      "chainage": 8280,
      "zlevel": 38.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 940,
      "chainage": 8300,
      "zlevel": 39.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 941,
      "chainage": 8320,
      "zlevel": 40.863,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 942,
      "chainage": 8321,
      "zlevel": 40.935,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 943,
      "chainage": 8340,
      "zlevel": 41.836,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 944,
      "chainage": 8360,
      "zlevel": 42.748,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 945,
      "chainage": 8380,
      "zlevel": 43.598,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 946,
      "chainage": 8400,
      "zlevel": 44.387,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 947,
      "chainage": 8420,
      "zlevel": 45.114,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 948,
      "chainage": 8440,
      "zlevel": 45.779,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 949,
      "chainage": 8460,
      "zlevel": 46.382,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 950,
      "chainage": 8480,
      "zlevel": 46.924,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 951,
      "chainage": 8494,
      "zlevel": 47.261,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 952,
      "chainage": 8500,
      "zlevel": 47.404,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 953,
      "chainage": 8520,
      "zlevel": 47.823,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 954,
      "chainage": 8531,
      "zlevel": 48.028,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 955,
      "chainage": 8540,
      "zlevel": 48.18,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 956,
      "chainage": 8560,
      "zlevel": 48.476,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 957,
      "chainage": 8580,
      "zlevel": 48.71,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 958,
      "chainage": 8600,
      "zlevel": 48.882,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 959,
      "chainage": 8620,
      "zlevel": 48.993,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 960,
      "chainage": 8640,
      "zlevel": 49.042,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 961,
      "chainage": 8646,
      "zlevel": 49.045,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 962,
      "chainage": 8660,
      "zlevel": 49.03,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 963,
      "chainage": 8680,
      "zlevel": 48.956,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 964,
      "chainage": 8700,
      "zlevel": 48.821,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 965,
      "chainage": 8720,
      "zlevel": 48.624,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 966,
      "chainage": 8740,
      "zlevel": 48.366,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 967,
      "chainage": 8741,
      "zlevel": 48.352,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 968,
      "chainage": 8760,
      "zlevel": 48.074,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 969,
      "chainage": 8780,
      "zlevel": 47.782,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 970,
      "chainage": 8800,
      "zlevel": 47.49,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 971,
      "chainage": 8820,
      "zlevel": 47.198,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 972,
      "chainage": 8840,
      "zlevel": 46.906,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 973,
      "chainage": 8860,
      "zlevel": 46.614,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 974,
      "chainage": 8880,
      "zlevel": 46.322,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 975,
      "chainage": 8897,
      "zlevel": 46.076,
      "radius": 850,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 976,
      "chainage": 8900,
      "zlevel": 46.03,
      "radius": 877.742,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 977,
      "chainage": 8920,
      "zlevel": 45.738,
      "radius": 1109.293,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 978,
      "chainage": 8940,
      "zlevel": 45.446,
      "radius": 1506.788,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 979,
      "chainage": 8960,
      "zlevel": 45.154,
      "radius": 2348.238,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 980,
      "chainage": 8980,
      "zlevel": 44.862,
      "radius": 5318.045,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 981,
      "chainage": 9000,
      "zlevel": 44.57,
      "radius": 8624.157,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 982,
      "chainage": 9020,
      "zlevel": 44.278,
      "radius": 1492.604,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 983,
      "chainage": 9040,
      "zlevel": 43.986,
      "radius": 817.002,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 984,
      "chainage": 9046,
      "zlevel": 43.903,
      "radius": 723.376,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 985,
      "chainage": 9060,
      "zlevel": 43.738,
      "radius": 562.429,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 986,
      "chainage": 9079,
      "zlevel": 43.657,
      "radius": 432.432,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 987,
      "chainage": 9080,
      "zlevel": 43.658,
      "radius": 428.813,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 988,
      "chainage": 9080,
      "zlevel": 43.658,
      "radius": 428.302,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 989,
      "chainage": 9100,
      "zlevel": 43.751,
      "radius": 346.496,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 990,
      "chainage": 9103,
      "zlevel": 43.779,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 991,
      "chainage": 9114,
      "zlevel": 43.927,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 992,
      "chainage": 9120,
      "zlevel": 44.011,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 993,
      "chainage": 9140,
      "zlevel": 44.317,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 994,
      "chainage": 9160,
      "zlevel": 44.623,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 995,
      "chainage": 9180,
      "zlevel": 44.929,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 996,
      "chainage": 9200,
      "zlevel": 45.235,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 997,
      "chainage": 9220,
      "zlevel": 45.541,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 998,
      "chainage": 9240,
      "zlevel": 45.847,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 999,
      "chainage": 9242,
      "zlevel": 45.875,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1000,
      "chainage": 9260,
      "zlevel": 46.153,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1001,
      "chainage": 9280,
      "zlevel": 46.459,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1002,
      "chainage": 9300,
      "zlevel": 46.765,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1003,
      "chainage": 9312,
      "zlevel": 46.947,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1004,
      "chainage": 9320,
      "zlevel": 47.063,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1005,
      "chainage": 9340,
      "zlevel": 47.278,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1006,
      "chainage": 9360,
      "zlevel": 47.394,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1007,
      "chainage": 9373,
      "zlevel": 47.415,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1008,
      "chainage": 9380,
      "zlevel": 47.409,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1009,
      "chainage": 9381,
      "zlevel": 47.408,
      "radius": 337,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1010,
      "chainage": 9400,
      "zlevel": 47.324,
      "radius": 421.504,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1011,
      "chainage": 9420,
      "zlevel": 47.14,
      "radius": 569.744,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1012,
      "chainage": 9440,
      "zlevel": 46.855,
      "radius": 878.819,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1013,
      "chainage": 9440,
      "zlevel": 46.848,
      "radius": 889.525,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1014,
      "chainage": 9460,
      "zlevel": 46.471,
      "radius": 1920.836,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1015,
      "chainage": 188,
      "zlevel": 40.936,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1016,
      "chainage": 200,
      "zlevel": 40.9,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1017,
      "chainage": 220,
      "zlevel": 40.84,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1018,
      "chainage": 240,
      "zlevel": 40.78,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1019,
      "chainage": 260,
      "zlevel": 40.72,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1020,
      "chainage": 265,
      "zlevel": 40.704,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1021,
      "chainage": 741,
      "zlevel": 30.969,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1022,
      "chainage": 760,
      "zlevel": 30.417,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1023,
      "chainage": 780,
      "zlevel": 29.847,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1024,
      "chainage": 800,
      "zlevel": 29.277,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1025,
      "chainage": 808,
      "zlevel": 29.043,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1026,
      "chainage": 1674,
      "zlevel": 13.177,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1027,
      "chainage": 1993,
      "zlevel": 19.88,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1028,
      "chainage": 2447,
      "zlevel": 14.372,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1029,
      "chainage": 2788,
      "zlevel": 9.762,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1030,
      "chainage": 2800,
      "zlevel": 9.856,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1031,
      "chainage": 2811,
      "zlevel": 9.958,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1032,
      "chainage": 2820,
      "zlevel": 10.048,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1033,
      "chainage": 2840,
      "zlevel": 10.248,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1034,
      "chainage": 2860,
      "zlevel": 10.448,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1035,
      "chainage": 2880,
      "zlevel": 10.648,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1036,
      "chainage": 2900,
      "zlevel": 10.848,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1037,
      "chainage": 2920,
      "zlevel": 11.048,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1038,
      "chainage": 2924,
      "zlevel": 11.089,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1039,
      "chainage": 3470,
      "zlevel": 16.548,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1040,
      "chainage": 3480,
      "zlevel": 16.647,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1041,
      "chainage": 3500,
      "zlevel": 16.846,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1042,
      "chainage": 3520,
      "zlevel": 17.046,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1043,
      "chainage": 3540,
      "zlevel": 17.246,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1044,
      "chainage": 3560,
      "zlevel": 17.446,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1045,
      "chainage": 3575,
      "zlevel": 17.601,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1046,
      "chainage": 3580,
      "zlevel": 17.644,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1047,
      "chainage": 3600,
      "zlevel": 17.791,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1048,
      "chainage": 3620,
      "zlevel": 17.866,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1049,
      "chainage": 3630,
      "zlevel": 17.876,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1050,
      "chainage": 3640,
      "zlevel": 17.867,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1051,
      "chainage": 3647,
      "zlevel": 17.851,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1052,
      "chainage": 3660,
      "zlevel": 17.796,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1053,
      "chainage": 3680,
      "zlevel": 17.652,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1054,
      "chainage": 3700,
      "zlevel": 17.436,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1055,
      "chainage": 3718,
      "zlevel": 17.172,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1056,
      "chainage": 3720,
      "zlevel": 17.147,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1057,
      "chainage": 3740,
      "zlevel": 16.827,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1058,
      "chainage": 3760,
      "zlevel": 16.507,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1059,
      "chainage": 3780,
      "zlevel": 16.187,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1060,
      "chainage": 3800,
      "zlevel": 15.867,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1061,
      "chainage": 3820,
      "zlevel": 15.547,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1062,
      "chainage": 3833,
      "zlevel": 15.331,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1063,
      "chainage": 4133,
      "zlevel": 10.54,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1064,
      "chainage": 5000,
      "zlevel": 19.159,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1065,
      "chainage": 5000,
      "zlevel": 19.165,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1066,
      "chainage": 5020,
      "zlevel": 19.625,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1067,
      "chainage": 5040,
      "zlevel": 20.085,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1068,
      "chainage": 5060,
      "zlevel": 20.545,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1069,
      "chainage": 5080,
      "zlevel": 21.005,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1070,
      "chainage": 5100,
      "zlevel": 21.465,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1071,
      "chainage": 5106,
      "zlevel": 21.605,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1072,
      "chainage": 5120,
      "zlevel": 21.944,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1073,
      "chainage": 5140,
      "zlevel": 22.5,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1074,
      "chainage": 5160,
      "zlevel": 23.136,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1075,
      "chainage": 5173,
      "zlevel": 23.61,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1076,
      "chainage": 5180,
      "zlevel": 23.851,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1077,
      "chainage": 5200,
      "zlevel": 24.648,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1078,
      "chainage": 5220,
      "zlevel": 25.524,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1079,
      "chainage": 5240,
      "zlevel": 26.481,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1080,
      "chainage": 5241,
      "zlevel": 26.521,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1081,
      "chainage": 5260,
      "zlevel": 27.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1082,
      "chainage": 5280,
      "zlevel": 28.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1083,
      "chainage": 5300,
      "zlevel": 29.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1084,
      "chainage": 5320,
      "zlevel": 30.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1085,
      "chainage": 5340,
      "zlevel": 31.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1086,
      "chainage": 5360,
      "zlevel": 32.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1087,
      "chainage": 5380,
      "zlevel": 33.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1088,
      "chainage": 5400,
      "zlevel": 34.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1089,
      "chainage": 5420,
      "zlevel": 35.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1090,
      "chainage": 5433,
      "zlevel": 36.139,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1091,
      "chainage": 5624,
      "zlevel": 45.676,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1092,
      "chainage": 5640,
      "zlevel": 46.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1093,
      "chainage": 5660,
      "zlevel": 47.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1094,
      "chainage": 5680,
      "zlevel": 48.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1095,
      "chainage": 5700,
      "zlevel": 49.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1096,
      "chainage": 5720,
      "zlevel": 50.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1097,
      "chainage": 5740,
      "zlevel": 51.48,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1098,
      "chainage": 5743,
      "zlevel": 51.63,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1099,
      "chainage": 5760,
      "zlevel": 52.46,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1100,
      "chainage": 5780,
      "zlevel": 53.382,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1101,
      "chainage": 5800,
      "zlevel": 54.248,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1102,
      "chainage": 5820,
      "zlevel": 55.056,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1103,
      "chainage": 5840,
      "zlevel": 55.806,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1104,
      "chainage": 5860,
      "zlevel": 56.5,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1105,
      "chainage": 5880,
      "zlevel": 57.136,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1106,
      "chainage": 5900,
      "zlevel": 57.715,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1107,
      "chainage": 5920,
      "zlevel": 58.237,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1108,
      "chainage": 5940,
      "zlevel": 58.701,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1109,
      "chainage": 5960,
      "zlevel": 59.109,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1110,
      "chainage": 5980,
      "zlevel": 59.459,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1111,
      "chainage": 6000,
      "zlevel": 59.752,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1112,
      "chainage": 6020,
      "zlevel": 59.988,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1113,
      "chainage": 6040,
      "zlevel": 60.167,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1114,
      "chainage": 6060,
      "zlevel": 60.288,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1115,
      "chainage": 6080,
      "zlevel": 60.353,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1116,
      "chainage": 6093,
      "zlevel": 60.364,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1117,
      "chainage": 6093,
      "zlevel": 60.364,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1118,
      "chainage": 6100,
      "zlevel": 60.36,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1119,
      "chainage": 6120,
      "zlevel": 60.31,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1120,
      "chainage": 6140,
      "zlevel": 60.203,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1121,
      "chainage": 6160,
      "zlevel": 60.039,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1122,
      "chainage": 6180,
      "zlevel": 59.818,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1123,
      "chainage": 6200,
      "zlevel": 59.54,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1124,
      "chainage": 6220,
      "zlevel": 59.204,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1125,
      "chainage": 6240,
      "zlevel": 58.811,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1126,
      "chainage": 6260,
      "zlevel": 58.361,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1127,
      "chainage": 6280,
      "zlevel": 57.854,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1128,
      "chainage": 6300,
      "zlevel": 57.29,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1129,
      "chainage": 6320,
      "zlevel": 56.668,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1130,
      "chainage": 6340,
      "zlevel": 55.989,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1131,
      "chainage": 6360,
      "zlevel": 55.253,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1132,
      "chainage": 6380,
      "zlevel": 54.46,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1133,
      "chainage": 6400,
      "zlevel": 53.61,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1134,
      "chainage": 6420,
      "zlevel": 52.702,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1135,
      "chainage": 6440,
      "zlevel": 51.736,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1136,
      "chainage": 6442,
      "zlevel": 51.635,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1137,
      "chainage": 6460,
      "zlevel": 50.737,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1138,
      "chainage": 6480,
      "zlevel": 49.737,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1139,
      "chainage": 6500,
      "zlevel": 48.738,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1140,
      "chainage": 6520,
      "zlevel": 47.738,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1141,
      "chainage": 6540,
      "zlevel": 46.738,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1142,
      "chainage": 6560,
      "zlevel": 45.738,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1143,
      "chainage": 6580,
      "zlevel": 44.739,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1144,
      "chainage": 6580,
      "zlevel": 44.725,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1145,
      "chainage": 6965,
      "zlevel": 29.523,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1146,
      "chainage": 7704,
      "zlevel": 22.476,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1147,
      "chainage": 7720,
      "zlevel": 22.666,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1148,
      "chainage": 7740,
      "zlevel": 22.906,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1149,
      "chainage": 7760,
      "zlevel": 23.146,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1150,
      "chainage": 7780,
      "zlevel": 23.386,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1151,
      "chainage": 7800,
      "zlevel": 23.626,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1152,
      "chainage": 7820,
      "zlevel": 23.866,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1153,
      "chainage": 7840,
      "zlevel": 24.106,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1154,
      "chainage": 7860,
      "zlevel": 24.346,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1155,
      "chainage": 7880,
      "zlevel": 24.586,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1156,
      "chainage": 7900,
      "zlevel": 24.826,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1157,
      "chainage": 7920,
      "zlevel": 25.066,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1158,
      "chainage": 7940,
      "zlevel": 25.306,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1159,
      "chainage": 7945,
      "zlevel": 25.369,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1160,
      "chainage": 7960,
      "zlevel": 25.571,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1161,
      "chainage": 7980,
      "zlevel": 25.921,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1162,
      "chainage": 8000,
      "zlevel": 26.36,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1163,
      "chainage": 8020,
      "zlevel": 26.888,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1164,
      "chainage": 8023,
      "zlevel": 26.971,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1165,
      "chainage": 8996,
      "zlevel": 44.631,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1166,
      "chainage": 9477,
      "zlevel": 46.068,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1167,
      "chainage": 9480,
      "zlevel": 45.986,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1168,
      "chainage": 9500,
      "zlevel": 45.401,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1169,
      "chainage": 9520,
      "zlevel": 44.716,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1170,
      "chainage": 9540,
      "zlevel": 43.931,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1171,
      "chainage": 9560,
      "zlevel": 43.046,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1172,
      "chainage": 9569,
      "zlevel": 42.621,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1173,
      "chainage": 9580,
      "zlevel": 42.075,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 1174,
      "chainage": 9600,
      "zlevel": 41.103,
      "radius": null,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 589,
      "chainage": 129,
      "zlevel": 41.113,
      "radius": 95.3,
      "segment_order_no": 1,
      "a_intersection": 0
    },
    {
      "segment_data_id": 588,
      "chainage": 120,
      "zlevel": 41.14,
      "radius": 95.3,
      "segment_order_no": 1,
      "a_intersection": 0
    }
  ]
}