import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { copyTrafficAlignments } from '../../../../actions/alignment'
import LabelSelectBox from '../../../common/LabelSelectBox'
import Popup from '../Popup'

const CopyTraffic = ({ trafficModal, onHide, alignments, projectId }) => {
    const dispatch = useDispatch()
    const [copyProcess, setCopyProcess] = useState(false)
    const [refAlignmentId, setRefAlignmentId] = useState(false);

    useEffect(() => {
        if (!!alignments && alignments.length > 1) {
            setRefAlignmentId(alignments[0].alternative_id)
        }
    }, [alignments])
    const submitCopyTraffic = async (e) => {
        e.preventDefault()
        try {
            setCopyProcess(true)
            await dispatch(copyTrafficAlignments(refAlignmentId, projectId))
            setCopyProcess(false)
            toast.success('Successfully Completed')
            onHide()
        } catch (err) {
            toast.error('Something went wrong, Please try again')
            setCopyProcess(false)
            onHide()
        }
    }

    if (!trafficModal) return null

    return (<Popup show={trafficModal} onHide={onHide} title="Copy Reference Alignment">
        <form onSubmit={submitCopyTraffic}>
            <LabelSelectBox label="Select Alignment" valueKey="alternative_id" options={alignments}
                handleOnChange={e => setRefAlignmentId(e.target.value)} />
            <button id="btn" className={`mt-2`} disabled={(!refAlignmentId || !!copyProcess)}>
                {copyProcess ? 'Loading...' : 'Copy Traffic'}
            </button>
        </form>
    </Popup>
    )
}

export default CopyTraffic