import React, { useState, useEffect, useRef, useContext } from "react"
import Map from "ol/Map.js";
import View from "ol/View.js";
import XYZ from 'ol/source/XYZ';
import TileLayer from 'ol/layer/Tile';
import * as proj from "ol/proj";
import Overlay from 'ol/Overlay.js';
import Select from 'ol/interaction/Select';
import { Button } from "react-bootstrap";
import axios from "axios";
import { createPointLayer } from '../../../../utils/maputils';
import FuelSaveGlobalContext from '../../../../context/FuelSaveGlobalContext';
import { replaceCordiStr } from "../../../../constant/helpers";
import './map.css';

const getCentreCoord = (directions) => {
  const segments_data = directions.segments_data
  const length = Math.floor(segments_data.length / 2)
  const first_geom = segments_data[length].geom;
  const centreCoord = JSON.parse(replaceCordiStr(first_geom));
  return centreCoord;
}

const InputMap = ({ directions, isNewSegEnable, setSegmentModalShow, addInterSec = false, editMap }) => {
  const {
    apiAccessToken,
    mapCoordinateId, setMapCoordinateId
  } = useContext(FuelSaveGlobalContext)

  const overlayRef = useRef(null);
  const segments = directions.segments
  const segments_data = directions.segments_data

  const centreCoord = getCentreCoord(directions);
  const posCentre = proj.fromLonLat(centreCoord.coordinates);

  const view = new View({ center: posCentre, zoom: 11 });

  function customLoader(tile, src) {
    axios({
      method: 'get',
      url: src,
      headers: {
        'Authorization': `Bearer ${apiAccessToken.accessToken}`
      }
    }).then((response) => {
      tile.getImage().src = response.data;
    })
  }

  let urlVal = process.env.REACT_APP_API_URL
  urlVal = urlVal.replace('/graphql', '')

  const XYZSource = new XYZ({
    url: `${urlVal}/apimaptiler/?url=api.maptiler.com/maps/voyager/{z}/{x}/{y}.png`
  })
  XYZSource.setTileLoadFunction(customLoader);

  const bgLayer = new TileLayer({ source: XYZSource });

  const [map] = useState(
    new Map({
      target: null,
      layers: [bgLayer
      ],
      view: view,
      pixelRatio: 1.0
    })
  );

  useEffect(() => {

    // HINT: We're not getting updated props value inside the OL select event
    window.editMap = editMap
  }, [editMap])

  const wrapperRef = useRef()

  const handleClickDetect = event => {
    if (!!wrapperRef &&
      (wrapperRef.current !== null) &&
      (event.target === wrapperRef.current)) {
      window.skipSelectEvent = true
    } else {
      window.skipSelectEvent = false
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickDetect)
    return () => {
      document.removeEventListener('mousedown', handleClickDetect)
    }
    // eslint-disable-next-line
  }, [])


  useEffect(() => {

    var layersToRemove = [];
    map.getLayers().forEach(function (layer) {

      if (layer.get('name') !== undefined && layer.get('name').includes('pointlayer')) {
        layersToRemove.push(layer)
      }
    })

    var len = layersToRemove.length;
    for (var i = 0; i < len; i++) {
      map.removeLayer(layersToRemove[i]);
    }

    // INIT POINTS
    const mapLayers = createPointLayer(segments_data, segments);

    mapLayers.map(lyr => {
      map.addLayer(lyr)
      return lyr
    });

    var select = null;
    var selectSingleClick = new Select();

    //  Basic overlay to show where i want the new center to be
    const overlay = new Overlay({
      position: posCentre,
      element: overlayRef.current,
      positioning: "center-center",
      stopEvent: false
    });

    map.addOverlay(overlay);
    map.on('singleclick', function (evt) {
      var coordinate = evt.coordinate;
      overlay.setPosition(coordinate)
    });

    if (select !== null) {
      map.removeInteraction(select)
    }
    select = selectSingleClick;

    if (select !== null) {
      map.addInteraction(select);
      select.on('select', function (e) {
        if (!!window.skipSelectEvent) {
          return false
        }
        // show on map
        if (e.selected[0] && !!window.editMap) {
          setMapCoordinateId(e.selected[0].values_.geom_order_id);
        } else {
          setMapCoordinateId(null);
        }
      });
    }
    // eslint-disable-next-line
  }, [])

  // USE EFFCT

  useEffect(() => {

    map.setTarget('map');
    if (editMap === true) {

      // remove old point layers 
      var layersToRemove = [];
      map.getLayers().forEach(function (layer) {
        if (layer.get('name') !== undefined && layer.get('name').includes('pointlayer')) {
          layersToRemove.push(layer)
        }
      })

      var len = layersToRemove.length;
      for (var i = 0; i < len; i++) {
        map.removeLayer(layersToRemove[i]);
      }

      const mapLayers = createPointLayer(segments_data, segments)

      mapLayers.map(lyr => {
        map.addLayer(lyr)
        return lyr
      })

      return () => {
        map.setTarget(null);
      };
    }
    // eslint-disable-next-line
  }, [editMap, isNewSegEnable])

  return (
    <>
      <div id="map" style={{ width: "100%", height: "60vh" }} ></div>
      <div className="ol-popup" ref={overlayRef} id="overlay" title="overlay">
        <div id="popup-content">
          {(mapCoordinateId !== null) && editMap &&
            <Button title={!!addInterSec ? 'Add Stop Sign' : 'Split here'} ref={wrapperRef}
              variant="outline-primary" id="split-pointer" onClick={() => setSegmentModalShow(true)}>{!!addInterSec ? 'Add Stop Sign' : 'Split here'}</Button>}
        </div>
      </div>
      <div />
    </>
  );
}

export default InputMap