import ACTIONS from './action';

function reducerPrediction(prediction, action) {
    switch (action.type) {
        case ACTIONS.PREDICTION:
            return action.payload;
        default:
            return prediction;
    }
}

function reducerProjects(project, action) {
    switch (action.type) {
        case ACTIONS.PROJECT_NAME_CHANGE:
            return action.payload;
        case ACTIONS.PROJECT_NUMBER_CHANGE:
            return action.payload;
        case ACTIONS.PROJECT_GET_ALL:
            return action.payload;
        default:
            return project;
    }
}


export { reducerPrediction, reducerProjects };