import axios from "axios"
import { toast } from "react-toastify"
import { CURRENCY, DEFAULT_EPSG } from "."
import { setAutoOut, setIsLicense } from "../actions/global"
import store from "../store"
import { fuelDistributionCarsFinland, fuelDistributionCarsNorway, fuelDistributionCarsSweden, fuelDistributionTrucksFinland, fuelDistributionTrucksNorway, fuelDistributionTrucksSweden } from "../utils/aiRequestBodyUtils"
import { colorCodes } from "./colors"

const {
    RED_1, RICH_BLACK, BLUE_1, BLUE_3, BLUE_4, BLUE_5, BLUE_6,
    GREEN_1, GREEN_2, GREEN_3, GREEN_4, GREEN_5, TEAL,
    LIGHT_CYAN, GREY_3, AQUA, WATER_SPOUT, WHITE_1
} = colorCodes

const { NORWAY, SWEDISH, DANISH, FINNISH } = CURRENCY

/**
 * Allowed only last
 * two digit after
 * decimal value
 * @param {*} val 
 * @returns 
 */
export const floatWithDeci = val => parseFloat(parseFloat(val).toFixed(2))


/**
 * Get Opposite Direction
 * @param {String} dir 
 * @returns 
 */
export const oppositeDir = dir => {
    switch (dir) {
        case 'East':
            return 'West'
        case 'West':
            return 'East'
        case 'North':
            return 'South'
        case 'South':
            return 'North'
        default:
            return null
    }
}

/**
 * Check Coordinate
 * System value
 * @param {String} val 
 * @returns 
 */
export const isCordValid = val => {
    let isValid = false
    if (!!val && ((val.length === 9) || (val.length === 10)) &&
        val.startsWith('EPSG:') &&
        ((parseInt(val.substr(5)) >= 1024) &&
            (parseInt(val.substr(5)) <= 32767)) &&
        /^\d+$/.test(val.substr(5))
    ) {
        isValid = true
    }
    return isValid
}


/**
 * Check the number of occurrence of
 * a char in string
 * @param {*} str 
 * @param {*} char 
 * @returns 
 */
export const countString = (str, char) => {
    let count = 0

    // looping through the items
    for (let i = 0; i < str.length; i++) {

        // check if the character is at that position
        if (str.charAt(i) === char) {
            count += 1
        }
    }
    return count
}


/**
 * Handle CSV Array
 * @param {Array} data 
 * @returns 
 */
export const handleCSVArr = data => {
    const arrResp = [];
    data.map((row, index) => {
        if (index !== 0) {
            let rowVal = row

            // HINT: Quick fix for the semicolon delimeter csv
            if (!!row && !!row[0] && ((row[1] === undefined) || (row[1] === 'undefined'))) {
                const semiCount = countString(row[0], ';')
                if (!!semiCount && semiCount === 11) {
                    rowVal = (row[0]).split(";")
                }
            }

            arrResp.push({
                id: !!rowVal[0] ? parseInt(rowVal[0]) : 0,
                chainage: !!rowVal[1] ? parseFloat(rowVal[1]) : 0,
                zlevel: !!rowVal[2] ? parseFloat(rowVal[2]) : 0,
                radius: !!rowVal[3] ? parseFloat(rowVal[3]) : 0,
                easting: !!rowVal[4] ? parseFloat(rowVal[4]) : 0,
                northing: !!rowVal[5] ? parseFloat(rowVal[5]) : 0,
                coordinate_system: !!rowVal[6] && !!isCordValid(rowVal[6]) ? rowVal[6] : DEFAULT_EPSG,
                direction: !!rowVal[7] ? rowVal[7] : '',
                direction_nr: !!rowVal[8] ? parseInt(rowVal[8]) : 0,
                alternative_name: !!rowVal[9] ? rowVal[9] : '',
                segment_id: !!rowVal[10] ? parseInt(rowVal[10]) : 0,
                intersection: !!rowVal[11] ? parseInt(rowVal[11]) : 0
            })
        }
        return false
    })
    return arrResp
}


/**
 * Handle request
 * @param {*} method 
 * @param {*} serverUrl 
 * @param {*} data 
 * @param {*} contentType 
 * @param {*} acceptType 
 * @returns 
 */
export const handleRequest = async (method, serverUrl = false, data, contentType = false, acceptType = false) => {
    const apiAccessTokenObj = JSON.parse(localStorage.getItem("apiAccessTokenObj"))
    return new Promise((resolve, reject) => {
        axios({
            method,
            url: !!serverUrl ? serverUrl : process.env.REACT_APP_API_URL,
            headers: {
                ...(!!apiAccessTokenObj && { 'Authorization': 'Bearer ' + apiAccessTokenObj.accessToken }),
                ...(acceptType && { 'Accept': acceptType }),
                ...(contentType && { 'Content-Type': contentType })
            },
            ...(!!data && { data })
        })
            .then(result => {
                store.dispatch(setAutoOut(false))
                return resolve(result)
            })
            .catch(e => {
                if (!!e && !!e.response && !!e.response.status && e.response.status === 401) {
                    store.dispatch(setAutoOut(true))

                    if (!!e.response.data.code && (e.response.data.code === 'NO_LICENSE')) {
                        store.dispatch(setIsLicense(false))
                    }
                }
                return reject(e)
            })
    })
}

/**
 * Chart Size for 
 * making responsive
 * @param {*} width 
 * @returns 
 */
export const chartSizeAccToWidth = width => {
    if (width >= 1536) {
        return 20
    } else if (width >= 1280) {
        return 11
    } else if (width >= 1097) {
        return 8
    } else if (width >= 900) {
        return 7
    } else if (width >= 640) {
        return 4
    } else if (width < 500) {
        return 5
    }
}


/**
 * Draw Vertical line
 * in graph
 */
export const drawVerticalLine = {
    getLinePosition: function (chart) {
        const { data } = chart.getDatasetMeta(0); // first dataset is used to discover X coordinate of a point
        const chartData = !!chart && !!chart.config && !!chart.config.data && !!chart.config.data.labels ? chart.config.data.labels : []
        const lineIndex = !!chart && !!chart.config && !!chart.config.lineAtIndex && !!chart.config.lineAtIndex[0] ? chart.config.lineAtIndex[0] : false

        if (!!chartData && !!lineIndex) {
            const lineIndexVal = chartData.indexOf(lineIndex)
            if ((lineIndexVal > -1) && !!data && !!data[lineIndexVal]) {
                return data[lineIndexVal]._model.x;
            }
        }
    },
    renderVerticalLine: function (chartInstance, pointIndex) {
        const lineLeftOffset = this.getLinePosition(chartInstance, pointIndex);
        const scale = chartInstance.scales['y-axis-0'];
        const context = chartInstance.chart.ctx;

        // render vertical line
        context.beginPath();
        context.strokeStyle = RED_1;
        context.moveTo(lineLeftOffset, scale.top);
        context.lineTo(lineLeftOffset, scale.bottom);
        context.stroke();
    },
    afterDatasetsDraw: function (chart) {
        if (chart.config.lineAtIndex) {
            chart.config.lineAtIndex.forEach(pointIndex => this.renderVerticalLine(chart, pointIndex));
        }
    }
}


/**
 * Sleep function
 * for some delay
 * @param {*} ms 
 * @returns 
 */
export const sleep = (ms = 3000) => new Promise(resolve => setTimeout(() => resolve(true), ms))


/**
 * Return presumtion
 * Object response
 * @param {*} p 
 * @param {*} fullObj 
 * @returns 
 */
export const presumptionObj = (p = {}, fullObj = true) => {
    return {
        Speed_car: (typeof p.speed_car !== 'undefined') ? p.speed_car : 80,
        Speed_truck: (typeof p.speed_truck !== 'undefined') ? p.speed_truck : 80,
        cost_increase: (typeof p.cost_increase !== 'undefined') ? p.cost_increase : 0.01,
        gasoline_fuelfactor: (typeof p.gasoline_fuelfactor !== 'undefined') ? p.gasoline_fuelfactor : 1,
        gasoline_co2: (typeof p.gasoline_co2 !== 'undefined') ? p.gasoline_co2 : 2.85,
        gasoline_kwh: (typeof p.gasoline_kwh !== 'undefined') ? p.gasoline_kwh : 9.1,
        gasoline_efficiency: (typeof p.gasoline_efficiency !== 'undefined') ? p.gasoline_efficiency : 0.25,
        gasoline_cost: (typeof p.gasoline_cost !== 'undefined') ? p.gasoline_cost : 16,
        diesel_fuelfactor: (typeof p.diesel_fuelfactor !== 'undefined') ? p.diesel_fuelfactor : 1,
        diesel_co2: (typeof p.diesel_co2 !== 'undefined') ? p.diesel_co2 : 2.73,
        diesel_kwh: (typeof p.diesel_kwh !== 'undefined') ? p.diesel_kwh : 9.8,
        diesel_efficiency: (typeof p.diesel_efficiency !== 'undefined') ? p.diesel_efficiency : 0.35,
        diesel_cost: (typeof p.diesel_cost !== 'undefined') ? p.diesel_cost : 16,
        battery_fuelfactor: (typeof p.battery_fuelfactor !== 'undefined') ? p.battery_fuelfactor : 0,
        battery_co2: (typeof p.battery_co2 !== 'undefined') ? p.battery_co2 : 0.0,
        battery_kwh: (typeof p.battery_kwh !== 'undefined') ? p.battery_kwh : 9.8,
        battery_efficiency: (typeof p.battery_efficiency !== 'undefined') ? p.battery_efficiency : 1,
        battery_cost: (typeof p.battery_cost !== 'undefined') ? p.battery_cost : 0,
        inchargeable_hybrid_fuelfactor: (typeof p.inchargeable_hybrid_fuelfactor !== 'undefined') ? p.inchargeable_hybrid_fuelfactor : 1,
        inchargeable_hybrid_co2: (typeof p.inchargeable_hybrid_co2 !== 'undefined') ? p.inchargeable_hybrid_co2 : 2.85,
        inchargeable_hybrid_kwh: (typeof p.inchargeable_hybrid_kwh !== 'undefined') ? p.inchargeable_hybrid_kwh : 9.8,
        inchargeable_hybrid_efficiency: (typeof p.inchargeable_hybrid_efficiency !== 'undefined') ? p.inchargeable_hybrid_efficiency : 0.35,
        inchargeable_hybrid_cost: (typeof p.inchargeable_hybrid_cost !== 'undefined') ? p.inchargeable_hybrid_cost : 16,
        chargeable_hybrid_fuelfactor: (typeof p.chargeable_hybrid_fuelfactor !== 'undefined') ? p.chargeable_hybrid_fuelfactor : 1,
        chargeable_hybrid_co2: ((typeof p.chargable_hybrid_co2 !== 'undefined') && !!p.chargable_hybrid_co2) ? p.chargable_hybrid_co2 : 2.85,
        chargeable_hybrid_kwh: (typeof p.chargeable_hybrid_kwh !== 'undefined') ? p.chargeable_hybrid_kwh : 9.8,
        chargeable_hybrid_efficiency: (typeof p.chargeable_hybrid_efficiency !== 'undefined') ? p.chargeable_hybrid_efficiency : 0.35,
        chargeable_hybrid_cost: (typeof p.chargeable_hybrid_cost !== 'undefined') ? p.chargeable_hybrid_cost : 16,
        hydrogen_fuelfactor: (typeof p.hydrogen_fuelfactor !== 'undefined') ? p.hydrogen_fuelfactor : 0,
        hydrogen_co2: (typeof p.hydrogen_co2 !== 'undefined') ? p.hydrogen_co2 : 0.0,
        hydrogen_kwh: (typeof p.hydrogen_kwh !== 'undefined') ? p.hydrogen_kwh : 9.8,
        hydrogen_efficiency: (typeof p.hydrogen_efficiency !== 'undefined') ? p.hydrogen_efficiency : 1,
        hydrogen_cost: (typeof p.hydrogen_cost !== 'undefined') ? p.hydrogen_cost : 0,
        iri: (typeof p.iri !== 'undefined') ? p.iri : 1.0,
        mpd: (typeof p.mpd !== 'undefined') ? p.mpd : 1.0,
        ...(!!fullObj && {
            iri_increase: (typeof p.iri_increase !== 'undefined') ? p.iri_increase : 1.0,
            mpd_increase: (typeof p.mpd_increase !== 'undefined') ? p.mpd_increase : 1.0,
            iri_limit: (typeof p.iri_limit !== 'undefined') ? p.iri_limit : 1.0
        })
    }
}

/**
 * Return presumtion
 * Object response
 * HINT: Some keys name are not exact
 * @param {*} p 
 * @param {*} fullObj 
 * @returns 
 */
export const presumptionObj2 = (p = {}, fullObj = true) => {
    return {
        speed_car: (typeof p.speed_car !== 'undefined') ? p.speed_car : 80,
        speed_truck: (typeof p.speed_truck !== 'undefined') ? p.speed_truck : 80,
        cost_increase: (typeof p.cost_increase !== 'undefined') ? p.cost_increase : 0.01,
        gasoline_fuelfactor: (typeof p.gasoline_fuelfactor !== 'undefined') ? p.gasoline_fuelfactor : 1,
        gasoline_co2: (typeof p.gasoline_co2 !== 'undefined') ? p.gasoline_co2 : 2.85,
        gasoline_kwh: (typeof p.gasoline_kwh !== 'undefined') ? p.gasoline_kwh : 9.1,
        gasoline_efficiency: (typeof p.gasoline_efficiency !== 'undefined') ? p.gasoline_efficiency : 0.25,
        gasoline_cost: (typeof p.gasoline_cost !== 'undefined') ? p.gasoline_cost : 16,
        diesel_fuelfactor: (typeof p.diesel_fuelfactor !== 'undefined') ? p.diesel_fuelfactor : 1,
        diesel_co2: (typeof p.diesel_co2 !== 'undefined') ? p.diesel_co2 : 2.73,
        diesel_kwh: (typeof p.diesel_kwh !== 'undefined') ? p.diesel_kwh : 9.8,
        diesel_efficiency: (typeof p.diesel_efficiency !== 'undefined') ? p.diesel_efficiency : 0.35,
        diesel_cost: (typeof p.diesel_cost !== 'undefined') ? p.diesel_cost : 16,
        battery_fuelfactor: (typeof p.battery_fuelfactor !== 'undefined') ? p.battery_fuelfactor : 0,
        battery_co2: (typeof p.battery_co2 !== 'undefined') ? p.battery_co2 : 0.0,
        battery_kwh: (typeof p.battery_kwh !== 'undefined') ? p.battery_kwh : 9.8,
        battery_efficiency: (typeof p.battery_efficiency !== 'undefined') ? p.battery_efficiency : 1,
        battery_cost: (typeof p.battery_cost !== 'undefined') ? p.battery_cost : 0,
        inchargeable_hybrid_fuelfactor: (typeof p.inchargeable_hybrid_fuelfactor !== 'undefined') ? p.inchargeable_hybrid_fuelfactor : 1,
        inchargeable_hybrid_co2: (typeof p.inchargeable_hybrid_co2 !== 'undefined') ? p.inchargeable_hybrid_co2 : 2.85,
        inchargeable_hybrid_kwh: (typeof p.inchargeable_hybrid_kwh !== 'undefined') ? p.inchargeable_hybrid_kwh : 9.8,
        inchargeable_hybrid_efficiency: (typeof p.inchargeable_hybrid_efficiency !== 'undefined') ? p.inchargeable_hybrid_efficiency : 0.35,
        inchargeable_hybrid_cost: (typeof p.inchargeable_hybrid_cost !== 'undefined') ? p.inchargeable_hybrid_cost : 16,
        chargeable_hybrid_fuelfactor: (typeof p.chargeable_hybrid_fuelfactor !== 'undefined') ? p.chargeable_hybrid_fuelfactor : 1,
        chargeable_hybrid_co2: ((typeof p.chargable_hybrid_co2 !== 'undefined') && !!p.chargable_hybrid_co2) ? p.chargable_hybrid_co2 : 2.85,
        chargeable_hybrid_kwh: (typeof p.chargeable_hybrid_kwh !== 'undefined') ? p.chargeable_hybrid_kwh : 9.8,
        chargeable_hybrid_efficiency: (typeof p.chargeable_hybrid_efficiency !== 'undefined') ? p.chargeable_hybrid_efficiency : 0.35,
        chargeable_hybrid_cost: (typeof p.chargeable_hybrid_cost !== 'undefined') ? p.chargeable_hybrid_cost : 16,
        hydrogen_fuelfactor: (typeof p.hydrogen_fuelfactor !== 'undefined') ? p.hydrogen_fuelfactor : 0,
        hydrogen_co2: (typeof p.hydrogen_co2 !== 'undefined') ? p.hydrogen_co2 : 0.0,
        hydrogen_kwh: (typeof p.hydrogen_kwh !== 'undefined') ? p.hydrogen_kwh : 9.8,
        hydrogen_efficiency: (typeof p.hydrogen_efficiency !== 'undefined') ? p.hydrogen_efficiency : 1,
        hydrogen_cost: (typeof p.hydrogen_cost !== 'undefined') ? p.hydrogen_cost : 0,
        iri: (typeof p.iri !== 'undefined') ? p.iri : 1.0,
        mpd: (typeof p.mpd !== 'undefined') ? p.mpd : 1.0,
        ...(!!fullObj && {
            iri_increase: (typeof p.iri_increase !== 'undefined') ? p.iri_increase : 1.0,
            mpd_increase: (typeof p.mpd_increase !== 'undefined') ? p.mpd_increase : 1.0,
            iri_limit: (typeof p.iri_limit !== 'undefined') ? p.iri_limit : 1.0
        })
    }
}


/**
 * Get Fuel distribution
 * data on bases of currency
 * @param {*} currency 
 * @returns 
 */
export const getFuelDistOnCurrency = currency => {
    let fuelDistributionCars = null
    let fuelDistributionTrucks = null
    switch (currency) {
        case NORWAY:
            fuelDistributionCars = fuelDistributionCarsNorway
            fuelDistributionTrucks = fuelDistributionTrucksNorway
            break;
        case DANISH:
        case FINNISH:
            fuelDistributionCars = fuelDistributionCarsFinland
            fuelDistributionTrucks = fuelDistributionTrucksFinland
            break;
        case SWEDISH:
            fuelDistributionCars = fuelDistributionCarsSweden
            fuelDistributionTrucks = fuelDistributionTrucksSweden
            break;
        default:
            break;
    }
    return { fuelDistributionCars, fuelDistributionTrucks }
}

export const scaleLabelConfig = labelString => {
    return {
        display: true,
        labelString,
        fontColor: BLUE_1,
        fontSize: 18
    }
}

export const scaleXCommonConfig = labelString => {
    return {
        scaleLabel: scaleLabelConfig(labelString),
        ticks: {
            fontSize: 13,
            fontColor: GREY_3
        }
    }
}

export const scaleYCommonConfig = labelString => {
    return {
        scaleLabel: scaleLabelConfig(labelString),
        ticks: {
            fontSize: 12,
            fontColor: GREY_3,
            beginAtZero: true,
        }
    }
}


export const getColorForMultiChart = (index = 0) => {
    const remainderIndex = index % 15
    const colorList = [BLUE_3, GREEN_2, RICH_BLACK, BLUE_4, GREEN_4, TEAL, BLUE_5, GREEN_1, AQUA, BLUE_1, GREEN_3, LIGHT_CYAN, BLUE_6, GREEN_5, WATER_SPOUT, WHITE_1]
    return colorList[remainderIndex]
}


/**
 * Replace some specail
 * character to coordinate
 * HINT: We'll revert that change after BE issue fixed
 * For now, we're receiving unwanted character in coordinate value
 * @param {*} val 
 * @returns 
 */
export const replaceCordiStr = (val = '') => (val.replaceAll('#IND', '0')).replaceAll('#INF', '0')


/**
 * Get Chainage value
 * for start distance
 * @param {*} idx 
 * @param {*} segments 
 * @returns 
 */
export const getStartDistanceToSegment = (idx, segments = []) => {
    let startDis = 0
    if (!!segments.length) {
        const record = segments.find(s => Number(s.geom_order_id) === Number(idx))
        startDis = !!record && !!record.chainage ? record.chainage : 0
    }
    return startDis
}

/**
 * Get Chainage step
 * value in between two
 * @param {*} segments 
 * @returns 
 */
export const getStepValue = segments => {
    if (!!segments && segments.length > 1) {
        if ((segments[0]['chainage'] !== 'undefined') && (segments[1]['chainage'] !== 'undefined')) {
            return parseInt(segments[1]['chainage'] - segments[0]['chainage'])
        }
    }
    return 0
}


/**
 * Get min and max
 * chainage value
 * @param {*} segments 
 * @returns 
 */
export const getMinMaxChainageVal = segments => {
    if (!!segments && segments.length > 1) {
        return {
            ...({ minChainage: (segments[0]['chainage'] !== 'undefined') ? segments[0]['chainage'] : 0 }),
            ...({ maxChainage: (segments[segments.length - 1]['chainage'] !== 'undefined') ? segments[segments.length - 1]['chainage'] : 0 })
        }
    }
    return { minChainage: 0, maxChainage: 0 }
}


/**
 * Flash Error message
 * @param {*} e 
 * @returns 
 */
export const flashErrMsg = e => {
    if (!!e && !!e.response && !!e.response.data && !!e.response.data.message) {
        toast.error(e.response.data.message)
    } else if (!!e && !!e.message) {
        toast.error(e.message)
    }
    return null
}


/**
 * Flash Error message in case
 * Success block
 * @param {*} resp 
 */
export const flashSuccessErrMsg = resp => {
    if (!!resp && !!resp.data && !!resp.data.errors && !!resp.data.errors[0] && !!resp.data.errors[0].message) {
        toast.error(resp.data.errors[0].message)
    }
}


/**
 * Get Full Year Value
 * @returns 
 */
export const currentFullYear = () => {
    const dateObj = new Date()
    return dateObj.getFullYear()
}