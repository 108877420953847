import { handleRequest } from "../constant/helpers"


/**
 * Get All Projects
 * @returns 
 */
export const getAllProjects = () => handleRequest("post", false, {
    query: `
              query{
                  projects{
                  project_name
                  project_id,
                  start_year,
                  end_year
                  }
              }`
})


/**
 * Get Project By ID
 * @param {*} id 
 * @returns 
 */
export const getProjectById = id => handleRequest("post", false, {
    query: `
        query{
            projects(project_ids:${id}){
                project_id, project_name,
                start_year, end_year
            }
          }`
})


/**
 * Add New Project
 * @param {*} data 
 * @returns 
 */
export const addNewProject = data => handleRequest('post', false, {
    query: `mutation addProject($data:addProjectInput!)
    {addProject(data: $data){
    project_id, project_name,
    start_year, end_year
    }}`,
    variables: { data }
}, 'application/json')


/**
 * Edit Project
 * @param {*} data 
 * @returns 
 */
export const editProjectById = data => handleRequest('post', false, {
    query: `mutation updateProject($data:editProjectInput!)
    {updateProject(data: $data){
    project_id, project_name,
    start_year, end_year
    }}`,
    variables: { data }
}, 'application/json')