import React from 'react'
import './index.scss'

const CostCircle = () => (
    <div className='center-flex f-right'>
        <div className='center-flex mr-10 energy-circle'>
            Energy
        </div>
        <div className='center-flex mr-10 cost-circle'>
            <div>Cost</div>
        </div>
        <div className='center-flex mr-10 co2-circle'>
            <div>CO<sub>2</sub></div>
        </div>
    </div>
)

export default CostCircle