import { Fragment } from "react"
import { PER_PAGE } from "../../../constant"
import "./index.scss"

const TablePagination = ({
    activePage, handlePageChange, total, perPage = PER_PAGE,
}) => {

    const numberOfPages = Math.ceil(total / perPage)
    if (!!total === false || numberOfPages <= 1) return null

    const doPaging = (activePage, numberOfPages) => {
        let pageNumberSet = []

        if (numberOfPages <= 6) {
            for (var i = 1; i <= numberOfPages; i++) {
                pageNumberSet.push(i)
            }
        }
        else {

            // Always print first page button
            pageNumberSet.push(1)

            // Print "..." if activePage is > 3
            if (activePage > 3) {
                pageNumberSet.push('...')
            }

            // special case where last page is selected...
            if (activePage === numberOfPages) {
                pageNumberSet.push(activePage - 2)
            }


            // Print previous number button if activePage > 3
            if (activePage > 2) {
                pageNumberSet.push(activePage - 1)
            }

            //Print current page number button as long as it not the first or last page
            if (activePage !== 1 && activePage !== numberOfPages) {
                pageNumberSet.push(activePage)
            }

            //print next number button if activePage < lastPage - 3
            if (activePage < numberOfPages - 1) {
                pageNumberSet.push(activePage + 1)
            }

            // special case where first page is selected...
            if (activePage === 1) {
                pageNumberSet.push(activePage + 2)
            }


            //print "..." if activePage is < lastPage -2
            if (activePage < numberOfPages - 2) {
                pageNumberSet.push('...')
            }

            //Always print last page button if there is more than 1 page
            if (numberOfPages > 1) {
                pageNumberSet.push(numberOfPages)
            }
        }
        return pageNumberSet
    }


    const generatePages = numberOfPages => {
        const uniqueArray = doPaging(activePage, numberOfPages)

        return uniqueArray.map((page, index) =>
            <Fragment key={index}>
                {
                    typeof page === 'number' ?
                        <button
                            className={`${(page === activePage) ? 'current-page' : ''}`}
                            onClick={() => handlePageChange(page)}
                        >
                            {page}
                        </button> :
                        <span>{page}</span>
                }
            </Fragment>
        )
    }

    return (
        <div className="waywize-table-pagination">
            <button onClick={() => activePage > 1 && handlePageChange(activePage - 1)} disabled={activePage <= 1}>{'<'}</button>{' '}
            {generatePages(numberOfPages)}
            <button disabled={activePage === numberOfPages} onClick={() => activePage !== numberOfPages && handlePageChange(activePage + 1)}>
                {'>'}
            </button>
        </div>
    )
}

export default TablePagination