const rangeArr = [
    { max: 100, dividend: 10 },
    { max: 1000, dividend: 100 },
    { max: 2000, dividend: 200 },
    { max: 3000, dividend: 300 },
    { max: 4000, dividend: 400 },
    { max: 5000, dividend: 500 },
    { max: 6000, dividend: 600 },
    { max: 7000, dividend: 700 },
    { max: 8000, dividend: 800 },
    { max: 9000, dividend: 900 },
    { max: 10000, dividend: 1000 },
    { max: 20000, dividend: 2000 },
    { max: 30000, dividend: 3000 },
    { max: 40000, dividend: 4000 },
    { max: 50000, dividend: 5000 },
    { max: 60000, dividend: 6000 },
    { max: 70000, dividend: 7000 },
    { max: 80000, dividend: 8000 },
    { max: 90000, dividend: 9000 },
    { max: 100000, dividend: 10000 },
    { max: 200000, dividend: 20000 },
    { max: 300000, dividend: 30000 },
    { max: 400000, dividend: 40000 },
    { max: 500000, dividend: 50000 },
    { max: 600000, dividend: 60000 },
    { max: 700000, dividend: 70000 },
    { max: 800000, dividend: 80000 },
    { max: 900000, dividend: 90000 },
    { max: 1000000, dividend: 100000 },
    { max: 2000000, dividend: 200000 },
    { max: 3000000, dividend: 300000 },
    { max: 4000000, dividend: 400000 },
    { max: 5000000, dividend: 500000 },
    { max: 6000000, dividend: 600000 },
    { max: 7000000, dividend: 700000 },
    { max: 8000000, dividend: 800000 },
    { max: 9000000, dividend: 900000 },
    { max: 10000000, dividend: 1000000 }
]

const arraySlab = multi => {
    let arr = []
    for (let i = 1; i <= 10; i++) {
        arr.push(i * multi)
    }
    return arr
}

/**
 * Create slab diffrence
 * array for chart's x-axis
 * @param {*} maxVal 
 * @returns 
 */
export const newSlabDiff = maxVal => {
    if (!maxVal) return [] // Return blank array if value not found

    for (var i = 0; i <= rangeArr.length; i++) {
        if (maxVal <= rangeArr[i].max) {
            return arraySlab(rangeArr[i].dividend)
        }
    }

    return []
}