import {
  SET_ALIGNMENT_ACTIVE_PAGE, SET_ALIGNMENT_PROCESSING,
  SET_CDR_ALL_ALIGNMENTS, SET_ALL_ALIGNMENTS, SET_CDR_ALIGNMENT_PROCESSING,
  SET_CDR_ALL_ALIGNMENTS_INPUT, SET_ALIGNMENT_INFO, UPDATE_ALIGNMENT_INFO
} from '../actions/alignment'

const defaultState = {
  isProcessing: false,
  isCDRProcessing: false,
  alignments: [],
  alignmentInfo: {},
  cdrAlignments: [],
  cdrAlignmentsInput: [],
  presumptions: [],
  activePage: 1,
  total: 0,
  project: {}
}

const Alignments = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ALIGNMENT_PROCESSING:
    case SET_CDR_ALIGNMENT_PROCESSING:
    case SET_ALL_ALIGNMENTS:
    case SET_ALIGNMENT_INFO:
    case SET_CDR_ALL_ALIGNMENTS:
    case SET_CDR_ALL_ALIGNMENTS_INPUT:
    case SET_ALIGNMENT_ACTIVE_PAGE:
      return Object.assign({}, state, action.payload)
    case UPDATE_ALIGNMENT_INFO: {
      const { dirId, segData } = action.payload
      let updatedSegData = (state.alignmentInfo.directions[dirId].segments_data).map(a => {
        if (!!segData.old_intersection_point && !!segData.new_intersection_point) {
          if (Number(a.geom_order_id) === Number(segData.old_intersection_point)) {
            a.a_intersection = 0
            return a
          } else if (Number(a.geom_order_id) === Number(segData.new_intersection_point)) {
            a.a_intersection = 1
            return a
          }
          return a
        } else if (Number(a.geom_order_id) === Number(segData.geom_order_id)) {
          return { ...a, ...segData }
        } else {
          return a
        }
      })

      state.alignmentInfo.directions[dirId].segments_data = updatedSegData
      return {
        ...state,
        isProcessing: false,
        alignmentInfo: {
          ...state.alignmentInfo,
          directions: [
            ...state.alignmentInfo.directions
          ]
        }
      }
    }
    default:
      return state
  }
}

export default Alignments