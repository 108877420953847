import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Form } from "react-bootstrap"
import { gql, useMutation } from '@apollo/client'
import FuelSaveGlobalContext from '../../../context/FuelSaveGlobalContext'
import SpinLoader2 from '../../common/SpinLoader2'
import GraphSection from './GraphSection'
import FormModal from './FormModal'
import ResponseTable from './ResponseTable'
import { MAX_YEAR, MIN_YEAR } from '../../../constant'
import { fetchAllCDRAlignments } from '../../../actions/alignment'
import './index.scss'

const UPDATE_CAP_INPUTS = gql`
mutation addUpdateCDRInvestmentMaintainance($project_id:ID!, $data:[AddCdrInvestmentMaintainanceInput]) { 
    addUpdateCDRInvestmentMaintainance(project_id:$project_id,data: $data) {
        alternative_id,year_value,investment_emssn,maintainance_emssn,is_active
    }
  }
`

const CDR = () => {
    const dispatch = useDispatch()
    const { setGlobalBrandCrumb } = useContext(FuelSaveGlobalContext)
    const { isProcessing } = useSelector(state => state.projects)
    const { projInfo, projectId } = useSelector(state => state.global)
    const alignmentState = useSelector(state => state.alignments)

    const alignments = alignmentState.cdrAlignments
    const alignmentLoading = alignmentState.isCDRProcessing
    const [cdrData, setCdrData] = useState({ baseId: '', endYear: 0 })
    const [showModal, setShowModal] = useState(false)
    const [formData, setFormData] = useState(alignmentState.cdrAlignments || [])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setGlobalBrandCrumb("Climate Declaration")
        dispatch(fetchAllCDRAlignments(projectId))

        // eslint-disable-next-line
    }, [projectId])

    useEffect(() => {
        const globalProjInfo = !!projInfo && Object.keys(projInfo).length ? projInfo : false
        if (!globalProjInfo || (!!globalProjInfo && !globalProjInfo.end_year)) {
            toast.error('End year value not found with this project')
        } else {
            setCdrData({ ...cdrData, endYear: globalProjInfo.end_year })
        }

        if (!!alignments && !!alignments.length) {
            const newArr = []

            setCdrData({
                ...cdrData,
                ...(!!globalProjInfo && !!globalProjInfo.end_year && { endYear: globalProjInfo.end_year }),
                baseId: alignments[0].alternative_id
            })
            alignments.map(g => {
                newArr.push({
                    ...g,
                    name: g.name || '',
                    investment_emssn: g.investment_emssn || 0,
                    maintainance_emssn: g.maintainance_emssn || 0,
                    baseId: g.alternative_id
                })
                return g
            })
            setFormData(newArr)
        }
        // eslint-disable-next-line
    }, [alignments])

    const handleChange = (e, i, key) => {
        formData[i][key] = (e.target.value).replace(/[^0-9]/g, '')
        setFormData([...formData])
    }

    const setEndYearVal = e => {
        const { value } = e.target
        setCdrData({ ...cdrData, endYear: value > MAX_YEAR ? MAX_YEAR : value })
    }

    const validateInput = name => {
        switch (name) {
            case 'end_year':
                return !cdrData || !cdrData.endYear || cdrData.endYear > MAX_YEAR || cdrData.endYear < MIN_YEAR
            default:
                return false
        }
    }

    const [updateCapInputs, { loading: updateLoading }] = useMutation(UPDATE_CAP_INPUTS, {
        onCompleted: ((data) => {
            // TODO: We'll remove this part
            if (!!data && !!data.addUpdateCDRInvestmentMaintainance) {
                setShowModal(false)
                toast.success('Updated Successfully.')
            }
        })
    })

    const handleUpdateCapInputs = async (formDataVal) => {
        const dataArr = []
        formDataVal.map(f => {
            dataArr.push({
                alternative_id: f.baseId,
                year_value: (cdrData.endYear).toString(),
                investment_emssn: (f.investment_emssn).toString(),
                maintainance_emssn: (f.maintainance_emssn).toString(),
                is_active: "1"
            })
            return f
        })
        updateCapInputs(
            {
                variables: {
                    project_id: projectId,
                    data: dataArr
                }
            }
        )
    }

    const isInValid = cdrData.endYear > MAX_YEAR || cdrData.endYear < MIN_YEAR

    return (
        <div className="container cdr-container">
            <FormModal show={showModal} onHide={() => setShowModal(false)}>
                <div className="d-flex">
                    <input type="text" value={cdrData.endYear} readOnly disabled />
                    <div className="ml-3">Selected calculation end year</div>
                </div>
                <div className="d-table mt-5 mb-5">
                    <div className="d-thead">
                        <div className="d-td">Alignment Name</div>
                        <div className="d-td">Emissions - Investment (Kg)</div>
                        <div className="d-td">Emissions - Maintenance (Kg)</div>
                    </div>
                    {!!formData && !!formData.length &&
                        <div className="d-tbody">{
                            formData.map((g, i) =>
                                <div className="d-tr" key={i}>
                                    <div className="d-td">{g.name}</div>
                                    <div className="d-td"><input value={g.investment_emssn} onChange={e => handleChange(e, i, 'investment_emssn')} /></div>
                                    <div className="d-td"><input value={g.maintainance_emssn} onChange={e => handleChange(e, i, 'maintainance_emssn')} /></div>
                                </div>)
                        }
                        </div>
                    }
                </div>
                <button id="btn" disabled={!!isLoading} onClick={() => handleUpdateCapInputs(formData)} >
                    {isLoading ? 'Loading...' : 'Update'}
                </button>
            </FormModal>
            {(!!isProcessing || !!alignmentLoading || !!isLoading || !!updateLoading) && <SpinLoader2 />}
            <div style={{ height: 100 }} className="f-end-flex">
                <div className="export-pdf-btn center-flex" onClick={() => alert('Coming Soon')}>
                    Export As PDF
                </div>
            </div>

            <div className="form-holder">
                <div className="pt-2 pb-2">
                    <label htmlFor="select-base-case">Select Base Case</label>
                    {!!alignments && !!alignments.length ?
                        <select onChange={e => setCdrData({ ...cdrData, baseId: e.target.value })} id="select-base-case">
                            <option disabled>Select Base Case:</option>
                            {alignments.map((op, i) =>
                                <option key={i} value={op.alternative_id}>{op.name}</option>
                            )}
                        </select>
                        : 'No Projects'}
                </div>

                <div className="pt-2 pb-2 d-flex">
                    <Form.Group className="mb-3 d-flex" controlId="select-calc-end-year">
                        <Form.Label className="m-0 ai-center d-flex">Select Calculation End Year</Form.Label>
                        <div>
                            <Form.Control type="number" placeholder="End Year" value={cdrData.endYear} onChange={e => setEndYearVal(e)} isInvalid={validateInput('end_year')} />
                            <Form.Control.Feedback type="invalid">Value should be in between {MIN_YEAR} and {MAX_YEAR}</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                </div>

                <div className="pt-2 pb-2">
                    <button type="button" disabled={isInValid} onClick={() => setShowModal(true)} className="submit-fm-btn center-flex">
                        Input Cap/OpCarb Emission
                    </button>
                </div>
            </div>

            <div className="response-holder d-flex mt-20 mb-20">
                <GraphSection setIsLoading={setIsLoading} setFormData={setFormData} endYear={cdrData.endYear} baseId={cdrData.baseId} formData={formData} />
                <ResponseTable baseId={cdrData.baseId} formData={formData} alignmentState={alignmentState} setIsLoading={setIsLoading} />
            </div>
        </div>
    )
}

export default CDR