import { SET_ALL_PRESUMPTIONS, SET_PRESUMPTION_PROCESSING } from '../actions/presumption'

const defaultState = {
  isProcessing: false,
  presumptions: []
}

const Presumptions = (state = defaultState, action) => {
  switch (action.type) {
    case SET_PRESUMPTION_PROCESSING:
    case SET_ALL_PRESUMPTIONS:
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}

export default Presumptions