import React, { Fragment } from 'react'
import { Form } from 'react-bootstrap'
import CSVImporter from '../CSVImporter'
import LandXMLImporter from '../LandXMLImporter'
import XMLCSVTab from '../XMLCSVTab'

const AddAlignment = ({
    inputRef, onSubmit, errResp, xmlLoader,
    xmlData, setXMLData, handleBlurNFocus, setActiveXMLAlign,
    handleXmlChoose, updateXmlData, isCsvActive, setActiveTab, validateForm
}) => (
    <Fragment>
        <XMLCSVTab isCsvActive={isCsvActive} setActiveTab={setActiveTab} />
        <Form onSubmit={onSubmit}>
            {
                isCsvActive ?
                    <CSVImporter inputRef={inputRef} /> :
                    <LandXMLImporter
                        errResp={errResp}
                        data={xmlData.dataList}
                        xmlData={xmlData}
                        xmlLoader={xmlLoader}
                        alignmentOpts={xmlData.alignmentOpts}
                        selectedOpts={xmlData.selected}
                        setXMLData={setXMLData}
                        handleBlurNFocus={handleBlurNFocus}
                        setActiveXMLAlign={setActiveXMLAlign}
                        handleXmlChoose={handleXmlChoose}
                        updateXmlData={updateXmlData}
                    />
            }
            {!!errResp && !!errResp.otherError && <p className='err-msg m-0 p-3'>{errResp.otherError}</p>}
            <button id="btn" className={`mt-2 ${isCsvActive ? '' : 'xml-uploader-btn center-flex'}`}
                disabled={!validateForm()}>
                {isCsvActive ? 'Submit' : (xmlLoader ? 'Loading...' : 'Generate & Upload')}
            </button>
        </Form>
    </Fragment>
)

export default AddAlignment