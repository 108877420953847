import * as proj from "ol/proj";
import Feature from "ol/Feature";
// import MultiPolygon from "ol/geom/MultiPolygon";
import Point from "ol/geom/Point";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import _ from "lodash";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { colorCodes } from "../constant/colors";
import { replaceCordiStr } from "../constant/helpers";

const { GREEN_1, WHITE_1, RED_1 } = colorCodes
export const fcStyles = {
  Point: [
    new Style({
      image: new CircleStyle({
        radius: 4,
        fill: new Fill({
          color: RED_1,
        }),
        stroke: new Stroke({
          color: RED_1,
        }),
      }),
    }),
  ],
  Point2: [
    new Style({
      image: new CircleStyle({
        radius: 4,
        fill: new Fill({
          color: "blue",
        }),
        stroke: new Stroke({
          color: "blue",
        }),
      }),
    }),
  ],
  Point3: [
    new Style({
      image: new CircleStyle({
        radius: 4,
        fill: new Fill({
          color: "yellow",
        }),
        stroke: new Stroke({
          color: "yellow",
        }),
      }),
    }),
  ],
};

export const polygonStyles = {
  SectionalComparePoint: [
    new Style({
      image: new CircleStyle({
        radius: 4,
        fill: new Fill({
          color: WHITE_1,
        }),
        stroke: new Stroke({
          color: "green",
        }),
      }),
    }),
  ],
  SectionalPoint: [
    new Style({
      image: new CircleStyle({
        radius: 4,
        fill: new Fill({
          color: WHITE_1,
        }),
        stroke: new Stroke({
          color: "blue",
        }),
      }),
    }),
  ],
  Point: [
    new Style({
      image: new CircleStyle({
        radius: 4,
        fill: new Fill({
          color: WHITE_1,
        }),
        stroke: new Stroke({
          color: WHITE_1,
        }),
      }),
    }),
  ],
  Point2: [
    new Style({
      image: new CircleStyle({
        radius: 4,
        fill: new Fill({
          color: GREEN_1,
        }),
        stroke: new Stroke({
          color: GREEN_1,
        }),
      }),
    }),
  ],
  MultiPolygonDark: new Style({
    stroke: new Stroke({
      color: "grey",
      lineDash: [4],
      width: 3,
    }),
    fill: new Fill({
      color: "rgb(23, 38, 120,0.7)",
    }),
  }),
  MultiPolygonWhite: new Style({
    stroke: new Stroke({
      color: WHITE_1,
      width: 1,
    }),
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.5)",
    }),
  }),
};

export const compareChainage = (a, b) => {
  if (a.chainage < b.chainage) {
    return -1;
  }
  if (a.chainage > b.chainage) {
    return 1;
  }
  return 0;
}

export const normalDistribution = (fc_data) => {
  // get fcHeavy
  const arr = _.map(fc_data, "fuelconsumption_trucks");
  const asc = arr.sort((a, b) => a - b);
  const count = asc.length;

  if (count > 0) {
    const perc75 = Math.floor(count * 0.75);
    const perc50 = Math.floor(count * 0.5);
    const perc25 = Math.floor(count * 0.25);

    return {
      perc75: asc[perc75],
      perc50: asc[perc50],
      perc25: asc[perc25],
    };
  }

  return {
    perc75: null,
    perc50: null,
    perc25: null,
  };
};

const styleFunction = function (feature, resolution) {
  let color = "grey";

  const values = feature.values_;
  if (values.fuelconsumption_trucks) {
    const value = values.fuelconsumption_trucks;
    const perc25 = values.perc25;
    const perc75 = values.perc75;
    const perc50 = values.perc50;

    if (value < perc25) {
      color = "green";
    } else if (value > perc25 && value <= perc50) {
      color = "yellow";
    } else if (value > perc50 && value <= perc75) {
      color = "orange";
    } else if (value > perc75) {
      color = RED_1;
    } else {
      color = "grey";
    }
  }

  return [
    new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: color,
        }),
        stroke: new Stroke({
          color: color,
          width: 3,
        }),
        radius: 5,
      }),
    }),
  ];
};

export const getCentreCoord = (directions) => {
  const segments_data = directions.segments_data;
  const length = Math.floor(segments_data.length / 2);
  const first_geom = segments_data[length].geom;
  const centreCoord = JSON.parse(replaceCordiStr(first_geom));
  return centreCoord;
};

const filteredSegments = (origSegments, zoom) => {
  let maxVal = 40;

  if (origSegments.length <= 40) {
    return origSegments;
  }

  if (zoom <= 11) {
    maxVal = 40;
  } else if (zoom > 11 && zoom <= 13) {
    maxVal = 80;
  } else if (zoom > 11 && zoom <= 14) {
    maxVal = 100;
  } else {
    maxVal = 120;
  }

  const count = origSegments.length;
  let arr = [];

  const delta = Math.floor(count / maxVal);

  // avoid filter because you don't want
  // to loop over 10000 elements !
  // just access them directly with a for loop !

  for (let i = 0; i < origSegments.length; i = i + delta) {
    arr.push(origSegments[i]);
  }
  return arr;
};

export const createPointDataLayer = (
  orig_segments_data,
  segments,
  perc75,
  perc50,
  perc25,
  zoom
) => {
  const segments_data =
    zoom <= 15
      ? filteredSegments(orig_segments_data, zoom)
      : orig_segments_data;
  const pointFeatures = [];

  segments_data.map((segment) => {
    const orderNo = segment.segment_order_no;
    const geomOrderId = segment.geom_order_id;
    const first_geom = segment.geom;
    const coord = JSON.parse(replaceCordiStr(first_geom));

    const pointFeature = new Feature({
      geometry: new Point(proj.fromLonLat(coord.coordinates)),
      name: "pointlayer",
    });
    pointFeature.set("chainage", segment.chainage);
    pointFeature.set("curvature", segment.curvature);
    pointFeature.set("fuelconsumption_car", segment.fuelconsumption_car);
    pointFeature.set("fuelconsumption_trucks", segment.fuelconsumption_trucks);
    pointFeature.set("hilliness", segment.hilliness);
    pointFeature.set("speedprofile_car", segment.speedprofile_car);
    pointFeature.set("speedprofile_trucks", segment.speedprofile_trucks);
    pointFeature.set("zlevel", segment.zlevel);
    pointFeature.set("geom_order_id", geomOrderId);
    pointFeature.set("segment_order_no", orderNo);
    pointFeature.set("perc75", perc75 != null ? perc75 : null);
    pointFeature.set("perc50", perc50 != null ? perc50 : null);
    pointFeature.set("perc25", perc25 != null ? perc25 : null);
    pointFeatures.push(pointFeature);
    return segment
  });

  const pointsVectorSource = new VectorSource({
    features: pointFeatures,
    wrapX: false,
  });

  const pointsLayer = new VectorLayer({
    source: pointsVectorSource,
    style: styleFunction,
  });

  // add unique name to data layer
  pointsLayer.set("name", "pointlayer");

  return pointsLayer;
};

export const createPointDataLayerComparitive = (
  orig_segments_data,
  segments,
  perc75,
  perc50,
  perc25,
  zoom
) => {
  const segments_data =
    zoom <= 15
      ? filteredSegments(orig_segments_data, zoom)
      : orig_segments_data;
  const pointFeatures = [];

  segments_data.map((segment) => {
    const orderNo = segment.segment_order_no;
    const geomOrderId = segment.geom_order_id;
    const first_geom = segment.geom;
    const coord = JSON.parse(replaceCordiStr(first_geom));

    const pointFeature = new Feature({
      geometry: new Point(proj.fromLonLat(coord.coordinates)),
      name: "pointlayer",
    });
    pointFeature.set("chainage", segment.chainage);
    pointFeature.set("curvature", segment.curvature);
    pointFeature.set("fuelconsumption_car", segment.fuelconsumption_car);
    pointFeature.set("fuelconsumption_trucks", segment.fuelconsumption_trucks);
    pointFeature.set("hilliness", segment.hilliness);
    pointFeature.set("speedprofile_car", segment.speedprofile_car);
    pointFeature.set("speedprofile_trucks", segment.speedprofile_trucks);
    pointFeature.set("zlevel", segment.zlevel);
    pointFeature.set("geom_order_id", geomOrderId);
    pointFeature.set("segment_order_no", orderNo);
    pointFeature.set("perc75", perc75 != null ? perc75 : null);
    pointFeature.set("perc50", perc50 != null ? perc50 : null);
    pointFeature.set("perc25", perc25 != null ? perc25 : null);
    pointFeatures.push(pointFeature);
    return segment
  });

  const pointsVectorSource = new VectorSource({
    features: pointFeatures,
    wrapX: false,
  });

  const pointsLayer = new VectorLayer({
    source: pointsVectorSource,
    style: styleFunction,
  });

  // add unique name to data layer
  pointsLayer.set("name", "pointlayerComparitive");

  return pointsLayer;
};




export const createPointLayer = (segments_data, segments) => {
  const slength = segments.length;
  const featureArrays = [];

  for (let i = 1; i < slength + 1; i++) {
    segments_data.map((segment) => {
      const orderNo = segment.segment_order_no;
      if (orderNo === i) {
        const geomOrderId = segment.geom_order_id;
        const first_geom = segment.geom;
        const coord = JSON.parse(replaceCordiStr(first_geom));

        const pointFeature = new Feature({
          geometry: new Point(proj.fromLonLat(coord.coordinates)),
          name: "pointlayer",
        });

        // add metadata point id
        pointFeature.set("geom_order_id", geomOrderId);
        pointFeature.set("segment_order_no", orderNo);
        const pointFeatures = featureArrays[i] ? featureArrays[i] : [];
        pointFeatures.push(pointFeature);
        featureArrays[i] = pointFeatures;
      }
      return segment
    });
  }

  const vectorSouces = [];

  featureArrays.map((featureArray) => {
    const pointsVectorSource = new VectorSource({
      features: featureArray,
      wrapX: false,
    });
    vectorSouces.push(pointsVectorSource);
    return featureArray
  });

  const layers = [];
  let odds = false;

  vectorSouces.map((vectorSource, i) => {
    odds = !odds;
    const pStyle = odds ? polygonStyles.Point2 : polygonStyles.Point;
    const pointsLayer = new VectorLayer({
      source: vectorSource,
      style: pStyle,
    });

    // add unique name to layer
    pointsLayer.set("name", "pointlayer");
    layers.push(pointsLayer);
    return vectorSouces
  });

  return layers;
};

export const createSectionalComparePointLayer = (
  orig_segments_data, segments, zoom, mapCentreIdData, previousMapCentreIdData
) => {
  const segments_data = zoom <= 15 ? filteredSegments(orig_segments_data, zoom) : orig_segments_data;
  const pointFeatures = [];

  segments_data.sort(compareChainage);

  const prev = previousMapCentreIdData.geom_order_id
  const curr = mapCentreIdData.geom_order_id;

  let minChainage = previousMapCentreIdData.geom_order_id;
  let maxChainage = mapCentreIdData.geom_order_id;

  if (curr < prev) {
    minChainage = mapCentreIdData.geom_order_id;
    maxChainage = previousMapCentreIdData.geom_order_id;
  }


  segments_data.map((segment) => {

    if (segment.geom_order_id >= minChainage && segment.geom_order_id <= maxChainage) {

      const orderNo = segment.segment_order_no;
      const geomOrderId = segment.geom_order_id;
      const first_geom = segment.geom;
      const coord = JSON.parse(replaceCordiStr(first_geom));

      const pointFeature = new Feature({
        geometry: new Point(proj.fromLonLat(coord.coordinates)),
        name: "pointlayer",
      });

      pointFeature.set("chainage", segment.chainage);
      pointFeature.set("curvature", segment.curvature);
      pointFeature.set("fuelconsumption_car", segment.fuelconsumption_car);
      pointFeature.set("fuelconsumption_trucks", segment.fuelconsumption_trucks);
      pointFeature.set("hilliness", segment.hilliness);
      pointFeature.set("speedprofile_car", segment.speedprofile_car);
      pointFeature.set("speedprofile_trucks", segment.speedprofile_trucks);
      pointFeature.set("zlevel", segment.zlevel);
      pointFeature.set("geom_order_id", geomOrderId);
      pointFeature.set("segment_order_no", orderNo);
      pointFeatures.push(pointFeature);
    }

    return segment
  });

  const pointsVectorSource = new VectorSource({
    features: pointFeatures,
    wrapX: false,
  });

  const pointsLayer = new VectorLayer({
    source: pointsVectorSource,
    style: polygonStyles.SectionalComparePoint,
  });

  // add unique name to data layer
  pointsLayer.set("name", "pointlayer");

  return pointsLayer;
};



/**
 * 
 * @param {*} orig_segments_data 
 * @param {*} segments 
 * @param {*} zoom 
 */
export const createSectionalPointLayer = (
  orig_segments_data,
  segments,
  zoom
) => {
  const segments_data =
    zoom <= 15
      ? filteredSegments(orig_segments_data, zoom)
      : orig_segments_data;
  const pointFeatures = [];

  segments_data.map((segment) => {
    const orderNo = segment.segment_order_no;
    const geomOrderId = segment.geom_order_id;
    const first_geom = segment.geom;
    const coord = JSON.parse(replaceCordiStr(first_geom));

    const pointFeature = new Feature({
      geometry: new Point(proj.fromLonLat(coord.coordinates)),
      name: "pointlayer",
    });
    pointFeature.set("chainage", segment.chainage);
    pointFeature.set("curvature", segment.curvature);
    pointFeature.set("fuelconsumption_car", segment.fuelconsumption_car);
    pointFeature.set("fuelconsumption_trucks", segment.fuelconsumption_trucks);
    pointFeature.set("hilliness", segment.hilliness);
    pointFeature.set("speedprofile_car", segment.speedprofile_car);
    pointFeature.set("speedprofile_trucks", segment.speedprofile_trucks);
    pointFeature.set("zlevel", segment.zlevel);
    pointFeature.set("geom_order_id", geomOrderId);
    pointFeature.set("segment_order_no", orderNo);
    pointFeatures.push(pointFeature);
    return segment
  });

  const pointsVectorSource = new VectorSource({
    features: pointFeatures,
    wrapX: false,
  });



  const pointsLayer = new VectorLayer({
    source: pointsVectorSource,
    style: polygonStyles.SectionalPoint,
  });

  // add unique name to data layer
  pointsLayer.set("name", "pointlayer");

  return pointsLayer;
};

export const correctAlternativeForMap_backup = (dataAlternative) => {

  if (dataAlternative) {
    const newDataAlternatives = JSON.parse(JSON.stringify(dataAlternative))
    let toCompareStartIndex = 1
    // const oldStartIndex = 0
    // const previousNumber = 1

    for (let index = 0; index < newDataAlternatives.length; index++) {
      for (let indexDIR = 0; indexDIR < newDataAlternatives[index].directions.length; indexDIR++) {
        for (let indexSEG = 0; indexSEG < newDataAlternatives[index].directions[indexDIR].segments.length; indexSEG++) {

          const startIndex = newDataAlternatives[index].directions[indexDIR].segments[indexSEG].startIndex

          for (let indexSEGDATA = 0; indexSEGDATA < newDataAlternatives[index].directions[indexDIR].segments_data.length; indexSEGDATA++) {
            if (startIndex === 0) {
              newDataAlternatives[index].directions[indexDIR].segments[indexSEG]["segment_order_no"] = toCompareStartIndex
              newDataAlternatives[index].directions[indexDIR].segments_data[indexSEGDATA]["segment_order_no"] = toCompareStartIndex
            }
            else if (startIndex > toCompareStartIndex) {
              toCompareStartIndex = toCompareStartIndex + 1
              newDataAlternatives[index].directions[indexDIR].segments[indexSEG]["segment_order_no"] = toCompareStartIndex
              newDataAlternatives[index].directions[indexDIR].segments_data[indexSEGDATA]["segment_order_no"] = toCompareStartIndex
            }
            else {
              newDataAlternatives[index].directions[indexDIR].segments[indexSEG]["segment_order_no"] = toCompareStartIndex
              newDataAlternatives[index].directions[indexDIR].segments_data[indexSEGDATA]["segment_order_no"] = toCompareStartIndex
            }
          }
        }
      }
    }
    return newDataAlternatives
  }

}



export const correctAlternativeForMap = (dataAlternative) => {

  if (dataAlternative) {
    const newDataAlternatives = JSON.parse(JSON.stringify(dataAlternative))
    for (let index = 0; index < newDataAlternatives.length; index++) {
      const dirLength = !!newDataAlternatives && !!newDataAlternatives[index] && !!newDataAlternatives[index].directions ? newDataAlternatives[index].directions.length : 0
      for (let indexDIR = 0; indexDIR < dirLength; indexDIR++) {
        let toCompareStartIndex = 1
        let oldStartIndex = 0
        const listStartIndex = []

        const segLength = !!dirLength && !!newDataAlternatives[index].directions[indexDIR] && !!newDataAlternatives[index].directions[indexDIR].segments ? newDataAlternatives[index].directions[indexDIR].segments.length : 0
        for (let indexSEG = 0; indexSEG < segLength; indexSEG++) {
          let startIndex = newDataAlternatives[index].directions[indexDIR].segments[indexSEG].start_index
          if (startIndex === 0) {
            newDataAlternatives[index].directions[indexDIR].segments[indexSEG]["segment_order_no"] = toCompareStartIndex
          }
          else if (startIndex > oldStartIndex) {
            toCompareStartIndex = toCompareStartIndex + 1
            oldStartIndex = startIndex
            listStartIndex.push(startIndex)
            newDataAlternatives[index].directions[indexDIR].segments[indexSEG]["segment_order_no"] = toCompareStartIndex

          }
          else {
            newDataAlternatives[index].directions[indexDIR].segments[indexSEG]["segment_order_no"] = toCompareStartIndex
          }
        }
        let orderNumber = 1
        const segDataLength = !!dirLength && !!newDataAlternatives[index].directions[indexDIR] && !!newDataAlternatives[index].directions[indexDIR].segments_data ? newDataAlternatives[index].directions[indexDIR].segments_data.length : 0
        for (let indexSEGDATA = 0; indexSEGDATA < segDataLength; indexSEGDATA++) {
          if (indexSEGDATA === 0) {
            newDataAlternatives[index].directions[indexDIR].segments_data[indexSEGDATA]["segment_order_no"] = orderNumber
          } else if (listStartIndex.includes(indexSEGDATA)) {
            orderNumber = orderNumber + 1
            newDataAlternatives[index].directions[indexDIR].segments_data[indexSEGDATA]["segment_order_no"] = orderNumber
          }
          else {
            newDataAlternatives[index].directions[indexDIR].segments_data[indexSEGDATA]["segment_order_no"] = orderNumber
          }
        }
      }
    }
    return newDataAlternatives
  }

}
/*** Making this for direction */



export const correctDirectionForMap = (directions) => {

  if (directions) {

    let toCompareStartIndex = 1
    // const orderNumber = 1
    // const previousNumber = 1


    for (let i = 0; i < directions.segments.length; i++) {
      const startIndex = directions.segments[i].startIndex

      for (let index = 0; index < directions.segments_data.length; index++) {
        if (startIndex === 0) {
          directions.segments[i]["segment_order_no"] = toCompareStartIndex
          directions.segments_data[index]["segment_order_no"] = toCompareStartIndex
        }
        else if (startIndex > toCompareStartIndex) {
          toCompareStartIndex = toCompareStartIndex + 1
          directions.segments[i]["segment_order_no"] = toCompareStartIndex
          directions.segments_data[index]["segment_order_no"] = toCompareStartIndex
        }
        else {
          directions.segments[i]["segment_order_no"] = toCompareStartIndex
          directions.segments_data[index]["segment_order_no"] = toCompareStartIndex
        }
      }
    }
  }

  return directions
}

