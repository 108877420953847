import React, { useState, useEffect, useContext } from "react";
import TrafficInputFormIndividual from "./traffic/TrafficInputFormIndividual";
import { Tab, Container, Tabs } from "react-bootstrap";
import FuelSaveGlobalContext from "../../../context/FuelSaveGlobalContext";


const Settings = ({ match }) => {
  const { setGlobalBrandCrumb } = useContext(FuelSaveGlobalContext);

  const [key, setKey] = useState('traffic');

  useEffect(() => {
    setGlobalBrandCrumb("Individual Settings")
    // eslint-disable-next-line
  }, [])

  return (
    <Container>
      <Tabs transition={false} id="noanim-tab-example" className="tab-body"
        activeKey={key}
        onSelect={(k) => setKey(k)}>
        <Tab eventKey="traffic" title="Traffic ">
          <TrafficInputFormIndividual alternative_id={match.params.alternative_id} />
        </Tab>
      </Tabs>
    </Container>
  )
}

export default Settings