import React, { useState, useEffect } from "react";
import axios from "axios";
import "./chartMixedsectional.css";
import { requestBody4 } from "../../../utils/aiRequestBodyUtils";
// import Box from '@material-ui/core/Box';
import {
    Container,
    Table,
    Row,
    Col
} from "react-bootstrap";
import { useSelector } from "react-redux";
import SectionalAnalysisMap from "./SectionalAnalysisMap";
import ChartMixedGroundLevelAlongRoad from "./ChartMixedGroundLevelAlongRoad";
import "./sectional.css";
import { numberFormat } from "../../../utils/numberFormat";

//Material UI Imports
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { presumptionObj } from "../../../constant/helpers";
import { colorCodes } from "../../../constant/colors";

const { WHITE_1, BLACK_1 } = colorCodes
const SectionalAnalysisIndividual = (props) => {
    const { currency, carConfig, truckConfig, projectId } = useSelector(state => state.global)
    const [baseAlternative, setBaseAlternative] = useState(null);
    const [startYear, setStartYear] = useState(2020);
    const [endYear, setEndYear] = useState(2041);
    const [dailySavings, setDailySavings] = useState(null);
    const [forecastSavings, setForecastSavings] = useState(null);
    const [gradientPercent, setGradientPercent] = useState(null);
    const [
        averagePositiveGradientDecreasePercent,
        setAveragePositiveGradientDecreasePercent,
    ] = useState(null);
    const [maxGradientDecreasePercent, setMaxGradientDecreasePercent] = useState(null)
    const [localMapCentreIdData, setLocalMapCentreIdData] = useState(null);
    const [localPreviousMapCentreIdData, setLocalPreviousMapCentreIdData] = useState(null);
    const [localEditSectionalAnalysisChart, setLocalEditSectionalAnalysisChart] = useState(null);
    const [localMapCoordinateId, setLocalMapCoordinateId] = useState(null);
    const { presumptions } = useSelector(state => state.presumptions)

    // const [coordinateX, setCoordinateX] = useState("")
    // const [coordinateY, setCoordinateY] = useState("")
    // const [windowX, setWindowX] = useState("")
    // const [windowY, setWindowY] = useState("")
    // const [mapRef, setMapRef] = useState("")
    // const [accordinOpen, setAccordinOpen] = useState(false)


    function handleStateChange(stateType, settingState) {
        switch (stateType) {
            case "localMapCentreIdData":
                setLocalMapCentreIdData(settingState)
                break;
            case "localPreviousMapCentreIdData":
                setLocalPreviousMapCentreIdData(settingState)
                break;
            case "localEditSectionalAnalysisChart":
                setLocalEditSectionalAnalysisChart(settingState)
                break;
            case "localMapCoordinateId":
                setLocalMapCoordinateId(settingState)
                break;
            default:
                break
        }
    }

    function handleFirstAlternativeClick(
        alternative,
        alternative_id,
        direction,
        start_year,
        end_year
    ) {
        setBaseAlternative(alternative);
        setStartYear(start_year);
        setEndYear(end_year);
    }

    const getDailyData = async (
        startSection,
        endSection,
        startYearData,
        endYearData,
        segmentsDir1,
        segmentsDataDir1,
        presumptionsData
    ) => {
        const presumptions = presumptionObj(presumptionsData[0])

        const startYear = startYearData ? startYearData : 2020;
        const endYear = endYearData ? endYearData : 2041;

        const requestBodyDir1 = {
            return_option: 4,
            // TODO: WIP for 7574
            sectional_start: startSection ? startSection : 3600,
            sectional_end: endSection ? endSection : 6000,
            start_year: startYear,
            end_year: endYear,
            fuel_distribution_cars: carConfig,
            fuel_distribution_trucks: truckConfig,
            sensitivity_param_factors: {
                speed: 1,
                fuelprice: 1,
                emission: 1,
                AADTheavy: 1,
            },
            presumptions: presumptions,
            segments: segmentsDir1,
            segments_data: segmentsDataDir1,
        };

        const config = {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };

        const AI_API_KEY = process.env.REACT_APP_AI_FUNCTION_API_KEY;
        const urlToken = process.env.REACT_APP_AI_FUNCTION_URL + '/api/HttpTrigger1?code=' + AI_API_KEY;

        try {
            const [responseDir1] = await Promise.all([
                axios.post(urlToken, endYear ? requestBodyDir1 : requestBody4, config),
            ]);

            return [responseDir1];
        } catch (err) {
            // throw new Error('Unable to get a response.')
            return null;
        }
    };

    const settingCoordinate = (X, Y) => {
        // window.addEventListener('click', (ev) => {
        //     //if (mapRef.current && mapRef.current.contains('<div />')) {
        //     // setWindowX(ev.clientX)
        //     // setWindowY(ev.clientY)
        //     // setCoordinateX(X)
        //     // setCoordinateY(Y)
        //     //}
        // })
    }



    useEffect(() => {
        //setMapCentreIdData(null);
        setLocalMapCentreIdData(null)
        //setPreviousMapCentreIdData(null);
        setLocalPreviousMapCentreIdData(null)
        setDailySavings(null);
        setForecastSavings(null);
        setGradientPercent(null);
        setAveragePositiveGradientDecreasePercent(null);
        setMaxGradientDecreasePercent(null);
        //setPreviousMapCentreIdData(null)
        setLocalPreviousMapCentreIdData(null)
    }, [baseAlternative, projectId]);

    // useEffect(() => {
    //     setBaseAlternative(null);
    // }, [projectId]);

    useEffect(() => {
        async function setData() {

            if (
                localPreviousMapCentreIdData &&
                baseAlternative &&
                presumptions
            ) {

                setDailySavings(null);
                setForecastSavings(null);
                setGradientPercent(null);
                setAveragePositiveGradientDecreasePercent(null);
                setMaxGradientDecreasePercent(null);

                let startSection = (!!localPreviousMapCentreIdData && !!localPreviousMapCentreIdData[0] && !!localPreviousMapCentreIdData[0].chainage) ? localPreviousMapCentreIdData[0].chainage : false;
                let endSection = (!!localPreviousMapCentreIdData && !!localPreviousMapCentreIdData[1] && !!localPreviousMapCentreIdData[1].chainage) ? localPreviousMapCentreIdData[1].chainage : false;

                if (!startSection || !endSection) {
                    return false
                }
                if (startSection > endSection) {
                    const dummy = startSection;
                    startSection = endSection;
                    endSection = dummy;
                }

                const dailyData = await getDailyData(
                    startSection,
                    endSection,
                    startYear,
                    endYear,
                    baseAlternative.segments,
                    baseAlternative.segments_data,
                    presumptions
                );

                if (dailyData) {
                    const savingsData = dailyData[0].data.alternative;
                    if (savingsData) {
                        setDailySavings(savingsData.daily);
                        setForecastSavings(savingsData.forecast);
                        setGradientPercent(savingsData.gradient_percent);
                        setAveragePositiveGradientDecreasePercent(
                            savingsData.average_positive_gradient_decrease_percent
                        );
                        setMaxGradientDecreasePercent(
                            savingsData.max_gradient_decrease_percent
                        );
                    }
                }
            }
        }

        setData();
        // eslint-disable-next-line
    }, [localPreviousMapCentreIdData]);

    useEffect(() => {
        handleFirstAlternativeClick(
            props.directions,
            props.alternative_id,
            props.direction,
            props.start_year,
            props.end_year
        )
        // eslint-disable-next-line
    }, [])

    return (
        (baseAlternative &&
            <Container style={{ backgroundColor: WHITE_1, color: BLACK_1 }}>
                <br />
                <Accordion expanded={props.expanded === `panel1a-${props.alternative_id}-${props.direction}`} onChange={props.handleChange(`panel1a-${props.alternative_id}-${props.direction}`)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel1a-${props.alternative_id}-${props.direction}`}
                        id={`panel1a-${props.alternative_id}-${props.direction}`}
                    >
                        {`Alternative ${props.alternative_id} ${props.name}: ${props.direction}`}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Container>
                            <Row>
                                <Col>
                                    {baseAlternative && (
                                        <>
                                            <div align="left">Click the graph to draw a comparison line</div>
                                            <div style={{ width: '35vw', height: '20vw' }}>
                                                <ChartMixedGroundLevelAlongRoad
                                                    baseAlternative={baseAlternative}
                                                    handleStateChange={handleStateChange}
                                                    localMapCentreIdData={localMapCentreIdData}
                                                    localPreviousMapCentreIdData={localPreviousMapCentreIdData}
                                                    localEditSectionalAnalysisChart={localEditSectionalAnalysisChart}
                                                    localMapCoordinateId={localMapCoordinateId}
                                                />
                                            </div>
                                        </>
                                    )}
                                </Col>
                                <Col>
                                    {baseAlternative && (
                                        <>
                                            <div align="left">Sectional analysis map</div>
                                            <SectionalAnalysisMap
                                                baseAlternative={baseAlternative}
                                                handleStateChange={handleStateChange}
                                                localMapCentreIdData={localMapCentreIdData}
                                                localPreviousMapCentreIdData={localPreviousMapCentreIdData}
                                                localEditSectionalAnalysisChart={localEditSectionalAnalysisChart}
                                                localMapCoordinateId={localMapCoordinateId}
                                                settingCoordinate={settingCoordinate}
                                            // setMapRef={setMapRef}
                                            />
                                        </>
                                    )}
                                    {
                                        // <div style={{position:'absolute',left:`${windowX}px`,top:`${windowY}px`,borderRadius: "25px",width:"100px",height:"100px",padding: '20px',border: "2px solid #73AD21"}}>Hello</div>

                                    }
                                </Col>

                            </Row>
                            <br />
                            <Row>
                                {/* coordinateX !=="" && 
                            <Box 
                                bgcolor="grey.700"
                                color="white"
                                p={2}
                                position="absolute"
                                left={`${windowX}px`}
                                top={`${windowY}px`}
                                zIndex="modal">{`X:${coordinateX},Y:${coordinateY}`}
                            </Box>
                            /*<div style={}>
                                <p>X:{coordinateX},Y:{coordinateY}</p>
                            </div>
                                    */}
                            </Row>
                            <Row>
                                <Col>
                                    <Table striped bordered variant="dark">
                                        <caption>Daily Savings</caption>
                                        <thead>
                                            <tr>
                                                <td>Fuel</td>
                                                <td>CO<sub>2</sub></td>
                                                <td>Energy</td>
                                                <td>Cost</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{dailySavings ? numberFormat(Math.floor(dailySavings.fuel)) : ''}</td>
                                                <td>{dailySavings ? numberFormat(Math.floor(dailySavings.co2)) : ''}</td>
                                                <td>{dailySavings ? numberFormat(Math.floor(dailySavings.energy)) : ''}</td>
                                                <td>{dailySavings ? `${numberFormat(Math.floor(dailySavings.cost))} ${currency}` : ''}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot></tfoot>
                                    </Table>
                                </Col>
                                {React.Children.toArray(
                                    <Col>
                                        <Table striped bordered variant="dark">
                                            <caption>Forecasted Savings</caption>
                                            <thead>
                                                <tr>
                                                    <td>Year</td>
                                                    <td>Fuel</td>
                                                    <td>CO<sub>2</sub></td>
                                                    <td>Energy</td>
                                                    <td>Cost</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!forecastSavings &&
                                                    <tr>
                                                        <td>{''}</td>
                                                        <td>{''}</td>
                                                        <td>{''}</td>
                                                        <td>{''}</td>
                                                        <td>{''}</td>
                                                    </tr>
                                                }
                                                {forecastSavings && forecastSavings.map((f, i) => (
                                                    <tr key={i}>
                                                        <td>{f.year}</td>
                                                        <td>{numberFormat(Math.floor(f.fuel))}</td>
                                                        <td>{numberFormat(Math.floor(f.co2))}</td>
                                                        <td>{numberFormat(Math.floor(f.energy))}</td>
                                                        <td>{numberFormat(Math.floor(f.cost))} {currency}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot></tfoot>
                                        </Table>
                                    </Col>
                                )}
                            </Row>
                            <Row>
                                <Col>
                                    <Table striped bordered variant="dark">
                                        <caption>Forecasted Savings</caption>
                                        <thead>
                                            <tr>
                                                <td>New gradient</td>
                                                <td>Average gradient decrease</td>
                                                <td>Maximum gradient decrease</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{gradientPercent ? numberFormat(gradientPercent) : ''}</td>
                                                <td>{averagePositiveGradientDecreasePercent ? numberFormat(averagePositiveGradientDecreasePercent) : ''}</td>
                                                <td>{maxGradientDecreasePercent ? numberFormat(maxGradientDecreasePercent) : ''}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot></tfoot>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </AccordionDetails>
                </Accordion>
            </Container>
        )
    )
}

export default SectionalAnalysisIndividual
