
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import "./dailysums.css"
import TextRotateUpOutlinedIcon from '@material-ui/icons/TextRotateUpOutlined';
import TextRotationDownOutlinedIcon from '@material-ui/icons/TextRotationDownOutlined';
import AlignmentPageWidget from '../../summary/AlignmentPageWidget';


const DailySums = ({ alternativeDailySums, dataQuerySearch, searchDefaultValue }) => {

    const { currency } = useSelector(state => state.global)

    const convertToLowerCase = (initialList, altDailySum) => {
        const randomList = [""]
        if (initialList) {
            initialList.map((item) => {
                randomList.push(item.toLowerCase())
                return item
            })
        }
        else {
            altDailySum.map((item) => {
                randomList.push(item.name.toLowerCase())
                return item
            })
        }
        return randomList
    }

    const searchDefaultVal = convertToLowerCase(searchDefaultValue, alternativeDailySums)

    //const [sortedField, setSortedField] = useState(null);
    const [sortConfig, setSortConfig] = React.useState(null);
    const [sortedDailySums, setSortedDailySums] = useState([])
    // const [sortConfigIcon, setSortConfigIcon] = useState(null)

    useEffect(() => {
        setSortedDailySums(alternativeDailySums)

        // eslint-disable-next-line
    }, [alternativeDailySums])

    const requestSort = key => {

        let direction = 'ascending';
        if (sortConfig !== null) {
            if (sortConfig.key === key && sortConfig.direction === 'ascending') {
                direction = 'descending';
                setSortConfig({ key, direction });
            }
            else {
                direction = 'ascending';
                setSortConfig({ key, direction });
            }
        } else {
            //to check the direction
            direction = 'ascending';
            setSortConfig({ key, direction });
        }

        const newlist = [...alternativeDailySums]
        if (newlist !== null && sortConfig !== null) {
            newlist.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });

            setSortedDailySums(newlist)
        }
    }


    return (
        (sortedDailySums &&
            <>
                <br />
                <Table hover>
                    <thead>
                        <tr>
                            <th>
                                S.No
                            </th>
                            <th>
                                <button type="button" id="rowButton" onClick={() => requestSort('name')} >
                                    Alignment

                                    {(sortConfig !== null &&
                                        (sortConfig.key === "name" ?
                                            (sortConfig.direction === "ascending" ?
                                                <TextRotateUpOutlinedIcon /> :
                                                <TextRotationDownOutlinedIcon />) :
                                            "")
                                    )
                                    }
                                </button>
                            </th>
                            <th>
                                <button type="button" id="rowButton" onClick={() => requestSort('daily_fuel')}>
                                    Daily Fuel (litres)
                                    {(sortConfig !== null &&
                                        (sortConfig.key === "daily_fuel" ?
                                            (sortConfig.direction === "ascending" ?
                                                <TextRotateUpOutlinedIcon /> :
                                                <TextRotationDownOutlinedIcon />) :
                                            "")
                                    )
                                    }
                                </button>
                            </th>
                            <th>
                                <button type="button" id="rowButton" onClick={() => requestSort('daily_CO2')}>
                                    Daily CO<sub>2</sub> (kg)
                                    {(sortConfig !== null &&
                                        (sortConfig.key === "daily_CO2" ?
                                            (sortConfig.direction === "ascending" ?
                                                <TextRotateUpOutlinedIcon /> :
                                                <TextRotationDownOutlinedIcon />) :
                                            "")
                                    )
                                    }
                                </button>
                            </th>
                            <th>
                                <button type="button" id="rowButton" onClick={() => requestSort('daily_energy')}>
                                    Energy (kWh)
                                    {(sortConfig !== null &&
                                        (sortConfig.key === "daily_energy" ?
                                            (sortConfig.direction === "ascending" ?
                                                <TextRotateUpOutlinedIcon /> :
                                                <TextRotationDownOutlinedIcon />) :
                                            "")
                                    )
                                    }
                                </button>
                            </th>
                            <th>
                                <button type="button" id="rowButton" onClick={() => requestSort('daily_cost')}>
                                    Cost
                                    {(sortConfig !== null &&
                                        (sortConfig.key === "daily_cost" ?
                                            (sortConfig.direction === "ascending" ?
                                                <TextRotateUpOutlinedIcon /> :
                                                <TextRotationDownOutlinedIcon />) :
                                            "")
                                    )
                                    }
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedDailySums
                            .filter(item => {
                                //return item.name.toLowerCase().includes(dataQuerySearch.toLowerCase()) && item.name.toLowerCase().includes(dropQuerySearch.toLowerCase())
                                return item.name.toLowerCase().includes(dataQuerySearch.toLowerCase()) && (searchDefaultVal).includes(item.name.toLowerCase())
                            })
                            .map(({ name, daily_fuel, daily_CO2, daily_energy, daily_cost }, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{name}</td>
                                    <td >{daily_fuel}</td>
                                    <td >{daily_CO2}</td>
                                    <td >{daily_energy}</td>
                                    <td >{`${daily_cost} ${currency}`}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                {!!sortedDailySums && !!sortedDailySums.length && <AlignmentPageWidget />}
            </>
        )
    )

}

export default DailySums