import React, { Fragment } from 'react'
import { floatWithDeci, oppositeDir } from '../../../../constant/helpers'
import SpinLoader from '../../../common/SpinLoader'
import LandXMLInput from '../LandXMLInput'
import LandXMLSelect from '../LandXMLSelect'
import './index.scss'

const directionArr = [
    { name: 'East', value: 'East' },
    { name: 'West', value: 'West' },
    { name: 'North', value: 'North' },
    { name: 'South', value: 'South' }
]

const LandXMLImporter = ({
    handleBlurNFocus, alignmentOpts, selectedOpts,
    data, xmlData, setActiveXMLAlign, xmlLoader = false,
    errResp, handleXmlChoose, setXMLData, updateXmlData
}) => {

    const handleSelect = (e, i) => {
        selectedOpts[i] = parseInt(e.target.value)
        setActiveXMLAlign(e.target.value)
        xmlData.selectedOpts = selectedOpts
        xmlData.dataList[i] = (xmlData.alignmentListArr).find(a => a.idx === parseInt(e.target.value))
        // TODO: WIP For testing
        const oppositeIns = (xmlData.alignmentListArr).find(a => a.idx === selectedOpts[i === 0 ? 1 : 0])
        if (!!oppositeIns && !!oppositeIns.direction) {
            oppositeIns.direction = oppositeDir(oppositeIns.direction)
            xmlData.dataList[i === 0 ? 1 : 0] = oppositeIns
        }
        setXMLData({ ...xmlData })
    }

    return (
        <div className="mr-0 ml-0 land-xml-importer-container">
            {!!xmlLoader && <SpinLoader />}
            <div className="landxml-file-upload-btn-container">
                <label htmlFor="landxml-upload" className="landxml-file-upload-btn center-flex">Load LandXML</label>
                <input id="landxml-upload" name="xml" type="file" onChange={e => handleXmlChoose(e)} />
            </div>

            <div className="between-flex pl-3 pr-3">
                <LandXMLInput type="text" name={"name"} label="Name" value={!!xmlData.dataList && !!xmlData.dataList[0] && !!xmlData.dataList[0]["name"] ? xmlData.dataList[0]["name"] : ''}
                    errMsg={!!errResp && !!errResp["input.name"] ? errResp["input.name"] : false}
                    handleOnFocus={e => handleBlurNFocus(e)}
                    handleOnBlur={e => handleBlurNFocus(e, false)}
                    handleOnChange={e => {
                        xmlData.dataList[0]["name"] = e.target.value
                        setXMLData({ ...xmlData })
                    }} />
                <LandXMLInput type="text" name="epsgCode" label="Coordinate System" value={xmlData.epsgCode}
                    errMsg={!!errResp && !!errResp['input.epsgCode'] ? errResp['input.epsgCode'] : false}
                    handleOnFocus={e => handleBlurNFocus(e)}
                    handleOnBlur={e => handleBlurNFocus(e, false)}
                    handleOnChange={e => updateXmlData({ epsgCode: e.target.value })} />
            </div>
            {
                !!data.length &&
                <div className="between-flex f-column pl-3 pr-3" style={{
                    alignItems: 'baseline'
                }}>
                    {
                        data.map((al, i) => {
                            let inputHtml = [];
                            for (const [k, v] of Object.entries(al)) {
                                ((k !== 'name') && (k !== 'step') && (k !== 'idx') && (k !== 'direction')) && inputHtml.push(
                                    <LandXMLInput key={i + k} type={k === 'name' ? 'text' : 'number'} name={`${k}_${i}`} label={k} value={v}
                                        errMsg={!!errResp && !!errResp[`input.` + k] ? errResp[`input.` + k] : false}
                                        handleOnFocus={e => handleBlurNFocus(e)}
                                        handleOnBlur={e => handleBlurNFocus(e, false)}
                                        handleOnChange={e => {
                                            xmlData.dataList[i][k] = k === 'name' ? e.target.value : floatWithDeci(e.target.value || 0)
                                            setXMLData({ ...xmlData })
                                        }} />
                                )
                            }

                            if ((i > 0) && !!xmlData.useSame) return null

                            const filteredList = alignmentOpts.filter(a => a.idx !== selectedOpts[i === 0 ? 1 : 0])
                            return <Fragment key={i}>
                                <div className="between-flex alignment-selector">
                                    <LandXMLSelect label={`Select Alignment ${i + 1}`}
                                        value={xmlData.dataList[i].idx}
                                        options={filteredList} handleOnChange={e => handleSelect(e, i)} />
                                </div>
                                <div className="land-xml-section-container">
                                    <div className="center-flex f-column">{inputHtml}</div>
                                    <div className="center-flex f-column">
                                        <LandXMLSelect name={`direction_${i}`} label="Select Direction" options={directionArr} parentClassName="w-100"
                                            value={xmlData.dataList[i].direction}
                                            handleOnChange={e => {
                                                if (!!xmlData.dataList[i]) {
                                                    xmlData.dataList[i].direction = e.target.value
                                                    if (xmlData.dataList.length > 1) {
                                                        xmlData.dataList[i === 0 ? 1 : 0].direction = oppositeDir(e.target.value)
                                                    }
                                                    setXMLData({ ...xmlData })
                                                }
                                            }} />
                                        <LandXMLInput type="number" name={`step_${i}`} label="Interval" value={xmlData.dataList[i].step}
                                            errMsg={!!errResp && !!errResp['input.step'] ? errResp['input.step'] : false}
                                            handleOnFocus={e => handleBlurNFocus(e)}
                                            handleOnBlur={e => handleBlurNFocus(e, false)}
                                            handleOnChange={e => {
                                                if (!!xmlData.dataList[i]) {
                                                    xmlData.dataList[i].step = parseInt(e.target.value)
                                                    setXMLData({ ...xmlData })
                                                }
                                            }} />
                                    </div>
                                </div></Fragment>
                        })
                    }

                    {
                        // HINT: For now, We're not modifying xml in case of multiple alignments
                        alignmentOpts.length > 1 && <div className="checkbox-sec mt-3 mb-3">
                            <input type="checkbox" id="use-same-align" className="mr-3" value={xmlData.useSame} onChange={e => {
                                xmlData.useSame = e.target.checked
                                setXMLData({
                                    ...xmlData,
                                    ...(!!xmlData.useSame && { selected: [0], numberOfAlignments: 1 })
                                })
                            }} />
                            <label htmlFor="use-same-align">Use same alignment for other direction</label>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default LandXMLImporter