export const norwayCarGasoline = [
    "0.25095",
    "0.22791",
    "0.20706",
    "0.18922",
    "0.17277",
    "0.15798",
    "0.14348",
    "0.12950",
    "0.11557",
    "0.10179",
    "0.08885",
    "0.07544",
    "0.06314",
    "0.05218",
    "0.04260",
    "0.03445",
    "0.02764",
    "0.02214",
    "0.01773",
    "0.01423",
    "0.01144",
    "0.00926",
    "0.00760",
    "0.00632",
    "0.00532",
    "0.00454",
    "0.00389",
    "0.00338",
    "0.00296",
    "0.00263",
    "0.00235",
    "0.00165",
    "0.00125",
    "0.00087",
    "0.00051",
    "0.00017",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000"
]

export const norwayCarDiesel = [
    "0.50641",
    "0.47929",
    "0.44983",
    "0.41889",
    "0.38722",
    "0.35336",
    "0.31822",
    "0.28300",
    "0.24851",
    "0.21486",
    "0.18435",
    "0.15447",
    "0.12814",
    "0.10543",
    "0.08605",
    "0.06999",
    "0.05693",
    "0.04626",
    "0.03743",
    "0.03030",
    "0.02466",
    "0.02037",
    "0.01705",
    "0.01451",
    "0.01253",
    "0.01092",
    "0.00960",
    "0.00850",
    "0.00757",
    "0.00678",
    "0.00609",
    "0.00459",
    "0.00358",
    "0.00262",
    "0.00171",
    "0.00083",
    "0.00049",
    "0.00016",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000"
]

export const norwayCarInChargeHybrid = [
    "0.03925",
    "0.03931",
    "0.03886",
    "0.03841",
    "0.03767",
    "0.03672",
    "0.03563",
    "0.03415",
    "0.03212",
    "0.02958",
    "0.02652",
    "0.02310",
    "0.01953",
    "0.01605",
    "0.01281",
    "0.00992",
    "0.00753",
    "0.00559",
    "0.00404",
    "0.00285",
    "0.00201",
    "0.00142",
    "0.00103",
    "0.00075",
    "0.00055",
    "0.00041",
    "0.00032",
    "0.00024",
    "0.00019",
    "0.00015",
    "0.00011",
    "0.00007",
    "0.00005",
    "0.00003",
    "0.00002",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000"
]

export const norwayCarPreChargeHybrid = [
    "0.07218",
    "0.08870",
    "0.10460",
    "0.12050",
    "0.13647",
    "0.15226",
    "0.16823",
    "0.18431",
    "0.20053",
    "0.21649",
    "0.23086",
    "0.24323",
    "0.25356",
    "0.26179",
    "0.26810",
    "0.27260",
    "0.27543",
    "0.27678",
    "0.27701",
    "0.27612",
    "0.27430",
    "0.27169",
    "0.26849",
    "0.26482",
    "0.26079",
    "0.25643",
    "0.25182",
    "0.24705",
    "0.24217",
    "0.23724",
    "0.23231",
    "0.25821",
    "0.25706",
    "0.25596",
    "0.25491",
    "0.25391",
    "0.25296",
    "0.25205",
    "0.25117",
    "0.25033",
    "0.24953",
    "0.24875",
    "0.24801",
    "0.24730",
    "0.24661",
    "0.24595",
    "0.24531",
    "0.24470",
    "0.24410",
    "0.24353",
    "0.24298",
    "0.24244",
    "0.24192",
    "0.24142",
    "0.24093",
    "0.24046",
    "0.24001",
    "0.23957",
    "0.23914",
    "0.23872",
    "0.23832"
]

export const norwayCarBattery = [
    "0.13100",
    "0.16460",
    "0.19940",
    "0.23272",
    "0.26562",
    "0.29942",
    "0.33413",
    "0.36875",
    "0.40299",
    "0.43698",
    "0.46915",
    "0.50347",
    "0.53536",
    "0.56431",
    "0.59018",
    "0.61283",
    "0.63225",
    "0.64905",
    "0.66360",
    "0.67634",
    "0.68742",
    "0.69712",
    "0.70572",
    "0.71347",
    "0.72071",
    "0.72761",
    "0.73430",
    "0.74077",
    "0.74704",
    "0.75313",
    "0.75908",
    "0.74178",
    "0.74293",
    "0.74403",
    "0.74507",
    "0.74607",
    "0.74703",
    "0.74794",
    "0.74881",
    "0.74965",
    "0.75046",
    "0.75123",
    "0.75197",
    "0.75269",
    "0.75337",
    "0.75403",
    "0.75467",
    "0.75529",
    "0.75588",
    "0.75645",
    "0.75701",
    "0.75754",
    "0.75806",
    "0.75856",
    "0.75905",
    "0.75952",
    "0.75998",
    "0.76042",
    "0.76085",
    "0.76126",
    "0.76167"
]

export const norwayCarHydro = [
    "0.00019",
    "0.00022",
    "0.00024",
    "0.00024",
    "0.00026",
    "0.00026",
    "0.00029",
    "0.00028",
    "0.00030",
    "0.00030",
    "0.00027",
    "0.00027",
    "0.00027",
    "0.00026",
    "0.00024",
    "0.00024",
    "0.00021",
    "0.00021",
    "0.00019",
    "0.00016",
    "0.00014",
    "0.00014",
    "0.00011",
    "0.00011",
    "0.00011",
    "0.00009",
    "0.00009",
    "0.00006",
    "0.00006",
    "0.00006",
    "0.00006",
    "0.00004",
    "0.00002",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000"
]

export const norwayTruckGas = [
    "0.00085",
    "0.00042",
    "0.00042",
    "0.00042",
    "0.00041",
    "0.00041",
    "0.00040",
    "0.00040",
    "0.00039",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000"
]

export const norwayTruckDiesel = [
    "0.99195",
    "0.99197",
    "0.99202",
    "0.99292",
    "0.99302",
    "0.99309",
    "0.99319",
    "0.99328",
    "0.99339",
    "0.99385",
    "0.99391",
    "0.99363",
    "0.99375",
    "0.99350",
    "0.99326",
    "0.99267",
    "0.99107",
    "0.98915",
    "0.98594",
    "0.98149",
    "0.97511",
    "0.96716",
    "0.95640",
    "0.94220",
    "0.92364",
    "0.90014",
    "0.87099",
    "0.83574",
    "0.79593",
    "0.75381",
    "0.71154",
    "0.75366",
    "0.73405",
    "0.71435",
    "0.69458",
    "0.67480",
    "0.65504",
    "0.63533",
    "0.61569",
    "0.59615",
    "0.57675",
    "0.55749",
    "0.53840",
    "0.51949",
    "0.50078",
    "0.48228",
    "0.46400",
    "0.44595",
    "0.42814",
    "0.41058",
    "0.39326",
    "0.37620",
    "0.35940",
    "0.34287",
    "0.32659",
    "0.31058",
    "0.29484",
    "0.27935",
    "0.26414",
    "0.24918",
    "0.23448"
]

export const norwayTruckNonCharge = [
    "0.00042",
    "0.00042",
    "0.00042",
    "0.00042",
    "0.00041",
    "0.00041",
    "0.00040",
    "0.00040",
    "0.00039",
    "0.00038",
    "0.00038",
    "0.00037",
    "0.00037",
    "0.00036",
    "0.00035",
    "0.00035",
    "0.00034",
    "0.00034",
    "0.00033",
    "0.00033",
    "0.00033",
    "0.00032",
    "0.00031",
    "0.00030",
    "0.00029",
    "0.00029",
    "0.00028",
    "0.00027",
    "0.00027",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000"
]

export const norwayTruckChargeHydro = [
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000",
    "0.00000"
]

export const norwayTruckHydro = [
    "0.00720",
    "0.00676",
    "0.00672",
    "0.00624",
    "0.00616",
    "0.00610",
    "0.00601",
    "0.00593",
    "0.00584",
    "0.00538",
    "0.00571",
    "0.00525",
    "0.00552",
    "0.00542",
    "0.00603",
    "0.00698",
    "0.00825",
    "0.01052",
    "0.01339",
    "0.01818",
    "0.02456",
    "0.03252",
    "0.04329",
    "0.05720",
    "0.07606",
    "0.09928",
    "0.12873",
    "0.16398",
    "0.20380",
    "0.24592",
    "0.28794",
    "0.24502",
    "0.26481",
    "0.28468",
    "0.30461",
    "0.32455",
    "0.34446",
    "0.36433",
    "0.38411",
    "0.40378",
    "0.42332",
    "0.44271",
    "0.46193",
    "0.48096",
    "0.49979",
    "0.51840",
    "0.53679",
    "0.55495",
    "0.57286",
    "0.59052",
    "0.60793",
    "0.62508",
    "0.64197",
    "0.65860",
    "0.67495",
    "0.69104",
    "0.70687",
    "0.72242",
    "0.73771",
    "0.75274",
    "0.76750"
]