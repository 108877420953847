import { SET_CDR_SETTINGS, SET_SETTING_PROCESSING } from '../actions/setting'

const defaultState = {
  isLoading: false,
  cdrSettings: {
    base_comp_thrshld: 0
  }
}

const Settings = (state = defaultState, action) => {
  switch (action.type) {
    case SET_SETTING_PROCESSING:
    case SET_CDR_SETTINGS:
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}

export default Settings