import { PER_PAGE } from "../constant"
import { handleRequest } from "../constant/helpers"

export const alternativeQueryParentBlock = (pageNo = 1, keys, fetchAll = false) => `
      alternatives(alternativeQuerry:{
        offset: ${(pageNo - 1) * PER_PAGE},
        fetchAll: ${fetchAll}
        }) {
          node {
            ${keys}
          }
          cursor
          totalAmount
      }
`;

export const singleAlternativeQueryParentBlock = (keys, alterNativeId) => `
      alternatives(alternativeQuerry: {
        alternative_id: ${!!alterNativeId ? alterNativeId : 0}
      }) {
          node {
            ${keys}
          }
          cursor
          totalAmount
      }
`;

/**
 * Get All Alignments & Presumptions
 * @param {*} projectId 
 * @returns 
 */
export const getAllAlignmentAndPresumption = projectId => handleRequest("post", false, {
  query: `
        query {
          projects(project_ids: [${projectId}]) {
            alternatives {
              alternative_id
              name
              geom
              ts_uploaded_on
              directions {
                directions_id
                direction
                segments {
                  segment_id
                  directions_id
                  year
                  aadt_cars
                  aadt_trucks
                  aadt_trucks_with_trailer
                  annual_increase_cars
                  annual_increase_heavy
                  start_index
                }
                segments_data {
                  segment_data_id
                  chainage
                  zlevel
                  radius
                  directions_id
                  a_intersection
                  geom_order_id
                  geom
                }
              }
            }
        
            presumptions {
              presumptions_id
              speed_car
              speed_truck
              cost_increase
              gasoline_fuelfactor
              gasoline_co2
              gasoline_kwh
              gasoline_efficiency
              gasoline_cost
              diesel_fuelfactor
              diesel_co2
              diesel_kwh
              diesel_efficiency
              diesel_cost
              battery_fuelfactor
              battery_co2
              battery_kwh
              battery_efficiency
              battery_cost
              inchargeable_hybrid_fuelfactor
              inchargeable_hybrid_co2
              inchargeable_hybrid_kwh
              inchargeable_hybrid_efficiency
              inchargeable_hybrid_cost
              chargable_hybrid_co2
              chargable_hybrid_kwh
              chargable_hybrid_efficiency
              chargable_hybrid_cost
              hydrogen_fuelfactor
              hydrogen_co2
              hydrogen_kwh
              hydrogen_efficiency
              hydrogen_cost
              iri
              mpd
              currency
              country_id
              country_name
              iri_increase
              mpd_increase
              iri_limit
            }
          }
        }
              `,
})


/**
 * Get All Alignments
 * @param {*} projectId 
 * @param {*} pageNo 
 * @returns 
 */
export const getAllAlignments = (projectId, pageNo = 1, fetchAll = false) => handleRequest("post", false, {
  query: `
        query {
          projects(project_ids: [${projectId}]) {
            ${alternativeQueryParentBlock(pageNo,
    `
              alternative_id
                  name
                  geom
                  ts_uploaded_on
                  
                  directions {
                    directions_id
                    direction
                    segments {
                      segment_id
                      directions_id
                      year
                      aadt_cars
                      aadt_trucks
                      aadt_trucks_with_trailer
                      annual_increase_cars
                      annual_increase_heavy
                      start_index
                    }
                    segments_data {
                      segment_data_id
                      chainage
                      zlevel
                      radius
                      directions_id
                      a_intersection
                      geom_order_id
                      geom
                    }
                  }
              `,
    fetchAll
  )}
          }
        }
              `,
})


/**
 * Get all cap inputs
 * @param {*} data 
 * @returns 
 */
export const getAllCapInputs = data => handleRequest('post', false, {
  query: `mutation CDRValuesByProjectId($data:GetCDRValues!)
  {CDRValuesByProjectId(data: $data){
      alternative_id
      year_value
      investment_emssn
      maintainance_emssn
      is_active
      project_id
  }}`,
  variables: { data }
}, 'application/json')



/**
 * Copy Traffic Inputs
 * @param {*} id 
 * @returns 
 */
export const copyTrafficInputs = id => handleRequest('post', false, {
  query: `mutation copySegments($alternative_id:ID!)
    {copySegments(alternative_id: $alternative_id)}`,
  variables: { "alternative_id": id }
}, 'application/json')