import React, { useState, useEffect, useRef, useContext } from "react";
import { Map, View } from "ol";
import * as proj from "ol/proj";
import TileLayer from "ol/layer/Tile";
import XYZ from 'ol/source/XYZ';
import Select from 'ol/interaction/Select';
import Overlay from 'ol/Overlay.js';
import { getCentreCoord, createPointDataLayer, normalDistribution, createPointDataLayerComparitive } from '../../../../utils/maputils';
import './map.css';
import axios from 'axios'

import FuelSaveGlobalContext from '../../../../context/FuelSaveGlobalContext';



function ComparisonMap(props) {

    //const directions = props.compareAlternative ? props.compareAlternative : props.baseAlternative;

    //Making it so that the direction will only be based on the baseAlternative and we will add a layer of compareAlternative

    const directions = props.baseAlternative ? props.baseAlternative : null;
    const comparitiveDirection = props.compareAlternative ? props.compareAlternative : null;

    const {
        // mapCoordinateId, setMapCoordinateId,
        // mapCentreIdData, setMapCentreIdData,
        // chainage, 
        setChainage,
        // fcCar, 
        setFcCar,
        // fcTrucks, 
        setFcTrucks,
        // hilliness, 
        setHilliness,
        // speedProfileCars, 
        setSpeedProfileCars,
        // speedProfileTrucks, 
        setSpeedProfileTrucks,
        // zlevel, 
        setZlevel,
        // curvature, 
        setCurvature,
        // segment, 
        setSegment,
        apiAccessToken

    } = useContext(FuelSaveGlobalContext);

    const segments = directions.segments_data ? directions.segments : null
    const segments_data = directions.segments_data ? directions.segments_data : null
    //const fc_data = directions.fc_data
    const { perc75, perc50, perc25 } = normalDistribution(segments_data)
    const centreCoord = segments_data && segments_data.length > 0 ? getCentreCoord(directions) : [0, 0];
    const posCentre = proj.fromLonLat(segments_data && segments_data.length > 0 ? centreCoord.coordinates : centreCoord);


    const overlayRef = useRef(null);
    const [map, setMap] = useState();
    const [zoom, setZoom] = useState(11);
    const mapElement = useRef();

    function customLoader(tile, src) {

        axios({
            method: 'get',
            url: src,
            headers:{
                'Authorization': `Bearer ${apiAccessToken.accessToken}`
            }
            
        }).then((response)=>{
            tile.getImage().src = response.data;
        })

      }

    useEffect(() => {
        if (props.accordinOpen) {

            //creating the source first
            let urlVal = process.env.REACT_APP_API_URL
            urlVal = urlVal.replace('/graphql', '')

            const XYZSource = new XYZ({
                url: `${urlVal}/apimaptiler/?url=api.maptiler.com/maps/voyager/{z}/{x}/{y}.png`
            })
            XYZSource.setTileLoadFunction(customLoader)

            const bgLayer = new TileLayer({
                source: XYZSource
            });
            
            const initialMap = new Map({
                target: mapElement.current,
                layers: [
                    bgLayer
                ],
                view: new View({
                    center: posCentre,
                    zoom: zoom
                }),
                pixelRatio: 1.0
            });

            let select = null;
            let selectSingleClick = new Select();

            const overlay = new Overlay({
                position: posCentre,
                element: overlayRef.current,
                positioning: "center-center",
                stopEvent: false
            });

            initialMap.on('singleclick', function (evt) {
                let coordinate = evt.coordinate;
                overlay.setPosition(coordinate)
            }
            );

            if (select !== null) {
                initialMap.removeInteraction(select)
            }
            select = selectSingleClick;

            if (select !== null) {
                initialMap.addInteraction(select);
                select.on('select', function (e) {

                    if (e.selected[0]) {
                        //setMapCoordinateId(e.selected[0].values_.geom_order_id);
                        props.handleStateChange("localMapCoordinateId", e.selected[0].values_.geom_order_id)
                        props.setLineAtIndex(e.selected[0].values_.chainage)
                        setChainage(e.selected[0].values_.chainage);
                        setFcCar(e.selected[0].values_.fuelconsumption_car);
                        setFcTrucks(e.selected[0].values_.fuelconsumption_trucks);
                        setHilliness(e.selected[0].values_.hilliness);
                        setSpeedProfileCars(e.selected[0].values_.speedprofile_car);
                        setSpeedProfileTrucks(e.selected[0].values_.speedprofile_trucks);
                        setZlevel(e.selected[0].values_.zlevel);
                        setCurvature(null);
                        setSegment(e.selected[0].values_.segment_order_no);

                    } else {
                        //setMapCoordinateId(null);
                        props.handleStateChange("localMapCoordinateId", null)
                        setChainage(null);
                        setFcCar(null);
                        setFcTrucks(null);
                        setHilliness(null);
                        setSpeedProfileCars(null);
                        setSpeedProfileTrucks(null);
                        setZlevel(null);
                        setCurvature(null);
                        setSegment(null);
                    }
                });
            }

            initialMap.on("moveend", () => {
                setZoom(initialMap.getView().getZoom());
            });

            initialMap.on('zoomend', function () {
                setZoom(initialMap.getView().getZoom());
            });

            let layersToRemove = [];

            initialMap.getLayers().forEach(function (layer) {
                if (layer.get('name') !== undefined && layer.get('name').includes('pointlayer')) {
                    layersToRemove.push(layer)
                }
            })

            let len = layersToRemove.length;
            for (let i = 0; i < len; i++) {
                initialMap.removeLayer(layersToRemove[i]);
            }


            const mapLayer = segments_data.length != null ? createPointDataLayer(segments_data, segments, perc75, perc50, perc25, zoom) : null;

            if (mapLayer) {
                initialMap.addLayer(mapLayer)
            }


            setMap(initialMap);
        }

        // eslint-disable-next-line
    }, [props.accordinOpen]);

    useEffect(() => {
        if (map && props.accordinOpen) {
            const directions = props.baseAlternative;
            const segments = directions && directions.segments_data ? directions.segments : null;
            const segments_data = directions.segments_data ? directions.segments_data : null;
            const centreCoord = segments_data && segments_data.length > 0 ? getCentreCoord(directions) : [0, 0];
            const posCentre = proj.fromLonLat(segments_data && segments_data.length > 0 ? centreCoord.coordinates : centreCoord);
            let layersToRemove = [];
            map.getLayers().forEach(function (layer) {
                if (
                    layer.get("name") !== undefined &&
                    layer.get("name").includes("pointlayer")
                ) {
                    layersToRemove.push(layer);
                }
            });

            let len = layersToRemove.length;
            for (let i = 0; i < len; i++) {
                map.removeLayer(layersToRemove[i]);
            }

            const mapLayer = segments_data.length != null ? createPointDataLayer(segments_data, segments, perc75, perc50, perc25, zoom) : null;
            if (mapLayer) {
                map.addLayer(mapLayer);
            }
            map.getView().setCenter(posCentre);

        }

        // eslint-disable-next-line
    }, [props.baseAlternative, props.accordinOpen])


    useEffect(() => {

        if (map && props.accordinOpen) {
            let layersToRemove = [];
            map.getLayers().forEach(function (layer) {
                if (layer.get('name') !== undefined && layer.get('name').includes('pointlayer')) {
                    layersToRemove.push(layer)
                }
            })

            let len = layersToRemove.length;
            for (let i = 0; i < len; i++) {
                map.removeLayer(layersToRemove[i]);
            }

            const mapLayer = segments_data.length != null ? createPointDataLayer(segments_data, segments, perc75, perc50, perc25, zoom) : null;

            if (mapLayer) {
                map.addLayer(mapLayer)
            }

            /*****Adding comparitive map as well */
            // Todo: Convert it into a funciton to use it later

            if (map && props.compareAlternative) {
                const directions = props.compareAlternative;
                const segments = directions && directions.segments_data ? directions.segments : null;
                const segments_data = directions.segments_data ? directions.segments_data : null;
                const centreCoord = segments_data && segments_data.length > 0 ? getCentreCoord(directions) : [0, 0];
                const posCentre = proj.fromLonLat(segments_data && segments_data.length > 0 ? centreCoord.coordinates : centreCoord);
                let layersToRemove = [];
                map.getLayers().forEach(function (layer) {
                    if (
                        layer.get("name") !== undefined &&
                        layer.get("name").includes("pointlayerComparitive")
                    ) {
                        layersToRemove.push(layer);
                    }
                });

                let len = layersToRemove.length;
                for (let i = 0; i < len; i++) {
                    map.removeLayer(layersToRemove[i]);
                }

                const mapLayer = segments_data.length != null ? createPointDataLayerComparitive(segments_data, segments, perc75, perc50, perc25, zoom) : null;
                if (mapLayer) {
                    map.addLayer(mapLayer);
                }
                map.getView().setCenter(posCentre);

            }

            // zoom to map if chart is clicked
            if (props.localMapCentreIdData) {
                const coord = JSON.parse(props.localMapCentreIdData.geom)
                const newCentreCoord = proj.fromLonLat(coord.coordinates);
                map.getView().setCenter(newCentreCoord);
                map.getView().setZoom(15);

                //setMapCentreIdData(null);
                props.handleStateChange("localMapCentreIdData", null)

                //setMapCoordinateId(mapCentreIdData ? mapCentreIdData.geom_order_id : null);
                props.handleStateChange("localMapCoordinateId", props.localMapCentreIdData ? props.localMapCentreIdData.geom_order_id : null)
                setChainage(props.localMapCentreIdData ? props.localMapCentreIdData.chainage : null);
                setFcCar(props.localMapCentreIdData ? props.localMapCentreIdData.fuelconsumption_car : null);
                setFcTrucks(props.localMapCentreIdData ? props.localMapCentreIdData.fuelconsumption_trucks : null);
                setHilliness(props.localMapCentreIdData ? props.localMapCentreIdData.hilliness : null);
                setSpeedProfileCars(props.localMapCentreIdData ? props.localMapCentreIdData.speedprofile_car : null);
                setSpeedProfileTrucks(props.localMapCentreIdData ? props.localMapCentreIdData.speedprofile_trucks : null);
                setZlevel(props.localMapCentreIdData ? props.localMapCentreIdData.zlevel : null);
                setCurvature(null);
                setSegment(props.localMapCentreIdData ? props.localMapCentreIdData.segment_order_no : null);

            }
        }

        // eslint-disable-next-line
    }, [zoom, props.localMapCentreIdData, props.accordinOpen])

    useEffect(() => {
        if (map && comparitiveDirection && props.accordinOpen) {
            const directions = comparitiveDirection;
            const segments = directions && directions.segments_data ? directions.segments : null;
            const segments_data = directions.segments_data ? directions.segments_data : null;
            const centreCoord = segments_data && segments_data.length > 0 ? getCentreCoord(directions) : [0, 0];
            const posCentre = proj.fromLonLat(segments_data && segments_data.length > 0 ? centreCoord.coordinates : centreCoord);
            let layersToRemove = [];
            map.getLayers().forEach(function (layer) {
                if (
                    layer.get("name") !== undefined &&
                    layer.get("name").includes("pointlayerComparitive")
                ) {
                    layersToRemove.push(layer);
                }
            });

            let len = layersToRemove.length;
            for (let i = 0; i < len; i++) {
                map.removeLayer(layersToRemove[i]);
            }

            const mapLayer = segments_data.length != null ? createPointDataLayerComparitive(segments_data, segments, perc75, perc50, perc25, zoom) : null;
            if (mapLayer) {
                map.addLayer(mapLayer);
            }
            map.getView().setCenter(posCentre);

        }

        if (map && !props.compareAlternative) {
            let layersToRemove = [];
            map.getLayers().forEach(function (layer) {
                if (
                    layer.get("name") !== undefined &&
                    layer.get("name").includes("pointlayerComparitive")
                ) {
                    layersToRemove.push(layer);
                }
            });

            let len = layersToRemove.length;
            for (let i = 0; i < len; i++) {
                map.removeLayer(layersToRemove[i]);
            }
        }

        // eslint-disable-next-line
    }, [props.compareAlternative, props.accordinOpen])

    //This is needed to fix map
    useEffect(() => {
        if (map) {
            map.updateSize();
        }
    });

    return (
        <div className="mapRow">
            <div ref={mapElement} className="map-container" />
        </div>
    );
}

export default ComparisonMap;