import { toast } from "react-toastify"
import { flashSuccessErrMsg, handleRequest } from "../constant/helpers"
import { getUser } from "../utils/licenseUtils"
import { fetchAllAlignments } from "./alignment"
import { fetchAllPresumptions } from "./presumption"
import { getProjectInfoById } from "./project"

export const SET_GLOBAL_IS_PROCESS = 'SET_GLOBAL_IS_PROCESS'
export const SET_IS_LICENSE = 'SET_IS_LICENSE'
export const SET_AUTO_OUT = 'SET_AUTO_OUT'
export const SET_PROJECT_ID = 'SET_PROJECT_ID'
export const SET_PROJ_INFO = 'SET_PROJ_INFO'
export const SET_WIN_WIDTH = 'SET_WIN_WIDTH'
export const SET_LICENSE_DATE = 'SET_LICENSE_DATE'
export const SET_COUNTRIES_LIST = 'SET_COUNTRIES_LIST'
export const SET_CURRENCY = 'SET_CURRENCY'
export const SET_COUNTRY = 'SET_COUNTRY'
export const SET_FORECAST_CAR_CONFIG = 'SET_FORECAST_CAR_CONFIG'
export const SET_FORECAST_TRUCK_CONFIG = 'SET_FORECAST_TRUCK_CONFIG'
export const SET_USER_INFO = 'SET_USER_INFO'

export const setGlobalIsProcess = isProcess => ({
  type: SET_GLOBAL_IS_PROCESS,
  payload: { isProcess }
})

export const setIsLicense = isLicense => ({
  type: SET_IS_LICENSE,
  payload: { isLicense }
})

export const setAutoOut = autoOut => ({
  type: SET_AUTO_OUT,
  payload: { autoOut }
})

const setProjectId = projectId => ({
  type: SET_PROJECT_ID,
  payload: { projectId }
})

const setProjInfo = projInfo => ({
  type: SET_PROJ_INFO,
  payload: { projInfo }
})

export const setWinWidth = winWidth => ({
  type: SET_WIN_WIDTH,
  payload: { winWidth }
})

const setLicenseDate = licenseValid => ({
  type: SET_LICENSE_DATE,
  payload: { licenseValid }
})

const setCountries = countries => ({
  type: SET_COUNTRIES_LIST,
  payload: { countries }
})

export const setCurrency = currency => ({
  type: SET_CURRENCY,
  payload: { currency }
})

export const setCountry = country => ({
  type: SET_COUNTRY,
  payload: { country }
})

export const setForecastCarConfig = carConfig => ({
  type: SET_FORECAST_CAR_CONFIG,
  payload: { carConfig }
})

export const setForecastTruckConfig = truckConfig => ({
  type: SET_FORECAST_TRUCK_CONFIG,
  payload: { truckConfig }
})

export const setUserInfo = userInfo => ({
  type: SET_USER_INFO,
  payload: { userInfo }
})

/**
 * Check License Expiration
 * @param {*} id 
 * @returns 
 */
export const checkTokenExp = id => async (dispatch) => {
  try {
    const userResp = await getUser(id)
    dispatch(setIsLicense(true))
    if (!!userResp && !!userResp.data && !!userResp.data[0] && !!userResp.data[0].license_exp_date) {
      dispatch(setLicenseDate(userResp.data[0].license_exp_date))
    }
  } catch (e) {
    dispatch(setIsLicense(false))
    dispatch(setLicenseDate(false))
    console.log('checkTokenExp Error: ', e)
    return e
  }
}


/**
 * Fetch all countries info
 * @returns 
 */
export const fetchAllCountries = () => async (dispatch) => {
  dispatch(setGlobalIsProcess(true))
  try {
    const data = await handleRequest("post", false, {
      query: `
      query{
        Countries(country_id:1){
        country_id country_name currency
        }
        }`
    })
    dispatch(setGlobalIsProcess(false))
    if (!!data && !!data.data && !!data.data.data && !!data.data.data.Countries) {
      const resp = data.data.data.Countries
      dispatch(setCountries(resp))
      return resp
    }
    return false
  } catch (e) {
    dispatch(setGlobalIsProcess(false))
    console.log('fetchAllCountries Error: ', e)
    return false
  }
}


/**
 * Fetch and set
 * forecast config
 * @param {*} country 
 * @returns 
 */
export const fetchForecastConfig = (country) => async (dispatch) => {

  try {
    const data = await handleRequest("post", false, {
      query: `
          query {
            ForecastData(
              country: "${country}"
              ){
                car{ year prc_gasoline prc_diesel prc_inchargeable_hybrid prc_chargeable_hybrid prc_battery prc_hydrogen }
                truck{ year prc_gasoline prc_diesel prc_inchargeable_hybrid prc_chargeable_hybrid prc_battery prc_hydrogen }
                currency
                country
              }
            }`
    })

    if (!!data && !!data.data && !!data.data.data && !!data.data.data.ForecastData) {
      const resp = data.data.data.ForecastData
      if (resp.car) {
        dispatch(setForecastCarConfig(resp.car))
      }
      if (resp.truck) {
        dispatch(setForecastTruckConfig(resp.truck))
      }
      if (resp.country) {
        dispatch(setCountry(resp.country))
      }
      return resp
    }
    return false
  } catch (e) {
    console.log('fetchForecastConfig Error: ', e)
    return false
  }
}


/**
 * Fetch and set
 * global project info
 * @param {*} userId 
 * @returns 
 */
export const fetchGlobalProject = (userId) => async (dispatch) => {
  try {
    const resp = await handleRequest("post", false, {
      query: `
      query {
        usersInfo(user_oid: "${userId}") {
          default_prjct_id
          }
        }`
    })

    dispatch(setIsLicense(true))
    if (!!resp && !!resp.data && !!resp.data.data && !!resp.data.data.usersInfo && resp.data.data.usersInfo.default_prjct_id) {
      const projId = resp.data.data.usersInfo.default_prjct_id
      dispatch(setProjectId(projId))
      dispatch(fetchAllAlignments(projId))
      dispatch(fetchAllPresumptions(projId))
      const globalProjInfo = await dispatch(getProjectInfoById(projId, false))
      if (!!globalProjInfo && !!globalProjInfo[0]) {
        dispatch(setProjInfo(globalProjInfo[0]))
      }
      return resp
    }
    flashSuccessErrMsg(resp)
    return false
  } catch (e) {
    console.log('fetchGlobalProject Error: ', e)
    return false
  }
}


/**
 * Update
 * global project info
 * @param {*} projectId 
 * @param {*} currentProjectId 
 * @returns 
 */
export const updateGlobalProject = (projectId, currentProjectId) => async (dispatch) => {
  try {
    const apiAccessTokenObj = JSON.parse(localStorage.getItem("apiAccessTokenObj"))

    if (!apiAccessTokenObj || (!!apiAccessTokenObj && !apiAccessTokenObj.accessToken)) {
      toast.error('You session has been expired, Please login again first.')
      return false
    }

    dispatch(setGlobalIsProcess(true))
    const resp = await handleRequest("post", false, {
      query: `
      mutation updateDefaultProjectId($project_id:ID!,$user_oid:String!) {
        updateDefaultProjectId(project_id: $project_id, user_oid: $user_oid) {
          success
          message
        }
    }`,
      variables: {
        project_id: parseInt(projectId),
        user_oid: apiAccessTokenObj.uniqueId
      }
    })

    dispatch(setGlobalIsProcess(false))
    if (!!resp && !!resp.data && !!resp.data.data && !!resp.data.data.updateDefaultProjectId && !!resp.data.data.updateDefaultProjectId.success) {
      toast.success('Updated Successfully')

      if (currentProjectId !== projectId) {
        dispatch(setProjectId(projectId))
        dispatch(fetchAllAlignments(projectId))
        dispatch(fetchAllPresumptions(projectId))
      }
      return resp
    }
    flashSuccessErrMsg(resp)
    return false
  } catch (e) {
    dispatch(setGlobalIsProcess(false))
    console.log('fetchGlobalProject Error: ', e)
    return false
  }
}