import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import { MAX_YEAR, MIN_YEAR } from '../../../../constant'
import { colorCodes } from '../../../../constant/colors'
import { handleRequest, presumptionObj, scaleXCommonConfig, scaleYCommonConfig, flashErrMsg } from '../../../../constant/helpers'
import { requestBody3 } from '../../../../utils/aiRequestBodyUtils'


const { GREEN_3, BLUE_1, AQUA } = colorCodes
const GraphSection = ({ setIsLoading, endYear, formData, baseId, setFormData }) => {
    const { currency, carConfig, truckConfig, projInfo } = useSelector(state => state.global)
    const alignments = useSelector(state => state.alignments.cdrAlignments)
    const { presumptions } = useSelector(state => state.presumptions)

    const [co2Fetched, setCo2Fetched] = useState(false)
    const [labelList, setLabelList] = useState([])
    const [co2List, setCo2List] = useState([])
    const [investData, setInvestData] = useState([])
    const [maintainData, setMaintainData] = useState([])
    const getForecastData = async (
        startYearData,
        endYearData,
        segmentsDir1,
        segmentsDir2,
        segmentsDataDir1,
        segmentsDataDir2,
        presumptionsData
    ) => {
        const presumptions = presumptionObj(presumptionsData[0])

        const startYear = startYearData ? startYearData : MIN_YEAR;
        const endYear = endYearData ? endYearData : 2041;

        const requestBodyDir1 = {
            return_option: 3,
            start_year: startYear,
            //end_year: endYear,
            end_year: endYear, //TODO: A temp fix
            fuel_distribution_cars: carConfig,
            fuel_distribution_trucks: truckConfig,
            sensitivity_param_factors: {
                speed: 1,
                fuelprice: 1,
                emission: 1,
                AADTheavy: 1,
            },
            presumptions: presumptions,
            segments: segmentsDir1,
            segments_data: segmentsDataDir1,
        };

        const requestBodyDir2 = {
            return_option: 3,
            start_year: startYear,
            //end_year: endYear, //TODO: A temp fix
            end_year: endYear,
            fuel_distribution_cars: carConfig,
            fuel_distribution_trucks: truckConfig,
            sensitivity_param_factors: {
                speed: 1,
                fuelprice: 1,
                emission: 1,
                AADTheavy: 1,
            },
            presumptions: presumptions,
            segments: segmentsDir2,
            segments_data: segmentsDataDir2,
        };

        const urlToken = process.env.REACT_APP_AI_FUNCTION_URL + '/api/HttpTrigger1?code=' + process.env.REACT_APP_AI_FUNCTION_API_KEY;

        try {
            setIsLoading(true)
            const [responseDir1, responseDir2] = await Promise.all([
                handleRequest('post', urlToken, endYear ? requestBodyDir1 : requestBody3, 'application/json', 'application/json'),
                handleRequest('post', urlToken, endYear ? requestBodyDir2 : requestBody3, 'application/json', 'application/json'),
            ])

            setIsLoading(false)
            return [responseDir1, responseDir2];

        } catch (e) {
            flashErrMsg(e)
            setIsLoading(false)
            return null;
        }
    }

    const setData = async (checkCo2Condition = true) => {
        const co2Data = []

        const globalProjInfo = !!projInfo && Object.keys(projInfo).length ? projInfo : false
        if (!globalProjInfo || (!!globalProjInfo && (!globalProjInfo.start_year || !globalProjInfo.end_year))) {
            return
        }
        if (!alignments || !presumptions || !globalProjInfo || (!!co2Fetched && checkCo2Condition)) {
            return
        }

        setIsLoading(true)
        // const firstPart = alignments.filter(a => a.alternative_id === baseId)
        // const secondPart = alignments.filter(a => a.alternative_id !== baseId)
        const newArr = formData// [...firstPart, ...secondPart]
        for (let index = 0; index < newArr.length; index++) {
            const alternative = newArr[index];

            const dailyData = await getForecastData(
                globalProjInfo.start_year,
                endYear,
                alternative.directions[0].segments,
                alternative.directions[1].segments,
                alternative.directions[0].segments_data,
                alternative.directions[1].segments_data,
                presumptions
            )

            if (!dailyData || (!!dailyData && (!dailyData[0] || !dailyData[1]))) {
                return false
            }
            const responseDir1 = dailyData[0];
            const responseDir2 = dailyData[1];

            const year = responseDir1.data.year;

            let annualCo2 = 0
            year.map((yr, idx) => {
                if (parseInt(yr) === parseInt(endYear)) {  //TODO: A temp fix calculation problem
                    annualCo2 = responseDir1.data.annual_co2[idx] + responseDir2.data.annual_co2[idx];
                }
                return yr
            })
            co2Data.push(annualCo2)

            formData.map(d => {
                if (d.baseId === alternative.alternative_id) {
                    d.annualCo2 = annualCo2
                }
                return d
            })
            if ((newArr.length - 1) === index) {
                setCo2Fetched(true)
            } else {
                setCo2Fetched(false)
            }
        }

        setIsLoading(false)
        setFormData(formData)
        setCo2List(co2Data)
    }

    useEffect(() => {

        if (endYear >= MIN_YEAR && MAX_YEAR >= endYear) {
            setData()
        }
        // eslint-disable-next-line
    }, [currency, presumptions, formData])

    useEffect(() => {

        if (endYear >= MIN_YEAR && MAX_YEAR >= endYear) {
            setData(false)
        }
        // eslint-disable-next-line
    }, [endYear, baseId])

    useEffect(() => {
        const firstPart = formData.filter(a => a.alternative_id === baseId)
        const secondPart = formData.filter(a => a.alternative_id !== baseId)
        setFormData([...firstPart, ...secondPart])
        // eslint-disable-next-line
    }, [baseId])

    useEffect(() => {
        if (!!formData && !!formData.length) {
            const labels = []
            const investments = []
            const maintainance = []
            const newCo2List = []
            formData.map(d => {
                investments.push(d.investment_emssn)
                maintainance.push(d.maintainance_emssn)
                labels.push(!!baseId && (baseId === d.alternative_id) ? `${d.name} (Base)` : d.name)
                newCo2List.push(d.annualCo2 || 0)
                d.annualCo2 = d.annualCo2 || 0
                return d
            })
            setFormData(formData)
            setCo2List(newCo2List)
            setLabelList(labels)
            setInvestData(investments)
            setMaintainData(maintainance)
        }

        // eslint-disable-next-line
    }, [formData])

    const options = {
        tooltips: {
            titleFontSize: 20,
            bodyFontSize: 20,
            callbacks: {
                label: function (tooltipItem, data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += (Math.round(tooltipItem.yLabel * 100) / 100).toLocaleString();
                    return label;
                }
            }
        },
        scales: {
            xAxes: [
                {
                    ...scaleXCommonConfig('Alignment name')
                }
            ],
            yAxes: [
                {
                    ...scaleYCommonConfig('Total CO2 (kg)'),
                    afterBuildTicks: function (mychart) {
                        const yLabels = mychart.ticks;
                        mychart.options.ticks.callback = function (label) {
                            return (yLabels).includes(label) ? label.toLocaleString() : '';
                        }
                    }
                },
            ],
        },
    };

    const data = {
        labels: labelList,
        datasets: [
            {
                label: 'Usage',
                data: co2List,
                backgroundColor: BLUE_1,
                stack: 'Stack 0',
            },
            {
                label: 'Investments',
                data: investData,
                backgroundColor: AQUA,
                stack: 'Stack 0',
            },
            {
                label: 'Maintenance',
                data: maintainData,
                backgroundColor: GREEN_3,
                stack: 'Stack 0',
            },
        ],
    }

    return (
        <div className="response-graph-holder center-flex">
            <Bar data={data} options={options} />
        </div>
    )
}

export default GraphSection