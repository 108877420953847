import color from '../assets/scss/common.module.scss'

export const colorCodes = {
  WHITE_1: color.white1,
  WHITE_2: color.white2,
  BLACK_1: color.black1,
  RICH_BLACK: color.richBlack,
  TEAL: color.teal,
  BLUE_1: color.blue1,
  BLUE_2: color.blue2,
  BLUE_3: color.blue3,
  BLUE_4: color.blue4,
  BLUE_5: color.blue5,
  BLUE_6: color.blue6,
  GREEN_1: color.green1,
  GREEN_2: color.green2,
  GREEN_3: color.green3,
  GREEN_4: color.green4,
  GREEN_5: color.green5,
  LIGHT_GREEN: color.lightGreen,
  LIGHT_CYAN: color.lightCyan,
  RED_1: color.red1,
  RED_2: color.red2,
  GREY_1: color.grey1,
  GREY_2: color.grey2,
  GREY_3: color.grey3,
  GREY_4: color.grey4,
  YELLOW_1: color.yellow1,
  AQUA: color.aqua,
  WATER_SPOUT: color.waterSpout
}
